import {
  type InputChipOption,
  InputChips,
  InputText,
  InfoBox,
  InputSelect,
  InputQuantity,
  Loading,
  InputTextArea,
  useBreakpoint,
  useToastr,
} from '@farmshare/ui-components';
import {
  formatToCurrency,
  RecursivePartial,
  capitalize,
  inspectionLevelHelper,
  getMeasurementLabel,
} from '@farmshare/utils';
import { type FormikProps } from 'formik';
import {
  filter,
  find,
  groupBy,
  map,
  orderBy,
  range,
  sortBy,
  startCase,
} from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';

import {
  EnumPrimalCutType,
  type ProcessorCapability,
  type PrimalCut,
  ProcessorSettings,
  EnumProcessorSchedulingAnimalSpecies,
  usePrimalCutBlockerManyQuery,
  EnumCutsheetInspectionLevels,
  ViewSchedulingCreateQuery,
  EnumCutsheetAnimalSpecies,
  Cutsheet,
} from 'lib/graphql';

import {
  ExtrasModel,
  ExtrasTrimOptionsType,
  TrimModel,
} from 'pages/processor/cutsheets/_views/cutsheet-modal';

import { CustomizeCutsheetButton } from './customize-cutsheet-button';

export interface SchedulingCustomCutsheetModalForm {
  name: string;
  cuts: InputChipOption[];
  inspectionLevel?: EnumCutsheetInspectionLevels;
  notes?: Record<string, string>;
  pricePerPound?: number;
  selectedSpecifications: Record<string, string | number>;
  activeSpecification?: string;
  extras?: ExtrasTrimOptionsType['extras'];
  trim?: ExtrasTrimOptionsType['trim'];
  blockedBy: { name: string; primalCutId: string; toggledBy: string[] }[];
}

interface SchedulingCustomCutsheetModalProps {
  fP: FormikProps<SchedulingCustomCutsheetModalForm>;
  capabilities?: RecursivePartial<ProcessorCapability>[];
  animalSpecies?: EnumProcessorSchedulingAnimalSpecies;
  settings?: RecursivePartial<ProcessorSettings>;
  isReadOnly?: boolean;
  canCustomizeCutsheet?: boolean;
  cutsheetId?: string;
  cutsheet?: Cutsheet;
  userCutsheets?: ViewSchedulingCreateQuery['cutsheetMany'];
  setUserCutsheets?: (
    newUserCutsheets: ViewSchedulingCreateQuery['cutsheetMany'],
  ) => void;
}

export function SchedulingCustomCutsheetModal({
  fP,
  capabilities,
  settings,
  animalSpecies,
  isReadOnly,
  canCustomizeCutsheet,
  cutsheetId,
  cutsheet,
  userCutsheets,
  setUserCutsheets,
}: SchedulingCustomCutsheetModalProps) {
  const breakpoint = useBreakpoint();
  const { push } = useToastr();
  const { data, loading } = usePrimalCutBlockerManyQuery({
    variables: {
      filter: {
        vendor: settings?.vendor?._id,
      },
    },
  });

  const inspectionLevelOptions = useMemo(() => {
    const animalSetting = settings?.animalSettings?.find(
      (o) => o?.species?.toString() === animalSpecies?.toString(),
    );

    return sortBy(
      map(animalSetting?.inspectionLevels, (inspectionLevel) => {
        const inspectionOption = inspectionLevelHelper(inspectionLevel ?? '');
        return { ...inspectionOption, sortValue: inspectionOption.order };
      }),
      'sortValue',
    );
  }, [animalSpecies, settings?.animalSettings]);

  const getCutOption: (
    cut: PrimalCut,
    isReadOnly?: boolean,
  ) => InputChipOption = useCallback(
    (cut: PrimalCut) => {
      if (isReadOnly) {
        return {
          label: cut.name,
          value: cut._id,
          disabled: true,
        };
      }

      let disabled = false;

      const foundBlockedByItem = find(
        fP.values.blockedBy,
        (blockedCut) => blockedCut.primalCutId === cut._id,
      );
      // if we find the cut in the blockedBy array and the toggledBy array is not empty then we disable the cut as it's been toggled by another cut
      if (foundBlockedByItem) {
        disabled = foundBlockedByItem?.toggledBy?.length > 0;
      }

      return {
        label: cut.name,
        value: cut._id,
        disabled,
      };
    },
    [fP.values.blockedBy, isReadOnly],
  );

  const renderSubGroup = useCallback(
    (cuts: PrimalCut[], label: string) => {
      const animalSpecies = cuts[0].primal?.group?.animalSpecies;
      const inspectionLevel = cuts[0].primal?.group?.inspectionLevel;
      const blockedBy = filter(data?.primalCutBlockerMany, (cutBlocker) => {
        return (
          cutBlocker.animalSpecies === animalSpecies &&
          cutBlocker.inspectionLevel === inspectionLevel &&
          cutBlocker.type === label
        );
      });

      return (
        <div key={label}>
          <div className="mb-1 fs-5">{label}</div>
          <InputChips
            nameOveride="cuts"
            options={map(orderBy(cuts, 'order'), (cut) => getCutOption(cut))}
            size="sm"
            action={(item) => {
              // toggle the blockedBy cuts if the cut is selected
              if (blockedBy.length > 0) {
                const cutBlocker = find(blockedBy, (blockedCut) => {
                  return blockedCut.name === item.label;
                });
                if (cutBlocker) {
                  // to toggle the cuts we need to check if all values in the blockedBy array are already set in the form state
                  const blockedByValuesSet = fP.values.blockedBy;
                  const blockedBy = cutBlocker.blockedBy ?? [];
                  let foundBlockedByItems = true; // toggle flag

                  // check if all blockedBy items are already set in the blockedBy form state
                  for (const element of blockedBy) {
                    if (
                      !find(
                        blockedByValuesSet,
                        (blockedByItem) =>
                          blockedByItem.primalCutId === element?.primalCutId,
                      )
                    ) {
                      foundBlockedByItems = false;
                      break;
                    }
                  }

                  if (foundBlockedByItems) {
                    // toggle cut blocker from the toggledBy property
                    const updatedBlockByValues = [...blockedByValuesSet];

                    for (const element of updatedBlockByValues) {
                      if (
                        find(
                          blockedBy,
                          (blockedByItem) =>
                            blockedByItem?.primalCutId === element?.primalCutId,
                        )
                      ) {
                        const toggledBy = element.toggledBy;

                        if (toggledBy.includes(item.value)) {
                          element.toggledBy = filter(
                            toggledBy,
                            (toggledByItem) => toggledByItem !== item.value,
                          );
                        } else {
                          element.toggledBy = [...toggledBy, item.value];
                        }
                      }
                    }

                    fP.setFieldValue(`blockedBy`, updatedBlockByValues);
                  } else {
                    // add cut blocker to blockedBy form state
                    const mappedToggledBy = map(blockedBy, (blockedByItem) => ({
                      name: blockedByItem?.name,
                      primalCutId: blockedByItem?.primalCutId,
                      toggledBy: [item.value],
                    }));
                    fP.setFieldValue(`blockedBy`, [
                      ...(blockedByValuesSet ?? []),
                      ...mappedToggledBy,
                    ]);
                  }
                }
              }

              // toggle the cut
              const cuts = fP.values.cuts;
              const foundCut = find(cuts, { value: item.value });
              if (foundCut) {
                fP.setFieldValue(
                  'cuts',
                  filter(cuts, (o) => o.value !== item.value),
                );
              } else {
                fP.setFieldValue('cuts', [...cuts, item]);
              }
            }}
            required
          />
        </div>
      );
    },
    [data?.primalCutBlockerMany, fP, getCutOption],
  );

  const renderExtraTrimSection = useCallback(
    (label: 'extras' | 'trim') => {
      if (animalSpecies && fP.values?.[label]?.[animalSpecies]) {
        return (
          <div key={label}>
            <div className="fw-bold fs-4 mb-3">{startCase(label)}</div>
            {map(
              fP.values?.[label][animalSpecies],
              (item: ExtrasModel | TrimModel, index: number) => {
                return (
                  <div
                    key={`${animalSpecies}-${item.name}-${index}`}
                    className="gx-1 mb-3 d-flex flex-wrap justify-content-between align-items-center me-3"
                  >
                    <InputChips
                      options={[
                        {
                          label: `${capitalize(item.name)} / ${formatToCurrency(
                            item.pricePerPound,
                          )}/lb ${
                            (item.minLbs ?? 0) > 0
                              ? ` / ${item?.minLbs}lb min `
                              : ''
                          }`,
                          value: item.name,
                          isActive: item.isActive,
                          disabled: isReadOnly,
                        },
                      ]}
                      size="sm"
                      action={() => {
                        if ((item as TrimModel).disabled) {
                          return;
                        }

                        fP.setFieldValue(`${label}.${animalSpecies}.${index}`, {
                          ...item,
                          isActive: !item.isActive,
                        });
                      }}
                      disabled={isReadOnly}
                    />

                    {item.minLbs ? (
                      <InputQuantity
                        nameOveride={`${label}.${animalSpecies}.${index}.quantity`}
                        step={1}
                        min={0}
                        showEdit
                        disabled={
                          (label === 'trim' && (item as TrimModel).disabled) ||
                          isReadOnly
                        }
                      />
                    ) : null}

                    {label === 'trim' && (
                      <div
                        style={{
                          visibility:
                            item.isActive && item.minLbs ? 'visible' : 'hidden',
                        }}
                      >
                        <InputChips
                          options={[
                            {
                              label: 'All trim',
                              value: 'allTrim',
                              isActive: (item as TrimModel).isAllTrim,
                              disabled: isReadOnly,
                            },
                          ]}
                          size="sm"
                          action={() => {
                            const animalSpecie = animalSpecies;
                            const trimOptions = fP.values.trim?.[animalSpecie];

                            if (trimOptions && trimOptions.length > 0) {
                              for (let i = 0; i < trimOptions.length; i++) {
                                const allTrimToggle = !(item as TrimModel)
                                  .isAllTrim;
                                if (i === index) {
                                  // keep option active and set quantity to 0
                                  fP.setFieldValue(
                                    `${label}.${animalSpecie}.${i}`,
                                    {
                                      ...item,
                                      isAllTrim: allTrimToggle,
                                      disabled: allTrimToggle,
                                      ...(allTrimToggle && {
                                        quantity: 0,
                                        rank: 1,
                                      }),
                                    },
                                  );
                                } else {
                                  // disable all other options and set quantity to 0
                                  fP.setFieldValue(
                                    `${label}.${animalSpecie}.${i}`,
                                    {
                                      ...fP.values.trim?.[animalSpecie][i],
                                      isAllTrim: false,
                                      isActive: false,
                                      quantity: 0,
                                      disabled: allTrimToggle,
                                      rank: 1,
                                    },
                                  );
                                }
                              }
                            }
                          }}
                        />
                      </div>
                    )}

                    {label === 'trim' && (
                      <div style={{ width: '75px' }}>
                        <InputText
                          label="Rank"
                          nameOveride={`${label}.${animalSpecies}.${index}.rank`}
                          type="number"
                          size="sm"
                          floatingLabel
                          required={item.isActive}
                          min={1}
                          disabled={
                            (label === 'trim' &&
                              (item as TrimModel).disabled) ||
                            isReadOnly
                          }
                        />
                      </div>
                    )}
                  </div>
                );
              },
            )}
          </div>
        );
      }
    },
    [animalSpecies, fP, isReadOnly],
  );

  const renderGroup = useCallback(
    (cuts: PrimalCut[] = [], label: string) => {
      const subGroups = groupBy(cuts, (c) => {
        let _name = c.primal?.name;
        if (c.type !== EnumPrimalCutType.Other) {
          _name += ` ${startCase(c.type)}s`;
        }
        return _name;
      });

      const groupId = cuts[0]?.primal?.group?._id;

      return (
        <Col key={label}>
          <Card className="h-100" body>
            <Stack gap={2} className="h-100">
              <div className="fw-bold fs-5">{label}</div>
              {map(subGroups, renderSubGroup)}

              <div className="fw-bold fs-5 mt-auto">Notes</div>
              <InputTextArea
                nameOveride={`notes.${groupId}`}
                hint="Add any notes about this group of cuts."
                disabled={isReadOnly}
              />
            </Stack>
          </Card>
        </Col>
      );
    },
    [renderSubGroup, isReadOnly],
  );

  const cutsByInspectionLevel = useMemo(() => {
    if (
      !settings ||
      !animalSpecies ||
      (animalSpecies as string) === 'Select one...'
    ) {
      return (
        <p>
          Please select an animal species and inspection level to display the
          available cuts
        </p>
      );
    }
    if (
      !fP.values.inspectionLevel ||
      (fP.values.inspectionLevel as string) === 'Select one...'
    ) {
      return (
        <p>Please select an inspection level to display the available cuts</p>
      );
    }

    const animalSettings = find(
      settings.animalSettings,
      (o) =>
        (o?.species as unknown as EnumProcessorSchedulingAnimalSpecies) ===
        animalSpecies,
    );
    if (
      !animalSettings ||
      !(animalSettings.inspectionLevels as string[])?.includes(
        fP.values.inspectionLevel,
      )
    ) {
      push({
        title: 'Error',
        body: 'Animal species not found. Please select a valid species.',
        bg: 'danger',
        delay: 5000,
      });

      return null;
    }

    const processorCapabilities = find(capabilities, (capability) => {
      return capability.inspectionLevel === fP.values.inspectionLevel;
    });
    if (!processorCapabilities) {
      push({
        title: 'Error',
        body: 'Processor capabilities not found. Please select a valid inspection level.',
        bg: 'danger',
        delay: 5000,
      });

      return null;
    }

    const activeCuts = filter(
      processorCapabilities?.cuts,
      (cut) => !cut?.isDeleted,
    );

    const groupedCapabilities = groupBy(
      activeCuts,
      (c) => c?.primal?.group?.name,
    );

    return (
      groupedCapabilities && (
        <Row xs={1} lg={2} xl={3} className="g-2">
          {map(groupedCapabilities, renderGroup)}
          <Col>
            <Card className="h-100" body>
              <Stack gap={2}>
                {map(['extras', 'trim'], renderExtraTrimSection)}
              </Stack>
            </Card>
          </Col>
        </Row>
      )
    );
  }, [
    animalSpecies,
    capabilities,
    fP.values.inspectionLevel,
    push,
    renderExtraTrimSection,
    renderGroup,
    settings,
  ]);

  const activeSpecification = useMemo(() => {
    console.log('specifications', capabilities);
    if (animalSpecies && fP.values.inspectionLevel) {
      if (isReadOnly) {
        return cutsheet?.activeSpecification;
      } else {
        return capabilities?.find(
          (pc) =>
            pc.animalSpecies?.toString() === animalSpecies?.toString() &&
            pc.inspectionLevel === fP.values.inspectionLevel,
        )?.activeSpecification;
      }
    }
    return undefined;
  }, [
    fP.values.inspectionLevel,
    animalSpecies,
    isReadOnly,
    cutsheet?.activeSpecification,
    capabilities,
  ]);

  useEffect(() => {
    console.log('actove,', activeSpecification);
    fP.setFieldValue('selectedSpecifications', {}).then(() => {
      activeSpecification?.specifications?.forEach((s) => {
        const defaultOption = s?.options?.find((op) => op?.isDefault);
        if (defaultOption) {
          // @ts-ignore
          if (
            s?.name &&
            !(s.name in (fP.values.selectedSpecifications ?? {}))
          ) {
            fP.setFieldValue(
              `selectedSpecifications.${s.name}`,
              defaultOption.name,
            );
          }
        }
      });
      fP.setFieldValue('activeSpecification', activeSpecification?._id);
    });
  }, [activeSpecification]);

  const errorMessage = useMemo(() => {
    const isAnimalSpeciesValid =
      animalSpecies && String(animalSpecies) !== 'Select one...';
    const isInspectionLevelValid =
      fP.values.inspectionLevel &&
      String(fP.values.inspectionLevel) !== 'Select one...';

    if (!settings || !isAnimalSpeciesValid || !isInspectionLevelValid) {
      if (!isAnimalSpeciesValid && !isInspectionLevelValid) {
        return 'Please select both an animal species and an inspection level to display the specifications.';
      } else if (!isAnimalSpeciesValid) {
        return 'Please select an animal species to display the specifications.';
      } else if (!isInspectionLevelValid) {
        return 'Please select an inspection level to display the specifications.';
      }
    }

    return ''; // Return an empty string when there's no error
  }, [animalSpecies, fP.values.inspectionLevel, settings]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="fs-5 fw-bold mb-2">Details</div>
      <Row className="g-2">
        <Col xs={12} lg={4}>
          <InputText
            label="Name"
            type="text"
            hint="Make this name something that is recognizable to you."
            floatingLabel
            required
            disabled={isReadOnly}
          />
        </Col>

        <Col xs={12} lg={4}>
          <InputSelect
            label="Inspection Level"
            nameOveride="inspectionLevel"
            options={inspectionLevelOptions}
            floatingLabel
            required
            disabled={isReadOnly}
          />
        </Col>
        <Col xs={0} lg={2}></Col>
        {canCustomizeCutsheet && setUserCutsheets && userCutsheets && (
          <Col xs={12} lg={2}>
            {['xs', 'sm', 'md'].includes(breakpoint) ? (
              <CustomizeCutsheetButton
                fP={fP}
                cutsheetId={cutsheetId}
                cutsheet={cutsheet}
                settings={settings}
                capabilities={capabilities}
                animalSpecies={animalSpecies}
                setUserCutsheets={setUserCutsheets}
                userCutsheets={userCutsheets}
              />
            ) : (
              <div className="d-flex justify-content-end">
                <CustomizeCutsheetButton
                  fP={fP}
                  cutsheetId={cutsheetId}
                  cutsheet={cutsheet}
                  settings={settings}
                  capabilities={capabilities}
                  animalSpecies={animalSpecies}
                  setUserCutsheets={setUserCutsheets}
                  userCutsheets={userCutsheets}
                />
              </div>
            )}
          </Col>
        )}
      </Row>
      <hr />
      <div className="fs-5 fw-bold mb-2">Specifications</div>
      {errorMessage && <p>{errorMessage}</p>}
      <Row xs={1} lg={4} className="g-2">
        {activeSpecification?.specifications
          ?.filter((sp) => sp?.isVisible)
          .map((sp) => (
            <Col key={String(sp?._id)}>
              <InputSelect
                label={`${sp?.name} ${
                  sp?.measurementUnit && sp?.measurementUnit !== 'none'
                    ? `(${getMeasurementLabel(sp?.measurementUnit)})`
                    : ''
                }`}
                options={map(
                  sp?.options?.filter((op) => op?.isVisible) ?? [],
                  (value) => ({
                    label: `${value?.name} ${getMeasurementLabel(
                      sp?.measurementUnit,
                    )}`,
                    value: value?.name || '',
                  }),
                )}
                nameOveride={`selectedSpecifications.${sp?.name}`}
                floatingLabel
                required={sp?.isRequired}
                disabled={isReadOnly}
              />
            </Col>
          ))}
      </Row>
      <hr />
      <div className="fs-5 fw-bold mb-2">Cuts</div>
      {!isReadOnly &&
        fP.values.inspectionLevel &&
        (fP.values.inspectionLevel as string) !== 'Select one...' && (
          <InfoBox
            content={
              <>
                Select all cuts that you would like. Every unselected cut will
                default to ground meat.
              </>
            }
          />
        )}

      {cutsByInspectionLevel}
    </>
  );
}
