import { InputSelect } from '@farmshare/ui-components';
import { FormikProps } from 'formik';
import { map } from 'lodash';
import { Stack } from 'react-bootstrap';

import { PrimalCut } from 'lib/graphql';

export interface EditCutBlockersModalForm {
  cutBlockers: (PrimalCut & { group?: string })[];
  selectedCutBlockers: string[];
}

interface EditCutBlockersModalProps {
  formikProps: FormikProps<EditCutBlockersModalForm>;
}

export function EditCutBlockersModal({
  formikProps,
}: EditCutBlockersModalProps) {
  return (
    <Stack gap={3}>
      <InputSelect
        label="Cut 1"
        nameOveride="selectedCutBlockers.0"
        options={map(formikProps.values.cutBlockers, (cut) => ({
          label: cut.name,
          value: cut._id,
          group: cut.group,
        }))}
        floatingLabel
      />
      <InputSelect
        label="Cut 2"
        nameOveride="selectedCutBlockers.1"
        options={map(formikProps.values.cutBlockers, (cut) => ({
          label: cut.name,
          value: cut._id,
          group: cut.group,
        }))}
        floatingLabel
      />
      <InputSelect
        label="Cut 3"
        nameOveride="selectedCutBlockers.2"
        options={map(formikProps.values.cutBlockers, (cut) => ({
          label: cut.name,
          value: cut._id,
          group: cut.group,
        }))}
        floatingLabel
      />
    </Stack>
  );
}
