import { OperationVariables } from '@apollo/client';
import { useModal, useToastr } from '@farmshare/ui-components';
import { generateTrackingNumber } from '@farmshare/utils';
import {
  faFileDownload,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import { find, map, reduce } from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';

import {
  EnumProcessorSchedulingStatus,
  ProcessorScheduling,
  ProcessorSchedulingAnimalHeads,
  ProcessorSchedulingCalendarDocument,
  useProcessorSchedulingUpdateByIdMutation,
} from 'lib/graphql';

import { DownloadCarcassTagsModal } from '../_views/modals/download-carcass-tags-modal';
import {
  DroppedOffModal,
  DroppedOffModalProps,
} from '../_views/modals/dropped-off-modal';
import {
  GenerateInvoiceModal,
  GenerateInvoiceModalFormProps,
} from '../_views/modals/generate-invoice-modal';
import {
  KilledModal,
  KilledModalFormProps,
} from '../_views/modals/killed-modal';

export function useStatusChanges<T extends OperationVariables>(
  getVariables: () => T,
) {
  const { save, info } = useModal();
  const { push } = useToastr();
  const [updateScheduling, updateSchedulingOp] =
    useProcessorSchedulingUpdateByIdMutation({
      refetchQueries: [
        {
          query: ProcessorSchedulingCalendarDocument,
          variables: getVariables(),
        },
      ],
    });

  const handleInvoicing = useCallback(
    (
      scheduledJob: ProcessorScheduling,
      callback?: (newStatus: EnumProcessorSchedulingStatus) => void,
    ) => {
      save<GenerateInvoiceModalFormProps>({
        backdrop: 'static',
        type: 'save',
        size: 'lg',
        icon: faFileInvoiceDollar,
        title: 'Generate Invoice',
        initialValues: {
          animalHeads: map(scheduledJob.animalHeads, (ah) => ({
            _id: ah?._id,
            additionalCost: undefined,
            additionalCostReason: undefined,
          })),
          additionalPickupDetails: undefined,
          isPayAtPickup: false,
        },
        body: <GenerateInvoiceModal scheduledJob={scheduledJob} />,
        onSubmit: async (form) => {
          const animalHeadsWithInvoicing = reduce(
            scheduledJob.animalHeads,
            (acc: Partial<ProcessorSchedulingAnimalHeads>[], animalHead) => {
              const formAnimalHead = find(
                form.animalHeads,
                (ah) => ah._id === animalHead?._id,
              );
              if (animalHead) {
                acc.push({
                  _id: formAnimalHead?._id,
                  invoice: {
                    additionalCost: {
                      cost: formAnimalHead?.additionalCost,
                      reason: formAnimalHead?.additionalCostReason,
                    },
                  },
                });
              }
              return acc;
            },
            [],
          );
          await updateScheduling({
            variables: {
              id: scheduledJob._id,
              record: {
                status: EnumProcessorSchedulingStatus.Invoicing,
                invoice: {
                  isPayAtPickup: form.isPayAtPickup,
                },
                additionalPickupDetails: form.additionalPickupDetails,
                animalHeads: animalHeadsWithInvoicing,
              },
            },
          });

          callback?.(EnumProcessorSchedulingStatus.Invoicing);
        },
      });
    },
    [save, updateScheduling],
  );

  const handleDroppedOff = useCallback(
    (
      scheduledJob: ProcessorScheduling,
      callback?: (newStatus: EnumProcessorSchedulingStatus) => void,
    ) => {
      save<DroppedOffModalProps>({
        backdrop: 'static',
        type: 'save',
        size: 'lg',
        title: 'Live Weight',
        initialValues: {
          // doing this because dealing with the graphql type here is not fun. So we convert to a format for the form
          animalHeads: map(scheduledJob.animalHeads, (ah) => ({
            _id: ah?._id,
            liveWeight: ah?.liveWeight,
          })),
        },
        body: <DroppedOffModal scheduledJob={scheduledJob} />,
        onSubmit: async (form) => {
          const animalHeadsWithLiveWeight = reduce(
            scheduledJob.animalHeads,
            (acc: Partial<ProcessorSchedulingAnimalHeads>[], animalHead) => {
              const formAnimalHead = find(
                form.animalHeads,
                (ah) => ah._id === animalHead?._id,
              );
              if (animalHead) {
                acc.push({
                  _id: formAnimalHead?._id,
                  liveWeight: formAnimalHead?.liveWeight,
                });
              }
              return acc;
            },
            [],
          );
          try {
            await updateScheduling({
              variables: {
                id: scheduledJob._id,
                record: {
                  status: EnumProcessorSchedulingStatus.DroppedOff,
                  animalHeads: animalHeadsWithLiveWeight,
                },
              },
            });
            callback?.(EnumProcessorSchedulingStatus.DroppedOff);
          } catch (error) {
            push({
              title: 'Error',
              body: (error as string).toString(),
              bg: 'danger',
              delay: 4000,
            });
          }
        },
      });
    },
    [save, updateScheduling, push],
  );

  const handleKilled = useCallback(
    (
      selectedStatus: EnumProcessorSchedulingStatus,
      scheduledJob: ProcessorScheduling,
      callback?: (newStatus: EnumProcessorSchedulingStatus) => void,
    ) => {
      const initialKillDate = moment().format('yyyy-MM-DD');
      save<KilledModalFormProps>({
        backdrop: 'static',
        type: 'save',
        size: 'lg',
        title: 'Hanging Weight',
        initialValues: {
          // doing this because dealing with the graphql type here is not fun. So we convert to a format for the form
          animalHeads: map(scheduledJob.animalHeads, (ah) => ({
            _id: ah?._id,
            tracking:
              ah?.tracking ??
              generateTrackingNumber({
                animalSpecies: scheduledJob.animalSpecies,
                inspectionLevel: ah?.inspectionLevel ?? '',
                killDate: new Date(initialKillDate),
                uniqueId: ah?.publicId,
              }),
            isHangingWeightPerSide: ah?.isHangingWeightPerSide ?? false,
            hangingWeight: ah?.hangingWeight,
            hangingWeightSideA: ah?.hangingWeightSideA,
            hangingWeightSideB: ah?.hangingWeightSideB,
            killDate: initialKillDate,
            cutDate: undefined,
            liveWeight: ah?.liveWeight,
            yield: '0%',
          })),
        },
        body: <KilledModal scheduledJob={scheduledJob} />,
        onSubmit: async (form) => {
          const animalHeadsWithInvoicing = reduce(
            scheduledJob.animalHeads,
            (acc: Partial<ProcessorSchedulingAnimalHeads>[], animalHead) => {
              const formAnimalHead = find(
                form.animalHeads,
                (ah) => ah._id === animalHead?._id,
              );

              if (animalHead) {
                acc.push({
                  _id: formAnimalHead?._id,
                  tracking: formAnimalHead?.tracking,
                  isHangingWeightPerSide:
                    formAnimalHead?.isHangingWeightPerSide,
                  hangingWeight: formAnimalHead?.isHangingWeightPerSide
                    ? null
                    : formAnimalHead?.hangingWeight,
                  hangingWeightSideA: formAnimalHead?.isHangingWeightPerSide
                    ? formAnimalHead.hangingWeightSideA
                    : null,
                  hangingWeightSideB: formAnimalHead?.isHangingWeightPerSide
                    ? formAnimalHead?.hangingWeightSideB
                    : null,
                  killDate: formAnimalHead?.killDate,
                  cutDate: formAnimalHead?.cutDate,
                  liveWeight: formAnimalHead?.liveWeight,
                  grade: formAnimalHead?.grade,
                });
              }
              return acc;
            },
            [],
          );
          try {
            await updateScheduling({
              variables: {
                id: scheduledJob._id,
                record: {
                  status: EnumProcessorSchedulingStatus.Killed,
                  animalHeads: animalHeadsWithInvoicing,
                },
              },
            });

            callback?.(EnumProcessorSchedulingStatus.Killed);

            if (selectedStatus === EnumProcessorSchedulingStatus.Invoicing) {
              handleInvoicing(scheduledJob, callback);
            } else {
              // Show the download carcass tags modal
              info({
                type: 'info',
                icon: faFileDownload,
                title: 'Carcass Tags',
                size: 'lg',
                body: (modalProps) => (
                  <DownloadCarcassTagsModal
                    scheduledJobId={scheduledJob._id}
                    modalProps={modalProps}
                  />
                ),
              });
            }
          } catch (error) {
            push({
              title: 'Error',
              body: (error as string).toString(),
              bg: 'danger',
              delay: 4000,
            });
          }
        },
      });
    },
    [save, updateScheduling, handleInvoicing, info, push],
  );

  return {
    handleDroppedOff,
    handleKilled,
    handleInvoicing,
    updateScheduling,
    updateSchedulingOp,
  };
}

export type StatusChanges = ReturnType<typeof useStatusChanges>;
