import {
  ErrorMessage,
  Field,
  FieldArray,
  FieldAttributes,
  useFormikContext,
} from 'formik';
import { camelCase, find, findIndex, get, map } from 'lodash';
import { useMemo } from 'react';
import { Form, FormControlProps, Stack } from 'react-bootstrap';

import { InputComponent } from '.';
import { SelectableChip } from '../selectable-chips/selectable-chip';

export type InputChipOption = {
  value: string;
  label: string;
  disabled?: boolean;
  sortValue?: number;
  isActive?: boolean;
};

export interface InputChipsProps extends InputComponent {
  label?: string;
  className?: string;
  size?: 'sm';
  options?: InputChipOption[];
  action?: (option: InputChipOption) => void;
}

export function InputChips({
  disabled,
  hidden,
  label,
  readOnly,
  required,
  className,
  nameOveride,
  size,
  action,
  options = [],
}: InputChipsProps) {
  const { errors, setFieldTouched, values } =
    useFormikContext<Record<string, any>>();

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  const fieldValues = get(values, name) ?? [];

  const fieldProps: FieldAttributes<FormControlProps> = useMemo(
    () => ({
      name,
      disabled,
      hidden,
      readOnly,
      required: required && !readOnly,
      plaintext: readOnly,
      as: Form.Control,
      placeholder: label?.replace(/[?:]/g, ''),
      isInvalid: !!errors[name],
    }),
    [disabled, errors, hidden, label, name, readOnly, required],
  );

  const isSelected = (option: InputChipOption) => {
    const found = find(fieldValues, { value: option.value });
    return Boolean(found || option.isActive);
  };

  return (
    <Form.Group controlId={`form.${name}`} className={className}>
      {label && (
        <Form.Label className="fw-light">
          {label}
          {fieldProps.required && (
            <sup className="text-danger fw-bold">&nbsp;*</sup>
          )}
        </Form.Label>
      )}
      <Stack direction="horizontal" gap={1} className={'flex-wrap'}>
        <FieldArray
          name={name}
          render={({ remove, push }) =>
            map(options, (o) => (
              <Field
                as={SelectableChip}
                isInvalid={!!errors[name]}
                disabled={o.disabled}
                id={o.value}
                key={o.value}
                label={o.label}
                size={size}
                selected={isSelected(o)}
                onSelect={() => {
                  setFieldTouched(name, true);

                  if (action) {
                    return action(o);
                  }

                  const foundIdx = findIndex(fieldValues, {
                    value: o.value,
                  });
                  if (foundIdx > -1) {
                    remove(foundIdx);
                  } else {
                    push(o);
                  }
                }}
              />
            ))
          }
        />
      </Stack>
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {typeof msg === 'string' ? msg : null}
          </Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
}
