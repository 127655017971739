import {
  Button,
  type ButtonProps,
  useModal,
  Loading,
} from '@farmshare/ui-components';
import { RecursivePartial } from '@farmshare/utils';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { filter, cloneDeep } from 'lodash';
import { useMemo } from 'react';

import {
  EnumCutsheetInspectionLevels,
  EnumProcessorSchedulingAnimalSpecies,
  ProcessorCapability,
  usePrimalCutBlockerManyQuery,
  useViewProcessorAdminQuery,
  ViewSchedulingCreateQuery,
  type Cutsheet,
} from 'lib/graphql';

import {
  ExtrasModel,
  ExtrasTrimOptionsType,
  TrimModel,
} from 'pages/processor/cutsheets/_views/cutsheet-modal';
import {
  SchedulingCustomCutsheetModal,
  SchedulingCustomCutsheetModalForm,
} from 'pages/scheduling/_views/custom-cutsheet-modal';

export type CutsheetDetailsProps = ButtonProps & {
  content: string;
  cutsheet: Cutsheet;
  extrasTrimOptions: ExtrasTrimOptionsType;
  capabilities?: RecursivePartial<ProcessorCapability>[];
  animalSpecies?: EnumProcessorSchedulingAnimalSpecies;
  canCustomizeCutsheet: boolean;
  userCutsheets: ViewSchedulingCreateQuery['cutsheetMany'];
  setUserCutsheets: (
    newUserCutsheets: ViewSchedulingCreateQuery['cutsheetMany'],
  ) => void;
};

export function CutsheetDetailsButton({
  content,
  cutsheet,
  extrasTrimOptions,
  canCustomizeCutsheet,
  userCutsheets,
  setUserCutsheets,
  capabilities,
  animalSpecies,
}: CutsheetDetailsProps) {
  const { readOnly } = useModal();
  const settings = useViewProcessorAdminQuery();
  const { data, loading } = usePrimalCutBlockerManyQuery({
    variables: {
      filter: {
        vendor: settings.data?.processorSettingsOne?.vendor?._id,
      },
    },
  });

  // merges the extras and trim options from the cutsheet with the ones from the extrasTrimOptions props
  const duplicateExtrasTrimOptions = useMemo(() => {
    const duplicateExtras = cloneDeep(extrasTrimOptions.extras);
    const duplicateTrim = cloneDeep(extrasTrimOptions.trim);

    if (cutsheet.extras && cutsheet.extras.length > 0) {
      duplicateExtras[cutsheet.animalSpecies] = [
        ...filter(
          [...extrasTrimOptions.extras[cutsheet.animalSpecies]],
          (activeExtra) =>
            !cutsheet.extras?.find(
              (cutsheetExtra) => cutsheetExtra?.name === activeExtra.name,
            ),
        ),
        ...cutsheet.extras,
      ] as ExtrasModel[];
    }
    if (cutsheet.trim && cutsheet.trim.length > 0) {
      duplicateTrim[cutsheet.animalSpecies] = [
        ...filter(
          [...extrasTrimOptions.trim[cutsheet.animalSpecies]],
          (activeTrim) =>
            !cutsheet.trim?.find(
              (cutsheetTrim) => cutsheetTrim?.name === activeTrim.name,
            ),
        ),
        ...cutsheet.trim,
      ] as TrimModel[];
    }

    return { extras: duplicateExtras, trim: duplicateTrim };
  }, [extrasTrimOptions, cutsheet]);

  // disable cuts blocked by other cuts, defined in the processor capabilities tab
  const blockedBy = useMemo(() => {
    let mappedBlockedBy: {
      name: string;
      primalCutId: string;
      toggledBy: string[];
    }[] = [];

    if (data?.primalCutBlockerMany && data.primalCutBlockerMany.length > 0) {
      // for each active cut add the blockedBy cuts to the mappedBlockedBy array, that will be used to disable the cuts blocked by the active cuts
      for (const activeCut of cutsheet.primalCuts) {
        const cutBlocker = data.primalCutBlockerMany.find(
          (cutBlocker) => cutBlocker.primalCutId === activeCut._id,
        );

        if (cutBlocker?.blockedBy && cutBlocker.blockedBy.length > 0) {
          const isCutAlreadyAdded = mappedBlockedBy.find(
            (item) => item.primalCutId === cutBlocker.primalCutId,
          );
          if (!isCutAlreadyAdded) {
            mappedBlockedBy = [
              ...mappedBlockedBy,
              ...(cutBlocker.blockedBy.map((blockedBy) => ({
                name: blockedBy?.name,
                primalCutId: blockedBy?.primalCutId,
                toggledBy: [cutBlocker.primalCutId],
              })) as {
                name: string;
                primalCutId: string;
                toggledBy: string[];
              }[]),
            ];
          }
        }
      }
    }

    return mappedBlockedBy;
  }, [cutsheet.primalCuts, data?.primalCutBlockerMany]);

  const cuts = useMemo(() => {
    let primalCuts = cutsheet.primalCuts;

    // filter out the cuts that are blocked by other cuts
    if (blockedBy.length > 0) {
      primalCuts = primalCuts.filter(
        (cut) =>
          !blockedBy.find(
            (blockedByItem) => blockedByItem.primalCutId === cut._id,
          ),
      );
    }

    return primalCuts.map((c) => ({
      label: c.name,
      value: c._id,
    }));
  }, [blockedBy, cutsheet.primalCuts]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Button
      className="btn-create-new-cutsheet"
      content={content}
      size="sm"
      onClick={() =>
        readOnly<SchedulingCustomCutsheetModalForm>({
          type: 'readOnly',
          title: `${cutsheet.name}`,
          initialValues: {
            name: cutsheet.name,
            selectedSpecifications: cutsheet.selectedSpecifications ?? {},
            cuts,
            extras: duplicateExtrasTrimOptions.extras,
            trim: duplicateExtrasTrimOptions.trim,
            notes: cutsheet.notes,
            blockedBy,
            inspectionLevel: cutsheet
              .inspectionLevels?.[0] as EnumCutsheetInspectionLevels,
          },
          icon: faEye,
          fullscreen: true,
          body: (fP) => (
            <SchedulingCustomCutsheetModal
              isReadOnly
              canCustomizeCutsheet={canCustomizeCutsheet}
              cutsheetId={cutsheet._id}
              cutsheet={cutsheet}
              setUserCutsheets={setUserCutsheets}
              userCutsheets={userCutsheets}
              fP={fP}
              capabilities={capabilities}
              animalSpecies={animalSpecies}
              settings={settings.data?.processorSettingsOne ?? undefined}
            />
          ),
        })
      }
    />
  );
}
