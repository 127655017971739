import { map } from 'lodash';
import { ReactElement, ReactNode, useState } from 'react';
import { Alert, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import styles from './site-header.module.scss';
import { Auth } from '../../components/auth/auth';
import { Button } from '../button/button';

export interface SiteHeaderNavLink {
  label: string;
  href?: string;
  dropDownItems?: {
    label: string;
    href: string;
  }[];
}

interface SiteHeaderProps<TUser> {
  title: ReactNode;
  navLinks: SiteHeaderNavLink[];
  loginFn: () => Promise<void>;
  logoutFn: () => Promise<void>;
  isProduction?: boolean;
  user?: TUser;
  logo: ReactNode;
  dropdownItems?: ReactElement[];
  vendorName: string;
}

export function SiteHeader<
  TUser extends { first_name?: string; picture_url?: string | null },
>(props: SiteHeaderProps<TUser>) {
  const [expanded, setExpanded] = useState(false);

  const SignedOutAuth = () => (
    <div>
      <Button
        content="Log In"
        className={`rounded-pill fw-bold text-primary `}
        onClick={() => props.loginFn()}
        variant="light"
      />
    </div>
  );

  return (
    <>
      {!props.isProduction && window.location.hostname !== 'localhost' && (
        <Alert className="m-1 text-center" variant="success">
          Demo Environment
        </Alert>
      )}
      <Navbar
        id="navbar"
        expand="lg"
        sticky="top"
        className={props.user ? 'bg-body border-bottom' : `bg-primary`}
        expanded={expanded}
      >
        <Container>
          <Navbar.Brand to="/" as={NavLink} className="fw-light">
            {props.logo}
          </Navbar.Brand>
          <Navbar.Toggle
            className={
              !props.user
                ? styles.landingMobileNavbarIcon
                : styles.dashboardMobileNavbarIcon
            }
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <div className="d-lg-flex w-100 justify-content-between align-items-center">
              <Nav
                variant={!props.user ? 'pills' : 'underline'}
                className="d-flex justify-content-center w-100"
              >
                {map(props.navLinks, (navLink, idx) =>
                  navLink.href ? (
                    <Nav.Link
                      key={idx}
                      to={navLink.href || '/'}
                      as={NavLink}
                      onClick={() => setExpanded(false)}
                      className={
                        !props.user
                          ? styles.navLink
                          : navLink.label.toLocaleLowerCase()
                      }
                    >
                      {navLink.label}
                    </Nav.Link>
                  ) : (
                    <NavDropdown
                      key={idx}
                      title={navLink.label}
                      className={
                        !props.user
                          ? styles.navLink
                          : navLink.label.toLocaleLowerCase()
                      }
                      id={`${navLink.label.toLocaleLowerCase()}-dropdown`}
                    >
                      {map(navLink.dropDownItems, (item, idx) => {
                        return (
                          <NavDropdown.Item
                            key={idx}
                            to={item.href || '/'}
                            as={NavLink}
                            onClick={() => setExpanded(false)}
                          >
                            {item.label}
                          </NavDropdown.Item>
                        );
                      })}
                    </NavDropdown>
                  ),
                )}
              </Nav>
              {props.user ? (
                <Auth<TUser>
                  user={props.user}
                  logoutFn={props.logoutFn}
                  dropdownItems={props.dropdownItems}
                  vendorName={props.vendorName}
                />
              ) : (
                <SignedOutAuth />
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}
