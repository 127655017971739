import { useFormikContext } from 'formik';
import {
  camelCase,
  first,
  get,
  has,
  isObject,
  join,
  map,
  sortBy,
} from 'lodash';
import { useCallback, useMemo } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

import { type InputComponent } from '.';

export type InputSelectBarOption =
  | {
      value: string | number;
      label: string;
      order?: number;
      disabled?: boolean;
    }
  | string
  | number;

export interface InputSelectBarProps extends InputComponent {
  options?: InputSelectBarOption[];
}

export function InputSelectBar({
  label,
  nameOveride,
  options,
  className,
}: InputSelectBarProps) {
  const { setFieldValue, values } = useFormikContext<Record<string, string>>();

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  const selectedOption = useMemo(() => {
    if (has(values, name)) {
      return get(values, name);
    }
    return first(options);
  }, [name, options, values]);

  const getClassName = useCallback(
    (option: InputSelectBarOption, index: number) => {
      const styles = ['px-4', 'fw-bold'];

      if (options) {
        if (options.length === 1) {
          styles.push('rounded-4');
        } else if (index === 0) {
          // Give first option radii on left
          styles.push('rounded-start-4');
        } else if (index === options.length - 1) {
          // Give last option radii on right
          styles.push('rounded-end-4');
        }

        if (option === selectedOption) {
          styles.push('active');
        }
      }

      return join(styles, ' ');
    },
    [options, selectedOption],
  );

  const handleSelect = useCallback(
    (option: InputSelectBarOption) =>
      setFieldValue(name, isObject(option) ? option.value : option),
    [name, setFieldValue],
  );

  return (
    <ButtonGroup className={className}>
      {map(sortBy(options, 'order'), (option, i) => (
        <Button
          key={i}
          variant={
            (isObject(option) ? option.value : option) === selectedOption
              ? 'primary'
              : 'outline-primary'
          }
          className={getClassName(option, i)}
          onClick={() => handleSelect(option)}
          type="button"
        >
          {isObject(option) ? option.label : option}
        </Button>
      ))}
    </ButtonGroup>
  );
}
