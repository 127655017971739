import {
  DataDetailListMinimal,
  Loading,
  MoneyDisplay,
  PageTitle,
  useScrollToTop,
} from '@farmshare/ui-components';
import { animalSpeciesHelper } from '@farmshare/utils';
import { filter, find, findIndex, forEach, isNil, map } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import {
  AnimalHeadFragment,
  ComplexAnimalCapacity,
  EnumProcessorSchedulingAnimalSpecies,
  EnumProcessorSettingsAnimalSettingsInspectionLevels,
  EnumProcessorSettingsAnimalSettingsSpecies,
  EnumProcessorSettingsCapacitySettingsDailyCapacitiesType,
  ProcessingJobByIdDocument,
  ProcessorSchedulingAnimalHeadPartialUpdateByIdMutationHookResult,
  useProcessorSchedulingAnimalHeadPartialUpdateByIdMutation,
  useViewProcessingPartnerQuery,
  useProcessorSchedulingManyQuery,
} from 'lib/graphql';
import {
  getCapacity,
  processingJobAnimalHeadLabel,
} from 'lib/processingJobUtils';

import { AnimalHeadCutsheetInformation } from './_views/animal-head-cutsheet-information';
import { AnimalHeadDetails } from './_views/animal-head-details';
import { useProcessingJobPage } from './useProcessingJobPage';

export interface AnimalHeadDetailsSectionProps {
  animalHead: AnimalHeadFragment | undefined | null;
  currentSpeciesInspectionLevels?: string[];
  animalSpecies?: EnumProcessorSchedulingAnimalSpecies;
  processingJobId: string | undefined;
  updateAnimalHead: ProcessorSchedulingAnimalHeadPartialUpdateByIdMutationHookResult['0'];
  updateAnimalHeadOp: ProcessorSchedulingAnimalHeadPartialUpdateByIdMutationHookResult['1'];
}

export function AnimalHead() {
  useScrollToTop();
  const { animalHeadId } = useParams();
  const { processingJobQueryResult, processingJobDisplayName, jobId } =
    useProcessingJobPage();

  const { data: processorSettingsData, loading: processorSettingsLoading } =
    useViewProcessingPartnerQuery({
      variables: {
        vendorId:
          processingJobQueryResult.data?.findProcessorSchedulingById?.vendor
            ?._id,
      },
    });
  const {
    data: processorSchedulingsData,
    loading: processorSchedulingsLoading,
  } = useProcessorSchedulingManyQuery({
    // fetchPolicy: 'network-only',
    variables: {
      filter: {
        vendor:
          processingJobQueryResult.data?.findProcessorSchedulingById?.vendor
            ?._id,
      },
    },
  });
  const momentDate = useMemo(() => {
    if (
      processingJobQueryResult.data?.findProcessorSchedulingById?.dropoffDate
    ) {
      return moment.utc(
        processingJobQueryResult.data?.findProcessorSchedulingById?.dropoffDate,
      );
    }
  }, [processingJobQueryResult.data?.findProcessorSchedulingById?.dropoffDate]);
  const customCapacity = useMemo(() => {
    return find(
      processorSettingsData?.processingPartnersOne?.customCapacities,
      (cc) =>
        // I don't know if this is a bug in lodash typing or expected behavior. However if you don't return a Boolean true or false it incorrectly infers the result type of lodash functions.
        // The momentDate might not be defined so that causes this check to maybe be undefined but we have to force it to a Boolean so lodash types properly and doesn't try to
        // say the result could be the Object | undefined | number
        Boolean(momentDate?.isSame(cc?.date, 'day')),
    );
  }, [
    momentDate,
    processorSettingsData?.processingPartnersOne?.customCapacities,
  ]);
  // This is dependent on if we are using a custom day capacity or not. If so its the type on the custom day capacity else we use whats configured on the processor
  const capacityConfigurationType = useMemo(() => {
    return customCapacity
      ? EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection
      : processorSettingsData?.processingPartnersOne?.capacityConfigurationType;
  }, [
    processorSettingsData?.processingPartnersOne?.capacityConfigurationType,
    customCapacity,
  ]);

  const spotsRemaining: {
    total: number;
    inspectionLevels?: Partial<
      Record<EnumProcessorSettingsAnimalSettingsInspectionLevels, number>
    >;
  } = useMemo(() => {
    const dayOfWeek: string | undefined = moment
      .utc(
        processingJobQueryResult.data?.findProcessorSchedulingById?.dropoffDate,
      )
      ?.format('dddd');
    let dayCapacity: {
      total: number;
      inspectionLevels: Partial<
        Record<EnumProcessorSettingsAnimalSettingsInspectionLevels, number>
      >;
    } = { total: 0, inspectionLevels: {} };
    const defaultDayCapacity =
      processorSettingsData?.processingPartnersOne?.capacitySettings?.find(
        (o) => o?.dayOfWeek === dayOfWeek?.toLowerCase(),
      );
    const animalSpecies =
      processingJobQueryResult.data?.findProcessorSchedulingById?.animalSpecies;

    const animalSetting =
      processorSettingsData?.processingPartnersOne?.animalSettings?.find(
        (o) => o?.species === animalSpecies,
      );

    if (
      animalSpecies &&
      dayOfWeek &&
      momentDate &&
      defaultDayCapacity?.isEnabled
    ) {
      // Update slots based on Processor's custom days
      if (customCapacity) {
        const dailyCapacities: any | undefined = find(
          customCapacity.dailyCapacities,
          (o) =>
            o?.type ===
            EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection,
        );
        dayCapacity = getCapacity(
          // custom day capacity only supports SpeciesInspection capacity configuration right now
          EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection,
          animalSpecies as unknown as EnumProcessorSettingsAnimalSettingsSpecies,
          dailyCapacities?.capacities ?? [],
          animalSetting ?? undefined,
        );
      } else {
        const dayCapacitySetting = find(
          processorSettingsData?.processingPartnersOne?.capacitySettings,
          {
            dayOfWeek: dayOfWeek.toLowerCase(),
          },
        );
        const dailyCapacityConfiguration = find(
          dayCapacitySetting?.dailyCapacities,
          (o) => o?.type === capacityConfigurationType,
        );
        const capacities = dailyCapacityConfiguration?.capacities;

        dayCapacity = getCapacity(
          capacityConfigurationType,
          animalSpecies as unknown as EnumProcessorSettingsAnimalSettingsSpecies,
          capacities as ComplexAnimalCapacity[],
          animalSetting ?? undefined,
        );
      }

      const currentSchedulings = filter(
        processorSchedulingsData?.processorSchedulingMany,
        (psm) => {
          const datesMatch = momentDate?.isSame(psm.dropoffDate, 'day');

          if (
            capacityConfigurationType ===
            EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.WeightedAnimalUnit
          ) {
            return datesMatch;
          }
          return psm.animalSpecies === animalSpecies && datesMatch;
        },
      );
      // Update slots based on already scheduled appointments
      forEach(currentSchedulings, (currentScheduling) => {
        // we subtract from the total.
        dayCapacity.total -= currentScheduling.headCount;

        // we will optionally subtract from the sub counts of inspection levels
        if (
          capacityConfigurationType ===
          EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection
        ) {
          forEach(currentScheduling.animalHeads, (o) => {
            if (
              o?.inspectionLevel &&
              Object.keys(dayCapacity.inspectionLevels).includes(
                o.inspectionLevel,
              )
            ) {
              dayCapacity.inspectionLevels[o.inspectionLevel] =
                (dayCapacity.inspectionLevels[o.inspectionLevel] ?? 0) - 1;
            }
          });
        }
      });
    }

    return dayCapacity;
  }, [
    processorSettingsData?.processingPartnersOne,
    momentDate,
    processorSchedulingsData?.processorSchedulingMany,
    processingJobQueryResult.data?.findProcessorSchedulingById,
    capacityConfigurationType,
    customCapacity,
  ]);

  const [updateAnimalHead, updateAnimalHeadOp] =
    useProcessorSchedulingAnimalHeadPartialUpdateByIdMutation({
      refetchQueries: [
        {
          query: ProcessingJobByIdDocument,
          variables: {
            jobId,
            includeNotificationHistory: true,
            includeProcessorSettings: true,
          },
        },
      ],
    });

  const animalHead = useMemo(() => {
    return find(
      processingJobQueryResult.data?.findProcessorSchedulingById?.animalHeads,
      (o) => o?._id === animalHeadId,
    );
  }, [
    animalHeadId,
    processingJobQueryResult.data?.findProcessorSchedulingById?.animalHeads,
  ]);

  const animalSpeciesHelped = useMemo(
    () =>
      animalSpeciesHelper(
        processingJobQueryResult.data?.findProcessorSchedulingById
          ?.animalSpecies ?? '',
      ),
    [processingJobQueryResult.data?.findProcessorSchedulingById?.animalSpecies],
  );

  const animalHeadDisplayName = useMemo(() => {
    const foundIdx = findIndex(
      processingJobQueryResult.data?.findProcessorSchedulingById?.animalHeads,
      (o) => o?._id === animalHeadId,
    );

    return processingJobAnimalHeadLabel({
      producerIdentifier: animalHead?.producerIdentifier,
      requestedBy: {
        first_name: '',
        last_name: '',
      },
      animalNumber: foundIdx + 1,
      animalSpecies:
        processingJobQueryResult.data?.findProcessorSchedulingById
          ?.animalSpecies,
      inspectionLevel: animalHead?.inspectionLevel ?? undefined,
    });
  }, [
    processingJobQueryResult.data?.findProcessorSchedulingById?.animalSpecies,
    processingJobQueryResult.data?.findProcessorSchedulingById?.animalHeads,
    animalHead?.producerIdentifier,
    animalHeadId,
    animalHead?.inspectionLevel,
  ]);

  const currentSpeciesInspectionLevels = useMemo(() => {
    const found = find(
      processingJobQueryResult.data?.findProcessorSchedulingById
        ?.processorSettings?.animalSettings,
      (o) => o?.species === animalSpeciesHelped.value,
    );

    // All this work to make sure I get back an array of only the enums.
    // Not null or undefined or an array with null or undefined in it.
    // NOTE: It is not possible for this to ever have null or undefined mixed into the array.
    // Only return the inspection levels that have spots remaining.
    return (filter(found?.inspectionLevels, (o) => {
      if (isNil(o)) {
        return false;
      }

      //if by weighted animal (not by inspection level), you can always select and inspection level
      const spotsRemainingForInspectionLevel =
        spotsRemaining.inspectionLevels?.[o] ?? 1;

      return spotsRemainingForInspectionLevel > 0;
    }) ?? []) as EnumProcessorSettingsAnimalSettingsInspectionLevels[];
  }, [
    processingJobQueryResult.data?.findProcessorSchedulingById
      ?.processorSettings?.animalSettings,
    animalSpeciesHelped.value,
    spotsRemaining.inspectionLevels,
  ]);

  if (
    processingJobQueryResult.loading ||
    processorSettingsLoading ||
    processorSchedulingsLoading
  ) {
    return <Loading />;
  }

  return (
    <Container className="pt-3">
      <PageTitle
        title={animalHeadDisplayName}
        showHr={false}
        // TODO only show this if they are the processor.
        // Other users will need to go elsewhere.
        innerBreadcrumbs={[
          { text: 'Bookings', to: '/processor/agenda' },
          { text: processingJobDisplayName, to: `/processing-job/${jobId}` },
        ]}
      />
      <Container>
        <Stack gap={3}>
          <Row className="g-4">
            <Col>
              <AnimalHeadDetails
                animalHead={animalHead}
                animalSpecies={
                  processingJobQueryResult.data?.findProcessorSchedulingById
                    ?.animalSpecies
                }
                currentSpeciesInspectionLevels={currentSpeciesInspectionLevels}
                processingJobId={jobId}
                updateAnimalHead={updateAnimalHead}
                updateAnimalHeadOp={updateAnimalHeadOp}
              />
            </Col>
          </Row>
          <Row className="g-4">
            <Col>
              <Card className="h-100">
                <Card.Header className="d-flex align-items-center">
                  <span className="fw-bold fs-5">Invoice Information</span>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>
                      <DataDetailListMinimal
                        rows={[
                          {
                            label: 'Additional Cost',
                            value: (
                              <MoneyDisplay
                                value={
                                  animalHead?.invoice?.additionalCost?.cost
                                }
                              />
                            ),
                          },
                          {
                            label: 'Additional Cost Details',
                            value: animalHead?.invoice?.additionalCost?.reason,
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="g-4">
            {map(animalHead?.cutsheetInformation, (ci, idx) => {
              return (
                <Col lg={6} key={idx}>
                  <AnimalHeadCutsheetInformation
                    cutsheetInformationIdx={idx}
                    animalHead={animalHead}
                    cutsheetInformation={ci}
                    processingJobId={jobId}
                    updateAnimalHead={updateAnimalHead}
                    updateAnimalHeadOp={updateAnimalHeadOp}
                  />
                </Col>
              );
            })}
          </Row>
        </Stack>
      </Container>
    </Container>
  );
}
