import { assign, join, map, split } from 'lodash';
import { ReactNode, useEffect } from 'react';
import { Breadcrumb, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Button, type ButtonProps } from '../button/button';

interface PageTitleProps {
  title: ReactNode;
  subTitle?: ReactNode;
  breadCrumbLabel?: string;
  actionButtons?: ButtonProps[];
  innerBreadcrumbs?: { text: string; to: string }[];
  ellipsis?: ReactNode;
  showHr?: boolean;
  // Set to false if you only want document.title to change for page tracking
  show?: boolean;

  // Set to false if you don't want to render breadcrumbs
  showBreadCrumbs?: boolean;
}

export function PageTitle({
  title,
  subTitle,
  actionButtons,
  innerBreadcrumbs,
  breadCrumbLabel,
  ellipsis,
  showHr = true,
  show = true,
  showBreadCrumbs = true,
}: PageTitleProps) {
  useEffect(() => {
    if (title) {
      const delimiter = ' | ';
      const [base] = split(window.document.title, delimiter);
      window.document.title = join([base, breadCrumbLabel || title], delimiter);
    }
  }, [breadCrumbLabel, title]);

  if (!show) {
    return;
  }

  return (
    <Container>
      <div className="d-flex align-items-center gap-2">
        <h2 className="flex-grow-1 fw-bold">{title}</h2>
        {subTitle && <div>{subTitle}</div>}
        {map(actionButtons, (actionButton, idx) => (
          <div key={idx}>
            <Button {...assign({}, actionButton)} />
          </div>
        ))}
      </div>
      {showBreadCrumbs && (
        <div className="d-flex align-items-center gap-2">
          <Breadcrumb className="flex-grow-1" listProps={{ className: 'mb-0' }}>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
              Home
            </Breadcrumb.Item>
            {map(innerBreadcrumbs, ({ text, to }, idx) => (
              <Breadcrumb.Item key={idx} linkAs={Link} linkProps={{ to }}>
                {text}
              </Breadcrumb.Item>
            ))}
            <Breadcrumb.Item active>{breadCrumbLabel || title}</Breadcrumb.Item>
          </Breadcrumb>
          {ellipsis}
        </div>
      )}

      {showHr ? <hr /> : <div className="mb-3" />}
    </Container>
  );
}
