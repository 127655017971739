import { Request } from 'express';
import {
  includes,
  isNaN,
  isNull,
  isUndefined,
  map,
  mapValues,
  wrap,
} from 'lodash';
import moment, { Moment, isMoment } from 'moment';
import numeral, { Numeral, isNumeral } from 'numeral';

import { Nullable } from './helper-types';

function convertMetersToMiles(meters?: number) {
  if (!meters || isNaN(meters)) {
    return 0;
  }
  return meters / 1609;
}

function getHostFromRequest(req: Request, includeProtocol = false) {
  const host = `${req.hostname}${
    !includes([80, 443], req.socket.localPort) ? `:${req.socket.localPort}` : ''
  }`;
  return includeProtocol ? `${req.protocol}://${host}` : host;
}

function isArrayNullOrEmpty<T>(arr?: T[] | null) {
  if (isNullOrUndefined(arr) || arr?.length === 0) {
    return true;
  }
  return false;
}

function isNullOrUndefined(val?: unknown) {
  return isUndefined(val) || isNull(val);
}

function emptyStringToUndefined(str?: string): string | undefined {
  if (str === '') {
    return undefined;
  }
  return str;
}

/**
 * adds US country code onto phone number
 * @param phoneNumber phone number string
 * @returns phone number w/ non-numeric chars removed
 */
function cleanPhoneNumber(phoneNumber: string): string {
  const cleaned = phoneNumber.replace(/\D/g, '');
  if (/^\+\d+/i.test(cleaned)) {
    return cleaned;
  }
  // assuming all phone numbers are USA for now
  return `+1${cleaned}`;
}

/**
 * Removes the US country code from a phone number.
 *
 * We currently assume that all phone numbers are US based.
 *
 * @param phoneNumber phone number string
 * @returns phone number w/ non-numeric chars removed
 */
function removeCountryCode(phoneNumber?: string): Nullable<string> {
  // Handle a +1 country code
  if (!phoneNumber) {
    return null;
  }

  if (/^\+\d+/.test(phoneNumber)) {
    return phoneNumber.slice(2);
    // handle only having the 1 no +. Still country code
  } else if (/^1/.test(phoneNumber)) {
    return phoneNumber.slice(1);
  }
  return phoneNumber;
}

function formatPhoneNumber(phoneNumber: string) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumber;
}

function formatToCurrency(
  amount?: string | number | Numeral | undefined | null,
): string {
  if (isNullOrUndefined(amount)) {
    return '-';
  }

  if (isNumeral(amount)) {
    return (amount as Numeral).format('$0,0.00');
  }

  const nAmount = numeral(amount);

  if (!isNaN(nAmount)) {
    return nAmount.format('$0,0.00');
  }
  return '-';
}

type TDateType = 'short' | 'long';
function formatToDate(
  type: TDateType,
  date?: string | number | Date | Moment,
): string {
  const lookup: Record<TDateType, string> = { short: 'l', long: 'lll' };

  if (isNullOrUndefined(date)) {
    return '-';
  }

  if (isMoment(date)) {
    return date.format(lookup[type]);
  }

  const mDate = moment.utc(date);

  if (mDate.isValid()) {
    return mDate.format(lookup[type]);
  }
  return '-';
}

const formatToShortDate = wrap('short', formatToDate);
const formatToLongDate = wrap('long', formatToDate);

function removeEmptyProperties(obj: object) {
  return mapValues(obj, (v) => (v !== '' ? v : null));
}

function formatFullName(firstName: string, lastName?: Nullable<string>) {
  return `${firstName} ${lastName ? lastName : ''}`;
}

interface Address {
  address_1?: string;
  address_2?: string;
  city?: string;
  state?: string;
  zip?: string;
  postcode?: string;
}
function formatMailingAddress(address: Address) {
  const parts = [];
  if (address.address_1 && address.address_2) {
    parts.push(`${address.address_1.trim()} ${address.address_2.trim()}`);
  } else if (address.address_1) {
    parts.push(address.address_1);
  }

  if (address.city) {
    parts.push(address.city);
  }

  if (address.state) {
    parts.push(address.state);
  }

  if (address.postcode) {
    parts.push(address.postcode);
  }

  return map(parts, (part) => part.trim()).join(', ');
}

function formatAddressShort(address: Address) {
  return `${(address.address_1 || '').trim()} ${(
    address.address_2 || ''
  ).trim()}`.trim();
}

function formatAddressCityStateZip(address: Address) {
  return `${(address.city || '').trim()}, ${(address.state || '').trim()} ${(
    address.postcode || ''
  ).trim()}`;
}

// this is needed when the startCase function in lodash is not working as expected
function capitalize(str: string) {
  const splitWords = str.split(' ');
  return splitWords
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export {
  cleanPhoneNumber,
  convertMetersToMiles,
  emptyStringToUndefined,
  formatAddressCityStateZip,
  formatAddressShort,
  formatFullName,
  formatMailingAddress,
  formatPhoneNumber,
  formatToCurrency,
  formatToLongDate,
  formatToShortDate,
  getHostFromRequest,
  isArrayNullOrEmpty,
  isNullOrUndefined,
  removeEmptyProperties,
  removeCountryCode,
  capitalize,
};
