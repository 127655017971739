import {
  InputPhone,
  InputText,
  InputDateRange,
  InputSelect,
} from '@farmshare/ui-components';
import { capitalize } from '@farmshare/utils';
import { useFormikContext } from 'formik';
import { Col, Row, Stack } from 'react-bootstrap';

import { EnumProcessorSchedulingAnimalSpecies } from 'lib/graphql';

export type WaitlistFormData = {
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  company?: string;
  animal?: EnumProcessorSchedulingAnimalSpecies | '';
  dates: { start: string; end: string };
};

export function WaitListModal() {
  const { touched, values } = useFormikContext<WaitlistFormData>();

  return (
    <Stack gap={1}>
      <Row>
        <Col xs={12} md={6} className="px-2 pb-3">
          <InputText
            type="text"
            label="First Name"
            floatingLabel
            nameOveride="firstName"
            required
          />
        </Col>

        <Col xs={12} md={6} className="px-2 pb-3">
          <InputText
            type="text"
            label="Last Name"
            floatingLabel
            nameOveride="lastName"
            required
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6} className="px-2 pb-3">
          <InputPhone
            label="Phone"
            floatingLabel
            nameOveride="phone"
            required={touched.phone && (!values.email || !!values.phone)}
          />
        </Col>

        <Col xs={12} md={6} className="px-2 pb-3">
          <InputText
            type="email"
            label="Email"
            floatingLabel
            nameOveride="email"
            required={touched.email && (!values.phone || !!values.email)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6} className="px-2 pb-3">
          <InputSelect
            label="Animal"
            options={Object.values(EnumProcessorSchedulingAnimalSpecies).map(
              (value) => ({ label: capitalize(value), value }),
            )}
            floatingLabel
            nameOveride="animal"
            required
          />
        </Col>

        <Col xs={12} md={6} className="px-2 pb-3">
          <InputText
            type="text"
            label="Company/Farm"
            floatingLabel
            nameOveride="company"
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3"></Col>
        <Col>
          <p className="fs-6 fw-bold">Date Range:</p>
          <InputDateRange nameOveride="dates" step={6} />
        </Col>
      </Row>
    </Stack>
  );
}
