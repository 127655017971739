import { Button, Form, InputText } from '@farmshare/ui-components';
import { faMessage, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Types } from 'mongoose';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import {
  ChatMessage,
  EnumUserRole,
  useChatRoomCreateOneMutation,
  useMessagesByChatRoomQuery,
  User,
  useSendMessageMutation,
} from 'lib/graphql';

import { renderTenant, renderUser } from './utils';

export default function ChatMessageList({
  myChatRoomsCount,
  selectedRoomId,
  vendorId,
  customerId,
  customerAsUser,
  onChatRoomCreated,
}: {
  myChatRoomsCount: number | undefined | null;
  selectedRoomId?: string;
  vendorId?: string;
  customerId?: string;
  customerAsUser?: boolean;
  onChatRoomCreated?: (id: string) => void;
}) {
  function renderMessageDate(date: Date) {
    if (moment(date).isSame(moment(), 'day')) return 'Today';
    if (moment(date).isSame(moment(), 'week'))
      return moment(date).format('dddd');
    if (moment(date).isSame(moment(), 'year'))
      return moment(date).format('ddd, MMM D');
    return moment(date).format('ddd, MMM D, YYYY');
  }

  const PER_PAGE = 100;

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const user = useRecoilValue(userState);
  const userId = customerAsUser ? customerId : user?._id;
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [mounted, setMounted] = useState<boolean>(false);
  const [messageUpdateFrom, setMessageUpdateFrom] = useState<
    'send-message' | 'first-load' | 'more-load' | null
  >(null);

  const {
    data: messageData,
    loading: messageLoading,
    error: messageError,
    refetch: refetchMessages,
    fetchMore,
  } = useMessagesByChatRoomQuery({
    skip: !selectedRoomId,
    pollInterval: 10000,
    variables: {
      chatRoomId: selectedRoomId,
      page: currentPage,
      perPage: PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (messageData?.messagesByChatRoom?.items?.length) {
      setMessages((prev) => {
        const existingIds = new Set(prev.map((msg) => msg?._id));

        const uniqueMessages = messageData?.messagesByChatRoom?.items?.filter(
          (msg) => !existingIds.has(msg?._id),
        );

        return [...prev, ...(uniqueMessages as ChatMessage[])].sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        );
      });
    }
  }, [messageData?.messagesByChatRoom, messagesEndRef, setMounted, mounted]);

  useEffect(() => {
    if (
      messageUpdateFrom === 'first-load' ||
      messageUpdateFrom === 'send-message'
    )
      messagesEndRef.current?.scrollIntoView({
        behavior: messageUpdateFrom === 'send-message' ? 'smooth' : 'instant',
      });
    if (!mounted) {
      setMounted(true);
    }
  }, [messagesEndRef, setMounted, mounted, messages, messageUpdateFrom]);

  useEffect(() => {
    if (selectedRoomId) refetchMessages();
    setMessages([]);
    setMounted(false);
    setMessageUpdateFrom('first-load');
  }, [selectedRoomId, refetchMessages]);

  const [sendMsg, sendMsgOp] = useSendMessageMutation();
  const [createRoom, createRoomOp] = useChatRoomCreateOneMutation();

  async function sendMessage(message: string) {
    try {
      let createdRoom;
      if (!selectedRoomId && customerId && vendorId && user) {
        createdRoom = await createRoom({
          variables: {
            record: {
              vendor: new Types.ObjectId(vendorId),
              createdBy: new Types.ObjectId(user._id),
              participants: [
                new Types.ObjectId(user._id),
                new Types.ObjectId(customerId),
              ],
            },
          },
        });
        onChatRoomCreated?.(createdRoom.data?.chatRoomCreateOne?.record?._id);
      }
      setMessageUpdateFrom('send-message');
      const sentMessage = await sendMsg({
        variables: {
          message,
          chatRoomId: new Types.ObjectId(
            createdRoom?.data?.chatRoomCreateOne?.record?._id || selectedRoomId,
          ),
          ...(customerAsUser ? { customerId } : {}),
        },
      });
      if (sentMessage.data?.sendMessage) {
        setMessages([
          sentMessage.data?.sendMessage as ChatMessage,
          ...messages,
        ]);
      }
    } catch (error) {
      // If sending sms's fail refetch the messages again
      refetchMessages({
        chatRoomId: selectedRoomId,
        page: 1,
        perPage: PER_PAGE,
      });
    }
  }

  const fetchPreviousMessages = useCallback(async () => {
    if (messageLoading || !hasMoreMessages) return;
    const msgs = chatContainerRef.current?.querySelectorAll('.message');

    // Fetch previous page of messages
    setMessageUpdateFrom('more-load');
    fetchMore({
      variables: {
        page: currentPage + 1,
        perPage: PER_PAGE,
      },
    }).then((res) => {
      // Check if there are more messages
      if (res.data.messagesByChatRoom?.items?.length) {
        setCurrentPage((prevPage) => prevPage + 1);
        if (msgs) {
          const lastVisibleMessage: HTMLDivElement = msgs[
            msgs.length - 1
          ] as HTMLDivElement;
          if (lastVisibleMessage) {
            setTimeout(() => {
              if (lastVisibleMessage) {
                // lastVisibleMessage?.scrollIntoView({
                //   behavior: 'instant',
                // });
              }
            }, 0);
          }
        }
        if (res.data.messagesByChatRoom?.items?.length < PER_PAGE) {
          setHasMoreMessages(false);
        }
      }
    });
  }, [currentPage, fetchMore, hasMoreMessages, messageLoading]);

  useEffect(() => {
    const handleScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop } = chatContainerRef.current;
        if (scrollTop === 0) {
          fetchPreviousMessages();
        }
      }
    };

    chatContainerRef?.current?.addEventListener('scroll', handleScroll);
    return () => {
      chatContainerRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [
    messageData,
    messageLoading,
    currentPage,
    hasMoreMessages,
    fetchPreviousMessages,
  ]);

  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <div
        ref={chatContainerRef}
        className="p-3 flex-fill"
        style={{
          overflow: 'auto',
          maxHeight: 'var(--chat-container-height)',
        }}
      >
        {((myChatRoomsCount === 0 && !selectedRoomId) ||
          messages.length === 0) && (
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <FontAwesomeIcon icon={faMessage} size="3x" className="mb-3" />
            Start new conversation
          </div>
        )}
        {messages.length ? (
          <div className="d-flex flex-column-reverse gap-1">
            <div ref={messagesEndRef} />
            {messages?.map((item, i) => {
              const msgDate = renderMessageDate(item.updatedAt);
              const previousMsgDate =
                i !== messages.length - 1
                  ? renderMessageDate(messages[i + 1].updatedAt)
                  : undefined;
              const showDate = msgDate !== previousMsgDate;
              const showUserAvatar =
                userId !== item.sender?._id &&
                (item.sender?._id !== messages[i + 1]?.sender?._id || showDate);
              return [
                <div key={item._id}>
                  {showUserAvatar ? (
                    <div className="mb-2">
                      {item.sender?.role === EnumUserRole.Basic
                        ? renderUser(item.sender)
                        : renderTenant(
                            item.chatRoom?.vendor,
                            item.sender as User,
                          )}
                    </div>
                  ) : undefined}
                  <div
                    className={`message d-flex align-items-end w-fit px-3 py-1 gap-2 rounded-3 ${
                      userId === item.sender?._id
                        ? 'bg-success-subtle'
                        : 'bg-secondary-subtle'
                    }`}
                    style={{
                      width: 'fit-content',
                      marginLeft: userId === item.sender?._id ? 'auto' : '0',
                    }}
                  >
                    {item.content}
                    <span className="text-muted small">
                      {moment(item.updatedAt).format('LT')}
                    </span>
                  </div>
                </div>,
                showDate && (
                  <div
                    className="bg-body-tertiary mx-auto px-4 py-1 rounded-4"
                    style={{ width: 'fit-content' }}
                    key={msgDate}
                  >
                    {msgDate}
                  </div>
                ),
              ];
            })}
          </div>
        ) : undefined}
      </div>
      <div className="">
        <Form<{ message?: string }>
          initialValues={{ message: '' }}
          onSubmit={async ({ message = '' }, { setFieldValue, resetForm }) => {
            if (!message) return;
            sendMessage(message);
            setFieldValue('message', '', false);
            resetForm();
          }}
        >
          <div className="chat-input position-relative" style={{ height: 48 }}>
            <style>
              {`
                .chat-input input {
                  padding: 0.75rem 1rem;
                  border: none;
                  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
                  border-radius: 0 0 var(--bs-border-radius-xl) var(--bs-border-radius-xl) !important;
                  height: 100%;
                }
              `}
            </style>
            <InputText
              type="text"
              placeholder="Write your message here..."
              nameOveride="message"
              showLabel={false}
              className="h-100"
            />
            <div className="position-absolute end-0 top-50 translate-middle-y me-2">
              <Button
                type="submit"
                className="border-0 bg-transparent text-body p-0"
                style={{ height: 32, width: 32 }}
                variant="secondary"
                icon={faPaperPlane}
                isLoading={sendMsgOp.loading || createRoomOp.loading}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
