import { assign, map } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { Table, TableProps } from 'react-bootstrap';

import { DataDetailListProps } from './data-detail-list';

export function DataDetailListMinimal({
  heading,
  rows,
  tableProps,
}: DataDetailListProps) {
  const _tableProps = useMemo(
    () => assign({ size: 'sm', borderless: true }, tableProps),
    [tableProps],
  );

  return (
    <div>
      {heading && <h6 className="pb-2 fw-bold">{heading}</h6>}
      <Table {..._tableProps}>
        <tbody>
          {map(rows, (row, idx) => (
            <tr key={idx} className="align-middle">
              <td className="fw-bold text-nowrap w-50">{row.label}&nbsp;</td>
              <td className="">{row.value || '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
