import { InputText } from '@farmshare/ui-components';
import { useFormikContext } from 'formik';
import { Col, Row, Stack } from 'react-bootstrap';

export interface GenerateKillsheetModalFormProps {
  killDate: Date;
}

export const GenerateKillsheetModal = () => {
  useFormikContext<GenerateKillsheetModalFormProps>();

  return (
    <div>
      <Stack gap={4}>
        <Row>
          <Col>
            Select a date and click the button below to download the kill sheet
            PDF. The file will contain a report of all animal heads with the
            selected harvest date.
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <InputText
              label="Harvest Date"
              type="date"
              nameOveride="killDate"
              floatingLabel
              required
            />
          </Col>
        </Row>
      </Stack>
    </div>
  );
};
