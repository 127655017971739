import { Loading } from '@farmshare/ui-components';
import { kebabCase, map, some } from 'lodash';
import { ReactNode, useEffect, useMemo } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useViewProcessorAdminQuery } from 'lib/graphql';

import { ProcessorAddJobTab } from './add-job';
import ProcessorCalendar from './calendar';
import { ProcessorCapabilitiesTab } from './capabilities';
import { ConfigurationErrors } from './configuration-errors';
import ProcessorCutsheets from './cutsheets';
import ProcessorDashboard from './dashboard';
import { JobsTab } from './jobs';
import SchedulingSettings from './scheduling-settings';

interface Tab {
  key: string;
  title: string;
  element: ReactNode;
}

export default function ProcessorAdminPage() {
  const navigate = useNavigate();
  const { tab } = useParams();

  const settings = useViewProcessorAdminQuery();

  const tabs: Tab[] = useMemo(() => {
    const _tabs: Pick<Tab, 'title' | 'element'>[] = [];

    if (settings.data?.processorSettingsOne) {
      _tabs.push({ title: 'Agenda', element: <ProcessorDashboard /> });
      _tabs.push({
        title: 'Calendar',
        element: <ProcessorCalendar />,
      });
      _tabs.push({
        title: 'Capabilities',
        element: <ProcessorCapabilitiesTab settings={settings} />,
      });
      _tabs.push({
        title: 'Jobs',
        element: <JobsTab />,
      });
      _tabs.push({
        title: 'Cutsheets',
        element: <ProcessorCutsheets settings={settings} />,
      });
      _tabs.push({
        title: 'Add Job',
        element: <ProcessorAddJobTab settings={settings} />,
      });
    }

    _tabs.push({
      title: 'Settings',
      element: <SchedulingSettings settings={settings} />,
    });

    return map(_tabs, (t): Tab => ({ ...t, key: kebabCase(t.title) }));
  }, [settings]);

  useEffect(() => {
    if (settings.data && (!tab || !some(tabs, (t) => t.key === tab))) {
      if (settings.data.processorSettingsOne) {
        navigate('/processor/agenda', { replace: true });
      } else {
        navigate('/processor/settings', { replace: true });
      }
    }
  }, [navigate, settings.data, settings.data?.processorSettingsOne, tab, tabs]);

  if (settings.loading) {
    return <Loading />;
  }

  return (
    <Container className="pt-1">
      <div className="mt-3">
        <ConfigurationErrors settings={settings} />
      </div>
      <Tabs
        activeKey={tab}
        onSelect={(tabName) => {
          if (tabName) {
            navigate(`/processor/${tabName}`);
          }
        }}
      >
        {map(tabs, (tab, idx) => (
          <Tab
            key={idx}
            title={tab.title}
            eventKey={tab.key}
            mountOnEnter
            unmountOnExit
          >
            <Container className="mt-4">{tab.element}</Container>
          </Tab>
        ))}
      </Tabs>
    </Container>
  );
}
