import { Button, InputText, useModal } from '@farmshare/ui-components';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Stack } from 'react-bootstrap';

import {
  type TenantByIdQuery,
  useTenantPictureUrlEditMutation,
} from 'lib/graphql';

interface PictureUrlModalForm {
  pictureUrl?: string;
}

function PictureUrlModal() {
  return (
    <Stack gap={3} className="mb-md-2 px-2 px-md-3">
      <InputText label="Picture URL" type="text" floatingLabel />
    </Stack>
  );
}

interface EditPictureUrlButtonProps {
  tenant: TenantByIdQuery['tenantById'];
  refetch: () => Promise<unknown>;
}

export function EditPictureUrlButton(props: EditPictureUrlButtonProps) {
  const [editPictureUrl, { loading }] = useTenantPictureUrlEditMutation();

  const { save } = useModal();

  return (
    <Button
      content="Edit Picture URL"
      size="sm"
      icon={faEdit}
      isLoading={loading}
      onClick={() =>
        save<PictureUrlModalForm>({
          type: 'save',
          title: 'Edit Picture Url',
          size: 'lg',
          icon: faEdit,
          initialValues: {
            pictureUrl: props.tenant?.picture_url ?? undefined,
          },
          body: <PictureUrlModal />,
          isLoading: loading,
          onSubmit: async ({ pictureUrl }) => {
            await editPictureUrl({
              variables: {
                tenantId: props.tenant?._id,
                picture_url: pictureUrl,
              },
            });

            await props.refetch();
          },
        })
      }
    />
  );
}
