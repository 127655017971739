import {
  InputChips,
  InputQuantity,
  InputSelect,
  InputText,
  type InputChipOption,
} from '@farmshare/ui-components';
import {
  animalSplitTypeHelper,
  ALL_ANIMAL_SPLITS,
  animalSpeciesHelper,
  inspectionLevelHelper,
  formatToCurrency,
  capitalize,
} from '@farmshare/utils';
import { faInfoCircle, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import {
  concat,
  filter,
  find,
  groupBy,
  intersection,
  map,
  orderBy,
  range,
  reduce,
  sortBy,
  startCase,
  unionWith,
} from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import {
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Stack,
} from 'react-bootstrap';

import {
  EnumCutsheetAnimalSpecies,
  EnumCutsheetInspectionLevels,
  EnumPrimalCutType,
  ProcessorCapabilityExtras,
  type PrimalCut,
  type ViewProcessorAdminQueryResult,
} from 'lib/graphql';

export interface ExtrasModel {
  name: string;
  pricePerPound: number;
  minLbs?: number;
  isActive: boolean;
  quantity?: number;
}

export interface TrimModel extends ExtrasModel {
  rank: number;
  isAllTrim: boolean;
  disabled: boolean;
}

export type ExtrasTrimOptionsType = {
  extras: Record<Partial<EnumCutsheetAnimalSpecies>, ExtrasModel[]>;
  trim: Record<Partial<EnumCutsheetAnimalSpecies>, TrimModel[]>;
};

export interface NewCutsheetForm {
  animalSpecies: EnumCutsheetAnimalSpecies;
  inspectionLevel?: EnumCutsheetInspectionLevels;
  name: string;
  cuts: InputChipOption[];
  splitTypes: InputChipOption[];
  pricePerPound?: number;
  steaksPerPack?: string;
  steakThickness?: string;
  groundMeatSize: string;
  roastSize?: string;
  extras?: ExtrasTrimOptionsType['extras'];
  trim?: ExtrasTrimOptionsType['trim'];
}

interface NewCutsheetProps {
  fP: FormikProps<NewCutsheetForm>;
  settings?: ViewProcessorAdminQueryResult['data'];
}

export function CutsheetModal({ fP, settings }: NewCutsheetProps) {
  const ref = useRef(null);

  const foundCapability = useMemo(() => {
    if (settings && fP.values.animalSpecies) {
      // Sorry about this code. However it was the only way many or no inspection levels on cutsheets until the triplicated primal cuts is refactored.
      // We get the current active inspection levels for the animalSetting for the selected species.
      const animalSettings = find(
        settings.processorSettingsOne?.animalSettings,
        (o) =>
          (o?.species as unknown as EnumCutsheetAnimalSpecies) ===
          fP.values.animalSpecies,
      );
      const inspectionLevels = animalSettings?.inspectionLevels ?? [];

      // We will now get all processor capabilities that match the species and have no inspection level or an inspection level that is currently active for that species.
      const processorCapabilities = filter(
        settings.processorCapabilityMany,
        (o) => {
          const isSpeciesMatch = o.animalSpecies
            ? (o.animalSpecies as unknown as EnumCutsheetAnimalSpecies) ===
              fP.values.animalSpecies
            : false;

          const isInspectionLevelMatch = o.inspectionLevel
            ? inspectionLevels.includes(o.inspectionLevel)
            : true;

          return isSpeciesMatch && isInspectionLevelMatch;
        },
      );

      // Compare the union with pc.cuts.type pc.cuts.name and pc.primate.name
      // Just carry all the trim and extras for each.

      // Now we reduce this list into one pseudo processorCapability. With things like cuts and extras and trim are actually unions of those properties from all the processor capabilities
      const pseudoProcessorCapability = reduce(
        processorCapabilities,
        (
          acc: {
            animalSpecies: EnumCutsheetAnimalSpecies;
            cuts: any[];
            extras: ProcessorCapabilityExtras[];
            trim: ProcessorCapabilityExtras[];
            vendor: string;
          },
          pc,
        ) => {
          // So silly we have to filter out any nulls or undefined from the extras even though that isn't actually possible to have but the generated types can't figure that out
          acc.extras = concat(acc.extras, filter(pc.extras, (o) => !!o) ?? []);
          acc.trim = concat(acc.trim, filter(pc.trim, (o) => !!o) ?? []);

          // Union the cuts
          acc.cuts = unionWith(acc.cuts, pc.cuts, (left, right) => {
            return (
              left.type === right.type &&
              left.name === right.name &&
              left.primal.name === right.primal.name
            );
          });
          return acc;
        },
        {
          animalSpecies: fP.values.animalSpecies,
          cuts: [],
          extras: [],
          trim: [],
          vendor: settings.processorSettingsOne?.vendor?._id,
        },
      );

      return pseudoProcessorCapability;
    }
  }, [fP.values.animalSpecies, settings]);

  const groupedCapabilities = useMemo(() => {
    if (foundCapability) {
      return groupBy(foundCapability.cuts, (c) => c.primal?.group?.name);
    }
    return [];
  }, [foundCapability]);

  const selectedCutsById = useMemo(
    () => map(fP.values.cuts, (c) => c.value),
    [fP.values.cuts],
  );

  const getCutOption: (cut: PrimalCut) => InputChipOption = useCallback(
    (cut: PrimalCut) => {
      // checking to see if any of the 'blockedBy' values are currently selected
      const disabled = intersection(selectedCutsById, cut.blockedBy).length > 0;
      return { label: cut.name, value: cut._id, disabled };
    },
    [selectedCutsById],
  );

  const renderExtraTrimSection = useCallback(
    (label: 'extras' | 'trim') => {
      if (fP.values?.[label]?.[fP.values.animalSpecies]) {
        return (
          <div key={label}>
            <div className="fw-bold fs-4 mb-3">{startCase(label)}</div>
            {map(
              fP.values?.[label][fP.values.animalSpecies],
              (item: ExtrasModel | TrimModel, index: number) => {
                return (
                  <div
                    key={`${fP.values.animalSpecies}-${item.name}-${index}`}
                    className="gx-1 mb-3 d-flex flex-wrap justify-content-between align-items-center me-3"
                  >
                    <InputChips
                      options={[
                        {
                          label: `${capitalize(item.name)} / ${formatToCurrency(
                            item.pricePerPound,
                          )}/lb ${
                            (item.minLbs ?? 0) > 0
                              ? ` / ${item?.minLbs}lb min `
                              : ''
                          }`,
                          value: item.name,
                          isActive: item.isActive,
                        },
                      ]}
                      size="sm"
                      action={() => {
                        if ((item as TrimModel).disabled) {
                          return;
                        }

                        fP.setFieldValue(
                          `${label}.${fP.values.animalSpecies}.${index}`,
                          {
                            ...item,
                            isActive: !item.isActive,
                          },
                        );
                      }}
                    />

                    {item.minLbs ? (
                      <InputQuantity
                        nameOveride={`${label}.${fP.values.animalSpecies}.${index}.quantity`}
                        step={1}
                        min={0}
                        showEdit
                        disabled={
                          label === 'trim' && (item as TrimModel).disabled
                        }
                      />
                    ) : null}

                    {label === 'trim' && (
                      <div
                        style={{
                          visibility:
                            item.isActive && item.minLbs ? 'visible' : 'hidden',
                        }}
                      >
                        <InputChips
                          options={[
                            {
                              label: 'All trim',
                              value: 'allTrim',
                              isActive: (item as TrimModel).isAllTrim,
                            },
                          ]}
                          size="sm"
                          action={() => {
                            const animalSpecie = fP.values.animalSpecies;
                            const trimOptions = fP.values.trim?.[animalSpecie];

                            if (trimOptions && trimOptions.length > 0) {
                              for (let i = 0; i < trimOptions.length; i++) {
                                const allTrimToggle = !(item as TrimModel)
                                  .isAllTrim;
                                if (i === index) {
                                  // keep option active and set quantity to 0
                                  fP.setFieldValue(
                                    `${label}.${animalSpecie}.${i}`,
                                    {
                                      ...item,
                                      isAllTrim: allTrimToggle,
                                      disabled: allTrimToggle,
                                      ...(allTrimToggle && {
                                        quantity: 0,
                                        rank: 1,
                                      }),
                                    },
                                  );
                                } else {
                                  // disable all other options and set quantity to 0
                                  fP.setFieldValue(
                                    `${label}.${animalSpecie}.${i}`,
                                    {
                                      ...fP.values.trim?.[animalSpecie][i],
                                      isAllTrim: false,
                                      isActive: false,
                                      quantity: 0,
                                      disabled: allTrimToggle,
                                      rank: 1,
                                    },
                                  );
                                }
                              }
                            }
                          }}
                        />
                      </div>
                    )}

                    {label === 'trim' && (
                      <div style={{ width: '75px' }}>
                        <InputText
                          label="Rank"
                          nameOveride={`${label}.${fP.values.animalSpecies}.${index}.rank`}
                          type="number"
                          size="sm"
                          floatingLabel
                          required={item.isActive}
                          min={1}
                          disabled={
                            label === 'trim' && (item as TrimModel).disabled
                          }
                        />
                      </div>
                    )}
                  </div>
                );
              },
            )}
          </div>
        );
      }
    },
    [fP],
  );

  const renderSubGroup = useCallback(
    (cuts: PrimalCut[], label: string) => {
      return (
        <div key={label}>
          <div className="mb-1 fs-5">{label}</div>
          <InputChips
            nameOveride="cuts"
            options={map(orderBy(cuts, 'order'), getCutOption)}
            size="sm"
            required
          />
        </div>
      );
    },
    [getCutOption],
  );

  const renderGroup = useCallback(
    (cuts: PrimalCut[], label: string) => {
      const subGroups = groupBy(cuts, (c) => {
        let _name = c.primal?.name;
        if (c.type !== EnumPrimalCutType.Other) {
          _name += ` ${startCase(c.type)}s`;
        }
        return _name;
      });

      return (
        <Col key={label}>
          <Card className="h-100" body>
            <Stack gap={2}>
              <div className="fw-bold fs-4">{label}</div>
              {map(subGroups, renderSubGroup)}
            </Stack>
          </Card>
        </Col>
      );
    },
    [renderSubGroup],
  );

  const animalSpeciesOptions = useMemo(() => {
    return reduce(
      settings?.processorSettingsOne?.animalSettings,
      (acc: InputChipOption[], animalSetting) => {
        if (animalSetting?.isEnabled) {
          const speciesOption = animalSpeciesHelper(animalSetting.species);

          acc.push({ ...speciesOption, sortValue: speciesOption.order });
        }
        return acc;
      },
      [],
    );
  }, [settings?.processorSettingsOne?.animalSettings]);

  const inspectionLevelOptions = useMemo(() => {
    const animalSetting = settings?.processorSettingsOne?.animalSettings?.find(
      (o) => o?.species.toString() === fP.values.animalSpecies.toString(),
    );

    return sortBy(
      map(animalSetting?.inspectionLevels, (inspectionLevel) => {
        const inspectionOption = inspectionLevelHelper(inspectionLevel ?? '');
        return { ...inspectionOption, sortValue: inspectionOption.order };
      }),
      'sortValue',
    );
  }, [settings?.processorSettingsOne?.animalSettings, fP.values.animalSpecies]);

  return (
    <>
      <div className="fs-5 fw-bold mb-2">Details</div>
      <Row xs={1} lg={3} className="g-2">
        <Col>
          <InputSelect
            label="Animal Species"
            options={animalSpeciesOptions}
            floatingLabel
            required
          />
        </Col>
        <Col>
          <InputSelect
            label="Inspection Level"
            nameOveride="inspectionLevel"
            options={inspectionLevelOptions}
            floatingLabel
          />
        </Col>
        <Col>
          <InputText label="Name" type="text" floatingLabel required />
        </Col>
        <Col>
          <InputText
            label="Price/lb."
            nameOveride="pricePerPound"
            type="number"
            floatingLabel
            required
          />
        </Col>
      </Row>
      <hr />
      <div className="fs-5 fw-bold mb-2">Specifications</div>
      <Row xs={1} lg={4} className="g-2">
        <Col>
          <InputSelect
            label="Ground Meat Size (lb.)"
            options={map([1, 1.5, 2], (value) => ({
              label: `${value} lb.`,
              value,
            }))}
            nameOveride="groundMeatSize"
            floatingLabel
            required
          />
        </Col>
        <Col>
          <InputSelect
            label="Roast Size (lb.)"
            options={map(['2-3', '3-4', '4-5'], (value) => ({
              label: `${value} lb.`,
              value,
            }))}
            nameOveride="roastSize"
            floatingLabel
          />
        </Col>
        <Col>
          <InputSelect
            label="Steak Thickness (in.)"
            nameOveride="steakThickness"
            options={map([0.75, 1, 1.25, 1.5, 1.75, 2], (value) => ({
              label: `${value} in.`,
              value,
            }))}
            floatingLabel
          />
        </Col>
        <Col>
          <InputSelect
            label="Steaks/Pack"
            nameOveride="steaksPerPack"
            options={map(range(1, 5, 1), (value) => ({
              label: value.toString(),
              value,
            }))}
            floatingLabel
          />
        </Col>
      </Row>
      <hr />

      <div className="fs-5 fw-bold mb-2">Split Types</div>
      <InputChips
        nameOveride="splitTypes"
        options={map(ALL_ANIMAL_SPLITS, (split) => ({
          label: animalSplitTypeHelper(split).label,
          value: animalSplitTypeHelper(split).value,
        }))}
        size="sm"
        required
      />
      <hr />

      <div className="fs-5 fw-bold mb-2" ref={ref}>
        Cuts
        <OverlayTrigger
          container={ref}
          placement="right"
          overlay={
            <Popover className="p-3 bg-info-subtle">
              <Stack
                gap={2}
                direction="horizontal"
                className="align-items-start"
              >
                <FontAwesomeIcon
                  icon={faLightbulb}
                  size="lg"
                  className="mt-1"
                />
                <div>
                  Select all cuts that will be offered in this cutsheet. Every
                  unselected cut will default to ground meat.
                </div>
              </Stack>
            </Popover>
          }
        >
          <span className="ms-2">
            <FontAwesomeIcon icon={faInfoCircle} />
          </span>
        </OverlayTrigger>
      </div>

      {groupedCapabilities && (
        <Row xs={1} lg={2} xl={3} className="g-2">
          {map(groupedCapabilities, renderGroup)}
          <Col>
            <Card className="h-100" body>
              <Stack gap={2}>
                {map(['extras', 'trim'], renderExtraTrimSection)}
              </Stack>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}
