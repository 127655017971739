import { Image } from 'react-bootstrap';

import { Maybe, User, Vendor } from 'lib/graphql';

export function renderImage(
  imgUrl: Maybe<string> | undefined,
  fallback: string | undefined,
): JSX.Element {
  return (
    <div className="me-2">
      {imgUrl ? (
        <Image
          src={imgUrl}
          height="31px"
          width="31px"
          referrerPolicy="no-referrer"
          roundedCircle
          fluid
        />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center bg-body-tertiary"
          style={{ height: 32, width: 32, borderRadius: 64 }}
        >
          {fallback}
        </div>
      )}
    </div>
  );
}

export function renderTenant(
  vendor: Maybe<Vendor> | undefined,
  vendorUser: User | undefined,
): JSX.Element {
  const imgUrl = vendor?.tenant?.picture_url || vendorUser?.picture_url;
  return (
    <div className="d-flex align-items-center">
      {renderImage(
        imgUrl,
        vendorUser?.first_name || vendor?.shop_name || vendor?.tenant?.name,
      )}
      <div>
        <div>
          {vendorUser?.first_name} {vendorUser?.last_name}
        </div>
        <div className="text-muted small">
          {vendor?.shop_name || vendor?.tenant?.name}
        </div>
      </div>
    </div>
  );
}

export function renderUser(user: User | undefined) {
  return (
    <div className="d-flex align-items-center">
      {renderImage(user?.picture_url, user?.first_name?.charAt(0))}

      <div>
        <div>{`${user?.first_name} ${user?.last_name}`}</div>
        <div className="text-muted small">{user?.company}</div>
      </div>
    </div>
  );
}
