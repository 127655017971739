import { Button } from '@farmshare/ui-components';
import { Stack } from 'react-bootstrap';

import { type Spot } from './scheduling-select-form';

interface ScheduleBlockProps {
  spot: Spot;
  onClick: (spot: Spot) => void;
}

export const ScheduleBlock = ({ spot, onClick }: ScheduleBlockProps) => {
  return (
    <Stack
      gap={1}
      className="align-items-center bg-body-secondary p-3 border rounded dropoff-date-box"
      data-date={spot.date.format('YYYY-MM-DD')}
    >
      <div className="text-muted">Dropping Off:</div>
      <h4 className="fw-bold text-truncate">
        {spot.date.format('ddd. MMMM Do')}
      </h4>
      <p>
        <span className="slots">{Math.max(0, spot.spotsRemaining)}</span> spots
        remaining
      </p>
      {spot.spotsRemaining > 0 ? (
        <Button onClick={() => onClick(spot)} content="Schedule Now" block />
      ) : (
        <Button
          variant="outline-primary"
          content="Unavailable"
          disabled
          block
        />
      )}
    </Stack>
  );
};
