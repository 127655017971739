import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ReactNode, useMemo } from 'react';
import { Stack, ButtonGroup } from 'react-bootstrap';

import { Button, type ButtonProps } from '../button/button';

export interface SelectableChipProps {
  label: string;
  selected: boolean;
  onSelect: (e: any) => void;
  disabled?: boolean;
  id?: string;
  size?: ButtonProps['size'];
  action?: ReactNode;
  isEditable?: boolean;
  onEdit?: () => void;
}

export function SelectableChip({
  label,
  selected,
  disabled,
  id,
  size,
  onSelect,
  action,
  isEditable,
  onEdit,
}: SelectableChipProps) {
  const variant = useMemo(() => {
    if (selected) {
      return 'primary';
    }
    return window.localStorage.getItem('theme') ?? 'light';
  }, [selected]);

  return isEditable && Boolean(onEdit) ? (
    <ButtonGroup>
      <Button
        content={label}
        size={size}
        variant={variant}
        onClick={(e) => onSelect(e)}
        disabled={disabled}
        className={'chip-' + id}
        id={id}
      />
      <Button
        className={'edit-chip-' + id}
        icon={faEdit}
        size={size}
        variant={variant}
        onClick={onEdit}
      />
    </ButtonGroup>
  ) : (
    <Stack
      direction="horizontal"
      className={action ? 'justify-content-between' : ''}
    >
      <Button
        content={label}
        size={size}
        variant={variant}
        className={'rounded-pill border chip-' + id}
        onClick={(e) => onSelect(e)}
        disabled={disabled}
        id={id}
      />
      {action}
    </Stack>
  );
}
