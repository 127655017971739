import moment from 'moment';
import { Card } from 'react-bootstrap';

import { ChatRoom, EnumUserRole } from 'lib/graphql';

import { renderTenant, renderUser } from './utils';

export default function ChatRoomList({
  chatRooms,
  pageType,
  selectedRoomId,
  onChatRoomClicked,
}: {
  chatRooms: ChatRoom[];
  pageType: 'customer' | 'processor' | 'processor-customer-detail';
  selectedRoomId?: string;
  onChatRoomClicked: (chatRoomId: string) => void;
}) {
  function renderChatDate(date: Date) {
    if (moment(date).isSame(moment(), 'day')) return moment(date).format('LT');
    if (moment(date).isSame(moment(), 'week'))
      return moment(date).format('ddd, hA');
    if (moment(date).isSame(moment(), 'year'))
      return moment(date).format('MMM D, hA');
    return moment(date).format('MMM D, YYYY - hA');
  }

  return (
    <div className="d-flex flex-column gap-2">
      {chatRooms?.map((room) => {
        return (
          <Card
            key={room._id}
            onClick={() => onChatRoomClicked(room._id)}
            style={{
              textAlign: 'left',
              cursor: 'pointer',
            }}
            className={`rounded-4 px-3 py-2 border-0 ${
              selectedRoomId === room._id ? 'bg-success-subtle' : 'secondary'
            }`}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <div>
                  {pageType === 'customer'
                    ? renderTenant(
                        room.vendor,
                        room.participants.find(
                          (u) =>
                            u.role === EnumUserRole.Admin ||
                            EnumUserRole.Vendor,
                        ),
                      )
                    : undefined}
                  {pageType === 'processor'
                    ? renderUser(
                        room.participants.find(
                          (u) => u.role === EnumUserRole.Basic,
                        ),
                      )
                    : undefined}
                </div>
              </div>
              <div className="text-muted small">
                <div>{renderChatDate(room.updatedAt)}</div>
              </div>
            </div>
            <div className="text-muted">{room.lastMessage?.content}</div>
          </Card>
        );
      })}
    </div>
  );
}
