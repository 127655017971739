import { InfoBox, InputText } from '@farmshare/ui-components';
import { Card, InputGroup, Stack } from 'react-bootstrap';

export interface OFSInitStripeFormForm {
  ofsUrl: string;
  testStripeApiKey: string;
  testStripeApiSecret: string;
  prodStripeApiKey: string;
  prodStripeApiSecret: string;
}

export function OFSInitStripeForm() {
  return (
    <Stack gap={3}>
      <InfoBox
        content={
          <span>
            Enter the Stripe API keys and secrets for both production and test
            mode. You can obtain these from the Stripe Developer page.
          </span>
        }
      />
      <InputGroup>
        <InputGroup.Text>https://</InputGroup.Text>
        <InputText
          type="text"
          label="OFS URL"
          hint="The final hosted domain for this OFS."
          floatingLabel
          required
        />
      </InputGroup>
      <Card>
        <Card.Header>Test Mode Keys</Card.Header>
        <Card.Body>
          <Stack gap={2}>
            <InputText
              type="text"
              label="Test API Key"
              nameOveride="testStripeApiKey"
              hint="Starts with 'pk_test_'"
              floatingLabel
              required
            />
            <InputText
              type="text"
              label="Test API Secret"
              nameOveride="testStripeApiSecret"
              hint="Starts with 'sk_test_' or 'rk_test_'"
              floatingLabel
              required
            />
          </Stack>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Production Keys</Card.Header>
        <Card.Body>
          <Stack gap={2}>
            <InputText
              type="text"
              label="API Key"
              nameOveride="prodStripeApiKey"
              hint="Starts with 'pk_live_'"
              floatingLabel
              required
            />
            <InputText
              type="text"
              label="API Secret"
              nameOveride="prodStripeApiSecret"
              hint="Starts with 'sk_live_' or 'rk_live_'"
              floatingLabel
              required
            />
          </Stack>
        </Card.Body>
      </Card>
      {/* <InputText
        type="url"
        label="Store URL"
        hint="The final hosted url for this OFS."
        floatingLabel
        required
      /> */}
    </Stack>
  );
}
