import {
  DataDetailList,
  InputCheck,
  InputPhone,
  InputSelect,
  InputSelectOption,
  InputText,
  PhoneDisplay,
  useModal,
} from '@farmshare/ui-components';
import {
  AnimalSexType,
  animalSpeciesHelper,
  AnimalSplitPart,
  AnimalSplitType,
  formatFullName,
} from '@farmshare/utils';
import { faFileEdit, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { get, isNil, map, set } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  Card,
  Col,
  Form as BSForm,
  Row,
  Stack,
  type RowProps,
} from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import {
  EnumCutsheetType,
  EnumProcessorSchedulingAnimalHeadsInspectionLevel,
  EnumProcessorSchedulingAnimalSpecies,
} from 'lib/graphql';
import { isAnimalBeef } from 'lib/processingJobUtils';

import { type SchedulingCreateForm } from './create-scheduling-form';
import { AnimalHeadsCardHeader } from '../../../components/animal-heads/animal-heads-card-header';

export interface ContactInfo {
  firstName: string;
  lastName?: string;
  phone?: string;
  email?: string;
  company?: string;
  isRequestedByUser: boolean;
  splitPart: AnimalSplitPart;
  cutsheet?: {
    id?: string;
    // DO I need this
    type?: EnumCutsheetType;
    notes?: string;
  };
}

type EditContactForm = Pick<
  ContactInfo,
  | 'firstName'
  | 'lastName'
  | 'phone'
  | 'email'
  | 'isRequestedByUser'
  | 'cutsheet'
>;

const ContactInformationForm = ({
  currentUserInfo,
  isOnBehalfOf,
}: {
  currentUserInfo: Pick<
    EditContactForm,
    'firstName' | 'lastName' | 'phone' | 'email'
  >;
  isOnBehalfOf: boolean;
}) => {
  const { setValues, touched, values } = useFormikContext<ContactInfo>();

  useEffect(() => {
    if (values.isRequestedByUser) {
      setValues({
        ...values,
        firstName: currentUserInfo.firstName,
        lastName: currentUserInfo.lastName,
        phone: currentUserInfo.phone,
        email: currentUserInfo.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isRequestedByUser, setValues, currentUserInfo]);

  return (
    <Stack gap={3}>
      <Stack direction="horizontal" gap={3}>
        <InputCheck
          type="switch"
          label={
            isOnBehalfOf
              ? 'The scheduling customer is the contact for this animal split.'
              : 'I am the contact for this animal split.'
          }
          nameOveride="isRequestedByUser"
          inline
        />
      </Stack>
      <Row className="mb-2">
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="First Name"
            floatingLabel
            required
            nameOveride="firstName"
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="Last Name"
            floatingLabel
            required
            nameOveride="lastName"
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputPhone
            label="Phone"
            floatingLabel
            nameOveride="phone"
            required={touched.phone && (!values.email || !!values.phone)}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="email"
            label="Email"
            floatingLabel
            nameOveride="email"
            required={touched.email && (!values.phone || !!values.email)}
          />
        </Col>
      </Row>
    </Stack>
  );
};

const ContactInformationCard = ({
  parentBaseName,
  contactIdx,
  header,
  splitPart,
  currentUserInfo,
  isOnBehalfOf,
}: {
  parentBaseName: string;
  contactIdx: number;
  header: string;
  splitPart: AnimalSplitPart;
  currentUserInfo: {
    firstName: string;
    lastName?: string;
    email?: string;
    phone?: string;
  };
  isOnBehalfOf: boolean;
}) => {
  const { save } = useModal();
  const { values, setFieldValue, errors, submitCount } =
    useFormikContext<SchedulingCreateForm>();

  const currentData: ContactInfo | undefined = useMemo(() => {
    const data = get(values, `${parentBaseName}.contacts[${contactIdx}]`);

    if (data) {
      // add company/farm name to the contact info
      (data as ContactInfo).company =
        values.requesterContactInformation?.company;

      return data as ContactInfo;
    }
    return data;
  }, [values, parentBaseName, contactIdx]);

  const producerName = useMemo(() => {
    if (currentData?.firstName) {
      return (
        currentData?.company ||
        formatFullName(currentData?.firstName, currentData?.lastName)
      );
    }
    return undefined;
  }, [currentData]);

  const contactErrors = useMemo(
    () => get(errors, `${parentBaseName}.contacts.[${contactIdx}]`),
    [errors, parentBaseName, contactIdx],
  );

  const handleEditContact = () =>
    save<EditContactForm>({
      type: 'save',
      title: `Edit Contact ${header}`,
      icon: faFileEdit,
      initialValues: {
        firstName: currentData?.firstName ?? '',
        lastName: currentData?.lastName,
        phone: currentData?.phone,
        email: currentData?.email,
        isRequestedByUser: currentData?.isRequestedByUser ?? false,
      },
      body: (
        <ContactInformationForm
          currentUserInfo={currentUserInfo}
          isOnBehalfOf={isOnBehalfOf}
        />
      ),
      validate: (values) => {
        const errors: Partial<Record<keyof EditContactForm, string>> = {};

        if (!values.email && !values.phone) {
          set(errors, 'email', 'Please provide either a phone or email.');
          set(errors, 'phone', 'Please provide either a phone or email.');
        }

        return errors;
      },
      onSubmit: (contact) => {
        setFieldValue(`${parentBaseName}.contacts[${contactIdx}]`, {
          firstName: contact.firstName,
          lastName: contact.lastName,
          phone: contact.phone,
          email: contact.email,
          isRequestedByUser: contact.isRequestedByUser,
          splitPart: splitPart,
          cutsheet: contact.cutsheet,
        });
      },
    });

  return (
    <Card className="h-100 job-contact">
      <Card.Header className="d-flex align-items-center">
        {header}
        <Button variant="link" className="ms-auto">
          <FontAwesomeIcon
            className="edit-contact"
            icon={faFileEdit}
            onClick={handleEditContact}
          />
        </Button>
      </Card.Header>
      <Card.Header className="d-flex justify-content-center">
        <FontAwesomeIcon icon={faUser} size="4x" />
      </Card.Header>
      <Card.Body>
        <div className="d-block text-break">
          <DataDetailList
            rows={[
              {
                label: 'Producer',
                value: producerName,
              },
              {
                label: 'Phone',
                value: currentData?.phone ? (
                  <PhoneDisplay phone={currentData?.phone} />
                ) : undefined,
              },
              {
                label: 'Email',
                value: currentData?.email,
              },
            ]}
          />
        </div>
      </Card.Body>
      {submitCount > 0 && contactErrors && (
        <Card.Footer>
          <Stack gap={1} className="px-1 px-lg-2">
            <BSForm.Control.Feedback
              type="invalid"
              style={{ display: 'block' }}
            >
              {typeof contactErrors === 'string' ? contactErrors : null}
              {typeof contactErrors === 'object'
                ? map(contactErrors, (val: string) => <Row>{val}</Row>)
                : null}
            </BSForm.Control.Feedback>
          </Stack>
        </Card.Footer>
      )}
    </Card>
  );
};

export interface AnimalHead {
  producerIdentifier?: string;
  splitType?: AnimalSplitType | 'Select one...' | undefined;
  contacts: ContactInfo[];
  sex?: AnimalSexType;
  isOverThirtyMonths?: boolean;
  publicId?: string;
  inspectionLevel?: EnumProcessorSchedulingAnimalHeadsInspectionLevel;
}

interface AnimalHeadFormProps {
  name: string;
  animalSpecies?: string;
  isActive: boolean;
  isOnBehalfOf: boolean;
  scheduledHeadsIdx: number;
  onEdit?: (idx: number) => void;
  onRemove?: (idx: number) => void;
  enabledAnimalSplitOptions: InputSelectOption[];
  enabledAnimalInspectionLevelOptions: InputSelectOption[];
  isSingle?: boolean;
  isSplitTypeRequired?: boolean;
}

export const AddAnimalHeadForm = ({
  animalSpecies,
  isSingle,
  enabledAnimalSplitOptions,
  enabledAnimalInspectionLevelOptions,
  isActive,
  isOnBehalfOf,
  name,
  onEdit,
  onRemove,
  scheduledHeadsIdx,
  isSplitTypeRequired = true,
}: AnimalHeadFormProps) => {
  const { values, setFieldTouched, submitCount, errors, setFieldValue } =
    useFormikContext<SchedulingCreateForm>();
  const user = useRecoilValue(userState);

  const animalSpeciesFormatted = useMemo(
    () => (animalSpecies ? animalSpeciesHelper(animalSpecies).label : ''),
    [animalSpecies],
  );

  const currentUserInfo = useMemo(() => {
    if (user && !isOnBehalfOf) {
      return {
        firstName: user.first_name,
        lastName: user.last_name ?? undefined,
        phone: user.phone ?? undefined,
        email: user.email ?? undefined,
      };
    } else {
      return {
        firstName: values.requesterContactInformation?.firstName,
        lastName: values.requesterContactInformation?.lastName,
        phone: values.requesterContactInformation?.phone,
        email: values.requesterContactInformation?.email,
      };
    }
  }, [user, values.requesterContactInformation, isOnBehalfOf]);

  const baseName = useMemo(
    () => `${name}[${scheduledHeadsIdx}]`,
    [scheduledHeadsIdx, name],
  );

  const handleRemove = useCallback(() => {
    if (onRemove) {
      onRemove(scheduledHeadsIdx);
    }
  }, [onRemove, scheduledHeadsIdx]);

  const handleEdit = useCallback(() => {
    if (onEdit) {
      onEdit(scheduledHeadsIdx);
    }
  }, [onEdit, scheduledHeadsIdx]);

  const splitValue: AnimalSplitType | 'Select one...' | undefined = useMemo(
    () => get(values, `${baseName}.splitType`),
    [baseName, values],
  );

  const producerIdentifier: string | undefined = useMemo(
    () => get(values, `${baseName}.producerIdentifier`),
    [baseName, values],
  );

  const inspectionLevel = useMemo(
    () => get(values, `${baseName}.inspectionLevel`),
    [baseName, values],
  );

  const showContacts = useMemo(
    () => !isNil(splitValue) && splitValue !== 'Select one...',
    [splitValue],
  );

  const contactsFill = useMemo(() => {
    const contacts: { label: string; splitPart: AnimalSplitPart }[] = [];
    if (!splitValue) {
      return contacts;
    }

    switch (splitValue) {
      case 'whole':
        contacts.push({ label: 'Whole', splitPart: 'whole' });
        break;

      case 'half':
        contacts.push(
          { label: 'Half #1', splitPart: 'half' },
          { label: 'Half #2', splitPart: 'half' },
        );
        break;

      case 'half_and_two_quarters':
        contacts.push(
          { label: 'Half', splitPart: 'half' },
          { label: 'Quarter #1', splitPart: 'quarter' },
          { label: 'Quarter #2', splitPart: 'quarter' },
        );
        break;

      case 'quarters':
        contacts.push(
          { label: 'Quarter #1', splitPart: 'quarter' },
          { label: 'Quarter #2', splitPart: 'quarter' },
          { label: 'Quarter #3', splitPart: 'quarter' },
          { label: 'Quarter #4', splitPart: 'quarter' },
        );
        break;

      default:
        return contacts;
    }

    return contacts;
  }, [splitValue]);

  const colWidths: RowProps = useMemo(() => {
    if (splitValue) {
      switch (splitValue) {
        case 'whole':
          return { xs: 1 };
        case 'half':
        case 'quarters':
          return { lg: 2, xs: 1 };
        case 'half_and_two_quarters':
          return { lg: 3, xs: 1 };
      }
    }
    return { xs: 1 };
  }, [splitValue]);

  // We update the form data manually for contacts array when the splitValue changes
  useEffect(
    () => {
      if (!splitValue) {
        setFieldValue(`${baseName}.contacts`, [], false);
        return;
      }

      switch (splitValue) {
        case 'whole':
          setFieldValue(`${baseName}.contacts`, [{}], false);
          break;

        case 'half':
          setFieldValue(`${baseName}.contacts`, [{}, {}], false);
          break;

        case 'half_and_two_quarters':
          setFieldValue(`${baseName}.contacts`, [{}, {}, {}], false);
          break;

        case 'quarters':
          setFieldValue(`${baseName}.contacts`, [{}, {}, {}, {}], false);
          break;

        default:
          setFieldValue(`${baseName}.contacts`, [], false);
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [splitValue, setFieldValue, baseName],
  );

  // Formik should do this automatically but it doesn't there isn't a good way to handle this globally in formik
  useEffect(() => {
    if (submitCount > 0 || !isActive) {
      setFieldTouched(`${baseName}.splitType`, true);
    }
  }, [submitCount, setFieldTouched, baseName, isActive]);

  const hasErrors = useMemo(
    () => Boolean(get(errors, `scheduledHeads.${scheduledHeadsIdx}`)),
    [errors, scheduledHeadsIdx],
  );

  const cardHeader = useMemo(() => {
    const header = animalSpeciesFormatted;

    if (!isSingle) {
      return `${header} #${scheduledHeadsIdx + 1}`;
    }

    return header;
  }, [animalSpeciesFormatted, isSingle, scheduledHeadsIdx]);

  const animalIsTypeBeef = useMemo(() => {
    return isAnimalBeef(animalSpecies as EnumProcessorSchedulingAnimalSpecies);
  }, [animalSpecies]);

  if (isActive || hasErrors) {
    return (
      <Card>
        <AnimalHeadsCardHeader
          isActive={true}
          cardHeader={cardHeader}
          onRemove={onRemove}
          handleRemove={handleRemove}
        />
        <Card.Body>
          <Stack key={scheduledHeadsIdx} gap={3}>
            <Row>
              <Col>
                <InputSelect
                  label="Inspection Level"
                  options={enabledAnimalInspectionLevelOptions}
                  floatingLabel
                  nameOveride={`${baseName}.inspectionLevel`}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputText
                  label="Identifier"
                  floatingLabel
                  type="text"
                  nameOveride={`${baseName}.producerIdentifier`}
                  hint="This can be any descriptor (or ear tag) of the animal."
                />
              </Col>

              <Col>
                <InputSelect
                  label="Split"
                  options={enabledAnimalSplitOptions}
                  floatingLabel
                  nameOveride={`${baseName}.splitType`}
                  hint="Select the split you would like to use for this animal."
                  required={isSplitTypeRequired}
                />
              </Col>
            </Row>
            {animalIsTypeBeef && (
              <Row>
                <Col>
                  <InputSelect
                    label="Sex"
                    options={['M', 'F']}
                    floatingLabel
                    nameOveride={`${baseName}.sex`}
                    hint="What sex is this animal?"
                  />
                </Col>
                <Col>
                  <InputCheck
                    label="Beef is over 30 months old?"
                    floatingLabel
                    type="checkbox"
                    inline
                    nameOveride={`${baseName}.isOverThirtyMonths`}
                  />
                </Col>
              </Row>
            )}
            <Row {...colWidths}>
              {showContacts &&
                map(contactsFill, (c, idx) => (
                  <Col className="pb-4" key={idx}>
                    <ContactInformationCard
                      key={idx}
                      parentBaseName={baseName}
                      contactIdx={idx}
                      header={c.label}
                      splitPart={c.splitPart}
                      currentUserInfo={currentUserInfo}
                      isOnBehalfOf={isOnBehalfOf}
                    />
                  </Col>
                ))}
            </Row>
          </Stack>
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card>
        <AnimalHeadsCardHeader
          isActive={false}
          cardHeader={cardHeader}
          producerIdentifier={producerIdentifier}
          splitValue={splitValue}
          inspectionLevel={inspectionLevel}
          contactsFill={contactsFill}
          onEdit={handleEdit}
          handleEdit={handleEdit}
        />
      </Card>
    );
  }
};
