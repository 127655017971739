import { Loading, PageTitle } from '@farmshare/ui-components';
import { useCallback, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { useViewProcessingPartnerQuery } from 'lib/graphql';

import { CreateScheduling } from './_views/create-scheduling';

export default function SchedulingCreatePage() {
  const navigate = useNavigate();
  const { slug, animalType, date } = useParams();
  const [vendorName, setVendorName] = useState('');
  const handleCancel = useCallback(() => navigate(-1), [navigate]);
  const isSlug = slug?.includes('-');
  const isVendorId = /^[0-9a-fA-F]{24}$/.test(slug ?? '') || !isSlug; // MongoID
  const { data, loading } = useViewProcessingPartnerQuery({
    variables: {
      ...(isVendorId && { vendorId: slug }), // MongoID
      ...(isSlug && { slug }),
    },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="pt-3 pt-lg-4">
      <PageTitle
        title="Schedule New Processing"
        innerBreadcrumbs={[
          { text: 'Scheduling', to: '/scheduling' },
          {
            text: vendorName,
            to: `/scheduling/${slug}`,
          },
        ]}
      />
      <Container>
        <CreateScheduling
          animalSpecies={animalType}
          date={date}
          vendorId={data?.processingPartnersOne?.vendor?._id}
          setVendorName={setVendorName}
          onCancel={handleCancel}
        />
      </Container>
    </div>
  );
}
