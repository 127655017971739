import { useToastr } from '@farmshare/ui-components';
import { faArrowLeft, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Button, Stack } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Maybe, ViewProcessorAdminQueryResult } from 'lib/graphql';

import { CreateScheduling } from 'pages/scheduling/_views/create-scheduling';
import {
  SchedulingSelectForm,
  dateFormat,
} from 'pages/scheduling/_views/scheduling-select-form';

interface ProcessorAddJobTabProps {
  addJobRef?: MutableRefObject<
    | {
        cancel: () => void;
      }
    | undefined
  >;
  settings?: Maybe<ViewProcessorAdminQueryResult>;
  pageType?: ScheduleDatePageType;
  scheduleDatesRange?: ScheduleDatesRange;
}

export function ProcessorAddJobTab({
  addJobRef,
  settings,
  pageType,
  scheduleDatesRange,
}: ProcessorAddJobTabProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState<string>('');

  const [searchParams, setSearchParams] = useSearchParams();
  const { push } = useToastr();

  const [selection, setSelection] = useState<{
    animalSpecies: string;
    date: moment.Moment;
  }>();

  const clearSelection = useCallback(() => {
    setSearchParams(undefined);
    setSelection(undefined);
  }, [setSelection, setSearchParams]);

  const handleCancel = useCallback(() => {
    setSelection(undefined);
  }, [setSelection]);

  useImperativeHandle(addJobRef, () => ({
    cancel: () => {
      clearSelection();
    },
  }));

  const handleCreateSuccess = useCallback(
    (newScheduleId: string) => {
      push({
        title: 'Booking Success',
        body: 'New booking created successfully.',
        bg: 'primary',
        delay: 4000,
      });
      setSelection(undefined);
      navigate(`/processing-job/${newScheduleId}/details`);
    },
    [push, setSelection, navigate],
  );

  useEffect(() => {
    const animalSpecies = searchParams.get('animalSpecies');
    const date = searchParams.get('date');
    if (animalSpecies && date) {
      setSelection({ animalSpecies, date: moment(date) });
    }
  }, [searchParams, setSelection]);

  useEffect(() => {
    if (selection) {
      setSearchParams({
        animalSpecies: selection.animalSpecies,
        date: selection.date.format('YYYY-MM-DD'),
      });
    }
  }, [selection, setSearchParams]);

  /**
   * @author @abdullahcalisir12
   * Why clear selection on navigating?
   * We are using same component in Calendar Page when clicking Schedule button.
   * If not clearing state, and navigating to the calendar page shows the state from the add job page
   */
  useEffect(() => {
    if (location.pathname !== prevLocation) {
      clearSelection();
    }
    setPrevLocation(location.pathname);
  }, [location, prevLocation, clearSelection]);

  return (
    <Stack>
      <div>
        <Stack direction="horizontal" gap={2} className="align-items-start">
          {selection && (
            <Button
              variant="ghost"
              onClick={() => {
                clearSelection();
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="lg" />
            </Button>
          )}
          <h2 className="fw-bold">Add Job</h2>
        </Stack>
        <p className="mb-0">
          Use this form to add a job on behalf of your customers.
        </p>
        <hr />
      </div>
      {selection ? (
        <CreateScheduling
          animalSpecies={selection.animalSpecies}
          date={selection.date.format(dateFormat)}
          vendorId={settings?.data?.processorSettingsOne?.vendor?._id}
          isOnBehalfOf={true}
          onCancel={handleCancel}
          onCreateSuccess={handleCreateSuccess}
          submitButtonLabel="Create Scheduled Job"
          submitButtonIcon={faCheck}
        />
      ) : (
        <SchedulingSelectForm
          onSelect={(animalSpecies, date) =>
            setSelection({ animalSpecies, date })
          }
          vendorId={settings?.data?.processorSettingsOne?.vendor?._id}
          scheduleDatesRange={scheduleDatesRange}
        />
      )}
    </Stack>
  );
}
