import { Button, useModal } from '@farmshare/ui-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { first } from 'lodash';

import { type TenantByIdQuery, useOfsCreateMutation } from 'lib/graphql';

import { OFSModal, type OFSModalForm } from './ofs-create-modal';

interface AddOFSButtonProps {
  tenant: TenantByIdQuery['tenantById'];
  refetch: () => Promise<unknown>;
}

export function AddOFSButton(props: AddOFSButtonProps) {
  const [createOFS, { loading }] = useOfsCreateMutation();

  const { save } = useModal();

  return (
    <Button
      content="Add OFS"
      size="sm"
      icon={faPlus}
      isLoading={loading}
      onClick={() =>
        save<OFSModalForm>({
          type: 'save',
          title: 'Add OFS',
          icon: faPlus,
          initialValues: {
            vendor:
              props.tenant?.vendors.length === 1
                ? first(props.tenant.vendors)?._id
                : '',
            ofsName: '',
          },
          body: <OFSModal vendors={props.tenant?.vendors} />,
          isLoading: loading,
          onSubmit: async ({ vendor, ofsName }) => {
            await createOFS({ variables: { newOFS: { vendor, ofsName } } });
            await props.refetch();
          },
        })
      }
    />
  );
}
