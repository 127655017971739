import { ChangeEvent, useState } from 'react';

import styles from './table-editor.module.scss';
import { type InlineEditorProps } from './table-types';

export function TableEditorNumber<TData>({
  initialValue,
  row,
  column,
  updateRow,
}: InlineEditorProps<TData>) {
  const [value, setValue] = useState(initialValue);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateRow(row.original, column.id, value);
  };

  return (
    <input
      className={styles.tableEditor}
      type="number"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      step={0.01}
    />
  );
}
