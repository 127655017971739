import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { Dropdown, Image, Stack } from 'react-bootstrap';

export interface AuthProps<TUser> {
  user: TUser;
  logoutFn: () => Promise<void>;
  vendorName: string;
  dropdownItems?: ReactElement[];
}

export function Auth<
  TUser extends { first_name?: string; picture_url?: string | null },
>(props: AuthProps<TUser>) {
  return (
    <div className="d-print-none">
      <Dropdown drop="down-centered" className="auth-dropdown">
        <Dropdown.Toggle variant="ghost">
          <div className="d-inline-flex align-items-center">
            {props.user.picture_url && (
              <span className="me-2" style={{ width: '31px', height: '31px' }}>
                <Image
                  src={props.user.picture_url}
                  height="31px"
                  width="31px"
                  referrerPolicy="no-referrer"
                  roundedCircle
                  fluid
                />
              </span>
            )}
            <Stack className="pe-1">
              <div>
                <span className="fw-light me-1">Welcome,</span>
                <span className="fw-bold">{props.user.first_name}</span>
              </div>
              <div className="text-start text-muted small">
                {props.vendorName}
              </div>
            </Stack>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-100">
          {props.dropdownItems}
          <Dropdown.Item onClick={props.logoutFn}>
            <FontAwesomeIcon icon={faRightFromBracket} className="me-2" />
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
