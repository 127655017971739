import {
  Button,
  Form,
  InputCheck,
  InputChips,
  useModal,
  useToastr,
  Loading,
  type InputChipOption,
  InputText,
} from '@farmshare/ui-components';
import { defaultSpecifications } from '@farmshare/utils';
import { faCircle, faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faEdit,
  faEye,
  faEyeSlash,
  faListCheck,
  faPlus,
  faRuler,
  faSave,
  faWeightScale,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, FieldArray } from 'formik';
import {
  reduce,
  startCase,
  camelCase,
  has,
  set,
  assign,
  groupBy,
  map,
  orderBy,
  get,
  find,
  every,
  filter,
  flatMap,
  partialRight,
  omit,
  maxBy,
  isEqual,
  isArray,
  isObject,
  forEach,
} from 'lodash';
import { useCallback, useMemo } from 'react';
import {
  Col,
  Card,
  Stack,
  Row,
  ButtonGroup,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from 'react-bootstrap';

import {
  EnumPrimalCutType,
  EnumProcessorCapabilityAnimalSpecies,
  EnumProcessorCapabilityInspectionLevel,
  PrimalGroupManyDocument,
  PrimalCutBlockerManyDocument,
  usePrimalCutBlockerUpsertMutation,
  usePrimalCutBlockerManyQuery,
  usePrimalCutCreateOneMutation,
  usePrimalCutUpdateByIdMutation,
  useProcessorCapabilityUpsertMutation,
  usePrimalCutSoftDeleteMutation,
  ViewProcessorAdminDocument,
  type Primal,
  type PrimalCut,
  type PrimalGroup,
  type ProcessorCapability,
  type CapabilitySpecificationFragment,
} from 'lib/graphql';

import {
  CapabilityEditModal,
  type CapabilityEditModalForm,
} from './capability-edit';
import {
  EditCutBlockersModal,
  type EditCutBlockersModalForm,
} from './edit-cut-blockers-modal';
import {
  ExtrasTrimModel,
  ExtrasTrimSection,
  type ExtrasTrimSectionForm,
} from './extras-trim-section';

function removeTypename(obj: Record<string, any>[] | Record<string, any>): any {
  if (isArray(obj)) {
    return obj.map(removeTypename);
  } else if (isObject(obj) && obj !== null) {
    const newObj = omit(obj, '__typename');
    forEach(newObj, (value, key) => {
      newObj[key] = removeTypename(value);
    });
    return newObj;
  }
  return obj;
}

type ProcessorCapabilitiesTabForm = {
  [key: string]: any;
} & ExtrasTrimSectionForm & { specifications: any[] };

interface ProcessorCapabilitiesTabProps {
  capability?: ProcessorCapability;
  foundPrimalGroups?: PrimalGroup[];
}

export function ProcessorCapabilitiesTabInner({
  capability,
  foundPrimalGroups,
}: ProcessorCapabilitiesTabProps) {
  const { save, ask } = useModal();
  const { push } = useToastr();

  const [createPrimalCut, createPrimalCutOp] = usePrimalCutCreateOneMutation({
    refetchQueries: [PrimalGroupManyDocument],
  });

  const [deletePrimalCut, deletePrimalCutOp] = usePrimalCutSoftDeleteMutation({
    refetchQueries: [PrimalGroupManyDocument],
    fetchPolicy: 'no-cache',
  });

  const [upsertPrimalCutBlocker, upsertPrimalCutBlockerOp] =
    usePrimalCutBlockerUpsertMutation({
      refetchQueries: [PrimalCutBlockerManyDocument],
    });

  const [updatePrimalCut, updatePrimalCutOp] = usePrimalCutUpdateByIdMutation({
    refetchQueries: [PrimalGroupManyDocument],
  });

  const [upsertCapability, upsertCapabilityOp] =
    useProcessorCapabilityUpsertMutation({
      update(cache, { data }) {
        /**
         * @author @abdullahcalisir12
         * @description manually updating the f***ing cache
         */
        const existingData = cache.readQuery<{
          processorCapabilityMany: any[];
          processorSettingsOne: any;
        }>({
          query: ViewProcessorAdminDocument,
        });

        if (existingData) {
          cache.writeQuery({
            query: ViewProcessorAdminDocument,
            data: {
              processorCapabilityMany: existingData.processorCapabilityMany.map(
                (item) =>
                  item._id === data?.processorCapabilityUpsert?._id
                    ? data?.processorCapabilityUpsert
                    : item,
              ),
              processorSettingsOne: existingData.processorSettingsOne,
            },
          });
        }
      },
    });

  const { data, loading } = usePrimalCutBlockerManyQuery({
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        vendor: capability?.vendor,
      },
    },
  });

  const initialValues: ProcessorCapabilitiesTabForm = useMemo(
    () => ({
      ...reduce(
        capability?.cuts,
        (acc, primalCut) => {
          let _name = primalCut.primal?.name;
          if (primalCut.type !== EnumPrimalCutType.Other) {
            _name += ` ${startCase(primalCut.type)}s`;
          }
          _name = camelCase(_name);

          const option: InputChipOption = {
            label: primalCut.name,
            value: primalCut._id,
          };
          if (has(acc, _name)) {
            const group = get(acc, _name);
            group.push(option);
          } else {
            set(acc, _name, [option]);
          }

          return acc;
        },
        {},
      ),
      extras:
        (map(
          capability?.extras,
          partialRight(omit, ['__typename']),
        ) as ExtrasTrimModel[]) || [],
      trim:
        (map(
          capability?.trim,
          partialRight(omit, ['__typename']),
        ) as ExtrasTrimModel[]) || [],
      specifications: removeTypename([
        ...(capability?.activeSpecification?.specifications ??
          defaultSpecifications),
      ]) as CapabilitySpecificationFragment[],
      editMode: false,
    }),
    [
      capability?.cuts,
      capability?.extras,
      capability?.trim,
      capability?.activeSpecification?.specifications,
    ],
  );

  const handleEdit = useCallback(
    (
      selectedCut: PrimalCut,
      cutType: string,
      cuts: PrimalCut[],
      primalCutTypes: Record<string, PrimalCut[]>,
    ) => {
      save<EditCutBlockersModalForm>({
        type: 'save',
        icon: faPlus,
        isLoading: upsertPrimalCutBlockerOp.loading,
        initialValues: {
          cutBlockers: Object.entries(primalCutTypes)
            .reduce((acc, [key, value]: [string, PrimalCut[]]) => {
              acc.push(...value.map((c) => ({ ...c, group: key })));
              return acc;
            }, [] as PrimalCut[])
            .filter((c) => c._id !== selectedCut._id),
          selectedCutBlockers:
            map(
              filter(data?.primalCutBlockerMany, (cutBlocker) => {
                return (
                  cutBlocker.animalSpecies === capability?.animalSpecies &&
                  cutBlocker.inspectionLevel === capability?.inspectionLevel &&
                  cutBlocker.primalCutId === selectedCut._id
                );
              }),
              (cutBlocker) =>
                map(
                  cutBlocker.blockedBy,
                  (blockedBy) => blockedBy?.primalCutId as string,
                ),
            )[0] ?? [],
        },
        title: 'Blocked By',
        body: (formikProps) => (
          <EditCutBlockersModal formikProps={formikProps} />
        ),
        async onSubmit({ selectedCutBlockers, cutBlockers }) {
          const mappedBlockedBy = map(
            filter(
              Array.from(new Set(selectedCutBlockers)), // Remove duplicates and empty values
              (cutBlockerId) =>
                cutBlockerId && cutBlockerId !== 'Select one...',
            ),
            (selectedCutBlockerId) => {
              const primalCut = find(
                cutBlockers,
                (primalCut) => primalCut._id === selectedCutBlockerId,
              );
              return {
                name: primalCut?.name as string,
                primalCutId: primalCut?._id as string,
              };
            },
          );

          await upsertPrimalCutBlocker({
            variables: {
              animalSpecies: capability?.animalSpecies?.toString() ?? '',
              inspectionLevel: capability?.inspectionLevel?.toString() ?? '',
              name: selectedCut.name,
              primalCutId: selectedCut._id,
              type: cutType,
              blockedBy: mappedBlockedBy,
            },
          });
          push({
            bg: 'success',
            title: 'Success',
            body: 'Cut blockers saved successfully',
            delay: 5000,
          });
        },
      });
    },
    [
      save,
      upsertPrimalCutBlockerOp.loading,
      data,
      capability?.animalSpecies,
      capability?.inspectionLevel,
      upsertPrimalCutBlocker,
      push,
    ],
  );

  const handleDelete = useCallback(
    (primalCutId: string) => {
      ask({
        type: 'ask',
        title: 'Delete Cut',
        body: 'Are you sure you want to delete this cut?',
        isLoading: deletePrimalCutOp.loading,
        onConfirm: async () => {
          try {
            await deletePrimalCut({
              variables: {
                primalCutId,
              },
            });
            push({
              bg: 'success',
              title: 'Success',
              body: 'Cut deleted successfully',
              delay: 5000,
            });
          } catch (error) {
            console.error('Failed to delete cut', error);
            push({
              bg: 'danger',
              title: 'Error',
              body: (error as Error).message,
              delay: 5000,
            });
          }
        },
      });
    },
    [ask, deletePrimalCutOp.loading, deletePrimalCut, push],
  );

  const renderCuts = useCallback(
    (
      cuts: PrimalCut[],
      name: string,
      values: ProcessorCapabilitiesTabForm,
      primalCutTypes: Record<string, PrimalCut[]>,
    ) => (
      <div
        key={name}
        className={`mb-2 ${name.replace(/\s+/g, '-').toLowerCase()}`}
      >
        <div className="mb-1">
          Do you offer <span>{name}</span>?
        </div>
        <InputChips
          nameOveride={camelCase(name)}
          options={map(orderBy(cuts, 'order'), (cut) => ({
            label: cut.name,
            value: cut._id,
            isEditable: cuts.length > 1 && !values.editMode,
            onEdit:
              cuts.length > 1 && !values.editMode
                ? () => handleEdit(cut, name, cuts, primalCutTypes)
                : undefined,
            isDeletable: values.editMode,
            onDelete: values.editMode ? () => handleDelete(cut._id) : undefined,
          }))}
          action={
            values.editMode
              ? (o) => {
                  const primalCut = find(cuts, { _id: o.value });
                  if (primalCut) {
                    save<CapabilityEditModalForm>({
                      type: 'save',
                      icon: faEdit,
                      initialValues: {
                        primal: primalCut.primal?._id,
                        name: primalCut.name,
                        type: primalCut.type,
                      },
                      title: 'Edit Cut',
                      body: <CapabilityEditModal />,
                      isLoading: updatePrimalCutOp.loading,
                      async onSubmit(values) {
                        await updatePrimalCut({
                          variables: {
                            _id: o.value,
                            record: {
                              name: values.name,
                              primal: values.primal,
                              type: values.type,
                            },
                          },
                        });
                        push({
                          bg: 'success',
                          title: 'Success',
                          body: 'Cut updated successfully',
                          delay: 5000,
                        });
                      },
                    });
                  }
                }
              : undefined
          }
          size="sm"
        />
      </div>
    ),
    [
      handleEdit,
      handleDelete,
      save,
      updatePrimalCutOp.loading,
      updatePrimalCut,
      push,
    ],
  );

  const renderSection = useCallback(
    (
      label: string,
      primals: Primal[],
      values: ProcessorCapabilitiesTabForm,
    ) => {
      const primalCutTypes: Record<string, PrimalCut[]> = reduce(
        orderBy(primals, 'order'),
        (acc, primal) => {
          const filteredCuts = filter(primal.cuts, (c) => !c.isDeleted);
          const groups = groupBy(filteredCuts, (c) => {
            let _name = primal.name;
            if (c.type !== EnumPrimalCutType.Other) {
              _name += ` ${startCase(c.type)}s`;
            }
            return _name;
          });

          return assign(acc, groups);
        },
        {},
      );

      return (
        <Col key={label}>
          <Card className="h-100  rounded-4" body>
            <Stack gap={2}>
              <Stack
                direction="horizontal"
                className="justify-content-between align-content-center cut-header"
              >
                <div className="fw-bold fs-4">{label}</div>
                {values.editMode && (
                  <Button
                    content="Add Cut"
                    icon={faPlus}
                    size="sm"
                    onClick={() =>
                      save<CapabilityEditModalForm>({
                        type: 'save',
                        icon: faPlus,
                        isLoading: createPrimalCutOp.loading,
                        initialValues: { primal: '', name: '' },
                        title: 'Add Cut',
                        body: <CapabilityEditModal primals={primals} />,
                        async onSubmit({ name, primal, type }) {
                          if (name && primal && type) {
                            const foundPrimal = find(
                              primals,
                              (o) => o._id === primal,
                            );
                            // Silly logic to make sure order is "unique?"
                            const order: any =
                              maxBy(foundPrimal?.cuts, (c) => c.order)?.order ??
                              -1;

                            await createPrimalCut({
                              variables: {
                                record: {
                                  name,
                                  primal,
                                  type,
                                  order: order >= 0 ? order + 1 : 99,
                                },
                              },
                            });
                            push({
                              bg: 'success',
                              title: 'Success',
                              body: 'Cut added successfully',
                              delay: 5000,
                            });
                          }
                        },
                      })
                    }
                  />
                )}
              </Stack>
              {map(primalCutTypes, (cuts, name) =>
                renderCuts(cuts, name, values, primalCutTypes),
              )}
            </Stack>
          </Card>
        </Col>
      );
    },
    [createPrimalCut, createPrimalCutOp.loading, push, renderCuts, save],
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <Form<ProcessorCapabilitiesTabForm>
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={async (values, formikHelpers) => {
        const { extras, trim, specifications, isEditMode, ...cuts } = values;
        if (capability?.animalSpecies && capability?.inspectionLevel) {
          await upsertCapability({
            variables: {
              animalSpecies:
                capability.animalSpecies.toString() as EnumProcessorCapabilityAnimalSpecies,
              inspectionLevel:
                capability.inspectionLevel.toString() as EnumProcessorCapabilityInspectionLevel,
              primalCuts: map(
                flatMap(
                  filter(
                    cuts,
                    (value) => Array.isArray(value) && every(value, 'value'),
                  ),
                ),
                (v) => v?.value,
              ),
              extras: extras,
              trim: trim,
              ...(capability.activeSpecification &&
              isEqual(specifications, initialValues.specifications)
                ? {}
                : { specifications }),
            },
          });
          push({
            bg: 'success',
            title: 'Success',
            body: 'Capability saved successfully',
            delay: 5000,
          });
        }
      }}
    >
      {({ dirty, values }) => (
        <>
          <Stack
            className="mt-3 justify-content-between align-items-baseline"
            direction="horizontal"
          >
            <Stack direction="horizontal" gap={2}>
              <div className="me-2 fw-bold fs-5">Mode:</div>
              <div className="me-1" style={{ paddingBottom: '0.1rem' }}>
                Select
              </div>
              <InputCheck
                type="switch"
                label="Edit"
                nameOveride="editMode"
                className={values.editMode ? 'fw-bold text-primary' : ''}
                inline
              />
            </Stack>
            <Button
              type="submit"
              content="Save"
              icon={faSave}
              isLoading={upsertCapabilityOp.loading}
              disabled={!dirty}
            />
          </Stack>
          <FieldArray name="specifications">
            {({ push: addSpecification, replace: replaceSpecification }) => (
              <Card className="bg-body-secondary rounded-4 mt-4" body>
                <div className="d-flex gap-3 align-items-center lh-1">
                  <FontAwesomeIcon
                    className="text-primary"
                    icon={faListCheck}
                    size="2x"
                  />
                  <div className="d-flex flex-fill flex-column justify-content-between text-start">
                    <div className="fw-bolder fs-2">Specifications</div>
                  </div>
                  {values.editMode && (
                    <Button
                      content="Add new specification"
                      icon={faPlus}
                      onClick={() => {
                        addSpecification({
                          name: '',
                          options: [],
                          isRequired: false,
                          isVisible: true,
                          measurementUnit: 'none',
                        });
                      }}
                    />
                  )}
                </div>
                <hr />
                <div className="">
                  <Row xs={1} lg={2} xl={3} className="g-2">
                    {values.specifications.map((s, index) => (
                      <Col className="" key={s._id}>
                        <Card className="h-100 rounded-4" body>
                          <fieldset
                            className="d-flex flex-column gap-3"
                            disabled={!values.editMode}
                            style={{
                              opacity: s.isVisible ? 1 : 0.5,
                            }}
                          >
                            <div>
                              <InputText
                                label="Specification Name"
                                nameOveride={`specifications.${index}.name`}
                                type="text"
                                size="sm"
                                floatingLabel
                              />
                            </div>
                            <div className="bg-body-secondary rounded-3 p-2 d-flex flex-column gap-2">
                              {s.options.map((o: any, optInd: number) => (
                                <div
                                  className="d-flex justify-content-between position-relative"
                                  key={optInd}
                                  style={{
                                    opacity: o.isVisible ? 1 : 0.5,
                                  }}
                                >
                                  <InputText
                                    nameOveride={`specifications.${index}.options.${optInd}.name`}
                                    type="text"
                                    showLabel={false}
                                    className="flex-fill"
                                  />
                                  <div className="position-absolute d-flex gap-2 top-50 end-0 translate-middle-y pe-2">
                                    <OverlayTrigger
                                      overlay={(p) => (
                                        <Tooltip {...p}>
                                          {o.isDefault
                                            ? 'Remove default'
                                            : 'Set as default'}
                                        </Tooltip>
                                      )}
                                    >
                                      <label>
                                        <Field
                                          type="radio"
                                          name={`specifications.${index}.options.${optInd}.isDefault`}
                                          value={optInd}
                                          checked={o.isDefault}
                                        >
                                          {({ field }: { field: any }) => (
                                            <div
                                              className="w-100"
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <input
                                                className="d-none"
                                                type="radio"
                                                {...field}
                                                onChange={() => {
                                                  replaceSpecification(index, {
                                                    ...s,
                                                    options: s.options.map(
                                                      (
                                                        op: any,
                                                        opInd: number,
                                                      ) => ({
                                                        ...op,
                                                        isDefault:
                                                          optInd === opInd
                                                            ? !o.isDefault
                                                            : false,
                                                      }),
                                                    ),
                                                  });
                                                }}
                                              />
                                              <FontAwesomeIcon
                                                icon={
                                                  o.isDefault
                                                    ? faCircleCheck
                                                    : faCircle
                                                }
                                                width={20}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </label>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                      overlay={(p) => (
                                        <Tooltip {...p}>
                                          {o.isVisible ? 'Hide' : 'Show'}
                                        </Tooltip>
                                      )}
                                    >
                                      <label>
                                        <Field
                                          type="checkbox"
                                          name={`specifications.${index}.options.${optInd}.isVisible`}
                                        >
                                          {({ field }: { field: any }) => (
                                            <div
                                              className="w-100"
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <input
                                                className="d-none"
                                                type="checkbox"
                                                {...field}
                                              />
                                              <FontAwesomeIcon
                                                icon={
                                                  o.isVisible
                                                    ? faEye
                                                    : faEyeSlash
                                                }
                                                width={20}
                                              />
                                            </div>
                                          )}
                                        </Field>
                                      </label>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              ))}
                              <FormControl
                                placeholder="Type an option and press Enter"
                                style={{ width: 'auto' }}
                                onKeyDown={(e) => {
                                  if (e.code === 'Enter' || e.key === 'Enter') {
                                    e.preventDefault();
                                    const name =
                                      (e.target as HTMLInputElement).value ||
                                      '';
                                    replaceSpecification(index, {
                                      ...s,
                                      options: s.options.concat({
                                        name,
                                        isDefault: false,
                                        isVisible: true,
                                      }),
                                    });
                                    (e.target as HTMLInputElement).value = '';
                                  }
                                }}
                              />
                            </div>
                            <div>
                              <div className="mb-2">Measurement Unit</div>
                              <ButtonGroup className="gap-2 w-100">
                                {[
                                  { label: 'none', icon: faXmark },
                                  { label: 'inches', icon: faRuler },
                                  { label: 'pounds', icon: faWeightScale },
                                ].map((b, bInd) => (
                                  <label key={bInd} className="flex-fill">
                                    <Field
                                      type="radio"
                                      name={`specifications.${index}.measurementUnit`}
                                      value={b.label}
                                    >
                                      {({ field }: { field: any }) => (
                                        <div
                                          className="w-100"
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <input
                                            className="d-none"
                                            type="radio"
                                            {...field}
                                          />
                                          <Button
                                            className="pe-none w-100 text-capitalize"
                                            variant={
                                              s.measurementUnit === b.label
                                                ? 'primary'
                                                : 'secondary'
                                            }
                                            content={
                                              <div className="d-flex align-items-center gap-2">
                                                <FontAwesomeIcon
                                                  icon={b.icon}
                                                />
                                                <span className="small">
                                                  {b.label}
                                                </span>
                                              </div>
                                            }
                                          />
                                        </div>
                                      )}
                                    </Field>
                                  </label>
                                ))}
                              </ButtonGroup>
                            </div>
                            <div>
                              <Field
                                type="checkbox"
                                name={`specifications.${index}.isVisible`}
                              >
                                {({ field }: { field: any }) => (
                                  <InputCheck
                                    {...field}
                                    nameOveride={field.name}
                                    type="switch"
                                    label="Is visible?"
                                    className="d-flex justify-content-between p-0"
                                  />
                                )}
                              </Field>

                              <Field
                                type="checkbox"
                                name={`specifications.${index}.isRequired`}
                              >
                                {({ field }: { field: any }) => (
                                  <InputCheck
                                    {...field}
                                    type="switch"
                                    nameOveride={field.name}
                                    label="Is required?"
                                    className="d-flex justify-content-between p-0"
                                  />
                                )}
                              </Field>
                            </div>
                          </fieldset>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Card>
            )}
          </FieldArray>
          <Card className="bg-body-secondary rounded-4 mt-4" body>
            <div
              className="d-flex gap-3 align-items-center lh-1"
              style={{ height: 30 }}
            >
              <svg
                className="svg-inline--fa fa-3x text-primary"
                id="Icons"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
                fill="currentColor"
              >
                <path d="M511.904,253.069c-1.554-48.548-44.377-84.942-104.409-88.656c-120.865-7.486-109.51-67.345-209.664-73.722 c-5.642-0.361-11.206-0.536-16.662-0.536c-102.294,0-171.103,61.839-180.098,137.418c-0.548,4.591-0.839,9.124-0.968,13.612H0 v52.824h0.31c3.218,66.926,53.423,119.234,135.051,121.51c48.123,1.342,182.039,5.082,224.552,6.268 c69.628,1.94,136.722-44.738,149.856-104.255c0.87-3.94,1.419-7.815,1.767-11.639H512v-52.824H511.904z M483.976,270.015 c-10.169,46.098-63.947,83.595-119.866,83.595c-1.154,0-2.308-0.02-3.462-0.046l-56.247-1.574l-168.305-4.694 c-36.877-1.032-66.784-13.638-86.496-36.458c-18.042-20.898-25.967-49.361-22.304-80.144 c7.996-67.196,71.272-114.127,153.874-114.127c4.913,0,9.956,0.162,14.985,0.484c40.514,2.579,58.118,14.489,80.41,29.578 c27.553,18.642,58.788,39.779,129.299,44.144c29.797,1.844,54.784,13.387,68.557,31.655 C484.569,235.897,487.877,252.353,483.976,270.015z" />
                <path d="M338.827,236.587l-101.03-7.222c-10.969-0.786-20.957-6.596-27.05-15.746l-27.257-40.882 c-3.037-4.553-9.182-5.784-13.734-2.747c-4.553,3.037-5.784,9.182-2.747,13.734l12.522,18.784 c5.474,8.215,7.287,18.338,5.004,27.946c-2.283,9.608-8.454,17.829-17.043,22.704l-38.947,22.104 c-4.759,2.702-6.43,8.744-3.728,13.502c2.702,4.759,8.744,6.43,13.502,3.728l69.48-39.431c6.1-3.469,13.09-5.043,20.086-4.546 l109.535,7.828c5.455,0.387,10.188-3.72,10.582-9.176C348.39,241.714,344.289,236.974,338.827,236.587z" />
              </svg>
              <div className="d-flex flex-column justify-content-between text-start">
                <div className="fw-bolder fs-2">Cuts</div>
              </div>
            </div>
            <hr />
            <div className="">
              <Row xs={1} lg={2} xl={3} className="g-2">
                {map(
                  orderBy(foundPrimalGroups, (fPG) => fPG.order),
                  (foundPrimalGroup) =>
                    renderSection(
                      foundPrimalGroup?.name ?? '',
                      foundPrimalGroup?.primals ?? [],
                      values,
                    ),
                )}
                <ExtrasTrimSection />
              </Row>
            </div>
          </Card>
        </>
      )}
    </Form>
  );
}
