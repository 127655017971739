import { Button } from '@farmshare/ui-components';
import { animalSpeciesHelper } from '@farmshare/utils';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { CalendarApi, MoreLinkArg } from '@fullcalendar/core';
import { DateClickArg } from '@fullcalendar/interaction';
import moment, { Moment } from 'moment';
import { useCallback, useRef, useState } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState, vendorState } from 'state';

import {
  EnumProcessorSchedulingStatus,
  Maybe,
  ProcessorScheduling,
  ProcessorSchedulingCalendarDocument,
  ProcessorSchedulingCalendarQuery,
  ProcessorSchedulingCalendarQueryVariables,
  useProcessorSchedulingCalendarLazyQuery,
  ViewProcessorAdminQueryResult,
} from 'lib/graphql';

import { CalendarDateCell } from './_views/calendar-date-cell';
import { CalendarDateDetails } from './_views/calendar-date-details';
import { CalendarEventContent } from './_views/calendar-event-content';
import { CalendarLegend } from './_views/calendar-legend';
import { MobileCustomView } from './_views/calendar-mobile';
import { ScheduleJob } from './_views/schedule-job';
import { BigCalendar } from '../../../components/big-calendar/big-calendar';
import { GenerateKillsheetButton } from '../lib/_views/generate-killsheet-button';
import {
  ALL_SHOWN_STATUSES,
  getStatusColor,
} from '../lib/helpers/processorStatusPresentation';
import { useProcessorSchedulingModals } from '../lib/hooks/useProcessorSchedulingModals';
import { useStatusChanges } from '../lib/hooks/useStatusChanges';

const eventsAccessor = (
  data: ProcessorSchedulingCalendarQuery,
  filters: string[],
) =>
  data.getProcessorSchedulingCalendar?.reduce((acc, processorScheduling) => {
    if (!processorScheduling) {
      return acc;
    }

    const {
      requestedBy,
      headCount,
      animalSpecies,
      status,
      dropoffDate,
      animalHeads,
    } = processorScheduling;
    const baseEvent = {
      start: moment.utc(dropoffDate).format('YYYY-MM-DD'),
      title: `Drop-off: ${
        requestedBy?.company ? requestedBy.company : requestedBy?.last_name
      }: ${headCount} ${animalSpeciesHelper(animalSpecies).label}`,
      allDay: true,
      color: getStatusColor(status),
      ...processorScheduling,
    };

    if (filters.includes('dropOffDate')) {
      acc.push(baseEvent);
    }
    if (filters.includes('harvestDate')) {
      if (animalHeads && animalHeads.length > 0 && animalHeads[0]?.killDate) {
        const harvestEvent = {
          ...baseEvent,
          title: `Harvest: ${
            requestedBy?.company ? requestedBy.company : requestedBy?.last_name
          }: ${headCount} ${animalSpeciesHelper(animalSpecies).label}`,
          start: moment.utc(animalHeads[0].killDate).format('YYYY-MM-DD'),
        };
        acc.push(harvestEvent);
      }
    }
    if (filters.includes('cutDate')) {
      if (animalHeads && animalHeads.length > 0 && animalHeads[0]?.cutDate) {
        const cutEvent = {
          ...baseEvent,
          title: `Cut: ${
            requestedBy?.company ? requestedBy.company : requestedBy?.last_name
          }: ${headCount} ${animalSpeciesHelper(animalSpecies).label}`,
          start: moment.utc(animalHeads[0].cutDate).format('YYYY-MM-DD'),
        };
        acc.push(cutEvent);
      }
    }

    return acc;
  }, [] as any[]);

export default function ProcessorCalendar({
  settings,
}: {
  settings?: Maybe<ViewProcessorAdminQueryResult>;
}) {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const vendor = useRecoilValue(vendorState);
  const today = moment().startOf('day');

  const [viewFilters, setViewFilters] = useState<string[]>(['dropOffDate']);
  const processorSchedulingCalendarQuery =
    useProcessorSchedulingCalendarLazyQuery({ fetchPolicy: 'network-only' });

  const [selectedDate, setSelectedDate] = useState<Date>();
  const [selectedDateProcessors, setSelectedDateProcessors] =
    useState<ProcessorScheduling[]>();

  const [isSideOverVisible, setIsSideOverVisible] = useState(false);
  const [isScheduleJobSideOverVisible, setIsScheduleJobSideOverVisible] =
    useState(false);

  const [scheduleDatesRange, setScheduleDatesRange] =
    useState<ScheduleDatesRange>();

  const calendarRef = useRef<
    { getApi: () => CalendarApi | undefined } | undefined
  >();

  const buildQueryVariables = useCallback(
    (startDate: string | undefined, endDate: string | undefined) => ({
      endDate,
      startDate,
      vendorId: vendor?._id,
      filter: {
        OR: ALL_SHOWN_STATUSES.map((s) => ({
          status: s as EnumProcessorSchedulingStatus,
        })),
      },
    }),
    [vendor?._id],
  );

  const toggleViewFilter = (filter: string) => {
    if (viewFilters.includes(filter)) {
      setViewFilters(viewFilters.filter((f) => f !== filter));
    } else {
      setViewFilters([...viewFilters, filter]);
    }
  };
  const viewFilterVariant = (filter: string) => {
    if (viewFilters.includes(filter)) {
      return 'primary';
    }
    return window.localStorage.getItem('theme') ?? 'light';
  };

  const statusChanges = useStatusChanges([
    {
      query: ProcessorSchedulingCalendarDocument,
      variables: buildQueryVariables(
        moment(selectedDate).startOf('months').format('YYYY-MM-DD'),
        moment(selectedDate).endOf('months').format('YYYY-MM-DD'),
      ),
    },
  ]);

  const onProcessorChange = (
    processsorId: string,
    newStatus: EnumProcessorSchedulingStatus,
  ) => {
    let updatedProcessors: ProcessorScheduling[];

    if (isSideOverVisible) {
      if (moment().isSame(moment(selectedDate), 'day')) {
        updatedProcessors = selectedDateProcessors!.map((processor) => {
          return processor._id === processsorId
            ? Object.assign(processor, { status: newStatus })
            : processor;
        });
      } else {
        updatedProcessors = selectedDateProcessors!.filter((processor) => {
          return processor._id !== processsorId;
        });
      }

      setSelectedDateProcessors(updatedProcessors);
    }
  };

  const { openCancelModal, openDetailsModal } = useProcessorSchedulingModals(
    statusChanges.updateScheduling,
    onProcessorChange,
  );

  const openSlider = useCallback(
    (date: Moment, events: ProcessorScheduling[], info?: DateClickArg) => {
      if (
        events.length ||
        (info?.jsEvent.target as HTMLElement)?.classList.contains(
          'btn-view-days-agenda',
        ) ||
        date.isBefore(today)
      ) {
        setSelectedDate(date.toDate());
        setSelectedDateProcessors(events);
        setIsSideOverVisible(true);
        return;
      }

      setScheduleDatesRange({
        start: date.startOf('day'),
        end: date.endOf('day'),
      });
      setIsScheduleJobSideOverVisible(true);
    },
    [today],
  );

  const moreLinkClick = useCallback(
    (eventArgs: MoreLinkArg) => {
      const { date, allSegs } = eventArgs;

      const events = allSegs.map(
        (seg) => seg.event.extendedProps as ProcessorScheduling,
      );

      openSlider(moment(date), events);
    },
    [openSlider],
  );

  function ScheduleButton({ type }: { type: 'day' | 'calendar' }) {
    const sDate = moment(selectedDate);
    // If button is in CalendarDateDetails and selected date is before today don't show anything
    if (type === 'day' && sDate.isBefore(today)) return;
    return (
      <Button
        content="Schedule New Job"
        icon={faCalendarPlus}
        onClick={() => {
          if (type === 'day') {
            setScheduleDatesRange({
              start: sDate.startOf('day'),
              end: sDate.endOf('day'),
            });
          } else {
            const calendarApi = calendarRef.current?.getApi();
            const currentStart = moment(calendarApi?.view.currentStart);
            const currentEnd = moment(calendarApi?.view.currentEnd).subtract(
              1,
              'days',
            );
            setScheduleDatesRange({
              // if currentStart is before today start from today
              start: currentStart.isBefore(today) ? today : currentStart,
              // if currentEnd is before today end with lasy day of the today's month
              end: currentEnd.isBefore(today)
                ? today.endOf('month')
                : currentEnd,
            });
          }
          setIsScheduleJobSideOverVisible(true);
        }}
      />
    );
  }

  return (
    <>
      <div className="pb-5 h-100">
        <Stack
          direction="horizontal"
          className="justify-content-between pb-2 mb-4"
        >
          <div>
            <h2 className="fw-bold">
              {user?.first_name ? `Welcome, ${user.first_name}!` : 'Welcome!'}
            </h2>
            <p className="m-0">
              Check out your current bookings and update the status of ongoing
              processes here.
            </p>
          </div>
        </Stack>
        <Container fluid className="p-0">
          <BigCalendar<
            ProcessorScheduling,
            ProcessorSchedulingCalendarQuery,
            ProcessorSchedulingCalendarQueryVariables
          >
            query={processorSchedulingCalendarQuery}
            buildQueryVariables={buildQueryVariables}
            dateClick={openSlider}
            eventsAccessor={(data, filters) =>
              eventsAccessor(data, viewFilters)
            }
            viewFilters={viewFilters}
            toggleViewFilter={toggleViewFilter}
            viewFilterVariant={viewFilterVariant}
            eventContent={CalendarEventContent}
            dayMaxEventRows={4}
            mobileOptions={{
              component: MobileCustomView({
                openCancelModal,
                processorChangeCallback: onProcessorChange,
                statusChanges,
              }),
            }}
            eventClick={(arg) => {
              navigate(`/processing-job/${arg.event.extendedProps._id}`);
            }}
            moreLinkClick={moreLinkClick}
            headerRightPanel={CalendarLegend}
            headerLeftPanel={
              <>
                <GenerateKillsheetButton />
                <ScheduleButton type="calendar" />
              </>
            }
            dayCellContent={(args) => <CalendarDateCell {...args} />}
            calendarRef={calendarRef}
          />
        </Container>
      </div>

      <CalendarDateDetails
        events={selectedDateProcessors}
        hideSideOver={() => setIsSideOverVisible(false)}
        isOpen={isSideOverVisible}
        openCancelModal={openCancelModal}
        processorChangeCallback={onProcessorChange}
        statusChanges={statusChanges}
        date={selectedDate}
        actions={<ScheduleButton type="day" />}
      />

      <ScheduleJob
        hideSideOver={() => {
          setIsScheduleJobSideOverVisible(false);
          /** @author @abdullahcalisir12
           * Why setTimeout and not directly calling setScheduleDatesRange to undefined?
           * to wait for offcanvas animation to end to remove selected date range for schedule dates
           * if not, selectable dates are changing during offcanvas hide and it shows UI kind of buggy
           */
          setTimeout(() => {
            setScheduleDatesRange(undefined);
          }, 350);
        }}
        isOpen={isScheduleJobSideOverVisible}
        scheduleDatesRange={scheduleDatesRange}
        settings={settings}
      />
    </>
  );
}
