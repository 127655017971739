import { assign, map } from 'lodash';
import { useMemo } from 'react';
import { Table } from 'react-bootstrap';

import { DataDetailListProps } from './data-detail-list';

export function DataDetailListMinimal({
  heading,
  rows,
  tableProps,
}: DataDetailListProps) {
  const _tableProps = useMemo(
    () => assign({ size: 'sm', borderless: true }, tableProps),
    [tableProps],
  );

  return (
    <div>
      {heading && <h6 className="pb-2 fw-bold">{heading}</h6>}
      <Table {..._tableProps}>
        <tbody>
          {map(rows, (row, idx) => (
            <tr
              key={idx}
              className={
                'align-middle' + (row.className ? ` ${row.className}` : '')
              }
            >
              <td className="fw-bold text-nowrap w-50 label">
                {row.label}&nbsp;
              </td>
              <td className="value">{row.value || '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
