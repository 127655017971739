import {
  type InputChipOption,
  InputChips,
  InputText,
  InfoBox,
  InputSelect,
  InputQuantity,
} from '@farmshare/ui-components';
import {
  formatToCurrency,
  RecursivePartial,
  capitalize,
} from '@farmshare/utils';
import { type FormikProps } from 'formik';
import {
  concat,
  filter,
  find,
  groupBy,
  intersection,
  map,
  orderBy,
  range,
  reduce,
  startCase,
  unionWith,
} from 'lodash';
import { useCallback, useMemo } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';

import {
  EnumPrimalCutType,
  type ProcessorCapability,
  type PrimalCut,
  ProcessorSettings,
  EnumProcessorSchedulingAnimalSpecies,
} from 'lib/graphql';

import {
  ExtrasModel,
  ExtrasTrimOptionsType,
  TrimModel,
} from 'pages/processor/cutsheets/_views/cutsheet-modal';

export interface SchedulingCustomCutsheetModalForm {
  name: string;
  cuts: InputChipOption[];
  pricePerPound?: number;
  steaksPerPack?: string;
  steakThickness?: string;
  groundMeatSize?: string;
  roastSize?: string;
  extras?: ExtrasTrimOptionsType['extras'];
  trim?: ExtrasTrimOptionsType['trim'];
}

interface SchedulingCustomCutsheetModalProps {
  fP: FormikProps<SchedulingCustomCutsheetModalForm>;
  capabilities?: RecursivePartial<ProcessorCapability>[];
  animalSpecies?: EnumProcessorSchedulingAnimalSpecies;
  settings?: RecursivePartial<ProcessorSettings>;
}

export function SchedulingCustomCutsheetModal({
  fP,
  capabilities,
  settings,
  animalSpecies,
}: SchedulingCustomCutsheetModalProps) {
  const selectedCutsById = useMemo(
    () => map(fP.values.cuts, (c) => c.value),
    [fP.values.cuts],
  );

  const getCutOption: (cut: PrimalCut) => InputChipOption = useCallback(
    (cut: PrimalCut) => {
      // checking to see if any of the 'blockedBy' values are currently selected
      const disabled = intersection(selectedCutsById, cut.blockedBy).length > 0;
      return { label: cut.name, value: cut._id, disabled };
    },
    [selectedCutsById],
  );

  const foundCapability = useMemo(() => {
    // Sorry about this code. However it was the only way many or no inspection levels on cutsheets until the triplicated primal cuts is refactored.
    // We get the current active inspection levels for the animalSetting for the selected species.
    if (animalSpecies) {
      const animalSettings = find(
        settings?.animalSettings,
        (o) =>
          (o?.species as unknown as EnumProcessorSchedulingAnimalSpecies) ===
          animalSpecies,
      );
      const inspectionLevels = animalSettings?.inspectionLevels ?? [];
      // We will now get all processor capabilities that match the species and have no inspection level or an inspection level that is currently active for that species.
      const processorCapabilities = filter(capabilities, (o) => {
        const isSpeciesMatch = o.animalSpecies
          ? (o.animalSpecies as unknown as EnumProcessorSchedulingAnimalSpecies) ===
            animalSpecies
          : false;

        const isInspectionLevelMatch = o.inspectionLevel
          ? inspectionLevels.includes(o.inspectionLevel)
          : true;

        return isSpeciesMatch && isInspectionLevelMatch;
      });

      // Compare the union with pc.cuts.type pc.cuts.name and pc.primate.name
      // Just carry all the trim and extras for each.

      // Now we reduce this list into one pseudo processorCapability. With things like cuts and extras and trim are actually unions of those properties from all the processor capabilities
      const pseudoProcessorCapability = reduce(
        processorCapabilities,
        (acc: any, pc) => {
          // So silly we have to filter out any nulls or undefined from the extras even though that isn't actually possible to have but the generated types can't figure that out
          acc.extras = concat(acc.extras, filter(pc.extras, (o) => !!o) ?? []);
          acc.trim = concat(acc.trim, filter(pc.trim, (o) => !!o) ?? []);

          // Union the cuts
          acc.cuts = unionWith(acc.cuts, pc.cuts, (left, right) => {
            return (
              left?.type === right?.type &&
              left?.name === right?.name &&
              left?.primal?.name === right?.primal?.name
            );
          });
          return acc;
        },
        {
          animalSpecies,
          cuts: [],
          extras: [],
          trim: [],
          vendor: settings?.vendor?._id,
        },
      );

      return pseudoProcessorCapability;
    }
  }, [
    animalSpecies,
    capabilities,
    settings?.animalSettings,
    settings?.vendor?._id,
  ]);

  const renderSubGroup = useCallback(
    (cuts: PrimalCut[], label: string) => {
      return (
        <div key={label}>
          <div className="mb-1 fs-6">{label}</div>
          <InputChips
            nameOveride="cuts"
            options={map(orderBy(cuts, 'order'), getCutOption)}
            size="sm"
            required
          />
        </div>
      );
    },
    [getCutOption],
  );

  const renderExtraTrimSection = useCallback(
    (label: 'extras' | 'trim') => {
      if (animalSpecies && fP.values?.[label]?.[animalSpecies]) {
        return (
          <div key={label}>
            <div className="fw-bold fs-4 mb-3">{startCase(label)}</div>
            {map(
              fP.values?.[label][animalSpecies],
              (item: ExtrasModel | TrimModel, index: number) => {
                return (
                  <div
                    key={`${animalSpecies}-${item.name}-${index}`}
                    className="gx-1 mb-3 d-flex flex-wrap justify-content-between align-items-center me-3"
                  >
                    <InputChips
                      options={[
                        {
                          label: `${capitalize(item.name)} / ${formatToCurrency(
                            item.pricePerPound,
                          )}/lb ${
                            (item.minLbs ?? 0) > 0
                              ? ` / ${item?.minLbs}lb min `
                              : ''
                          }`,
                          value: item.name,
                          isActive: item.isActive,
                        },
                      ]}
                      size="sm"
                      action={() => {
                        if ((item as TrimModel).disabled) {
                          return;
                        }

                        fP.setFieldValue(`${label}.${animalSpecies}.${index}`, {
                          ...item,
                          isActive: !item.isActive,
                        });
                      }}
                    />

                    {item.minLbs ? (
                      <InputQuantity
                        nameOveride={`${label}.${animalSpecies}.${index}.quantity`}
                        step={1}
                        min={0}
                        showEdit
                        disabled={
                          label === 'trim' && (item as TrimModel).disabled
                        }
                      />
                    ) : null}

                    {label === 'trim' && (
                      <div
                        style={{
                          visibility:
                            item.isActive && item.minLbs ? 'visible' : 'hidden',
                        }}
                      >
                        <InputChips
                          options={[
                            {
                              label: 'All trim',
                              value: 'allTrim',
                              isActive: (item as TrimModel).isAllTrim,
                            },
                          ]}
                          size="sm"
                          action={() => {
                            const animalSpecie = animalSpecies;
                            const trimOptions = fP.values.trim?.[animalSpecie];

                            if (trimOptions && trimOptions.length > 0) {
                              for (let i = 0; i < trimOptions.length; i++) {
                                const allTrimToggle = !(item as TrimModel)
                                  .isAllTrim;
                                if (i === index) {
                                  // keep option active and set quantity to 0
                                  fP.setFieldValue(
                                    `${label}.${animalSpecie}.${i}`,
                                    {
                                      ...item,
                                      isAllTrim: allTrimToggle,
                                      disabled: allTrimToggle,
                                      ...(allTrimToggle && {
                                        quantity: 0,
                                        rank: 1,
                                      }),
                                    },
                                  );
                                } else {
                                  // disable all other options and set quantity to 0
                                  fP.setFieldValue(
                                    `${label}.${animalSpecie}.${i}`,
                                    {
                                      ...fP.values.trim?.[animalSpecie][i],
                                      isAllTrim: false,
                                      isActive: false,
                                      quantity: 0,
                                      disabled: allTrimToggle,
                                      rank: 1,
                                    },
                                  );
                                }
                              }
                            }
                          }}
                        />
                      </div>
                    )}

                    {label === 'trim' && (
                      <div style={{ width: '75px' }}>
                        <InputText
                          label="Rank"
                          nameOveride={`${label}.${animalSpecies}.${index}.rank`}
                          type="number"
                          size="sm"
                          floatingLabel
                          required={item.isActive}
                          min={1}
                          disabled={
                            label === 'trim' && (item as TrimModel).disabled
                          }
                        />
                      </div>
                    )}
                  </div>
                );
              },
            )}
          </div>
        );
      }
    },
    [fP, animalSpecies],
  );

  const renderGroup = useCallback(
    (cuts: PrimalCut[] = [], label: string) => {
      const subGroups = groupBy(cuts, (c) => {
        let _name = c.primal?.name;
        if (c.type !== EnumPrimalCutType.Other) {
          _name += ` ${startCase(c.type)}s`;
        }
        return _name;
      });
      return (
        <Col key={label}>
          <Card className="h-100" body>
            <Stack gap={2}>
              <div className="fw-bold fs-5">{label}</div>
              {map(subGroups, renderSubGroup)}
            </Stack>
          </Card>
        </Col>
      );
    },
    [renderSubGroup],
  );

  const groupedCapabilities = useMemo(() => {
    if (foundCapability) {
      return groupBy(foundCapability?.cuts, (c) => c?.primal?.group?.name);
    }
    return [];
  }, [foundCapability]);

  return (
    <>
      <div className="fs-5 fw-bold mb-2">Details</div>
      <Row xs={1} lg={3} className="g-2">
        <Col>
          <InputText
            label="Name"
            type="text"
            hint="Make this name something that is recognizable to you."
            floatingLabel
            required
          />
        </Col>
      </Row>
      <hr />
      <div className="fs-5 fw-bold mb-2">Specifications</div>
      <Row xs={1} lg={4} className="g-2">
        <Col>
          <InputSelect
            label="Ground Meat Size (lb.)"
            options={map([1, 1.5, 2], (value) => ({
              label: `${value} lb.`,
              value,
            }))}
            nameOveride="groundMeatSize"
            floatingLabel
            required
          />
        </Col>
        <Col>
          <InputSelect
            label="Roast Size (lb.)"
            options={map(['2-3', '3-4', '4-5'], (value) => ({
              label: `${value} lb.`,
              value,
            }))}
            nameOveride="roastSize"
            floatingLabel
          />
        </Col>
        <Col>
          <InputSelect
            label="Steak Thickness (in.)"
            nameOveride="steakThickness"
            options={map([0.75, 1, 1.25, 1.5, 1.75, 2], (value) => ({
              label: `${value} in.`,
              value,
            }))}
            floatingLabel
          />
        </Col>
        <Col>
          <InputSelect
            label="Steaks/Pack"
            nameOveride="steaksPerPack"
            options={map(range(1, 5, 1), (value) => ({
              label: value.toString(),
              value,
            }))}
            floatingLabel
          />
        </Col>
      </Row>
      <hr />
      <div className="fs-5 fw-bold mb-2">Cuts</div>
      <InfoBox
        content={
          <>
            Select all cuts that you would like. Every unselected cut will
            default to ground meat.
          </>
        }
      />

      {groupedCapabilities && (
        <Row xs={1} lg={2} xl={3} className="g-2">
          {map(groupedCapabilities, renderGroup)}
          <Col>
            <Card className="h-100" body>
              <Stack gap={2}>
                {map(['extras', 'trim'], renderExtraTrimSection)}
              </Stack>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}
