import {
  InputSelect,
  InputTextArea,
  InputChipOption,
  Button,
} from '@farmshare/ui-components';
import { RecursivePartial, formatFullName } from '@farmshare/utils';
import { FieldArray, useFormikContext } from 'formik';
import { filter, map } from 'lodash';
import { useMemo } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import { CutsheetDetailsButton } from 'components/cutsheet-details/cutsheet-details-button';

import {
  Cutsheet,
  EnumCutsheetInspectionLevels,
  EnumCutsheetType,
  EnumProcessorSchedulingAnimalHeadsInspectionLevel,
  EnumProcessorSchedulingAnimalSpecies,
  Maybe,
  ProcessorCapability,
  ProcessorSchedulingAnimalHeads,
  ProcessorSchedulingAnimalHeadsCutsheetInformation,
  ViewSchedulingCreateQuery,
} from 'lib/graphql';

import { ExtrasTrimOptionsType } from 'pages/processor/cutsheets/_views/cutsheet-modal';

import { AnimalHeadsCardHeader } from '../../../components/animal-heads/animal-heads-card-header';

interface CutsheetOptions {
  splitTypes: Maybe<string>[];
  inspectionLevels: Maybe<EnumCutsheetInspectionLevels>[];
  cutsheetType: EnumCutsheetType;
  label: string;
  value: string;
}

export interface CutsheetRequest {
  animalHeadId: string;
  animalHead?: RecursivePartial<ProcessorSchedulingAnimalHeads | null>;
  animalSpecies?: EnumProcessorSchedulingAnimalSpecies;
  animalIndex?: number;
  requestedBy?: {
    first_name?: string;
    last_name?: string | null;
  };
  splitType: string | undefined;
  inspectionLevel:
    | EnumProcessorSchedulingAnimalHeadsInspectionLevel
    | undefined;
  heading: string;
  cutsheets: RecursivePartial<ProcessorSchedulingAnimalHeadsCutsheetInformation | null>[];
}

export interface EditCutsheetsForm {
  cutsheetRequests: CutsheetRequest[];
}

export interface EditCutsheetsProps {
  cutsheetOptions: CutsheetOptions[];
  validCutsheets: Cutsheet[];
  extrasTrimOptions: ExtrasTrimOptionsType;
  userCutsheets: ViewSchedulingCreateQuery['cutsheetMany'];
  cutsheetRequest: CutsheetRequest;
  setUserCutsheets: (
    newUserCutsheets: ViewSchedulingCreateQuery['cutsheetMany'],
  ) => void;
  idx: number;
  capabilities?: RecursivePartial<ProcessorCapability>[];
}

function EditCutsheet({
  cutsheetOptions,
  cutsheetRequest,
  idx,
  extrasTrimOptions,
  userCutsheets,
  setUserCutsheets,
  validCutsheets,
  capabilities,
}: EditCutsheetsProps) {
  const { schedulingId } = useParams();
  const { values, setFieldValue, getFieldProps } =
    useFormikContext<EditCutsheetsForm>();
  const user = useRecoilValue(userState);

  const validCutsheetOptions: InputChipOption[] = useMemo(() => {
    const filtered: CutsheetOptions[] = filter(cutsheetOptions, (option) => {
      // Custom cutsheets don't have split types or inspection levels they always match
      if (
        option.cutsheetType === EnumCutsheetType.Orphan ||
        option.cutsheetType === EnumCutsheetType.User
      ) {
        return true;
      }

      const hasMatchingSplitType =
        cutsheetRequest.splitType && option.splitTypes?.length > 0
          ? option.splitTypes?.includes(cutsheetRequest.splitType)
          : option.splitTypes.length === 0;

      const hasMatchingInspectionLevel =
        cutsheetRequest.inspectionLevel && option.inspectionLevels?.length > 0
          ? option.inspectionLevels?.includes(
              cutsheetRequest.inspectionLevel as unknown as EnumCutsheetInspectionLevels,
            )
          : option.inspectionLevels?.length === 0;

      return (
        Boolean(hasMatchingSplitType) && Boolean(hasMatchingInspectionLevel)
      );
    });

    return map(filtered, (o) => ({
      value: o.value,
      label: o.label,
      cutsheetType: o.cutsheetType,
    }));
  }, [cutsheetOptions, cutsheetRequest]);

  const applyToAllForContact = (
    requestIndex: number,
    cutsheetIndex: number,
  ) => {
    const selectedCutsheetId =
      values.cutsheetRequests[requestIndex].cutsheets[cutsheetIndex]?.cutsheet
        ?._id;
    const contactId =
      values.cutsheetRequests[requestIndex].cutsheets[cutsheetIndex]
        ?.contactUser?._id;

    values.cutsheetRequests.forEach((cr, idx) => {
      cr.cutsheets.forEach((cutsheet, jdx) => {
        if (contactId === cutsheet?.contactUser?._id) {
          const fieldName = `cutsheetRequests.[${idx}].cutsheets.[${jdx}].cutsheet._id`;
          setFieldValue(fieldName, selectedCutsheetId);
        }
      });
    });
  };

  return (
    <div>
      <Card>
        <AnimalHeadsCardHeader
          isActive={false}
          cardHeader={cutsheetRequest.heading}
          producerIdentifier={cutsheetRequest.splitType}
          splitValue={cutsheetRequest.inspectionLevel}
        />
        <Card.Body>
          {!cutsheetRequest.inspectionLevel ? (
            <div>
              You need to select an inspection level to this head before adding
              a cutsheet. click{' '}
              <Link
                to={`/processing-job/${schedulingId}/head/${cutsheetRequest.animalHeadId}`}
              >
                here
              </Link>{' '}
              to do that now
            </div>
          ) : (
            <Stack gap={3}>
              {map(cutsheetRequest.cutsheets, (cr, jdx) => {
                const cutsheetToEdit = validCutsheets.find(
                  (validCutsheet) =>
                    validCutsheet._id ===
                    getFieldProps(
                      `cutsheetRequests.[${idx}].cutsheets.[${jdx}].cutsheet._id`,
                    ).value,
                );

                return (
                  <div key={jdx}>
                    <Row>
                      <Col className="mb-2">
                        Customer Name:{' '}
                        <span className="fw-bold">
                          {formatFullName(
                            cr?.contactUser?.first_name ?? '',
                            cr?.contactUser?.last_name ?? '',
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <InputSelect
                          label="Cutsheet"
                          nameOveride={`cutsheetRequests.[${idx}].cutsheets.[${jdx}].cutsheet._id`}
                          floatingLabel
                          options={validCutsheetOptions}
                        />
                        <Button
                          className={`p-0 ${
                            Boolean(
                              getFieldProps(
                                `cutsheetRequests.[${idx}].cutsheets.[${jdx}].cutsheet._id`,
                              ).value,
                            ) &&
                            // I don't like following comparison, but the same logic used on submit
                            // TODO: Update default value of InputSelect
                            getFieldProps(
                              `cutsheetRequests.[${idx}].cutsheets.[${jdx}].cutsheet._id`,
                            ).value !== 'Select one...'
                              ? 'd-block'
                              : 'd-none'
                          }`}
                          size="sm"
                          variant="link"
                          content="Apply to all for contact"
                          onClick={() => applyToAllForContact(idx, jdx)}
                        />
                      </Col>

                      <Col>
                        <InputTextArea
                          label="Notes"
                          nameOveride={`cutsheetRequests.[${idx}].cutsheets.[${jdx}].notes`}
                          floatingLabel
                        />
                      </Col>

                      {cutsheetToEdit && (
                        <Col xs="auto" className="mt-3">
                          <CutsheetDetailsButton
                            content={cutsheetToEdit.user ? 'Edit' : 'View'}
                            cutsheet={cutsheetToEdit}
                            extrasTrimOptions={extrasTrimOptions}
                            animalSpecies={cutsheetRequest.animalSpecies}
                            capabilities={capabilities}
                            setUserCutsheets={setUserCutsheets}
                            userCutsheets={userCutsheets}
                            canCustomizeCutsheet={
                              cutsheetToEdit.type === EnumCutsheetType.User &&
                              cutsheetToEdit.user === user?._id
                            }
                          />
                        </Col>
                      )}
                    </Row>
                  </div>
                );
              })}
            </Stack>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}

export function EditCutsheets({
  cutsheetOptions,
  validCutsheets,
  extrasTrimOptions,
  userCutsheets,
  setUserCutsheets,
  capabilities,
}: {
  cutsheetOptions: CutsheetOptions[];
  validCutsheets: Cutsheet[];
  extrasTrimOptions: ExtrasTrimOptionsType;
  userCutsheets: ViewSchedulingCreateQuery['cutsheetMany'];
  setUserCutsheets: (
    newUserCutsheets: ViewSchedulingCreateQuery['cutsheetMany'],
  ) => void;
  capabilities?: RecursivePartial<ProcessorCapability>[];
}) {
  const { values } = useFormikContext<EditCutsheetsForm>();

  return (
    <Stack gap={3}>
      <FieldArray
        name="cutsheetRequests"
        render={() =>
          map(values.cutsheetRequests, (cutsheetRequest, idx) => {
            return (
              <EditCutsheet
                validCutsheets={validCutsheets}
                userCutsheets={userCutsheets}
                setUserCutsheets={setUserCutsheets}
                extrasTrimOptions={extrasTrimOptions}
                cutsheetOptions={cutsheetOptions}
                key={idx}
                cutsheetRequest={cutsheetRequest}
                idx={idx}
                capabilities={capabilities}
              />
            );
          })
        }
      />
    </Stack>
  );
}
