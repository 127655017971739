import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ReactNode, useMemo } from 'react';
import { Stack, ButtonGroup } from 'react-bootstrap';

import { Button, type ButtonProps } from '../button/button';

export interface SelectableChipProps {
  label: string;
  selected: boolean;
  onSelect: (e: any) => void;
  disabled?: boolean;
  id?: string;
  size?: ButtonProps['size'];
  action?: ReactNode;
  isEditable?: boolean;
  onEdit?: () => void;
  isDeletable?: boolean;
  onDelete?: () => void;
}

export function SelectableChip({
  label,
  selected,
  disabled,
  id,
  size,
  onSelect,
  action,
  isEditable,
  onEdit,
  isDeletable,
  onDelete,
}: SelectableChipProps) {
  const variant = useMemo(() => {
    if (selected) {
      return 'primary';
    }
    return window.localStorage.getItem('theme') ?? 'light';
  }, [selected]);

  if ((isEditable && Boolean(onEdit)) || (isDeletable && Boolean(onDelete))) {
    return (
      <ButtonGroup>
        <Button
          content={label}
          size={size}
          variant={variant}
          onClick={(e) => onSelect(e)}
          disabled={disabled}
          className={'chip-' + id}
          id={id}
        />
        {isEditable && Boolean(onEdit) && (
          <Button
            className={'edit-chip-' + id}
            icon={faEdit}
            size={size}
            variant={variant}
            onClick={onEdit}
          />
        )}
        {isDeletable && Boolean(onDelete) && (
          <Button
            className={'delete-chip-' + id}
            icon={faTrash}
            size={size}
            variant={variant}
            onClick={onDelete}
          />
        )}
      </ButtonGroup>
    );
  }

  return (
    <Stack
      direction="horizontal"
      className={action ? 'justify-content-between' : ''}
    >
      <Button
        content={label}
        size={size}
        variant={variant}
        className={'rounded-pill border chip-' + id}
        onClick={(e) => onSelect(e)}
        disabled={disabled}
        id={id}
      />
      {action}
    </Stack>
  );
}
