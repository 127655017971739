export function animalSpeciesHelper(value: string) {
  switch (value.toLowerCase()) {
    case 'beef':
      return { label: 'Beef', order: 0, value };

    case 'hog':
      return { label: 'Hog', order: 1, value };

    case 'bison':
      return { label: 'Bison', order: 2, value };

    case 'lamb':
      return { label: 'Lamb', order: 3, value };

    case 'goat':
      return { label: 'Goat', order: 4, value };

    case 'venison':
      return { label: 'Venison', order: 5, value };

    default:
      return { label: value, order: 99, value };
  }
}
