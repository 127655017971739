import { DayCellContentArg } from '@fullcalendar/core';

import styles from './calendar-date-cell.module.scss';

export const CalendarDateCell = (test: DayCellContentArg) => {
  return (
    <div>
      <div>{test.dayNumberText}</div>
      {/**
       * @author @abdullahcalisir12
       * Don't remove btn-view-days-agenda class in the className
       * It's used to differentiate if click was made from day or view agenda
       */}
      <div
        className={`${styles.calendarDateCellLink} cell-agenda-link btn-view-days-agenda`}
      >
        View Day's Agenda
      </div>
    </div>
  );
};
