import { PageTitle } from '@farmshare/ui-components';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import ChatContainer from 'components/chat/ChatContainer';

export default function ChatPage() {
  const { userId } = useParams();
  return (
    <div className="pt-4">
      <PageTitle title="Chat" breadCrumbLabel="Chat" />

      <Container
        style={
          {
            '--chat-container-height': 'calc(100vh - 320px)',
          } as React.CSSProperties
        }
      >
        <ChatContainer pageType="customer" customerId={userId} />
      </Container>
    </div>
  );
}
