import { Loading } from '@farmshare/ui-components';
import { kebabCase, map } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { Container, Tab } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useViewProcessorAdminQuery } from 'lib/graphql';

import { ProcessorAddJobTab } from './add-job';
import ProcessorCalendar from './calendar';
import { ProcessorCapabilitiesTab } from './capabilities';
import ProcessorChatPage from './chat';
import { ConfigurationErrors } from './configuration-errors';
import ProcessorCustomers from './customers';
import ProcessorCutsheets from './cutsheets';
import ProcessorDashboard from './dashboard';
import { JobsTab } from './jobs';
import SchedulingSettings from './scheduling-settings';

interface Tab {
  key: string;
  title: string;
  element: ReactNode;
}

export default function ProcessorAdminPage() {
  const { tab } = useParams();

  const settings = useViewProcessorAdminQuery();

  const tabs: Tab[] = useMemo(() => {
    const _tabs: Pick<Tab, 'title' | 'element'>[] = [];
    _tabs.push({
      title: 'Settings',
      element: <SchedulingSettings settings={settings} />,
    });

    _tabs.push({
      title: 'Settings',
      element: <SchedulingSettings settings={settings} />,
    });

    if (settings.data?.processorSettingsOne) {
      _tabs.push(
        { title: 'Agenda', element: <ProcessorDashboard /> },
        {
          title: 'Calendar',
          element: <ProcessorCalendar settings={settings} />,
        },
        {
          title: 'Customers',
          element: <ProcessorCustomers settings={settings} />,
        },
        {
          title: 'Chat',
          element: <ProcessorChatPage />,
        },
        {
          title: 'Capabilities',
          element: <ProcessorCapabilitiesTab settings={settings} />,
        },
        {
          title: 'Jobs',
          element: <JobsTab />,
        },
        {
          title: 'Cutsheets',
          element: <ProcessorCutsheets settings={settings} />,
        },
        {
          title: 'Add Job',
          element: (
            <ProcessorAddJobTab settings={settings} pageType="add-job" />
          ),
        },
      );
    }

    return map(_tabs, (t): Tab => ({ ...t, key: kebabCase(t.title) }));
  }, [settings]);

  const tabToDisplay = tabs.find((t) => t.key === tab);

  if (settings.loading) {
    return <Loading />;
  }

  return (
    <Container className="pt-1">
      <div className="mt-3">
        <ConfigurationErrors settings={settings} />
      </div>
      <Container className="mt-4">
        {tabToDisplay ? tabToDisplay.element : null}
      </Container>
    </Container>
  );
}
