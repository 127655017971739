import {
  Button,
  IModalContext,
  IModalFooterProps,
  IToastrContext,
  IModalProperties,
} from '@farmshare/ui-components';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faXmark,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { CancellationReasonManyQuery, ProcessorScheduling } from 'lib/graphql';

import CancelBookingModalContents from '../../lib/_views/modals/cancel-booking-modal-contents';
import ViewRequestModalContents from '../../lib/_views/modals/view-request-modal-contents';
import {
  handleAcceptBooking,
  handleDenyBooking,
} from '../../lib/helpers/processorStatusHandlers';
import { StatusChanges } from '../../lib/hooks/useStatusChanges';

// Button used for a processor to immediately accept a pending booking
export const AcceptButton = ({
  row,
  updateScheduling,
  pushSuccessModal,
  callback,
}: {
  row: ProcessorScheduling;
  updateScheduling: StatusChanges['updateScheduling'];
  pushSuccessModal: IToastrContext['push'];
  callback: () => void;
}) => {
  return (
    <Button
      content="Accept"
      variant="primary"
      size="sm"
      onClick={() =>
        handleAcceptBooking(row, updateScheduling, pushSuccessModal, callback)
      }
      className="w-100 fw-bold text-white"
    />
  );
};

const ReviewModalFooter = ({
  modalProps,
  onDenyBooking,
}: {
  modalProps: IModalProperties;
  onDenyBooking: () => void;
}) => {
  // The way the modals render and handle state makes this necessary for now... Don't do this in the future unless we have no other choice
  const [isDenying, setIsDenying] = useState(false);

  const handleDenyClick = () => {
    setIsDenying(true);
    onDenyBooking();
    setIsDenying(false);
    if (modalProps.onClose) {
      modalProps.onClose();
    }
  };
  return (
    <Modal.Footer>
      <Button
        type="button"
        content="Deny"
        isLoading={isDenying}
        disabled={modalProps.isLoading}
        icon={faXmark}
        variant="danger"
        onClick={handleDenyClick}
      />
      <Button
        type="submit"
        content="Accept"
        icon={faCheck}
        variant="success"
        disabled={isDenying}
        isLoading={modalProps.isLoading}
      />
    </Modal.Footer>
  );
};

// Button used for a processor to review and subsequently accept/deny a pending booking
export const ReviewButton = ({
  row,
  saveModal,
  updateScheduling,
  pushSuccessModal,
  callback,
}: {
  row: ProcessorScheduling;
  saveModal: IModalContext['save'];
  updateScheduling: StatusChanges['updateScheduling'];
  pushSuccessModal: IToastrContext['push'];
  callback: () => void;
}) => {
  return (
    <Button
      content="Review"
      size="sm"
      variant="success"
      onClick={() =>
        saveModal({
          type: 'save',
          size: 'lg',
          title: row.requestedBy?.first_name
            ? `Processing Request by ${row.requestedBy.first_name}`
            : 'Processing Request',
          initialValues: {},
          body: () => <ViewRequestModalContents row={row} />,
          cancelText: 'Deny',
          saveText: 'Accept',
          saveIcon: faCheck,
          cancelIcon: faXmark,
          footer: ({ modalProps }: IModalFooterProps) => (
            <ReviewModalFooter
              modalProps={modalProps}
              onDenyBooking={() =>
                handleDenyBooking(
                  row,
                  updateScheduling,
                  pushSuccessModal,
                  callback,
                )
              }
            />
          ),
          onCancel: () => {},
          onSubmit: () =>
            handleAcceptBooking(
              row,
              updateScheduling,
              pushSuccessModal,
              callback,
            ),
        })
      }
      className="w-100 fw-bold text-white"
    />
  );
};

// Button used for a processor to review and subsequently accept/deny a pending booking
export const DetailsButton = ({
  row,
  modal,
  isLoading,
}: {
  row: ProcessorScheduling;
  modal: IModalContext['info'];
  isLoading: boolean;
}) => {
  return (
    <Button
      content="Details"
      size="sm"
      disabled={isLoading}
      onClick={() =>
        modal({
          type: 'info',
          size: 'lg',
          icon: faCalendarCheck,
          title: row.requestedBy?.first_name
            ? `Processing Order by ${row.requestedBy.first_name}`
            : 'View Processing Order',
          body: <ViewRequestModalContents row={row} />,
        })
      }
      className="w-100 fw-bold"
    />
  );
};

// Button used for a processor to review and subsequently accept/deny a pending booking
export const CancelButton = ({
  row,
  modal,
  onConfirm,
  cancellationReasonData,
  isLoading,
}: {
  row: ProcessorScheduling;
  modal: any;
  onConfirm: (values: any) => void;
  cancellationReasonData: CancellationReasonManyQuery | undefined;
  isLoading: boolean;
}) => {
  return (
    <Button
      content="Cancel"
      size="sm"
      disabled={isLoading}
      variant="danger"
      onClick={() =>
        modal({
          type: 'save',
          title: 'Are you sure you wish to cancel this booking?',
          initialValues: {
            reason: '',
            details: '',
          },
          body: () => (
            <CancelBookingModalContents
              row={row}
              cancellationReasonData={cancellationReasonData}
            />
          ),
          cancelText: 'Back',
          saveText: 'Cancel Order',
          saveIcon: faCheck,
          cancelIcon: faXmark,
          onSubmit: (values: any) => {
            onConfirm(values);
          },
          isLoading: false,
        })
      }
      className="w-100 fw-bold text-white"
    />
  );
};

export const SendCutsheetReminderButton = ({
  pushSuccessModal,
  onSendCutsheetReminder,
  isDisabled,
}: {
  row: ProcessorScheduling;
  pushSuccessModal: IToastrContext['push'];
  onSendCutsheetReminder: () => void;
  isDisabled: boolean;
}) => {
  return (
    <Button
      content="Send Cutsheet Reminder"
      size="sm"
      variant="primary"
      icon={faEnvelope}
      onClick={() => {
        onSendCutsheetReminder();
        pushSuccessModal({
          title: 'Reminder Sent!',
          body: `Cutsheet reminder sent successfully.`,
          bg: 'primary',
          delay: 4000,
        });
      }}
      disabled={isDisabled}
      className="w-100 fw-bold text-white"
    />
  );
};
