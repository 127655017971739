import { DataDetailListMinimal } from '@farmshare/ui-components';
import {
  animalSpeciesHelper,
  formatFullName,
  inspectionLevelHelper,
} from '@farmshare/utils';
import { EventContentArg } from '@fullcalendar/core';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { ProcessorScheduling } from 'lib/graphql';

import { statusStyles } from 'pages/processor/lib/_views/status-selector';
import { renderStatusText } from 'pages/processor/lib/helpers/processorStatusPresentation';

import styles from './calendar-event-content.module.scss';

export function CalendarEventContent(eventContent: EventContentArg) {
  const jobDetails: ProcessorScheduling & {
    harvestDate?: string;
    cutDate?: string;
  } = eventContent.event.extendedProps as ProcessorScheduling & {
    harvestDate?: string;
    cutDate?: string;
  };
  return (
    <OverlayTrigger
      placement="auto"
      overlay={(props) => (
        <Tooltip
          {...props}
          style={
            {
              ...props.style,
              '--bs-tooltip-max-width': 'auto',
              '--bs-tooltip-opacity': '1',
              '--bs-tooltip-bg': '#f8f9fa',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
            } as React.CSSProperties
          }
        >
          <DataDetailListMinimal
            heading={eventContent.event.title}
            headingStyle={{
              background: eventContent.event.backgroundColor,
              margin: '-0.25rem -0.5rem 0',
              padding: '0.5rem 0.75rem',
              color: 'var(--fc-event-text-color)',
              borderTopLeftRadius: 'var(--bs-tooltip-border-radius)',
              borderTopRightRadius: 'var(--bs-tooltip-border-radius)',
            }}
            tableProps={{
              variant: 'light',
            }}
            rows={[
              {
                label: 'Job Id',
                value: jobDetails.jobId,
                labelClassName: 'text-start',
              },
              {
                label: 'Head Count',
                value: jobDetails?.headCount,
                labelClassName: 'text-start',
              },
              {
                label: 'Item',
                value: jobDetails.animalSpecies
                  ? animalSpeciesHelper(jobDetails.animalSpecies).label
                  : '-',
                labelClassName: 'text-start',
              },
              ...(jobDetails.animalHeads?.[0]?.inspectionLevel
                ? [
                    {
                      label: 'Inspection Level',
                      value: inspectionLevelHelper(
                        jobDetails.animalHeads[0].inspectionLevel,
                      ).label,
                      labelClassName: 'text-start',
                    },
                  ]
                : []),

              {
                label: 'Producer',
                value: jobDetails.requestedBy?.company
                  ? jobDetails.requestedBy?.company
                  : formatFullName(
                      jobDetails?.requestedBy?.first_name ?? '',
                      jobDetails.requestedBy?.last_name,
                    ),
                labelClassName: 'text-start',
              },
              {
                label: 'Status',
                value: jobDetails ? (
                  <div className={`${statusStyles[jobDetails.status]} rounded`}>
                    {renderStatusText(jobDetails.status)}
                  </div>
                ) : (
                  '-'
                ),
                labelClassName: 'text-start',
              },
              ...(jobDetails?.dropoffDateStr
                ? [
                    {
                      label: 'Dropoff Date',
                      value: jobDetails?.dropoffDateStr,
                      labelClassName: 'text-start',
                    },
                  ]
                : []),
              ...(jobDetails?.harvestDate
                ? [
                    {
                      label: 'Harvest Date',
                      value: jobDetails?.harvestDate,
                      labelClassName: 'text-start',
                    },
                  ]
                : []),
              ...(jobDetails?.cutDate
                ? [
                    {
                      label: 'Cut Date',
                      value: jobDetails?.cutDate,
                      labelClassName: 'text-start',
                    },
                  ]
                : []),
            ]}
          />
        </Tooltip>
      )}
    >
      <div className={styles.eventWrapper}>
        <div className={styles.eventLastName}>{eventContent.event.title}</div>
      </div>
    </OverlayTrigger>
  );
}
