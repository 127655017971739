import { OperationVariables } from '@apollo/client';
import { faColumns, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from '@tanstack/react-table';
import { bind, filter, map, reduce } from 'lodash';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Col, Container, Row as BootstrapRow } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { NoResultsText, NoResultsOverrideType } from './no-results-text';
import { Pagination } from '../../components/pagination/pagination';
import { DropdownMultiSelect } from '../dropdown-multi-select/dropdown-multi-select';
import { Table } from '../table/table';
import { ColumnProps, TablePropsBase } from '../table/table-types';

export interface PaginationTableProps<
  TData,
  TQuery,
  TVars extends OperationVariables,
> extends TablePropsBase<TData, TQuery, TVars> {
  data: Array<TData>;
  isLoading: boolean;
  defaultSort?: string;
  renderSubComponent?: (row: Row<TData>) => ReactNode;
  hideButtons?: boolean;
  enableSearchParams?: boolean;
  noResultsOverride?: NoResultsOverrideType;
  defaultPerPage?: number;
}

interface ColumnSelectOption {
  label: string;
  value: string;
  isActive: boolean;
}

export function SimplePaginationTable<
  TData,
  TQuery = {},
  TVars extends OperationVariables = {},
>({
  columns,
  data = [],
  isLoading,
  renderSubComponent,
  hideButtons,
  enableSearchParams = true,
  noResultsOverride,
  defaultPerPage = 15,
  isEditModeEnabled = false,
  onRowEdit,
}: PaginationTableProps<TData, TQuery, TVars>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedColumns, setSelectedColumns] = useState(
    map(columns, (c) => c?.label),
  );

  // Determine what the initial page is based upon value in the url.
  const initialPage = useMemo(() => {
    const pageParam = parseFloat(searchParams.get('page') ?? '');
    if (enableSearchParams && !isNaN(pageParam)) {
      return pageParam;
    }
    return 1;
    // I only want this to run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState<number>(initialPage);
  const [perPage] = useState<number>(defaultPerPage);
  const [pageCount, setPageCount] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(data.length);

  const columnSelectOptions = useMemo(() => {
    return reduce(
      columns,
      (acc: ColumnSelectOption[], col) => {
        if (col.label) {
          acc.push({ label: col.label, value: col.label, isActive: true });
        }
        return acc;
      },
      [],
    );
  }, [columns]);

  const filteredColumns: ColumnProps<TData, any, any>[] = filter(
    columns,
    (c) => {
      // Always show button columns
      const isButtonColumn: boolean = !c.label;
      return selectedColumns.includes(c.label) || isButtonColumn;
    },
  );

  // update pageCount and itemCount when data updates
  useEffect(() => {
    setPageCount(Math.ceil(data.length / perPage));
    setItemCount(data.length);
  }, [data, perPage]);

  const visibleRows = useMemo(() => {
    if (perPage < 1) {
      return data.slice(0);
    }
    const start = (page - 1) * perPage;
    return data.slice(start, start + perPage);
  }, [data, page, perPage]);

  const handleSetPage = useCallback(
    (newPage: number) => {
      setPage(newPage);
      if (enableSearchParams) {
        searchParams.set('page', newPage.toString());
        setSearchParams(searchParams);
      }
    },
    [searchParams, setSearchParams, enableSearchParams],
  );

  useEffect(() => {
    const pageParam = searchParams.get('page');

    if (pageParam && enableSearchParams) {
      setPage(parseInt(pageParam));
      searchParams.set('page', page.toString());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setSearchParams]);

  const renderTable = () => {
    if (isLoading) {
      return (
        <Container className="pt-4">
          <BootstrapRow className="justify-content-md-center">
            <Col md="auto">
              <FontAwesomeIcon
                icon={faSpinner}
                size="5x"
                className="text-primary"
                spin
              />
            </Col>
          </BootstrapRow>
        </Container>
      );
    }

    if (visibleRows.length < 1) {
      return <NoResultsText textOverride={noResultsOverride} />;
    }

    return (
      <Table<TData>
        rows={visibleRows}
        isEditModeEnabled={isEditModeEnabled}
        onRowEdit={onRowEdit}
        rowAlignment="middle"
        renderSubComponent={renderSubComponent}
        columns={map(filteredColumns, (c) => {
          if (c.formatter) {
            c.formatter = bind(
              c.formatter,
              c,
              bind.placeholder,
              bind.placeholder,
            );
          }
          return c;
        })}
      />
    );
  };

  return (
    <Container>
      {!hideButtons && (
        <div className="d-flex justify-content-end gap-2 my-3">
          <DropdownMultiSelect
            title="Columns"
            options={columnSelectOptions}
            resetSelections
            content="Apply"
            icon={faColumns}
            onApply={(options) => {
              const selectedOptions = map(
                filter(options, (f) => !!f.isActive),
                (m) => m.value,
              );
              setSelectedColumns(selectedOptions);
            }}
          />
        </div>
      )}
      {renderTable()}
      <div className="d-flex justify-content-center mt-2">
        <Pagination
          setPage={handleSetPage}
          currentPage={page}
          pageCount={pageCount}
          itemCount={itemCount}
        />
      </div>
    </Container>
  );
}
