import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ErrorMessage,
  type FieldAttributes,
  useFormikContext,
  Field,
} from 'formik';
import { camelCase, get, isInteger, isNumber } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Button, Form, type FormControlProps } from 'react-bootstrap';

import { type InputComponent } from '.';

export interface InputQuantityProps extends InputComponent {
  min?: number;
  max?: number;
  step?: number;
  showEdit?: boolean;
}

/**
 * Modulo is not valid for non integer numbers or floating point numbers
 * I don't believe Javascript provides a floating point safe modulo function
 * like other programming languages.
 *
 * A quick and dirty solution is to convert the floating point numbers to
 * integers. Then we do our module and convert the result back to floating point.
 *
 * We do this with the decimal places in the step function.
 *
 * I cannot guaranty this works great on really large numbers.
 *
 * There is probably a better math formula for finding the remainder of
 * floating point numbers. However this works for now.
 */
const safeModulus = (value: number, step: number, decimalPlaces: number) => {
  if (decimalPlaces > 0 && isInteger(decimalPlaces)) {
    const multiplier = Math.pow(10, decimalPlaces);
    const remainder = (value * multiplier) % (step * multiplier);
    return remainder / multiplier;
  }

  return value % step;
};

export function InputQuantity({
  className,
  disabled,
  hidden,
  hint,
  label,
  max,
  min,
  nameOveride,
  readOnly,
  required,
  step = 1,
  showEdit,
}: InputQuantityProps) {
  const {
    errors,
    handleChange,
    values,
    setFieldValue,
    setFieldError,
    setFieldTouched,
  } = useFormikContext<Record<string, number>>();

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  let quantity: number = get(values, name) || 0;

  // This is necessary to support non integer steps.
  // 1. Modulo is not valid for floating points.
  // 2. If we are not doing integer math then we need to round so we don't
  //    have weird looking numbers. 1.499999999999 is 1.5 if step is 0.1
  const stepsDecimalPlaces = useMemo(() => {
    // Count the number of decimal places that are on the step
    return step?.toString()?.split('.')?.[1]?.length || 0;
  }, [step]);

  const validate = (value: any) => {
    if (min && value < min) {
      return `${min} is the minimum value.`;
    }
    if (max && value > max) {
      return `${max} is the maximum value.`;
    }
    if (step && safeModulus(value, step, stepsDecimalPlaces) !== 0) {
      return `The value must increase or decrease by ${step}.`;
    }
  };

  const changeValue = useCallback(
    (type: 'INCREMENT' | 'DECREMENT') => {
      if (disabled) return;

      switch (type) {
        case 'INCREMENT':
          if (isNumber(max)) {
            if (quantity === max) {
              setFieldError(name, `${max} is the maximum value.`);
            } else if (min && quantity < min) {
              quantity = min;
            } else if (quantity + step > max) {
              quantity = max;
            } else {
              quantity += step;
            }
          } else {
            quantity += step;
          }
          break;

        case 'DECREMENT':
          if (isNumber(min)) {
            if (quantity === min || quantity === 0) {
              quantity = 0;
              setFieldError(name, `${min} is the minimum value.`);
            } else if (quantity - step < min) {
              quantity = min;
            } else {
              quantity -= step;
            }
          } else {
            quantity -= step;
          }
          break;
      }

      setFieldTouched(name, true);
      // The quantity value is rounded to the stepsDecimalPlaces so we don't have goofy looking numbers
      setFieldValue(
        name,
        parseFloat(quantity.toFixed(stepsDecimalPlaces)),
        true,
      );
    },
    [values, name, min, max, step, setFieldError, setFieldValue],
  );

  const handleQuantityInputChange = useCallback(
    (value: string) => {
      const parsedValue = value.replace(/[^\d.]/g, '') || 0;

      setFieldTouched(name, true, false);
      setFieldValue(name, parsedValue, true);
    },
    [setFieldTouched, setFieldValue, name],
  );

  const fieldProps: FieldAttributes<FormControlProps> = useMemo(
    () => ({
      name,
      disabled,
      hidden,
      min,
      max,
      step,
      readOnly,
      required: required && !readOnly,
      plaintext: readOnly,
      as: Form.Control,
      type: 'number',
      placeholder: label?.replace(/[?:]/g, ''),
      isInvalid: !!errors[name],
      className: 'text-end',
      value: values[name],
      onChange: handleChange,
      showEdit,
      changeValue,
      handleQuantityInputChange,
    }),
    [
      disabled,
      errors,
      hidden,
      label,
      max,
      min,
      name,
      readOnly,
      required,
      step,
      values,
      handleChange,
      showEdit,
      changeValue,
      handleQuantityInputChange,
    ],
  );

  return (
    <Form.Group controlId={`form.${name}`} className={className}>
      {label && (
        <Form.Label className="fw-light me-2">
          {label}:
          {fieldProps.required && (
            <sup className="text-danger fw-bold">&nbsp;*</sup>
          )}
        </Form.Label>
      )}
      <Field {...fieldProps} validate={validate}>
        {() => (
          <div
            className={`bg-body-tertiary rounded-3 d-inline-flex align-items-center border`}
          >
            <Button variant="ghost" onClick={() => changeValue('DECREMENT')}>
              <FontAwesomeIcon icon={faMinus} />
            </Button>
            <div className="px-2" style={{ minWidth: 30 }}>
              {showEdit ? (
                <input
                  onChange={(e) => handleQuantityInputChange(e.target.value)}
                  value={quantity}
                  style={{ width: 50, textAlign: 'center' }}
                  disabled={disabled}
                />
              ) : (
                quantity
              )}
            </div>
            <Button variant="ghost" onClick={() => changeValue('INCREMENT')}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        )}
      </Field>
      {hint && <Form.Text>{hint}</Form.Text>}
      <ErrorMessage
        name={name}
        render={(msg: string) => {
          return (
            <div>
              <Form.Control.Feedback
                type="invalid"
                // Since we are not using bootstrap Form elements but formik we should force this to display the error message
                style={{ display: 'block' }}
              >
                <div>{msg}</div>
              </Form.Control.Feedback>
            </div>
          );
        }}
      />
    </Form.Group>
  );
}
