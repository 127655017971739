import type { Nullable, RecursivePartial } from '@farmshare/utils';
import { animalSpeciesHelper } from '@farmshare/utils';
import { map, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import type { ProcessingPartners } from 'lib/graphql';

export default function ProcessorCard({
  processingPartner,
}: {
  processingPartner: Nullable<RecursivePartial<ProcessingPartners>>;
}) {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    navigate(`/scheduling/${processingPartner?.slug}`);
  }, [navigate, processingPartner]);

  const sortedAnimals = useMemo(
    () =>
      sortBy(
        map(processingPartner?.enabledAnimals, (animal) =>
          animalSpeciesHelper(animal || ''),
        ),
        'order',
      ),
    [processingPartner?.enabledAnimals],
  );

  if (!processingPartner) {
    return;
  }

  return (
    <Card
      className={`bg-body-secondary rounded h-100 ${
        processingPartner.slug ?? processingPartner._id
      }`}
    >
      <Card.Body className="d-flex flex-column">
        <div className="mb-3 p-3" style={{ height: 164 }}>
          <div
            className="p-3 h-100 rounded-circle mx-auto bg-white"
            style={{
              backgroundImage: `url(${
                processingPartner.vendor?.tenant?.picture_url ??
                '/assets/images/farmshare-logo.svg'
              })`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width: 134,
            }}
          />
        </div>
        <Card.Title as="h4" className="mt-auto my-2 fw-bold">
          {processingPartner.vendor?.shop_name}
        </Card.Title>
        <Card.Text className="mb-2">
          {`${processingPartner.vendor?.address?.city}, ${processingPartner.vendor?.address?.state}`}
        </Card.Text>
        <div className="d-flex flex-wrap gap-1">
          {map(sortedAnimals, (animal) => (
            <div
              key={animal.value}
              className="px-3 py-1 rounded-pill bg-primary-subtle fw-bold"
            >
              {animal.label}
            </div>
          ))}
        </div>
        <Button className="rounded-pill mt-3 fw-bold" onClick={handleClick}>
          Schedule
        </Button>
      </Card.Body>
    </Card>
  );
}
