import { InputSelect, InputText } from '@farmshare/ui-components';
// import { useFormikContext } from 'formik';
import { map } from 'lodash';
import { Stack } from 'react-bootstrap';

import { EnumPrimalCutType, type Primal } from 'lib/graphql';

export interface CapabilityEditModalForm {
  name?: string;
  primal?: string;
  type?: EnumPrimalCutType;
}

interface CapabilityEditModalProps {
  primals?: Primal[];
}

export function CapabilityEditModal({ primals }: CapabilityEditModalProps) {
  // const { values } = useFormikContext<CapabilityEditModalForm>();

  return (
    <Stack gap={3}>
      <InputText label="Name" type="text" floatingLabel required />
      {primals && (
        <InputSelect
          label="Primal"
          options={map(primals, (primal) => ({
            label: primal.name,
            value: primal._id,
          }))}
          floatingLabel
          required
        />
      )}
      <InputSelect
        label="Type"
        options={map(EnumPrimalCutType, (value, key) => ({
          value,
          label: key,
        }))}
        floatingLabel
        required
      />
    </Stack>
  );
}
