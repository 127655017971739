import {
  Button,
  DataDetailList,
  DataDetailListMinimal,
  MoneyDisplay,
  PhoneDisplay,
  useToastr,
} from '@farmshare/ui-components';
import {
  animalSpeciesHelper,
  formatFullName,
  inspectionLevelHelper,
} from '@farmshare/utils';
import {
  faFileDownload,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { map, startCase } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { Card, Col, Row, Stack } from 'react-bootstrap';

import { downloadFromBase64Encoded } from 'lib/downloadFromBase64Encoded';
import { downlaodFromPresignedUrl } from 'lib/downloadFromPresignedUrl';
import {
  Maybe,
  ProcessorScheduling,
  ProcessorSchedulingAnimalHeadsCutsheetInformation,
  useProcessorSchedulingGenerateCutsheetRequestMutation,
} from 'lib/graphql';
import { processingJobAnimalHeadLabel } from 'lib/processingJobUtils';

const ContactsList = ({
  cutsheetInformation,
  scheduledJobId,
}: {
  cutsheetInformation: Maybe<ProcessorSchedulingAnimalHeadsCutsheetInformation>[];
  scheduledJobId: string;
}) => {
  const { push } = useToastr();
  const [generateCutsheetPdf, generateCutsheetPdfOp] =
    useProcessorSchedulingGenerateCutsheetRequestMutation();

  const handleDownloadCutsheetPdf = useCallback(
    async (cutsheetInformationId: string) => {
      try {
        const response = await generateCutsheetPdf({
          variables: {
            processorSchedulingId: scheduledJobId,
            cutsheetRequestId: cutsheetInformationId,
          },
        });

        if (
          response.data?.processorSchedulingGenerateCutsheetRequest?.fileUrl
        ) {
          downlaodFromPresignedUrl(
            response.data?.processorSchedulingGenerateCutsheetRequest?.fileUrl,
          );
        } else if (
          response.data?.processorSchedulingGenerateCutsheetRequest?.file
        ) {
          downloadFromBase64Encoded(
            response.data?.processorSchedulingGenerateCutsheetRequest.file,
            response.data?.processorSchedulingGenerateCutsheetRequest
              ?.filename ?? 'Cutsheet.pdf',
          );
        }
      } catch (error) {
        push({
          title: 'Error',
          body: (error as string).toString(),
          bg: 'danger',
          delay: 4000,
        });
      }
    },
    [push, scheduledJobId, generateCutsheetPdf],
  );

  return map(cutsheetInformation, (ci, idx) => {
    return (
      <Col key={idx}>
        <Card className="h-100">
          <Card.Header className="d-flex">
            {ci?.contactUser?.first_name
              ? formatFullName(
                  ci.contactUser?.first_name,
                  ci.contactUser?.last_name ?? '',
                )
              : 'Customer'}
          </Card.Header>
          <Card.Body>
            <div className="d-block text-break">
              <DataDetailList
                rows={[
                  {
                    label: 'Split Part',
                    value: startCase(ci?.splitPart ?? ''),
                  },
                  {
                    label: 'Email',
                    value: ci?.contactUser?.email,
                  },
                  {
                    label: 'Phone',
                    value: (
                      <PhoneDisplay phone={ci?.contactUser?.phone ?? ''} />
                    ),
                  },
                  {
                    label: 'Cutsheet',
                    value: ci?.cutsheet?.name ? (
                      <Stack direction="horizontal" gap={2}>
                        <Button
                          content={`${ci?.cutsheet?.name}`}
                          icon={faFileDownload}
                          size="sm"
                          isLoading={generateCutsheetPdfOp.loading}
                          disabled={generateCutsheetPdfOp.loading}
                          onClick={async () =>
                            scheduledJobId &&
                            ci._id &&
                            (await handleDownloadCutsheetPdf(ci._id))
                          }
                        />
                      </Stack>
                    ) : undefined,
                  },
                  { label: 'Cutsheet Notes', value: ci?.notes },
                ]}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  });
};

export default function ViewRequestModalContents({
  row,
}: {
  row: ProcessorScheduling;
}) {
  // TODO should I put inspection level here
  const animalSpeciesOption = useMemo(
    () => animalSpeciesHelper(row.animalSpecies),
    [row.animalSpecies],
  );

  return (
    <div>
      {/* Item details */}
      <div className="d-flex">
        <div className="me-auto">
          <div className="fw-bold">Item</div>
        </div>
        <div>
          <div>
            {row.headCount} {animalSpeciesOption.label}
          </div>
        </div>
      </div>
      <hr />
      {/* Date row */}
      <div className="d-flex">
        <div className="me-auto">
          <div className="fw-bold">Drop Off Date</div>
        </div>
        <div>
          <div>{moment.utc(row.dropoffDate).format('LL')}</div>
        </div>
      </div>
      {/* Contact Details */}
      <hr />
      <DataDetailListMinimal
        heading="Scheduled By"
        rows={[
          {
            label: 'Name',
            value: `${row.requestedBy?.first_name} ${row.requestedBy?.last_name}`,
          },
          {
            label: 'Email',
            value: row.requestedBy?.email,
          },
          {
            label: 'Phone',
            value: <PhoneDisplay phone={row.requestedBy?.phone ?? ''} />,
          },
        ]}
      />
      <hr />
      <DataDetailListMinimal
        heading="Price Details"
        rows={[
          {
            label: 'Deposit',
            value: <MoneyDisplay value={row.deposit?.amount} />,
          },
        ]}
      />
      <hr />

      {map(row.animalHeads, (animalHead, idx) => {
        return (
          <Stack gap={2} key={idx}>
            <DataDetailListMinimal
              heading={processingJobAnimalHeadLabel({
                producerIdentifier: animalHead?.producerIdentifier,
                requestedBy: { first_name: '', last_name: '' },
                animalNumber: idx + 1,
                animalSpecies: animalSpeciesOption.value,
                inspectionLevel: animalHead?.inspectionLevel ?? undefined,
              })}
              rows={[
                {
                  label: 'Split Type',
                  value: startCase(animalHead?.splitType ?? ''),
                },
                {
                  label: 'Producer Identifier',
                  value: animalHead?.producerIdentifier,
                },
                {
                  label: 'Inspection Level',
                  value: animalHead?.inspectionLevel
                    ? inspectionLevelHelper(animalHead.inspectionLevel).label
                    : undefined,
                },
              ]}
            />
            <Stack direction="horizontal" gap={2}>
              <h6 className="fw-bold mb-0">Cutsheets</h6>
              <Button
                content={`Edit Cutsheets`}
                icon={faInfoCircle}
                size="sm"
                onClick={() =>
                  window.open(`/scheduling/${row._id}/cutsheets`, '_blank')
                }
              />
            </Stack>
            <Row xs={1} lg={2} className="w-100 g-2 mt-1">
              <ContactsList
                cutsheetInformation={animalHead?.cutsheetInformation ?? []}
                scheduledJobId={row._id}
              />
            </Row>
            {idx + 1 < (row.animalHeads?.length ?? 0) && <hr />}
          </Stack>
        );
      })}
    </div>
  );
}
