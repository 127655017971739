import {
  Button,
  DataDetailList,
  Loading,
  PageTitle,
  useBreakpoint,
} from '@farmshare/ui-components';
import {
  faArrowLeft,
  faCalendarCheck,
  faHourglassHalf,
  faMessage,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import ChatContainer from 'components/chat/ChatContainer';

import {
  SortFindManyProcessorSchedulingInput,
  useProcessorCustomerByIdQuery,
  useProcessorSchedulingManyQuery,
  useProcessorSchedulingPaginationLazyQuery,
} from 'lib/graphql';

import NeedAttentionContainer from 'pages/processor/dashboard/_views/need-attention-container';
import PendingApprovalsContainer from 'pages/processor/dashboard/_views/pending-approvals-container';
import TableContainer, {
  ExtraColumnsEnum,
} from 'pages/processor/dashboard/_views/table-container';
import { getProcessorSchedulingQueryFilters } from 'pages/processor/dashboard/filters';

export default function CustomerDetailsPage() {
  const { id } = useParams();
  const vendor = useRecoilValue(vendorState);

  const { data, loading } = useProcessorCustomerByIdQuery({
    variables: { id },
  });

  const {
    pendingApprovals: pendingApprovalsQueryFilter,
    missingCutsheets: missingCutsheetsQueryFilter,
    processorScheduling: processorSchedulingQueryFilter,
    bookingsInProgress: bookingsInProgressQueryFilter,
    bookingsCompleted: completedBookingsQueryFilter,
    dropoffAlerts: dropoffAlertsQueryFilter,
  } = getProcessorSchedulingQueryFilters({
    vendorId: vendor?._id,
    customerId: id,
  });

  // Pagination Queries
  const bookingsInProgressPaginationQuery =
    useProcessorSchedulingPaginationLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        filter: bookingsInProgressQueryFilter,
        page: 1,
        perPage: 5,
      },
    });
  const completedBookingsPaginationQuery =
    useProcessorSchedulingPaginationLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        filter: completedBookingsQueryFilter,
        page: 1,
        perPage: 10,
      },
    });
  const dropoffAlertsPaginationQuery =
    useProcessorSchedulingPaginationLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        filter: dropoffAlertsQueryFilter,
        page: 1,
        perPage: 10,
      },
    });

  const {
    data: processorSchedulingsData,
    loading: processorSchedulingsLoading,
  } = useProcessorSchedulingManyQuery({
    fetchPolicy: 'network-only',
    variables: { filter: processorSchedulingQueryFilter },
  });

  const breakpoint = useBreakpoint();
  const navigate = useNavigate();

  if (!vendor) {
    return <>Not Found</>;
  }

  if (loading || processorSchedulingsLoading) {
    return <Loading />;
  }

  const user = data?.userById;

  if (!user) {
    return <>Error</>;
  }

  return (
    <Container>
      <Row>
        <Col lg={5} xl={4} className="pt-4">
          <div
            className="sticky-lg-top top-10 d-flex flex-column"
            style={{
              top: ['lg', 'xl', 'xxl'].includes(breakpoint) ? 92 : 0,
              maxHeight: 'calc(100vh - 128px)',
              height: '100%',
            }}
          >
            <Card className="bg-body-secondary rounded-4" body>
              <div
                className="d-flex align-items-center"
                style={{ marginInline: '-0.75rem', marginBottom: '-0.1rem' }}
              >
                <Button
                  variant="text"
                  content={<FontAwesomeIcon icon={faArrowLeft} size="2x" />}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <PageTitle
                  title="Customer Details"
                  innerBreadcrumbs={[
                    { text: 'Customers', to: '/processor/customers' },
                  ]}
                  showHr={false}
                />
              </div>
            </Card>
            <Card className="bg-body-secondary rounded-4 mt-4" body>
              <div className="d-flex gap-3 align-items-center lh-1">
                <FontAwesomeIcon
                  className="text-primary"
                  icon={faUser}
                  size="2x"
                />
                <div className="d-flex flex-column justify-content-between text-start">
                  <div className="fw-bolder fs-2">Customer Information</div>
                </div>
              </div>
              <hr />
              <div className="p-3 bg-body rounded-4">
                <DataDetailList
                  rows={[
                    {
                      label: 'Name',
                      value: `${user?.first_name} ${user?.last_name}`,
                      valueClassName: 'costumer-name',
                    },
                    {
                      label: 'Email',
                      value: user?.email || '--',
                      valueClassName: 'costumer-email',
                    },
                    {
                      label: 'Phone',
                      value: user?.phone || '--',
                      valueClassName: 'costumer-phone',
                    },
                    {
                      label: 'Farm/Company',
                      value: user?.company || '--',
                      valueClassName: 'costumer-farm',
                    },
                  ]}
                />
              </div>
            </Card>
            <Card className="bg-body-secondary rounded-4 mt-4" body>
              <div className="d-flex gap-3 align-items-center lh-1">
                <FontAwesomeIcon
                  className="text-primary"
                  icon={faMessage}
                  size="2x"
                />
                <div className="d-flex flex-column justify-content-between text-start">
                  <div className="fw-bolder fs-2">Chat</div>
                </div>
              </div>
              <hr />
              <div
                className="flex-fill"
                style={
                  {
                    '--chat-container-height': '302px',
                  } as React.CSSProperties
                }
              >
                <ChatContainer
                  pageType="processor-customer-detail"
                  customerId={id as string}
                  vendorId={vendor._id}
                />
              </div>
            </Card>
          </div>
        </Col>
        <Col lg={7} xl={8}>
          <TableContainer
            className="in-progress"
            processorSchedulingsData={processorSchedulingsData}
            titleText="Bookings In Progress"
            subText="Update Statuses to notify your customers!"
            noResultsText="No Bookings in Progress"
            icon={faCalendarCheck}
            buildFilter={bookingsInProgressQueryFilter}
            query={bookingsInProgressPaginationQuery}
            hideButtons
            rowActionButtons={['Details', 'Cancel']}
            onChangeCallback={() => {
              bookingsInProgressPaginationQuery[1].refetch();
              completedBookingsPaginationQuery[1].refetch();
              dropoffAlertsPaginationQuery[1].refetch();
            }}
            extraColumns={[ExtraColumnsEnum.StatusSelect]}
            pageType="customer"
          />
          <PendingApprovalsContainer
            processorSchedulingsData={processorSchedulingsData}
            titleText="Pending Approvals"
            subText=""
            noResultsText="No Pending Approvals"
            icon={faCalendarCheck}
            buildFilter={pendingApprovalsQueryFilter}
            hideButtons
            onChangeCallback={() => {
              bookingsInProgressPaginationQuery[1].refetch();
              completedBookingsPaginationQuery[1].refetch();
              dropoffAlertsPaginationQuery[1].refetch();
            }}
            pageType="customer"
          />
          <TableContainer
            className="dropoff-alerts"
            processorSchedulingsData={processorSchedulingsData}
            titleText="Drop Off Alerts"
            subText=""
            noResultsText="No Drop Off Alerts"
            icon={faCalendarCheck}
            buildFilter={dropoffAlertsQueryFilter}
            query={dropoffAlertsPaginationQuery}
            hideButtons
            rowActionButtons={['Details', 'Cancel']}
            onChangeCallback={() => {
              bookingsInProgressPaginationQuery[1].refetch();
              completedBookingsPaginationQuery[1].refetch();
              dropoffAlertsPaginationQuery[1].refetch();
            }}
            extraColumns={[ExtraColumnsEnum.StatusSelect]}
            pageType="customer"
          />
          <NeedAttentionContainer
            processorSchedulingsData={processorSchedulingsData}
            titleText="Jobs Need Attention"
            subText=""
            noResultsText="No Pending Jobs"
            icon={faHourglassHalf}
            buildFilter={missingCutsheetsQueryFilter}
            hideButtons
            onChangeCallback={() => {
              bookingsInProgressPaginationQuery[1].refetch();
              completedBookingsPaginationQuery[1].refetch();
              dropoffAlertsPaginationQuery[1].refetch();
            }}
            pageType="customer"
          />
          <TableContainer
            processorSchedulingsData={processorSchedulingsData}
            className="completed"
            titleText="Completed Bookings"
            subText=""
            noResultsText="No Completed Bookings To Show"
            icon={faCalendarCheck}
            buildFilter={completedBookingsQueryFilter}
            query={completedBookingsPaginationQuery}
            rowActionButtons={['Details']}
            hideButtons
            onChangeCallback={() => {
              completedBookingsPaginationQuery[1].refetch();
            }}
            extraColumns={[
              ExtraColumnsEnum.StatusText,
              ExtraColumnsEnum.StatusLastUpdated,
            ]}
            perPageOverride={10}
            pageType="customer"
          />
        </Col>
      </Row>
    </Container>
  );
}
