import { Loading } from '@farmshare/ui-components';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { ChatRoom, useMyChatRoomsQuery } from 'lib/graphql';

import ChatMessageList from './ChatMessageList';
import ChatRoomList from './ChatRoomList';

export default function ChatContainer({
  pageType,
  customerId,
  vendorId,
}: {
  pageType: 'customer' | 'processor' | 'processor-customer-detail';
  customerId?: string;
  vendorId?: string;
}) {
  const { id, userId, subTab } = useParams();
  const navigate = useNavigate();

  const pathnames: Record<string, string> = {
    processor: '/processor/chat',
    customer: userId ? `/${userId}/chat` : '/chat',
  };

  const defaultChatRoomId =
    pageType === 'customer' || pageType === 'processor'
      ? id || subTab
      : undefined;

  const [selectedRoomId, setSelectedRoomId] = useState<string | undefined>(
    defaultChatRoomId,
  );

  const { data, loading, error } = useMyChatRoomsQuery({
    pollInterval: 10000,
    variables: {
      ...(customerId ? { customerId } : {}),
      page: 1,
      perPage: 100,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.myChatRooms?.count && !selectedRoomId) {
      setSelectedRoomId(data.myChatRooms.items?.[0]._id);
    }
  }, [data, error, loading, selectedRoomId, setSelectedRoomId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Card
      className="bg-body-secondary rounded-4 flex-fill"
      body
      style={
        pageType === 'processor-customer-detail'
          ? ({
              '--bs-card-spacer-y': 0,
              '--bs-card-spacer-x': 0,
              '--bs-card-border-width': 0,
            } as React.CSSProperties)
          : {}
      }
    >
      <Row>
        {['customer', 'processor'].includes(pageType) &&
          (data?.myChatRooms?.count ? (
            <>
              <Col lg={4} className="pb-3">
                <ChatRoomList
                  chatRooms={data?.myChatRooms?.items as ChatRoom[]}
                  pageType={pageType}
                  selectedRoomId={selectedRoomId}
                  onChatRoomClicked={(chatRoomId) => {
                    setSelectedRoomId(chatRoomId);
                    navigate(`${pathnames[pageType]}/${chatRoomId}`);
                  }}
                />
              </Col>
              <Col lg={8}>
                <div className="bg-body rounded-4">
                  <ChatMessageList
                    selectedRoomId={selectedRoomId}
                    myChatRoomsCount={data?.myChatRooms?.count}
                    customerId={customerId}
                    customerAsUser={Boolean(pageType === 'customer' && userId)}
                  />
                </div>
              </Col>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              {pageType === 'processor'
                ? 'No messages sent yet. Try sending message in customer detail page.'
                : 'No messages received from processors.'}
            </div>
          ))}
        {pageType === 'processor-customer-detail' ? (
          <Col lg={12}>
            <div className="bg-body rounded-4">
              <ChatMessageList
                vendorId={vendorId}
                customerId={customerId}
                selectedRoomId={selectedRoomId}
                myChatRoomsCount={data?.myChatRooms?.count}
                onChatRoomCreated={(id) => setSelectedRoomId(id)}
              />
            </div>
          </Col>
        ) : undefined}
      </Row>
    </Card>
  );
}
