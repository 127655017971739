import { Button, useModal } from '@farmshare/ui-components';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import { useVendorUpdateByIdMutation, Vendor } from 'lib/graphql';

import { VendorModal, type VendorModalForm } from './vendor-modal';

interface EditVendorButtonProps {
  vendor: Pick<
    Vendor,
    '_id' | 'shop_name' | 'enabled' | 'address' | 'shop_url' | 'tagline'
  >;
  refetch: () => Promise<unknown>;
}

export function EditVendorButton({ vendor, refetch }: EditVendorButtonProps) {
  const [updateVendor, { loading }] = useVendorUpdateByIdMutation();

  const { save } = useModal();

  return (
    <div className="d-flex justify-content-end">
      <Button
        content="Edit Vendor"
        size="sm"
        icon={faEdit}
        isLoading={loading}
        onClick={() =>
          save<VendorModalForm>({
            type: 'save',
            title: 'Edit Vendor',
            icon: faEdit,
            size: 'lg',
            initialValues: {
              shopName: vendor.shop_name,
              shopUrl: vendor.shop_url || '',
              tagline: vendor.tagline || '',
              address: {
                type: vendor.address.type,
                firstName: vendor.address.first_name ?? '',
                lastName: vendor.address.last_name ?? '',
                company: vendor.address.company ?? '',
                address1: vendor.address.address_1,
                address2: vendor.address.address_2 ?? '',
                city: vendor.address.city,
                state: vendor.address.state,
                postcode: vendor.address.postcode,
                email: vendor.address.email ?? '',
                phone: vendor.address.phone ?? '',
              },
            },
            body: <VendorModal />,
            isLoading: loading,
            onSubmit: async ({ shopName, shopUrl, tagline, address }) => {
              await updateVendor({
                variables: {
                  _id: vendor._id,
                  record: {
                    shop_name: shopName,
                    shop_url: shopUrl,
                    address: {
                      first_name: address.firstName,
                      last_name: address.lastName,
                      company: address.company,
                      address_1: address.address1,
                      address_2: address.address2,
                      city: address.city,
                      state: address.state,
                      postcode: address.postcode,
                      email: address.email,
                      phone: address.phone,
                    },
                    tagline,
                  },
                },
              });
              await refetch();
            },
          })
        }
      />
    </div>
  );
}
