import { OperationVariables } from '@apollo/client';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCaretDown,
  faCaretRight,
  faCaretUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  type Row,
  type SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getExpandedRowModel,
  Header,
  ColumnDefTemplate,
  CellContext,
} from '@tanstack/react-table';
import { concat, join, map } from 'lodash';
import {
  type ReactNode,
  useCallback,
  useMemo,
  useState,
  Fragment,
} from 'react';
import {
  Button,
  Fade,
  Table as RBTable,
  type TableProps as RBTableProps,
} from 'react-bootstrap';

import { TBreakpoints, TablePropsBase } from './table-types';

// This type is for casting due to original ColumnMeta interface issue.
interface TableColumnMeta<TData, TValue> {
  className?: string | string[];
  minimumBreakpoint?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  editor?: ColumnDefTemplate<CellContext<TData, TValue>>;
}

export interface TableProps<TData, TQuery, TVars extends OperationVariables>
  extends TablePropsBase<TData, TQuery, TVars> {
  rows: TData[];
  onSelect?: (row: TData) => void;
  props?: Partial<RBTableProps>;
  rowAlignment?: 'top' | 'middle' | 'bottom';
}

export function Table<
  TData,
  TQuery = {},
  TVars extends OperationVariables = {},
>({
  columns,
  renderSubComponent,
  rows,
  onSelect,
  props,
  rowAlignment = 'middle',
  isEditModeEnabled = false,
  onRowEdit = () => {},
}: TableProps<TData, TQuery, TVars>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const breakpointClass = useCallback(
    (breakpoint?: TBreakpoints) => [
      'd-none',
      `d-${breakpoint}-table-cell`,
      'd-print-table-cell',
    ],
    [],
  );

  const _columns = useMemo(() => {
    const cols = map(
      columns,
      (
        {
          label,
          field,
          formatter,
          minSize,
          maxSize,
          footer,
          className,
          minimumBreakpoint,
          inlineEditor,
        },
        id,
      ) => {
        const columnHelper = createColumnHelper<TData>();

        const header = () => (
          <div
            className={join(
              concat(
                minimumBreakpoint ? breakpointClass(minimumBreakpoint) : [],
                className,
              ),
              ' ',
            )}
          >
            {label}
          </div>
        );

        if (field) {
          return columnHelper.accessor(field, {
            header,
            minSize,
            maxSize,
            id: field.toString(),
            meta: {
              editor: !!inlineEditor
                ? (props: any) => {
                    if (inlineEditor) {
                      const initialValue = props.cell.getValue() as string;

                      return (
                        <div
                          className={join(
                            concat(
                              minimumBreakpoint
                                ? breakpointClass(minimumBreakpoint)
                                : [],
                              className,
                            ),
                            ' ',
                          )}
                        >
                          {inlineEditor({
                            initialValue: initialValue,
                            row: props.row,
                            column: props.column,
                            updateRow: onRowEdit,
                          })}
                        </div>
                      );
                    }
                  }
                : undefined,
            },
            cell: (props) => {
              return (
                <div
                  className={join(
                    concat(
                      minimumBreakpoint
                        ? breakpointClass(minimumBreakpoint)
                        : [],
                      className,
                    ),
                    ' ',
                  )}
                >
                  {formatter
                    ? formatter(props.row.original, props.row.index + 1)
                    : (props.renderValue() as ReactNode)}
                </div>
              );
            },
            footer: () => (
              <div
                className={join(
                  concat(
                    minimumBreakpoint ? breakpointClass(minimumBreakpoint) : [],
                    className,
                  ),
                  ' ',
                )}
              >
                {footer?.formatter
                  ? footer.formatter(footer.aggregation(rows, field))
                  : footer?.aggregation(rows, field)}
              </div>
            ),
          });
        }

        return columnHelper.display({
          minSize,
          maxSize,
          header,
          id: id.toString(),
          cell: (props) => {
            const val = formatter
              ? formatter(props.row.original, props.row.index + 1)
              : props.getValue();

            return (
              <div
                className={join(
                  concat(
                    minimumBreakpoint ? breakpointClass(minimumBreakpoint) : [],
                    className,
                  ),
                  ' ',
                )}
              >
                {val as ReactNode}
              </div>
            );
          },
        });
      },
    );

    if (renderSubComponent) {
      return concat(
        [
          {
            id: 'expander',
            header: () => null,
            cell: ({ row }: { row: Row<TData> }) => {
              const isExpanded = row.getIsExpanded();
              return row.getCanExpand() ? (
                <Button
                  {...{
                    variant: 'ghost',
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer', width: '32px' },
                  }}
                >
                  <FontAwesomeIcon
                    icon={isExpanded ? faCaretDown : faCaretRight}
                    className={isExpanded ? 'text-primary' : ''}
                  />
                </Button>
              ) : null;
            },
          },
        ],
        cols,
      );
    }

    return cols;
  }, [breakpointClass, columns, onRowEdit, renderSubComponent, rows]);

  const table = useReactTable<TData>({
    data: rows,
    columns: _columns,
    state: { sorting },
    enableColumnFilters: true,
    enableSorting: true,
    getRowCanExpand: () => !!renderSubComponent,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
  });

  const renderHeader = useCallback((header: Header<TData, unknown>) => {
    if (!header.isPlaceholder) {
      let icon: IconProp | undefined;

      switch (header.column.getIsSorted()) {
        case 'asc':
          icon = faCaretUp;
          break;
        case 'desc':
          icon = faCaretDown;
          break;
      }

      return (
        <div
          className="d-flex gap-2"
          style={
            header.column.getCanSort()
              ? { cursor: 'pointer', userSelect: 'none' }
              : {}
          }
          onClick={header.column.getToggleSortingHandler()}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
          {icon && <FontAwesomeIcon icon={icon} size="lg" />}
        </div>
      );
    }
  }, []);

  return (
    <RBTable size="sm" hover borderless {...props}>
      <thead>
        {map(table.getHeaderGroups(), (headerGroup) => (
          <tr key={headerGroup.id}>
            {map(headerGroup.headers, (header) => (
              <th key={header.id}>{renderHeader(header)}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <Fragment key={row.id}>
            <tr
              className={`align-${rowAlignment} ${
                row.getIsExpanded() ? 'table-active' : ''
              }`}
              onClick={() => onSelect && onSelect(row.original)}
              style={onSelect ? { cursor: 'pointer' } : {}}
            >
              {row.getVisibleCells().map((cell) => (
                // cell.getValue() is necessary for key because of pagination. It helps to rerender cell's component if it's more complicated one (e.g. inlineEditor with hooks).
                <td key={`${cell.id}-${cell.getValue()}`}>
                  {isEditModeEnabled &&
                    !!(
                      cell.column.columnDef.meta as TableColumnMeta<
                        TData,
                        unknown
                      >
                    )?.editor &&
                    flexRender(
                      (
                        cell.column.columnDef.meta as TableColumnMeta<
                          TData,
                          unknown
                        >
                      )?.editor,
                      cell.getContext(),
                    )}
                  {isEditModeEnabled &&
                    !(
                      cell.column.columnDef.meta as TableColumnMeta<
                        TData,
                        unknown
                      >
                    )?.editor &&
                    flexRender(cell.column.columnDef.cell, cell.getContext())}
                  {!isEditModeEnabled &&
                    flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
            {renderSubComponent && (
              <Fade in={row.getIsExpanded()}>
                <tr className="table-active">
                  <td colSpan={row.getVisibleCells().length}>
                    {renderSubComponent(row)}
                  </td>
                </tr>
              </Fade>
            )}
          </Fragment>
        ))}
      </tbody>
      <tfoot>
        {table.getFooterGroups().map((footerGroup) => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((footer) => (
              <th key={footer.id}>
                {footer.isPlaceholder
                  ? null
                  : flexRender(
                      footer.column.columnDef.footer,
                      footer.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
    </RBTable>
  );
}
