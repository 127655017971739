import { LazyQueryResultTuple } from '@apollo/client';
import {
  type ColumnProps,
  PaginationTable,
  useModal,
} from '@farmshare/ui-components';
import { formatToShortDate } from '@farmshare/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import moment from 'moment';
import { ReactElement, cloneElement } from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  Exact,
  FilterFindManyProcessorSchedulingInput,
  ProcessorScheduling,
  ProcessorSchedulingManyQuery,
  ProcessorSchedulingPagination,
  ProcessorSchedulingPaginationDocument,
  ProcessorSchedulingPaginationQuery,
  ProcessorSchedulingPaginationQueryVariables,
  SortFindManyCancellationReasonInput,
  SortFindManyProcessorSchedulingInput,
  useCancellationReasonManyQuery,
} from 'lib/graphql';

import renderJobHeadsCellHelper from 'pages/processor/lib/renderJobHeadsCellHelper';

import { CancelButton, DetailsButton } from './table-action-buttons';
import StatusSelector from '../../lib/_views/status-selector';
import { handleCancelBooking } from '../../lib/helpers/processorStatusHandlers';
import { renderStatusText } from '../../lib/helpers/processorStatusPresentation';
import { useStatusChanges } from '../../lib/hooks/useStatusChanges';

export const enum ExtraColumnsEnum {
  StatusSelect = 'StatusSelect',
  StatusText = 'StatusText',
  StatusLastUpdated = 'StatusLastUpdated',
}

export default function TableContainer({
  processorSchedulingsData,
  titleText,
  subText,
  noResultsText,
  icon,
  buildFilter,
  query,
  hideButtons,
  rowActionButtons,
  onChangeCallback,
  extraColumns,
  perPageOverride,
  className,
  pageType = 'agenda',
}: {
  processorSchedulingsData: ProcessorSchedulingManyQuery | undefined;
  titleText: string;
  subText: string;
  noResultsText?: string;
  icon: any;
  buildFilter: FilterFindManyProcessorSchedulingInput;
  query: LazyQueryResultTuple<
    ProcessorSchedulingPaginationQuery,
    Exact<{
      sort: SortFindManyProcessorSchedulingInput;
      filter: FilterFindManyProcessorSchedulingInput;
      page: number;
      perPage: number;
    }>
  >;
  hideButtons?: boolean;
  rowActionButtons?: string[];
  onChangeCallback?: () => void;
  extraColumns?: ExtraColumnsEnum[];
  perPageOverride?: number;
  className?: string;
  pageType?: 'customer' | 'agenda';
}) {
  const { save, info } = useModal();
  const statusChanges = useStatusChanges([
    {
      query: ProcessorSchedulingPaginationDocument,
      variables: {
        filter: buildFilter,
        sort: SortFindManyProcessorSchedulingInput.IdAsc,
        page: 1,
        perPage: 5,
      },
    },
  ]);

  const { data: cancellationReasonData } = useCancellationReasonManyQuery({
    variables: {
      filter: { isEnabled: true },
      sort: SortFindManyCancellationReasonInput.IndexAsc,
    },
  });

  const columns: ColumnProps<
    ProcessorScheduling,
    ProcessorSchedulingPaginationQuery,
    ProcessorSchedulingPaginationQueryVariables
  >[] = [
    {
      label: 'Order',
      field: 'headCount',
      formatter: (row) => (
        <Link to={`/processing-job/${row._id}`}>
          {renderJobHeadsCellHelper(row)}
        </Link>
      ),
    },
    {
      label: 'Drop Off Date',
      field: 'dropoffDate',
      formatter: (row) => moment(row.dropoffDate).utc().format('LL'),
    },
  ];

  if (pageType === 'agenda') {
    columns.splice(1, 0, {
      label: 'Producer',
      field: 'requestedBy',
      formatter: (row: ProcessorScheduling) =>
        row.requestedBy?.company
          ? row.requestedBy?.company
          : `${row.requestedBy?.first_name} ${row.requestedBy?.last_name}`,
    });
  }

  // TODO: Rework extra columns code into it's own function or consolidate somewhere else
  if (extraColumns?.includes(ExtraColumnsEnum.StatusSelect)) {
    columns.push({
      label: 'Status',
      field: 'status',
      formatter: (row) => (
        <StatusSelector
          key={`status-${row._id}`}
          row={row}
          updateScheduling={statusChanges.updateScheduling}
          updateSchedulingOp={statusChanges.updateSchedulingOp}
          handleInvoicing={statusChanges.handleInvoicing}
          handleKilled={statusChanges.handleKilled}
          handleDroppedOff={statusChanges.handleDroppedOff}
          onChange={() => {
            if (onChangeCallback) {
              onChangeCallback();
            }
          }}
        />
      ),
    });
  } else if (extraColumns?.includes(ExtraColumnsEnum.StatusText)) {
    columns.push({
      label: 'Status',
      field: 'status',
      formatter: (row) => renderStatusText(row.status),
    });
  }
  if (extraColumns?.includes(ExtraColumnsEnum.StatusLastUpdated)) {
    columns.push({
      label: 'Updated',
      field: 'statusLastUpdated',
      formatter: (row) => formatToShortDate(row.statusLastUpdated),
    });
  }

  const ReviewButtons = ({ row }: { row: ProcessorScheduling }) => {
    let buttons: ReactElement[] = [];

    if (rowActionButtons?.includes('Details')) {
      buttons.push(
        <div className="ms-1 w-100">
          <DetailsButton
            row={row}
            modal={info}
            isLoading={statusChanges.updateSchedulingOp.loading}
          />
        </div>,
      );
    }

    if (rowActionButtons?.includes('Cancel')) {
      buttons.push(
        <div className="ms-1 w-100">
          <CancelButton
            row={row}
            modal={save}
            onConfirm={(values: any) =>
              handleCancelBooking(
                row,
                values,
                statusChanges.updateScheduling,
                onChangeCallback,
                cancellationReasonData?.cancellationReasonMany.find(
                  (c) => c.reason === values.reason,
                )?._id,
              )
            }
            cancellationReasonData={cancellationReasonData}
            isLoading={statusChanges.updateSchedulingOp.loading}
          />
        </div>,
      );
    }

    return (
      <div className="d-flex">
        {map(buttons, (b, key) => cloneElement(b, { key }))}
      </div>
    );
  };

  return (
    <Col className="pt-4">
      <Card
        className={`
          bg-body-secondary rounded-4 ${className ?? ''}`}
        body
      >
        <div className="d-flex gap-3 align-items-center">
          <FontAwesomeIcon
            className="text-primary"
            style={{ minWidth: '55px' }}
            icon={icon}
            size="3x"
          />
          <div className="d-flex flex-column justify-content-between text-start">
            <div className="fw-bolder fs-2">
              {query[1]?.data?.processorSchedulingPagination?.count ?? 0}{' '}
              {titleText}
            </div>
            {subText && <div className="text-secondary">{subText}</div>}
          </div>
        </div>
        <hr />
        {processorSchedulingsData &&
          processorSchedulingsData?.processorSchedulingMany?.length > 0 && (
            <div className="px-1 bg-body rounded-4">
              <PaginationTable<
                ProcessorScheduling,
                ProcessorSchedulingPagination,
                ProcessorSchedulingPaginationQuery,
                ProcessorSchedulingPaginationQueryVariables,
                SortFindManyProcessorSchedulingInput
              >
                paginationQuery={query}
                columns={[
                  ...columns,
                  {
                    formatter: (row, _) => {
                      return <ReviewButtons row={row} />;
                    },
                  },
                ]}
                dataAccessor={(a) => {
                  return a.processorSchedulingPagination as ProcessorSchedulingPagination;
                }}
                defaultSort={
                  SortFindManyProcessorSchedulingInput.DropoffdateAsc
                }
                buildFilterQuery={(_, defaultSort, page, __) => {
                  return {
                    filter: buildFilter,
                    sort: defaultSort,
                    page,
                    perPage: perPageOverride || 5,
                  };
                }}
                hideButtons={hideButtons}
                isDashboard
                enableSearchParams={false}
                noResultsOverride={{
                  mainText: noResultsText || 'No Bookings',
                  subText:
                    "Try refreshing your page if this doesn't seem right.",
                }}
              />
            </div>
          )}
      </Card>
    </Col>
  );
}
