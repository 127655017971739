import { map, some } from 'lodash';
import { useMemo } from 'react';
import { Form } from 'react-bootstrap';

import {
  EnumProcessorSchedulingStatus,
  ProcessorScheduling,
  ProcessorSettings,
  useViewProcessorAdminQuery,
} from 'lib/graphql';

import styles from './status-selector.module.scss';
import {
  ALL_SHOWN_STATUSES,
  renderStatusText,
} from '../helpers/processorStatusPresentation';
import { StatusChanges } from '../hooks/useStatusChanges';

export const statusStyles: { [key: string]: string } = {
  [EnumProcessorSchedulingStatus.Requested]: styles.statusRequested,
  [EnumProcessorSchedulingStatus.Scheduled]: styles.statusScheduled,
  [EnumProcessorSchedulingStatus.DroppedOff]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.Killed]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.Aging]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.Curing]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.ReadyPayAtPickup]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.Invoicing]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.InvoicePaid]: styles.statusInProgress,
  [EnumProcessorSchedulingStatus.Completed]: styles.statusCompleted,
};

export default function StatusSelector({
  row,
  updateScheduling,
  updateSchedulingOp,
  handleInvoicing,
  handleKilled,
  handleDroppedOff,
  onChange,
}: {
  row: ProcessorScheduling;
  updateScheduling: StatusChanges['updateScheduling'];
  updateSchedulingOp: StatusChanges['updateSchedulingOp'];
  handleInvoicing: StatusChanges['handleInvoicing'];
  handleKilled: StatusChanges['handleKilled'];
  handleDroppedOff: StatusChanges['handleDroppedOff'];
  onChange?: (newStatus: EnumProcessorSchedulingStatus) => void;
}) {
  const { data, loading } = useViewProcessorAdminQuery();
  const validOptions = useMemo(() => {
    const statusIndex = ALL_SHOWN_STATUSES.indexOf(row.status);
    const validOptions = ALL_SHOWN_STATUSES.slice(statusIndex);

    return validOptions;
  }, [row.status]);

  const handleUpdateStatus = async (value: EnumProcessorSchedulingStatus) => {
    const isHangingWeightMissing = some(
      row.animalHeads,
      (animalHead) =>
        !(
          (animalHead?.hangingWeight ?? 0) ||
          (animalHead?.hangingWeightSideA ?? 0) ||
          (animalHead?.hangingWeightSideB ?? 0)
        ),
    );

    switch (value) {
      case EnumProcessorSchedulingStatus.Killed:
        handleKilled(
          value,
          row,
          data?.processorSettingsOne as ProcessorSettings,
          onChange,
        );
        break;
      // Display the killed modal if killed status is skipped as we need to capture the hanging weight for invoicing
      case EnumProcessorSchedulingStatus.Aging:
      case EnumProcessorSchedulingStatus.Curing:
        if (isHangingWeightMissing) {
          handleKilled(
            value,
            row,
            data?.processorSettingsOne as ProcessorSettings,
            onChange,
          );
        } else {
          await updateScheduling({
            variables: {
              id: row._id,
              record: {
                status: value,
              },
            },
          });

          if (onChange) {
            onChange(value);
          }
        }
        break;
      case EnumProcessorSchedulingStatus.Invoicing:
        if (isHangingWeightMissing) {
          handleKilled(
            value,
            row,
            data?.processorSettingsOne as ProcessorSettings,
            onChange,
          );
        } else {
          handleInvoicing(row, onChange);
        }
        break;
      case EnumProcessorSchedulingStatus.DroppedOff:
        handleDroppedOff(row, onChange);
        break;
      default:
        await updateScheduling({
          variables: {
            id: row._id,
            record: {
              status: value,
            },
          },
        });

        if (onChange) {
          onChange(value);
        }
    }
  };

  if (updateSchedulingOp.loading || loading) {
    return null;
  }

  return (
    <Form.Select
      value={row.status}
      onChange={(e) =>
        handleUpdateStatus(e.target.value as EnumProcessorSchedulingStatus)
      }
      className={statusStyles[row.status]}
      name="job-status"
    >
      {map(validOptions, (option) => (
        <option key={`${row._id}-${option}`} value={option}>
          {renderStatusText(option)}
        </option>
      ))}
    </Form.Select>
  );
}
