import { InlineEditorProps } from '@farmshare/ui-components';
import { ChangeEvent, useState } from 'react';

import { ProcessorSchedulingAnimalHeads } from 'lib/graphql';

import styles from './table-editor-sides.module.scss';

export function TableEditorSides<TData extends ProcessorSchedulingAnimalHeads>({
  row,
  updateRow,
}: InlineEditorProps<TData>) {
  const [sideA, setSideA] = useState(row.original.hangingWeightSideA);
  const [sideB, setSideB] = useState(row.original.hangingWeightSideB);

  const onChangeSideA = (e: ChangeEvent<HTMLInputElement>) => {
    setSideA(Number(e.target.value));
  };

  const onChangeSideB = (e: ChangeEvent<HTMLInputElement>) => {
    setSideB(Number(e.target.value));
  };

  const onBlurSideA = () => {
    updateRow(row.original, 'hangingWeightSideA', sideA || 0);
  };

  const onBlurSideB = () => {
    updateRow(row.original, 'hangingWeightSideB', sideB || 0);
  };

  return (
    <div className={styles.tableEditor}>
      <input
        className={styles.tableEditorInput}
        type="number"
        value={sideA || 0}
        onChange={onChangeSideA}
        onBlur={onBlurSideA}
        step={0.01}
      />
      /
      <input
        className={styles.tableEditorInput}
        type="number"
        value={sideB || 0}
        onChange={onChangeSideB}
        onBlur={onBlurSideB}
        step={0.01}
      />
    </div>
  );
}
