import {
  Button,
  InputChips,
  InputChipOption,
  InputText,
  InputEditor,
  InputSelectBar,
  InputQuantity,
  InputCheck,
} from '@farmshare/ui-components';
import {
  ALL_ANIMAL_SPLITS,
  animalSpeciesHelper,
  animalSplitTypeHelper,
  capacityConfigurationHelper,
  dayOfWeekHelper,
  DAYS_OF_WEEK,
  inspectionLevelHelper,
} from '@farmshare/utils';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'environments/environment';
import { useFormikContext } from 'formik';
import {
  filter,
  findIndex,
  flatMap,
  map,
  reduce,
  sortBy,
  unionWith,
} from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Card, Col, Form, Row, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { themeState } from 'state';

import {
  EnumProcessorSettingsAnimalSettingsInspectionLevels,
  EnumProcessorSettingsAnimalSettingsSpecies,
  EnumProcessorSettingsCapacityConfigurationType,
} from 'lib/graphql';

import { AnimalSettingsPricing } from './animal-settings-pricing';
import { CapacityBlock } from './capacity-block';
import { WeeklyCapacitySettings } from './weekly-capacity-settings';

interface DailyCapacity {
  type?: string;
  capacities: any[];
}

interface CapacitySetting {
  dayOfWeek?: string;
  isEnabled?: boolean;
  dailyCapacities?: DailyCapacity[];
}

interface CustomDayCapacity {
  _id?: string; // MongoID if its an update instead of create this must be present
  date: string;
  isDeleted: boolean;
  dailyCapacities?: DailyCapacity[];
}

export type SettingsFormData = {
  daysOfWeek: InputChipOption[];
  animalSpecies: InputChipOption[];
  inspectionLevels: Partial<
    Record<EnumProcessorSettingsAnimalSettingsSpecies, Array<InputChipOption>>
  >;
  splitTypes: Partial<
    Record<EnumProcessorSettingsAnimalSettingsSpecies, Array<InputChipOption>>
  >;
  capacityConfigurationType: EnumProcessorSettingsCapacityConfigurationType;
  animalUnitCapacitySettings: Partial<
    Record<EnumProcessorSettingsAnimalSettingsSpecies, number>
  >;
  capacitySettings: CapacitySetting[];
  butcherSlotPricing: Partial<
    Record<
      EnumProcessorSettingsAnimalSettingsSpecies,
      Partial<
        Record<EnumProcessorSettingsAnimalSettingsInspectionLevels, number>
      >
    >
  >;
  customCutsheetPricing: Partial<
    Record<
      EnumProcessorSettingsAnimalSettingsSpecies,
      Partial<
        Record<EnumProcessorSettingsAnimalSettingsInspectionLevels, number>
      >
    >
  >;

  customCapacityDays: Record<string, CustomDayCapacity>;
  schedulingDeposit: number;
  refundDeadlineDays: number;
  isNotificationDisabled: boolean;
  isWaitlistFunctionalityDisabled: boolean;
  isPastDatesEnabled: boolean;
  about?: string;
};

export const daysOfWeekOptions = map(DAYS_OF_WEEK, (value) => {
  const dayOfWeekOption = dayOfWeekHelper(value);
  return { ...dayOfWeekOption, sortValue: dayOfWeekOption.order };
});

export const animalSpeciesOptions = sortBy(
  map(EnumProcessorSettingsAnimalSettingsSpecies, (value) => {
    const { label, order } = animalSpeciesHelper(value);
    return { label, value, sortValue: order };
  }),
  (animalSpeciesOption) => animalSpeciesOption.sortValue,
);

export const animalSplitOptions = sortBy(
  map(ALL_ANIMAL_SPLITS, (value) => {
    const { label, order } = animalSplitTypeHelper(value);
    return { label, value, sortValue: order };
  }),
  (splitOption) => splitOption.sortValue,
);

export const inspectionLevelOptions = sortBy(
  map(EnumProcessorSettingsAnimalSettingsInspectionLevels, (value) => {
    const { label, order } = inspectionLevelHelper(value);
    return { label, value, sortValue: order };
  }),
  (inspectionLevel) => inspectionLevel.sortValue,
);

export const capacityConfigurationTypeOptions = sortBy(
  reduce(
    EnumProcessorSettingsCapacityConfigurationType,
    (acc: any[], value) => {
      const capacityConfigurationTypeOption =
        capacityConfigurationHelper(value);

      acc.push({
        ...capacityConfigurationTypeOption,
        sortValue: capacityConfigurationTypeOption.order,
      });

      return acc;
    },
    [],
  ),
  (capacityConfigurationType) => capacityConfigurationType.sortValue,
);

const buildDefaultCapacities = (
  capacityConfigurationType: EnumProcessorSettingsCapacityConfigurationType,
  forSpecies: EnumProcessorSettingsAnimalSettingsSpecies[],
  forSpeciesInspectionLevels: Partial<
    Record<EnumProcessorSettingsAnimalSettingsSpecies, Array<InputChipOption>>
  > = {},
  defaultValue: number = 10,
) => {
  if (
    capacityConfigurationType ===
    EnumProcessorSettingsCapacityConfigurationType.Species
  ) {
    // Add defaults for the species
    return map(forSpecies, (species) => ({ species, value: defaultValue }));
  }
  if (
    capacityConfigurationType ===
    EnumProcessorSettingsCapacityConfigurationType.SpeciesInspection
  ) {
    // Add defaults for species inspection levels
    return flatMap(forSpecies, (species) =>
      map(forSpeciesInspectionLevels[species], (inspectionLevel) => ({
        species,
        inspectionLevel: inspectionLevel.value,
        value: defaultValue,
      })),
    );
  }

  if (
    capacityConfigurationType ===
    EnumProcessorSettingsCapacityConfigurationType.WeightedAnimalUnit
  ) {
    // Add defaults for weight animal units
    return [{ value: defaultValue }];
  }

  return [];
};

export default function SettingsForm({ isSaving }: { isSaving: boolean }) {
  const navigate = useNavigate();
  const theme = useRecoilValue(themeState);
  const { setFieldValue, values, initialValues } =
    useFormikContext<SettingsFormData>();
  const customDaysCapacity = useMemo(() => {
    return filter(values.customCapacityDays, (customDay) => {
      return values.isPastDatesEnabled
        ? true
        : moment(customDay.date)
            .utc()
            .startOf('day')
            .isSameOrAfter(moment().utc().startOf('day'));
    });
  }, [values.customCapacityDays, values.isPastDatesEnabled]);

  const [customDateField, setCustomDateField] = useState(moment());
  // watch fields and update values in capacity configuration
  useEffect(() => {
    // when certain form data changes we check to make sure we have the capacity settings for all the data.
    // Day of week capacity configuration per type
    // for animals and inspection levels etc.
    const updatedCapacitySettings = reduce(
      values.capacitySettings,
      (acc: any, capacitySetting) => {
        const foundDailyCapacityOfConfigurationTypeIndex = findIndex(
          capacitySetting.dailyCapacities,
          { type: values.capacityConfigurationType },
        );

        const defaultCapacities = buildDefaultCapacities(
          values.capacityConfigurationType,
          map(
            values.animalSpecies,
            (o: any) => o.value as EnumProcessorSettingsAnimalSettingsSpecies,
          ),
          values.inspectionLevels,
        );

        // If we don't have a a daily capacity setting for the currently selected type we create one.
        if (foundDailyCapacityOfConfigurationTypeIndex < 0) {
          acc.push({
            ...capacitySetting,
            dailyCapacities: (capacitySetting.dailyCapacities ?? []).concat([
              {
                type: values.capacityConfigurationType,
                capacities: defaultCapacities,
              },
            ]),
          });
        } else {
          // we do have one and we need to make sure its up to date.
          const updatedCapacities = unionWith(
            capacitySetting?.dailyCapacities?.[
              foundDailyCapacityOfConfigurationTypeIndex
            ]?.capacities,
            defaultCapacities,
            (left, right) =>
              right.species === left.species &&
              right.inspectionLevel === left.inspectionLevel,
          );

          acc.push({
            ...capacitySetting,
            dailyCapacities: map(capacitySetting.dailyCapacities, (dc) => {
              if (dc.type === values.capacityConfigurationType) {
                return { ...dc, capacities: updatedCapacities };
              }
              return { ...dc };
            }),
          });
        }

        return acc;
      },
      [],
    );

    const updatedCustomCapacityDays = reduce(
      values.customCapacityDays,
      (acc: any, customCapacityDay, key) => {
        // Currently we are only supporting SpeciesInspection type capacities on custom capacity days
        // This is for my sanity as these need to be configurable in a different way from the default weekly capacities
        const foundDailyCapacityOfConfigurationTypeIndex = findIndex(
          customCapacityDay.dailyCapacities,
          {
            type: EnumProcessorSettingsCapacityConfigurationType.SpeciesInspection,
          },
        );
        const defaultCapacities = buildDefaultCapacities(
          EnumProcessorSettingsCapacityConfigurationType.SpeciesInspection,
          map(
            values.animalSpecies,
            (o: any) => o.value as EnumProcessorSettingsAnimalSettingsSpecies,
          ),
          values.inspectionLevels,
          0,
        );

        // If we don't have a a daily capacity setting for the currently selected type we create one.
        if (foundDailyCapacityOfConfigurationTypeIndex < 0) {
          acc[key] = {
            ...customCapacityDay,
            dailyCapacities: (customCapacityDay.dailyCapacities ?? []).concat([
              {
                type: values.capacityConfigurationType,
                capacities: defaultCapacities,
              },
            ]),
          };
        } else {
          // we do have one and we need to make sure its up to date.
          const updatedCapacities = unionWith(
            customCapacityDay?.dailyCapacities?.[
              foundDailyCapacityOfConfigurationTypeIndex
            ]?.capacities,
            defaultCapacities,
            (left, right) =>
              right.species === left.species &&
              right.inspectionLevel === left.inspectionLevel,
          );
          acc[key] = {
            ...customCapacityDay,
            dailyCapacities: map(customCapacityDay.dailyCapacities, (dc) => {
              if (
                dc.type ===
                EnumProcessorSettingsCapacityConfigurationType.SpeciesInspection
              ) {
                return { ...dc, capacities: updatedCapacities };
              }
              return { ...dc };
            }),
          };
        }

        return acc;
      },
      {},
    );

    setFieldValue('capacitySettings', updatedCapacitySettings);
    setFieldValue('customCapacityDays', updatedCustomCapacityDays);
    // We only want to watch certain form values that when changed should cause us to check capacitySettings to make sure we have defaults
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.capacityConfigurationType,
    values.animalSpecies,
    values.inspectionLevels,
  ]);

  const handleAddCustomCapacityDay = () => {
    const currentCustomCapacityDays = values['customCapacityDays'];
    const addedDate = customDateField.format('MM/DD/YYYY');
    currentCustomCapacityDays[addedDate] = {
      date: addedDate,
      isDeleted: false,
      dailyCapacities: [
        {
          type: EnumProcessorSettingsCapacityConfigurationType.SpeciesInspection,
          capacities: buildDefaultCapacities(
            EnumProcessorSettingsCapacityConfigurationType.SpeciesInspection,
            map(
              values.animalSpecies,
              (o: any) => o.value as EnumProcessorSettingsAnimalSettingsSpecies,
            ),
            values.inspectionLevels,
            0,
          ),
        },
      ],
    };
    setFieldValue('customCapacityDays', {
      ...currentCustomCapacityDays,
    });
  };

  return (
    <>
      <Stack direction="horizontal" className="justify-content-between">
        <div>
          <h2 className="fw-bold">Set Your Schedule</h2>
          <p className="m-0">
            Fill out your availability by week and update specific days.
          </p>
        </div>
      </Stack>
      <hr />

      {/* Days of Week */}
      <div className="fw-bold mb-3 fs-5">Select the days of the week</div>
      <InputChips nameOveride="daysOfWeek" options={daysOfWeekOptions} />
      <hr />

      {/* Animal Species */}
      <div className="fw-bold mb-3 fs-5">Select the animals you process</div>
      <InputChips nameOveride="animalSpecies" options={animalSpeciesOptions} />
      <hr className=" mt-4 mb-4" />

      {/* Specific Animal Settings  */}
      {map(
        sortBy(values.animalSpecies, (option) => option.sortValue),
        (selectedAnimalSpecies) => (
          <Stack
            className="justify-content-between"
            key={selectedAnimalSpecies.value}
          >
            {/* Inspections */}
            <Row className="g-3 mb-3">
              <Col>
                <div className="fw-bold mb-3 fs-5">
                  Select {selectedAnimalSpecies.label} inspection you offer
                </div>
                <InputChips
                  nameOveride={`inspectionLevels.${selectedAnimalSpecies.value}`}
                  options={inspectionLevelOptions}
                />
              </Col>
            </Row>

            {/* Split Types */}
            <Row className="g-3 mb-3">
              <Col>
                <div className="fw-bold mb-3 fs-5">
                  Select {selectedAnimalSpecies.label} splits you offer
                </div>
                <InputChips
                  nameOveride={`splitTypes.${selectedAnimalSpecies.value}`}
                  options={animalSplitOptions}
                />
              </Col>
            </Row>
            <hr className="mt-4 mb-4" />
          </Stack>
        ),
      )}

      {/* Pricing Info */}
      <p className="fw-bold mb-3 fs-5">Pricing information</p>
      <Stack className="justify-content-between">
        <Row className="g-3 mb-3">
          <Col xs={3}>
            <InputText
              label="Scheduling Deposit ($)"
              type="number"
              nameOveride={`schedulingDeposit`}
            />
          </Col>
          <Col xs={3}>
            <InputText
              label="Refund Deadline (Days in advance)"
              type="number"
              nameOveride="refundDeadlineDays"
            />
          </Col>
        </Row>
        <Row className="g-3 mb-3 align-items-end">
          <Col>
            <AnimalSettingsPricing />
          </Col>
        </Row>
        <hr className="mt-4 mb-4" />
      </Stack>

      <Stack className="justify-content-between">
        <Row className="g-3 mb-3">
          <Col>
            <div className="fw-bold mb-3 fs-5">
              How would you like to set your capacity?
            </div>
            <InputSelectBar
              nameOveride="capacityConfigurationType"
              options={capacityConfigurationTypeOptions}
            />
          </Col>
        </Row>
        <hr className="mt-4 mb-4" />
        {/* Configure the conversion for weighted animal units its how we do the math. */}
        {values.capacityConfigurationType ===
          EnumProcessorSettingsCapacityConfigurationType.WeightedAnimalUnit && (
          <>
            <Row className="g-3 mb-3">
              <Col>
                <div className="fw-bold fs-5">
                  Weight the species you process
                </div>
                <p>
                  For example, how many hogs equals one beef in terms of your
                  processing capacity.
                </p>
              </Col>
            </Row>
            <Row className="g-4 mb-3">
              {map(
                sortBy(values.animalSpecies, (option) => option.sortValue),
                (speciesOffered) => {
                  return (
                    <Col xs={12} sm={6} lg={4} xl={3}>
                      <Card className="bg-body-tertiary text-center">
                        <Card.Body className="px-0">
                          <Stack
                            direction="horizontal"
                            className="justify-content-between py-1 px-3"
                          >
                            <b>{speciesOffered.label} =</b>
                            <InputQuantity
                              nameOveride={`animalUnitCapacitySettings.${speciesOffered.value}`}
                              min={0}
                              step={0.1}
                              showEdit
                            />
                            <b className="ms-1">units</b>
                          </Stack>
                        </Card.Body>
                      </Card>
                    </Col>
                  );
                },
              )}
            </Row>
            <hr className="mt-4 mb-4" />
          </>
        )}

        {/* Weekly Capacity Configuration Default */}
        <WeeklyCapacitySettings />
      </Stack>

      {/* Custom days */}
      <hr className=" my-4" />
      <div className="fw-bold fs-5">Customize individual days</div>
      <div>
        Select a date below to add a custom capacity. Keep capacity at 0 if
        you&apos;re unavailable on the given day.
      </div>
      <Row>
        <Col xs={12} lg={4} xl={3}>
          <Stack direction="horizontal" gap={2} className="mt-3">
            <Form.Control
              type="date"
              value={customDateField.format('YYYY-MM-DD')}
              onChange={(e) => setCustomDateField(moment(e.target.value))}
              min={
                values.isPastDatesEnabled
                  ? undefined
                  : moment().format('YYYY-MM-DD')
              }
            />
            <Button
              content="Add"
              icon={faPlus}
              onClick={handleAddCustomCapacityDay}
              className="ms-auto"
            />
          </Stack>
          <div className="mt-3">
            <InputCheck
              type="switch"
              inline
              label="Display dates in the past"
              nameOveride="isPastDatesEnabled"
            />
          </div>
        </Col>
      </Row>
      <p className="mt-3 mb-1">
        You currently have {customDaysCapacity.length} upcoming customized days:
      </p>
      <Row className="gx-2 gy-3">
        {map(customDaysCapacity, (dayDetails) => {
          if (dayDetails) {
            const day = moment(dayDetails.date).utc().format('MM/DD/YYYY');
            return (
              <CapacityBlock
                key={day}
                day={day}
                parentBaseName={`customCapacityDays.${day}`}
                title={moment(dayDetails.date).utc().format('ddd, MMM D, YYYY')}
                currentCapacityConfigurationType={
                  EnumProcessorSettingsCapacityConfigurationType.SpeciesInspection
                }
              />
            );
          }

          return null;
        }).sort((a, b) => {
          return moment(a?.props.day).isAfter(moment(b?.props.day)) ? 1 : -1;
        })}
      </Row>
      <hr className=" mt-4 mb-4" />
      {/* Notification Settings */}
      <div className="fw-bold fs-5 mb-2">Notification Settings</div>
      <InputCheck
        type="switch"
        inline
        label="Disable All Producer Notifications"
        nameOveride="isNotificationDisabled"
      />
      <div className="fw-bold fs-5 mb-2">Waitlist Settings</div>
      <div className="d-flex align-items-center">
        <InputCheck
          type="switch"
          inline
          label="Disable Waitlist Functionality"
          nameOveride="isWaitlistFunctionalityDisabled"
        />

        {!initialValues.isWaitlistFunctionalityDisabled && (
          <Button
            content="View Waitlist"
            className="ms-4 fw-bold text-white"
            onClick={() => {
              navigate('/processor/settings/waitlist');
            }}
          />
        )}
      </div>
      <hr className=" mt-4 mb-4" />

      {/* Text editor */}
      <div className="fw-bold fs-5">About</div>
      <p>
        Here you can provide a quick overview of your operation as well as any
        additional information you would like your customers to know when they
        are booking processing with you.
      </p>
      <InputEditor
        name="about"
        apiKey={environment.tinyApiKey}
        initialContent={values.about}
        theme={theme}
      />
      {/* Action Buttons */}
      <hr className=" mt-4 mb-4" />
      <div className="ms-auto mb-4">
        <Button
          className="btn-save-settings"
          content="Save Changes"
          type="submit"
          isLoading={isSaving}
        />
      </div>
    </>
  );
}
