import { InfoBox, InputSelectBar, InputText } from '@farmshare/ui-components';
import { useFormikContext } from 'formik';
import { Stack } from 'react-bootstrap';

export interface OFSInitSendgridFormForm {
  type?: 'email' | 'domain';
  email?: string;
  domain?: string;
  dnsEmail?: string;
}

export function OFSInitSendgridForm() {
  const { values } = useFormikContext<OFSInitSendgridFormForm>();

  return (
    <Stack gap={3}>
      <InfoBox
        content={
          <span>
            Sendgrid can be setup to either authenticate a single email address{' '}
            <b>OR</b> a domain. If you choose to authenticate a domain, you will
            need to add DNS records to the domain.
          </span>
        }
      />
      <div className="fw-bold">Type of verification:</div>
      <InputSelectBar
        label="Type"
        options={[
          { label: 'Email', value: 'email' },
          { label: 'Domain', value: 'domain' },
        ]}
        className="mb-3"
      />
      <InputText
        type="email"
        label="Email"
        hint={
          values.type === 'domain'
            ? 'Enter the primary email address for the domain.'
            : 'Enter the email address that will be used to send emails from the OFS.'
        }
        floatingLabel
        required
      />
      {values.type === 'domain' && (
        <>
          <InputText
            type="text"
            label="Domain"
            hint="Enter the domain that will be used to send emails from the OFS."
            required={values.type === 'domain'}
            floatingLabel
          />
          <InputText
            type="text"
            label="DNS Email"
            hint="Enter the email address to send the DNS instructions to."
            required={values.type === 'domain'}
            floatingLabel
          />
        </>
      )}
    </Stack>
  );
}
