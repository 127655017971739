import {
  Button,
  PageTitle,
  Loading,
  useToastr,
} from '@farmshare/ui-components';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { useFormik } from 'formik';
import moment from 'moment';
import { Col, Container, Form, Row } from 'react-bootstrap';

import { downloadFromBase64Encoded } from 'lib/downloadFromBase64Encoded';
import {
  useProcessorExportCsvByVendorIdMutation,
  useActiveVendorListQuery,
} from 'lib/graphql';

export default function AdminProcessorExportPage() {
  const { push } = useToastr();
  const { data, loading } = useActiveVendorListQuery();
  const [processorExportCsv, ProcessorExportCsvOp] =
    useProcessorExportCsvByVendorIdMutation();

  const handleExport = async (
    startDate: string,
    endDate: string,
    vendorId: string,
  ) => {
    try {
      const response = await processorExportCsv({
        variables: {
          vendorId,
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
        },
      });

      downloadFromBase64Encoded(
        response.data?.processorExportCsvByVendorId?.file ?? '',
        response.data?.processorExportCsvByVendorId?.filename ??
          'Job_Export.csv',
      );

      push({
        title: 'Success',
        bg: 'primary',
        body: 'CSV exported successfully',
        delay: 5000,
      });
    } catch (error) {
      console.error('Error exporting CSV:', error);
      push({
        title: 'Error',
        bg: 'danger',
        body: 'Error exporting CSV',
        delay: 5000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      processor: '',
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    },
    validate: (values) => {
      const errors: Record<string, string> = {};

      if (!values.processor || values.processor === '') {
        errors.processor = 'Processor is required';
      }
      if (!values.startDate) {
        errors.startDate = 'Start date is required';
      }
      if (!values.endDate) {
        errors.endDate = 'End date is required';
      }
      if (!moment(values.startDate).isValid()) {
        errors.startDate = 'Invalid start date';
      }
      if (!moment(values.endDate).isValid()) {
        errors.endDate = 'Invalid end date';
      }
      if (moment(values.startDate).isAfter(moment(values.endDate))) {
        errors.startDate = 'Start date must be before end date';
      }

      return errors;
    },
    onSubmit: async () => {
      const { startDate, endDate, processor } = formik.values;
      try {
        await handleExport(startDate, endDate, processor);
      } catch (error) {
        console.error('Error exporting CSV:', error);
      }
    },
  });

  if (loading || ProcessorExportCsvOp.loading) {
    return <Loading />;
  }

  return (
    <Container>
      <PageTitle title="Processor Export" showBreadCrumbs={false} />
      <form onSubmit={formik.handleSubmit}>
        <Row className="mb-3 px-3">
          <Col md="3">
            <Form.Label className="mb-0">Start Date</Form.Label>
            <Form.Control
              type="date"
              name="startDate"
              value={formik.values.startDate}
              onChange={formik.handleChange}
            />
            {formik.errors.startDate && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: 'block' }}
              >
                {formik.errors.startDate}
              </Form.Control.Feedback>
            )}
          </Col>
          <Col md="3">
            <Form.Label className="mb-0">End Date</Form.Label>
            <Form.Control
              type="date"
              name="endDate"
              value={formik.values.endDate}
              onChange={formik.handleChange}
              min={moment(formik.values.startDate).format('YYYY-MM-DD')}
            />
            {formik.errors.endDate && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: 'block' }}
              >
                {formik.errors.endDate}
              </Form.Control.Feedback>
            )}
          </Col>
          <Col md="3">
            <Form.Label className="mb-0">Processor</Form.Label>

            <Form.Select
              required
              onChange={formik.handleChange}
              name="processor"
            >
              <option value="">Select Processor</option>
              {data?.activeVendorList?.map((vendor) => (
                <option key={vendor?._id} value={vendor?._id}>
                  {vendor?.shop_name}
                </option>
              ))}
            </Form.Select>
            {formik.errors.processor && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: 'block' }}
              >
                {formik.errors.processor}
              </Form.Control.Feedback>
            )}
          </Col>
          <Col md="3">
            <Button
              style={{ marginTop: '25px' }}
              content="Export CSV"
              icon={faFileDownload}
              size="sm"
              type="submit"
              disabled={
                !formik.isValid ||
                formik.isSubmitting ||
                formik.values.processor === '' ||
                ProcessorExportCsvOp.loading
              }
            />
          </Col>
        </Row>
      </form>
    </Container>
  );
}
