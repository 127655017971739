import { Loading, useToastr } from '@farmshare/ui-components';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useWaitlistUnsubscribeMutation } from 'lib/graphql';

export default function WaitlistUnsubscribePage() {
  const { token } = useParams();
  const { push } = useToastr();
  const [unsubscribe, { loading }] = useWaitlistUnsubscribeMutation();
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    unsubscribe({ variables: { token: token! } })
      .then(() => {
        setUnsubscribed(true);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [token, unsubscribe, push]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Container>
      {!loading && unsubscribed && (
        <div className="d-flex justify-content-center mt-5">
          <h3>You've been unsubscribed from the waitlist.</h3>
        </div>
      )}
      {!loading && !unsubscribed && (
        <div className="d-flex justify-content-center mt-5">
          <h3>{error}</h3>
        </div>
      )}
    </Container>
  );
}
