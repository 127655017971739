import { inspectionLevelHelper, animalSpeciesHelper } from '@farmshare/utils';

import { ProcessorScheduling } from 'lib/graphql';

export default function renderJobHeadsCellHelper(row: ProcessorScheduling) {
  return `${row.headCount}${
    row.animalHeads?.[0]?.inspectionLevel
      ? ` ${inspectionLevelHelper(row.animalHeads[0].inspectionLevel).label}`
      : ``
  } ${animalSpeciesHelper(row.animalSpecies).label}`;
}
