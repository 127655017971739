import { assign, map } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { Table, TableProps } from 'react-bootstrap';

export interface DataDetailListProps {
  rows: {
    label: ReactNode;
    value?: ReactNode;
    className?: string;
    labelClassName?: string;
    valueClassName?: string;
  }[];
  heading?: ReactNode;
  headingStyle?: React.CSSProperties;
  tableProps?: TableProps;
}

export function DataDetailList({
  heading,
  rows,
  tableProps,
  headingStyle,
}: DataDetailListProps) {
  const _tableProps = useMemo(
    () => assign({ size: 'sm', borderless: true }, tableProps),
    [tableProps],
  );

  return (
    <div>
      {heading && (
        <>
          <h6 className="pb-2" style={headingStyle}>
            {heading}
          </h6>
          <hr />
        </>
      )}
      <Table {..._tableProps}>
        <tbody>
          {map(rows, (row, idx) => (
            <tr
              key={idx}
              className={
                'align-middle' + (row.className ? ` ${row.className}` : '')
              }
            >
              <td
                className={`bg-transparent fw-light small text-enad text-muted text-nowrap ${
                  row?.labelClassName ?? ''
                }`}
                // style={{ width: '0px' }}
              >
                {row.label}:&nbsp;
              </td>
              <td className={`bg-transparent ${row?.valueClassName ?? ''}`}>
                {row.value || '-'}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
