import { type AuthorizationParams, useAuth0 } from '@auth0/auth0-react';
import { Loading } from '@farmshare/ui-components';
import { assign } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export default function LoginPage() {
  const { loginWithRedirect, isLoading } = useAuth0();
  const [urlSeachParams] = useSearchParams();

  useEffect(() => {
    let authorizationParams: AuthorizationParams = {
      redirect_uri: `${window.location.origin}/processor/agenda`,
    };

    // check if the url has the invitation and organization parameters
    if (
      urlSeachParams.has('invitation') &&
      urlSeachParams.has('organization')
    ) {
      authorizationParams = assign(authorizationParams, {
        organization: urlSeachParams.get('organization') || '',
        invitation: urlSeachParams.get('invitation') || '',
      });
    } else if (urlSeachParams.has('organization')) {
      authorizationParams = assign(authorizationParams, {
        organization: urlSeachParams.get('organization') || '',
      });
    }
    loginWithRedirect({ authorizationParams });
  }, [urlSeachParams, loginWithRedirect]);

  return isLoading ? <Loading /> : <div />;
}
