import { Button, ColumnProps, PaginationTable } from '@farmshare/ui-components';
import { animalSpeciesHelper, formatToShortDate } from '@farmshare/utils';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import {
  ProcessorScheduling,
  type ProcessorSchedulingFilteredQuery,
  type ProcessorSchedulingFilteredQueryVariables,
  ProcessorSchedulingPagination,
  ProcessorSchedulingPaginationDocument,
  SortFindManyProcessorSchedulingInput,
  useProcessorSchedulingFilteredLazyQuery,
} from 'lib/graphql';

import styles from './jobs-table.module.scss';
import StatusSelector from '../lib/_views/status-selector';
import {
  COMPLETED_STATUSES,
  IN_PROGRESS_STATUSES,
  renderStatusText,
} from '../lib/helpers/processorStatusPresentation';
import { useProcessorSchedulingModals } from '../lib/hooks/useProcessorSchedulingModals';
import { useStatusChanges } from '../lib/hooks/useStatusChanges';

const PAGE_SIZE = 10;

export function JobsTab() {
  const vendor = useRecoilValue(vendorState);
  const [searchByContact, setSearchByContact] = useState<string>('');

  const processorSchedulingPaginationQuery =
    useProcessorSchedulingFilteredLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        vendorId: vendor?._id,
        statusFilter: [...IN_PROGRESS_STATUSES, ...COMPLETED_STATUSES],
        page: 1,
        perPage: PAGE_SIZE,
      },
    });

  const statusChanges = useStatusChanges([
    {
      query: ProcessorSchedulingPaginationDocument,
      variables: {
        searchByContact: searchByContact,
        vendorId: vendor?._id,
        statusFilter: [...IN_PROGRESS_STATUSES, ...COMPLETED_STATUSES],
        page: 1,
        perPage: PAGE_SIZE,
      },
    },
  ]);

  const searchContact = (searchValue: string) => {
    processorSchedulingPaginationQuery[1].refetch({
      searchByContact: searchValue,
      page: 1,
      perPage: PAGE_SIZE,
    });
  };

  const searchContactDebounced = useRef(debounce(searchContact, 300));

  const onSearchContactChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchByContact(e.target.value);

    searchContactDebounced.current(e.target.value);
  };

  const { openDetailsModal } = useProcessorSchedulingModals(
    statusChanges.updateScheduling,
    () => processorSchedulingPaginationQuery[1].refetch(),
  );

  const columns: ColumnProps<
    ProcessorScheduling,
    ProcessorSchedulingFilteredQuery,
    ProcessorSchedulingFilteredQueryVariables
  >[] = [
    {
      label: 'Job',
      field: 'headCount',
      formatter: (row) => (
        <Link to={`/processing-job/${row._id}`}>
          {row.headCount} {animalSpeciesHelper(row.animalSpecies).label}
        </Link>
      ),
    },
    {
      label: 'Producer',
      field: 'requestedBy',
      formatter: (row: ProcessorScheduling) =>
        row.requestedBy?.company
          ? row.requestedBy?.company
          : `${row.requestedBy?.first_name} ${row.requestedBy?.last_name}`,
    },
    {
      label: 'Harvest Date',
      field: 'firstKillDate',
      formatter: (row) =>
        row?.firstKillDate
          ? moment(row.firstKillDate).utc().format('LL')
          : '--',
    },
    {
      label: 'Status',
      field: 'status',
      formatter: (row: ProcessorScheduling) => {
        if (IN_PROGRESS_STATUSES.includes(row.status)) {
          return (
            <div className={styles.statusColumn}>
              <StatusSelector
                key={`status-${row._id}`}
                row={row}
                updateScheduling={statusChanges.updateScheduling}
                updateSchedulingOp={statusChanges.updateSchedulingOp}
                handleInvoicing={statusChanges.handleInvoicing}
                handleKilled={statusChanges.handleKilled}
                handleDroppedOff={statusChanges.handleDroppedOff}
                onChange={() => {
                  processorSchedulingPaginationQuery[1].refetch();
                }}
              />
            </div>
          );
        }

        return (
          <div className={styles.statusCellText}>
            {renderStatusText(row.status)}
          </div>
        );
      },
    },
    {
      label: 'Updated',
      field: 'statusLastUpdated',
      formatter: (row) => formatToShortDate(row.statusLastUpdated),
    },
    {
      label: '',
      formatter: (row) => (
        <Button
          className="ms-1 w-100"
          content="Details"
          icon={faInfoCircle}
          onClick={() => openDetailsModal(row)}
        />
      ),
    },
  ];

  const statusFilters = useMemo(() => {
    return [...IN_PROGRESS_STATUSES, ...COMPLETED_STATUSES].map((status) => ({
      label: renderStatusText(status),
      value: status,
    }));
  }, []);

  return (
    <div className="pb-5 h-100">
      <Card className="bg-body-secondary rounded-4" body>
        <div className={styles.search}>
          <Form.Control
            className={styles.searchInput}
            type="text"
            placeholder="Search by producer, customer"
            value={searchByContact}
            onChange={onSearchContactChanged}
          />
          <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
        </div>
        <hr />
        <div className="pt-2 px-1 bg-body rounded-4">
          <PaginationTable<
            ProcessorScheduling,
            ProcessorSchedulingPagination,
            ProcessorSchedulingFilteredQuery,
            ProcessorSchedulingFilteredQueryVariables,
            SortFindManyProcessorSchedulingInput
          >
            paginationQuery={processorSchedulingPaginationQuery}
            columns={columns}
            dataAccessor={(a) =>
              a.getFilteredProcessorScheduling as ProcessorSchedulingPagination
            }
            defaultSort={SortFindManyProcessorSchedulingInput.DropoffdateAsc}
            filters={statusFilters}
            defaultFilters={[...IN_PROGRESS_STATUSES, ...COMPLETED_STATUSES]}
            buildFilterQuery={(allFilters, defaultSort, page) => {
              const activeFilters = allFilters.filter(
                (f) => f.isActive || false,
              );

              return {
                searchByContact: searchByContact,
                vendorId: vendor?._id,
                statusFilter: activeFilters.map((filter) => filter.label),
                sort: defaultSort,
                page,
                perPage: PAGE_SIZE,
              };
            }}
            enableSearchParams={false}
            noResultsOverride={{
              mainText: 'Jobs were not found',
              subText: "Try refreshing your page if this doesn't seem right.",
            }}
          />
        </div>
      </Card>
    </div>
  );
}
