import { useRef } from 'react';
import { Offcanvas } from 'react-bootstrap';

import { Maybe, ViewProcessorAdminQueryResult } from 'lib/graphql';

import { ProcessorAddJobTab } from 'pages/processor/add-job';

import styles from './schedule-job.module.scss';

type ScheduleJobProps = {
  hideSideOver: () => void;
  isOpen: boolean;
  scheduleDatesRange?: ScheduleDatesRange;
  settings?: Maybe<ViewProcessorAdminQueryResult>;
};

export function ScheduleJob({
  hideSideOver,
  isOpen,
  scheduleDatesRange,
  settings,
}: ScheduleJobProps) {
  const addJobRef = useRef<{ cancel: () => void }>();
  return (
    <Offcanvas
      show={isOpen}
      onHide={() => {
        hideSideOver();
        addJobRef.current?.cancel();
      }}
      placement="end"
      scroll={false}
      className={styles.offcanvas}
      restoreFocus={false}
    >
      <Offcanvas.Header
        closeButton
        style={{ zIndex: 99, position: 'absolute', top: 0, right: 0 }}
      ></Offcanvas.Header>
      <Offcanvas.Body>
        <ProcessorAddJobTab
          addJobRef={addJobRef}
          settings={settings}
          pageType="calendar"
          scheduleDatesRange={scheduleDatesRange}
        />
      </Offcanvas.Body>
    </Offcanvas>
  );
}
