import {
  DataDetailListMinimal,
  Button,
  useModal,
  useToastr,
  MoneyDisplay,
} from '@farmshare/ui-components';
import {
  faFileArrowDown,
  faFileInvoiceDollar,
} from '@fortawesome/free-solid-svg-icons';
import { every } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import { downloadFromBase64Encoded } from 'lib/downloadFromBase64Encoded';
import { downloadFromPresignedUrl } from 'lib/downloadFromPresignedUrl';
import { useProcessorSchedulingGenerateBillingStatementMutation } from 'lib/graphql';

import { type ProcessingJobDetailsSectionProps } from './job-details-tab';

export function InvoiceDetails({
  processingJob,
  updateProcessingJob,
  updateProcessingJobOp,
}: ProcessingJobDetailsSectionProps) {
  const { ask } = useModal();
  const { push } = useToastr();
  const [generateBillingStatement, generateBillingStatementOp] =
    useProcessorSchedulingGenerateBillingStatementMutation();
  const isBillingStatementEnabled = useMemo(() => {
    if (!processingJob?.animalHeads?.length) {
      return false;
    }

    // Check if all animal heads have an inspection level, hanging weight, butcher slot price, and cutsheet
    return every(processingJob.animalHeads, (animalHead) => {
      return (
        animalHead?.inspectionLevel &&
        animalHead?.hangingWeight &&
        animalHead?.hangingWeight > 0 &&
        animalHead?.butcherSlotPrice !== null &&
        animalHead?.butcherSlotPrice !== undefined &&
        animalHead?.cutsheetInformation?.length &&
        every(
          animalHead.cutsheetInformation,
          (cutsheetInformation) => cutsheetInformation?.cutsheet,
        )
      );
    });
  }, [processingJob]);

  const handleMarkPaid = useCallback(() => {
    return ask({
      type: 'save',
      title: 'Confirm Mark Invoice Paid',
      initialValues: {},
      body: (
        <p>
          Are you sure you want to mark this invoice as paid? Once you do this
          you cannot go back and change this invoice to not be paid.
        </p>
      ),
      onSubmit: async () => {
        await updateProcessingJob({
          variables: {
            processorSchedulingId: processingJob?._id,
            record: {
              markInvoicePaid: true,
            },
          },
        });

        push({
          title: 'Invoice Marked Paid',
          body: `The invoice has been marked as paid.`,
          bg: 'success',
          delay: 4000,
        });
      },
    });
  }, [ask, updateProcessingJob, processingJob?._id, push]);

  const handleGenerateBillingStatement = useCallback(async () => {
    try {
      const response = await generateBillingStatement({
        variables: { processorSchedulingId: processingJob?._id },
      });

      if (response.data?.processorSchedulingGenerateBillingStatement?.fileUrl) {
        downloadFromPresignedUrl(
          response.data.processorSchedulingGenerateBillingStatement.fileUrl,
        );
      } else if (
        response.data?.processorSchedulingGenerateBillingStatement?.file
      ) {
        downloadFromBase64Encoded(
          response.data?.processorSchedulingGenerateBillingStatement?.file,
          response.data?.processorSchedulingGenerateBillingStatement
            ?.filename ?? 'Billing_Statement.pdf',
        );
      }
    } catch (error) {
      push({
        title: 'Error',
        body: (error as string).toString(),
        bg: 'danger',
        delay: 4000,
      });
    }
  }, [processingJob?._id, generateBillingStatement, push]);

  return (
    <Card className="h-100">
      <Card.Header className="d-flex align-items-center">
        <span className="fw-bold fs-5">Invoice</span>
        <div className="ms-auto">
          <Button
            className="me-3"
            disabled={
              !isBillingStatementEnabled || generateBillingStatementOp.loading
            }
            isLoading={generateBillingStatementOp.loading}
            content="Generate Billing Statement"
            onClick={handleGenerateBillingStatement}
            size="sm"
            icon={faFileArrowDown}
          />
          <Button
            content="Mark Paid"
            size="sm"
            icon={faFileInvoiceDollar}
            disabled={
              updateProcessingJobOp.loading ||
              !processingJob?.invoice?.invoice_status ||
              processingJob.invoice.invoice_status !== 'open'
            }
            onClick={handleMarkPaid}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <DataDetailListMinimal
              rows={[
                {
                  label: 'Invoice Status',
                  value: processingJob?.invoice?.invoice_status,
                  className: 'invoice-status',
                },
                {
                  label: 'Additional Cost',
                  className: 'invoice-additional-cost',
                  value: (
                    <MoneyDisplay
                      value={
                        processingJob?.animalHeads?.[0]?.invoice?.additionalCost
                          ?.cost
                      }
                    />
                  ),
                },
                {
                  label: 'Additional Cost Details',
                  className: 'invoice-additional-cost-details',
                  value:
                    processingJob?.animalHeads?.[0]?.invoice?.additionalCost
                      ?.reason,
                },
              ]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
