import { Button, useModal, useToastr } from '@farmshare/ui-components';
import {
  animalSplitTypeHelper,
  formatToCurrency,
  inspectionLevelHelper,
} from '@farmshare/utils';
import { faAdd, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import { concat, filter, map, omit, partialRight } from 'lodash';
import { Card, Col, Row, Stack } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import { CutsheetTooltipButton } from 'components/cutsheet-tooltip/cutsheet-tooltip-button';

import {
  EnumCutsheetAnimalSpecies,
  EnumCutsheetInspectionLevels,
  EnumCutsheetType,
  EnumUserRole,
  useCutsheetCreateOneMutation,
  ViewSchedulingCreateQuery,
  ViewSchedulingEditQuery,
  useCutsheetSoftDeleteMutation,
  type Cutsheet,
  type CutsheetCreateOneMutationVariables,
} from 'lib/graphql';

import {
  ExtrasModel,
  ExtrasTrimOptionsType,
  TrimModel,
} from 'pages/processor/cutsheets/_views/cutsheet-modal';

import {
  AddCutsheetToCart,
  AddCutsheetToCartModalForm,
} from './add-cutsheet-to-cart';
import {
  SchedulingCustomCutsheetModal,
  SchedulingCustomCutsheetModalForm,
} from './custom-cutsheet-modal';
import { CutsheetRequest } from './edit-cutsheets';

interface CutsheetCatalogueProps {
  schedulingEdit: ViewSchedulingEditQuery;
  validCutsheets: Cutsheet[];
  userCutsheetRequests: CutsheetRequest[];
  extrasTrimOptions: ExtrasTrimOptionsType;
  userCutsheets: ViewSchedulingCreateQuery['cutsheetMany'];
  setUserCutsheets: (
    newUserCutsheets: ViewSchedulingCreateQuery['cutsheetMany'],
  ) => void;
}

export function CutsheetCatalogue({
  schedulingEdit,
  validCutsheets,
  userCutsheetRequests,
  extrasTrimOptions,
  userCutsheets,
  setUserCutsheets,
}: CutsheetCatalogueProps) {
  const { save, ask } = useModal();
  const { push } = useToastr();
  const user = useRecoilValue(userState);
  const { setFieldValue } = useFormikContext();

  const [createCutsheet, createCutsheetOp] = useCutsheetCreateOneMutation();
  const [deleteCutsheet, deleteCutsheetOp] = useCutsheetSoftDeleteMutation();

  return (
    <Card className="cutsheet-catalogue">
      <Card.Header className="d-flex">Cutsheet Catalogue</Card.Header>
      <Card.Body>
        <Row xs={1} sm={2} lg={4} xl={4} className="w-100 g-2">
          {map(validCutsheets, (cutsheet, idx) => {
            return (
              <Col key={idx}>
                <Card className="h-100 cutsheet-card">
                  <Card.Body>
                    <Stack className="h-100">
                      <Row className="mb-4">
                        <Col
                          xs={user?.role === EnumUserRole.Basic ? 12 : 9}
                          className="text-truncate fw-bold cutsheet-name"
                        >
                          {cutsheet.name}
                        </Col>
                        {user?.role === EnumUserRole.Vendor ||
                          (user?.role === EnumUserRole.Admin && (
                            <Col xs={3}>
                              <Button
                                icon={faTrash}
                                variant="danger"
                                size="sm"
                                disabled={deleteCutsheetOp.loading}
                                onClick={async () =>
                                  ask({
                                    type: 'ask',
                                    title: 'Delete Cutsheet',
                                    body: `Are you sure you want to delete ${cutsheet.name}?`,
                                    onConfirm: async () => {
                                      const response = await deleteCutsheet({
                                        variables: { cutsheetId: cutsheet._id },
                                      });
                                      setUserCutsheets(
                                        filter(
                                          userCutsheets,
                                          (userCutsheet) =>
                                            response.data?.cutsheetSoftDelete
                                              ?._id !== userCutsheet._id,
                                        ),
                                      );
                                      push({
                                        title: 'Success',
                                        body: `Cutsheet ${cutsheet.name} successfully deleted.`,
                                        bg: 'primary',
                                        delay: 5000,
                                      });
                                    },
                                  })
                                }
                              />
                            </Col>
                          ))}
                      </Row>
                      <Row className="mb-2">
                        <span>
                          {cutsheet.type === EnumCutsheetType.Vendor
                            ? `${formatToCurrency(
                                cutsheet.pricePerPound || 0,
                              )}/lb`
                            : 'Custom cutsheet'}
                        </span>
                        <span>
                          {map(
                            cutsheet.inspectionLevels,
                            (o) => inspectionLevelHelper(o ?? 'N/A').label,
                          ).join(', ')}
                        </span>
                        <span>
                          {map(
                            cutsheet.splitTypes,
                            (o) => animalSplitTypeHelper(o ?? 'N/A').label,
                          ).join(', ')}
                        </span>
                      </Row>
                      <Row className="mt-auto">
                        <Col>
                          <Stack
                            direction="horizontal"
                            className="justify-content-between"
                          >
                            <CutsheetTooltipButton
                              cutsheet={cutsheet}
                              content="Details"
                              size="sm"
                              variant="primary"
                            />
                            <Button
                              content="Add to cart"
                              className="btn-add"
                              size="sm"
                              onClick={() => {
                                save<AddCutsheetToCartModalForm>({
                                  type: 'save',
                                  title: 'Add to Cart',
                                  icon: faAdd,
                                  initialValues: { cutsheets: [] },
                                  body: (
                                    <AddCutsheetToCart
                                      cutsheetRequests={userCutsheetRequests}
                                      inspectionLevels={
                                        cutsheet.inspectionLevels as EnumCutsheetInspectionLevels[]
                                      }
                                    />
                                  ),
                                  onSubmit: ({ cutsheets }) => {
                                    cutsheets.forEach((value) => {
                                      setFieldValue(
                                        `cutsheetRequests.[${value}].cutsheet._id`,
                                        cutsheet._id,
                                      );
                                    });
                                  },
                                });
                              }}
                            />
                          </Stack>
                        </Col>
                      </Row>
                    </Stack>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
      <Card.Footer>
        <Stack direction="horizontal" className="align-items-center">
          <div>Not finding the cutsheet you want?</div>
          <Button
            className="ms-auto btn-create-new-cutsheet"
            content="Create New Cutsheet"
            isLoading={createCutsheetOp.loading}
            onClick={() =>
              save<SchedulingCustomCutsheetModalForm>({
                type: 'save',
                title: 'Custom Cutsheet',
                initialValues: {
                  name: '',
                  cuts: [],
                  extras: extrasTrimOptions.extras,
                  trim: extrasTrimOptions.trim,
                  blockedBy: [],
                },
                isLoading: createCutsheetOp.loading,
                icon: faPlus,
                fullscreen: true,
                body: (fP) => (
                  <SchedulingCustomCutsheetModal
                    fP={fP}
                    capabilities={
                      schedulingEdit.findProcessorSchedulingById
                        ?.processorCapabilities
                    }
                    animalSpecies={
                      schedulingEdit.findProcessorSchedulingById?.animalSpecies
                    }
                    settings={
                      schedulingEdit.findProcessorSchedulingById
                        ?.processorSettings ?? undefined
                    }
                  />
                ),
                onSubmit: async ({
                  name,
                  cuts,
                  groundMeatSize,
                  roastSize,
                  steakThickness,
                  steaksPerPack,
                  extras,
                  trim,
                  notes,
                }) => {
                  const animalSpecies =
                    schedulingEdit.findProcessorSchedulingById?.animalSpecies;

                  const formattedExtras = animalSpecies
                    ? (map(
                        filter(
                          extras?.[animalSpecies],
                          (extra) => extra.isActive,
                        ) || [],
                        partialRight(omit, ['__typename']),
                      ) as ExtrasModel[])
                    : [];

                  const getFormattedTrim = () => {
                    if (!animalSpecies) {
                      return [];
                    }

                    // if there is an isAllTrim option, only return that
                    const isAllTrim = filter(
                      trim?.[animalSpecies],
                      (t) => t.isAllTrim,
                    );
                    if (isAllTrim.length > 0) {
                      return map(
                        isAllTrim,
                        partialRight(omit, ['__typename', 'disabled']),
                      ) as TrimModel[];
                    }

                    return map(
                      filter(trim?.[animalSpecies], (trim) => trim.isActive) ||
                        [],
                      partialRight(omit, ['__typename', 'disabled']),
                    ) as TrimModel[];
                  };

                  const variables: CutsheetCreateOneMutationVariables = {
                    record: {
                      type: user
                        ? EnumCutsheetType.User
                        : EnumCutsheetType.Orphan,
                      animalSpecies: schedulingEdit.findProcessorSchedulingById
                        ?.animalSpecies as unknown as EnumCutsheetAnimalSpecies,
                      inspectionLevels: [],
                      primalCuts: map(cuts, (c) => c.value),
                      user: user?._id,
                      name,
                      extras: formattedExtras,
                      trim: getFormattedTrim(),
                      notes,
                    },
                  };
                  if (steaksPerPack) {
                    variables.record.steaksPerPack = parseInt(steaksPerPack);
                  }
                  if (steakThickness) {
                    variables.record.steakThickness =
                      parseFloat(steakThickness);
                  }
                  if (groundMeatSize) {
                    variables.record.groundMeatSize =
                      parseFloat(groundMeatSize);
                  }
                  if (roastSize) {
                    variables.record.roastSize = roastSize;
                  }
                  const response = await createCutsheet({
                    variables,
                  });
                  if (response.data?.cutsheetCreateOne?.record) {
                    setUserCutsheets(
                      concat(userCutsheets, [
                        response.data.cutsheetCreateOne.record as Cutsheet,
                      ]),
                    );
                  }
                },
                validate: (values) => {
                  const errors: Record<string, any> = {};
                  const animalSpecies =
                    schedulingEdit.findProcessorSchedulingById?.animalSpecies;
                  if (!animalSpecies) {
                    return errors;
                  }

                  // validate quantity for active extras
                  const extrasOptions = values.extras?.[animalSpecies] ?? [];

                  if (extrasOptions.length > 0) {
                    const animalType = animalSpecies;

                    for (let i = 0; i < extrasOptions.length; i++) {
                      if (
                        extrasOptions[i].isActive &&
                        (extrasOptions[i].quantity ?? 0) <
                          (extrasOptions[i].minLbs ?? 0)
                      ) {
                        const extraErrors = errors.extras?.[animalType] || [];
                        extraErrors[i] = {
                          ...(extraErrors[i] ?? {}),
                          quantity: `Min quantity is ${extrasOptions[i].minLbs} lbs`,
                        };

                        errors.extras = {
                          ...errors?.extras,
                          [animalType]: extraErrors,
                        };
                      }
                    }
                  }

                  // check if rank is unique for active trims
                  const trimOptions = animalSpecies
                    ? values.trim?.[animalSpecies] ?? []
                    : [];
                  if (trimOptions.length > 0) {
                    const animalType = animalSpecies;

                    for (let i = 0; i < trimOptions.length; i++) {
                      // validate quantity for active trims
                      if (
                        trimOptions[i].isActive &&
                        !trimOptions[i].isAllTrim &&
                        (trimOptions[i].quantity ?? 0) <
                          (trimOptions[i].minLbs ?? 0)
                      ) {
                        const trimErrors = errors.trim?.[animalType] || [];
                        trimErrors[i] = {
                          ...(trimErrors[i] ?? {}),
                          quantity: `Min quantity is ${trimOptions[i].minLbs} lbs`,
                        };

                        errors.trim = {
                          ...errors?.trim,
                          [animalType]: trimErrors,
                        };
                      }

                      // check if rank is unique for active trims
                      const ranks = map(
                        filter(trimOptions, (t) => t.isActive),
                        (t) => t.rank,
                      );
                      if (ranks.length !== new Set(ranks).size) {
                        errors.trim = {
                          ...errors?.trim,
                          [animalType]: [
                            ...map(
                              filter(trimOptions, (t) => t.isActive),
                              (t) => ({
                                rank: 'Rank must be unique',
                              }),
                            ),
                          ],
                        };
                      }
                    }
                  }

                  // check if rank is greater than 0 for active trims
                  if (trimOptions.length > 0 && animalSpecies) {
                    const animalType = animalSpecies;

                    for (let i = 0; i < trimOptions.length; i++) {
                      if (trimOptions[i].isActive && trimOptions[i].rank < 1) {
                        const trimErrors = errors.trim?.[animalType] || [];

                        trimErrors[i] = {
                          ...(trimErrors[i] ?? {}),
                          rank: 'Rank must be greater than 0',
                        };
                        errors.trim = {
                          ...errors?.trim,
                          [animalType]: trimErrors,
                        };
                      }
                    }
                  }

                  return errors;
                },
              })
            }
          />
        </Stack>
      </Card.Footer>
    </Card>
  );
}
