import { Card } from 'react-bootstrap';

import ChatContainer from 'components/chat/ChatContainer';

export default function ChatPage() {
  return (
    <div
      style={
        {
          '--chat-container-height': 'calc(100vh - 256px)',
          marginInline: 'calc(var(--bs-gutter-x) * -0.5)',
        } as React.CSSProperties
      }
    >
      <ChatContainer pageType="processor" />
    </div>
  );
}
