import {
  Button,
  SimplePaginationTable,
  TableEditorNumber,
  useModal,
  useToastr,
  type RowUpdateHandler,
} from '@farmshare/ui-components';
import { animalSplitTypeHelper } from '@farmshare/utils';
import {
  faFileArrowDown,
  faPen,
  faPlus,
  faSave,
  faTrash,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { Card, Stack } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { downloadFromBase64Encoded } from 'lib/downloadFromBase64Encoded';
import { downloadFromPresignedUrl } from 'lib/downloadFromPresignedUrl';
import {
  ProcessingJobByIdDocument,
  ProcessingJobByIdQuery,
  ProcessorSchedulingAnimalHeads,
  useProcessorSchedulingAnimalHeadBulkUpdateMutation,
  useProcessorSchedulingAnimalHeadDeleteMutation,
  useProcessorSchedulingGenerateCarcassTagsMutation,
} from 'lib/graphql';
import { processingJobAnimalHeadLabel } from 'lib/processingJobUtils';

import { TableEditorSides } from './table-editor-sides';

const AnimalHeadButtons = ({
  animalHeadId,
  onDelete,
}: {
  animalHeadId: string;
  onDelete: (animalHeadId: string) => void;
}) => {
  const handleDelete = useCallback(() => {
    return onDelete(animalHeadId);
  }, [animalHeadId, onDelete]);

  return (
    <Stack gap={1} direction="horizontal" className="d-flex">
      <Button
        className="ms-auto"
        content="Delete"
        variant="danger"
        onClick={handleDelete}
        icon={faTrash}
        size="sm"
      />
    </Stack>
  );
};

export function AnimalHeadsList({
  processingJob,
  isLoading,
}: {
  processingJob?: ProcessingJobByIdQuery['findProcessorSchedulingById'];
  isLoading: boolean;
}) {
  const navigate = useNavigate();
  const { ask } = useModal();
  const { push } = useToastr();

  const [deleteAnimalHead] = useProcessorSchedulingAnimalHeadDeleteMutation({
    refetchQueries: [
      {
        query: ProcessingJobByIdDocument,
        variables: {
          jobId: processingJob?._id,
          includeNotificationHistory: true,
          includeProcessorSettings: true,
        },
      },
    ],
  });

  const [bulkSaveAnimalHeads] =
    useProcessorSchedulingAnimalHeadBulkUpdateMutation({
      refetchQueries: [
        {
          query: ProcessingJobByIdDocument,
          variables: {
            jobId: processingJob?._id,
            includeNotificationHistory: true,
            includeProcessorSettings: true,
          },
        },
      ],
      awaitRefetchQueries: true,
    });

  const [generateCarcassTags, generateCarcassTagsOp] =
    useProcessorSchedulingGenerateCarcassTagsMutation();

  const handleAddAnimalHead = () => {
    navigate(`/processing-job/${processingJob?._id}/head/add`);
  };

  const handleAnimalHeadDelete = useCallback(
    (animalHeadId: string) => {
      return ask({
        type: 'ask',
        title: 'Confirm Deletion',
        body: 'Are you sure you want to delete this animal head? Once you delete it you will not be able to restore it.',
        yesText: 'Delete',
        yesIcon: faTrash,
        onConfirm: async () => {
          await deleteAnimalHead({
            variables: {
              processorSchedulingId: processingJob?._id,
              animalHeadId,
            },
          });

          push({
            title: 'Processing Job Updated',
            body: `The animal head has been deleted.`,
            bg: 'success',
            delay: 4000,
          });
        },
      });
    },
    [ask, processingJob?._id, deleteAnimalHead, push],
  );

  const handleClickCreateCarcassTags = useCallback(async () => {
    try {
      const response = await generateCarcassTags({
        variables: { processorSchedulingId: processingJob?._id },
      });

      if (response.data?.processorSchedulingGenerateCarcassTags?.fileUrl) {
        downloadFromPresignedUrl(
          response.data?.processorSchedulingGenerateCarcassTags?.fileUrl,
        );
      } else if (response.data?.processorSchedulingGenerateCarcassTags?.file) {
        downloadFromBase64Encoded(
          response.data.processorSchedulingGenerateCarcassTags.file,
          response.data.processorSchedulingGenerateCarcassTags?.filename ??
            'Carcass_Tags.pdf',
        );
      }
    } catch (error) {
      push({
        title: 'Error',
        body: (error as string).toString(),
        bg: 'danger',
        delay: 4000,
      });
    }
  }, [processingJob?._id, generateCarcassTags, push]);

  // Bulk edit section
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const updatedRows = useRef<
    Record<string, Partial<ProcessorSchedulingAnimalHeads>>
  >({});

  const handleRowEdit: RowUpdateHandler<ProcessorSchedulingAnimalHeads> =
    useCallback(
      (row, column, newValue) => {
        const updatedRow = {
          ...updatedRows.current[row._id],
          _id: row._id,
          [column]: Number(newValue),
        };

        updatedRows.current = {
          ...updatedRows.current,
          [row._id]: updatedRow,
        };
      },
      [updatedRows],
    );

  const saveAnimalHeads = async () => {
    await bulkSaveAnimalHeads({
      variables: {
        processorSchedulingId: processingJob?._id,
        updatedAnimalHeads: Object.values(updatedRows.current),
      },
    });

    // Clear changes to awoid resaving
    updatedRows.current = {};

    push({
      title: 'Processing Job Updated',
      body: `The animal heads has been updated.`,
      bg: 'success',
      delay: 4000,
    });

    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const discardAnimalHeads = () => {
    if (isEmpty(updatedRows.current)) {
      setIsEditModeEnabled(false);
      updatedRows.current = {};
    } else {
      ask({
        type: 'ask',
        title: 'Discard changes',
        body: 'Are you sure you want to discard all the changes?',
        yesText: 'Discard',
        yesIcon: faUndo,
        onConfirm: () => {
          setIsEditModeEnabled(false);

          updatedRows.current = {};
        },
      });
    }
  };

  return (
    <Card className="animal-heads-list">
      <Card.Header className="d-flex align-items-center">
        <span className="fw-bold fs-5">Animal Heads</span>
        <Stack direction="horizontal" className="ms-auto" gap={3}>
          <Button
            size="sm"
            disabled={generateCarcassTagsOp.loading}
            isLoading={generateCarcassTagsOp.loading}
            content="Create Carcass Tags"
            onClick={handleClickCreateCarcassTags}
            icon={faFileArrowDown}
          />
          <Button
            className="ms-auto btn-add-animal-head"
            content="Add Animal Head"
            onClick={handleAddAnimalHead}
            icon={faPlus}
            size="sm"
          />
          {isEditModeEnabled ? (
            <>
              <Button
                className="ms-auto"
                content="Save"
                onClick={saveAnimalHeads}
                icon={faSave}
                size="sm"
              />
              <Button
                variant="danger"
                className="ms-auto"
                content="Discard"
                onClick={discardAnimalHeads}
                icon={faUndo}
                size="sm"
              />
            </>
          ) : (
            <Button
              className="ms-auto"
              content="Edit"
              onClick={() => setIsEditModeEnabled(true)}
              icon={faPen}
              size="sm"
            />
          )}
        </Stack>
      </Card.Header>
      <Card.Body>
        <SimplePaginationTable<ProcessorSchedulingAnimalHeads>
          hideButtons
          enableSearchParams={false}
          isEditModeEnabled={isEditModeEnabled}
          onRowEdit={handleRowEdit}
          columns={[
            {
              label: 'Label',
              field: 'producerIdentifier',
              formatter: (row, rowIdx) => {
                const label = processingJobAnimalHeadLabel({
                  producerIdentifier: row.producerIdentifier,
                  requestedBy: {
                    first_name: processingJob?.requestedBy?.first_name,
                    last_name: processingJob?.requestedBy?.last_name,
                  },
                  animalNumber: rowIdx,
                  animalSpecies: processingJob?.animalSpecies,
                  inspectionLevel: row.inspectionLevel ?? undefined,
                });

                return (
                  <Link
                    to={`/processing-job/${processingJob?._id}/head/${row._id}`}
                  >
                    {label}
                  </Link>
                );
              },
            },
            {
              label: 'Live Weight (lbs)',
              field: 'liveWeight',
              formatter: (row) => {
                return row.liveWeight ? row.liveWeight : '-';
              },
            },
            {
              label: 'Split Type',
              field: 'splitType',
              formatter: (row) => {
                return row.splitType
                  ? animalSplitTypeHelper(row.splitType).label
                  : '-';
              },
            },
            {
              label: 'Hanging Weight (lbs)',
              field: 'hangingWeight',
              inlineEditor: (props) => {
                if (props.row.original.isHangingWeightPerSide) {
                  return TableEditorSides(props);
                }

                return TableEditorNumber(props);
              },
              formatter: (row) => {
                if (row.isHangingWeightPerSide) {
                  const sideAPart = row.hangingWeightSideA
                    ? row.hangingWeightSideA
                    : '-';
                  const sideBPart = row.hangingWeightSideB
                    ? row.hangingWeightSideB
                    : '-';

                  return `${sideAPart}/${sideBPart}`;
                }
                return row.hangingWeight ? row.hangingWeight : '-';
              },
            },
            {
              formatter: (row) => (
                <AnimalHeadButtons
                  animalHeadId={row._id}
                  onDelete={handleAnimalHeadDelete}
                />
              ),
            },
          ]}
          data={
            // This is super annoying because the typeing that is generated says there could be nulls in this array. Which is really stupid.
            (processingJob?.animalHeads as ProcessorSchedulingAnimalHeads[]) ??
            []
          }
          isLoading={isLoading}
        />
      </Card.Body>
    </Card>
  );
}
