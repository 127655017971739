import { Table } from '@farmshare/ui-components';
import { formatToCurrency, isArrayNullOrEmpty } from '@farmshare/utils';
import {
  faCheckCircle,
  faCircleCheck,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FormikProps } from 'formik';
import { concat, map, partition, sortBy, startCase, take } from 'lodash';
import { Card } from 'react-bootstrap';

import type { ShipmentRate } from 'lib/graphql';

export interface RatesTablesProps<TForm> {
  formikProps: FormikProps<TForm>;
  rates?: ShipmentRate[];
}

export function RatesTables<TForm extends { chosenRate?: string }>({
  formikProps,
  rates,
}: RatesTablesProps<TForm>) {
  const [goodRates, issueRates] = partition(
    rates,
    (r) =>
      isArrayNullOrEmpty(r.warning_messages) &&
      isArrayNullOrEmpty(r.error_messages),
  );

  return (
    <div className="d-flex flex-column gap-3">
      {!isArrayNullOrEmpty(goodRates) && (
        <Card>
          <Card.Header>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-primary me-2"
            />
            Valid Rates
          </Card.Header>
          <Card.Body>
            <Table<ShipmentRate>
              onSelect={(chosenRate) =>
                formikProps.setFieldValue('chosenRate', chosenRate.external_id)
              }
              rows={take(
                sortBy<ShipmentRate>(goodRates, (r) => r?.total_amount),
                12,
              )}
              columns={[
                {
                  label: '',
                  formatter(row) {
                    return (
                      <div style={{ minWidth: '22px' }}>
                        {row.external_id === formikProps.values.chosenRate && (
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className={'text-primary'}
                          />
                        )}
                      </div>
                    );
                  },
                },
                {
                  label: 'Service',
                  field: 'service_type',
                  formatter(row) {
                    return <span className="small">{row.service_type}</span>;
                  },
                },
                {
                  label: 'Type',
                  field: 'package_type',
                  formatter(row) {
                    return (
                      <span className="small">
                        {startCase(row.package_type)}
                      </span>
                    );
                  },
                },
                { label: 'Days', field: 'delivery_days' },
                {
                  label: 'Price',
                  field: 'total_amount',
                  formatter(row) {
                    return formatToCurrency(row.total_amount);
                  },
                },
              ]}
            />
          </Card.Body>
        </Card>
      )}
      {!isArrayNullOrEmpty(issueRates) && (
        <Card>
          <Card.Header className="d-flex gap-2">
            <div>
              <FontAwesomeIcon icon={faInfoCircle} className="text-warning" />
            </div>
            <div>
              Rates with Warnings
              <div className="small text-muted">
                These rates are still usable. Please read the warning messages
                before using them.
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            <Table<ShipmentRate>
              onSelect={(chosenRate) =>
                formikProps.setFieldValue('chosenRate', chosenRate.external_id)
              }
              rowAlignment="top"
              rows={take(
                sortBy<ShipmentRate>(issueRates, (r) => r?.total_amount),
                60,
              )}
              columns={[
                {
                  label: '',
                  formatter(row) {
                    return (
                      <div style={{ minWidth: '22px' }}>
                        {row.external_id === formikProps.values.chosenRate && (
                          <FontAwesomeIcon
                            icon={faCircleCheck}
                            className={'text-primary'}
                          />
                        )}
                      </div>
                    );
                  },
                },
                {
                  label: 'Service',
                  field: 'service_type',
                  formatter(row) {
                    return <span className="small">{row.service_type}</span>;
                  },
                },
                {
                  label: 'Type',
                  field: 'package_type',
                  formatter(row) {
                    return (
                      <span className="small">
                        {startCase(row.package_type)}
                      </span>
                    );
                  },
                },
                {
                  label: 'Days',
                  field: 'delivery_days',
                  formatter: (row) => row.delivery_days ?? '-',
                },
                {
                  label: 'Issue(s)',
                  formatter: (row) => {
                    const arr = concat(
                      row.warning_messages,
                      row.error_messages,
                    );
                    return (
                      <ul className="ps-2 mb-0">
                        {map(arr, (a, idx) => (
                          <li key={idx} className="small">
                            {a}
                          </li>
                        ))}
                      </ul>
                    );
                  },
                },
                {
                  label: 'Price',
                  field: 'total_amount',
                  formatter(row) {
                    return formatToCurrency(row.total_amount);
                  },
                },
              ]}
            />
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
