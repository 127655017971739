import { useModal, useToastr, Loading } from '@farmshare/ui-components';
import {
  faTrash,
  faEdit,
  faClone,
  faPlus,
  faEllipsis,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, filter, map, omit, partialRight } from 'lodash';
import { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';

import {
  useCutsheetSoftDeleteMutation,
  useViewProcessorAdminQuery,
  usePrimalCutBlockerManyQuery,
  type Cutsheet,
  useCutsheetUpsertMutation,
  CutsheetUpsertMutationVariables,
  EnumCutsheetType,
} from 'lib/graphql';

import {
  CutsheetModal,
  ExtrasModel,
  ExtrasTrimOptionsType,
  NewCutsheetForm,
  TrimModel,
} from './cutsheet-modal';
import { EditCutsheetModal, EditCutsheetForm } from './edit-cutsheet-modal';

interface CutsheetsButtonsProps {
  cutsheet: Cutsheet;
  extrasTrimOptions: ExtrasTrimOptionsType;
  refetch: () => Promise<unknown>;
}

export function CutsheetButtons({
  cutsheet,
  extrasTrimOptions,
  refetch,
}: CutsheetsButtonsProps) {
  const [deleteCutsheet, deleteCutsheetOp] = useCutsheetSoftDeleteMutation();
  const [upsertCutSheet, upsertCutSheetOp] = useCutsheetUpsertMutation();
  const settings = useViewProcessorAdminQuery();
  const { data, loading } = usePrimalCutBlockerManyQuery({
    variables: {
      filter: {
        vendor: settings.data?.processorSettingsOne?.vendor?._id,
      },
    },
  });

  const { ask, save } = useModal();
  const { push } = useToastr();

  // merges the extras and trim options from the cutsheet with the ones from the extrasTrimOptions props
  const duplicateExtrasTrimOptions = useMemo(() => {
    const duplicateExtras = cloneDeep(extrasTrimOptions.extras);
    const duplicateTrim = cloneDeep(extrasTrimOptions.trim);

    if (cutsheet.extras && cutsheet.extras.length > 0) {
      duplicateExtras[cutsheet.animalSpecies] = [
        ...filter(
          [...extrasTrimOptions.extras[cutsheet.animalSpecies]],
          (activeExtra) =>
            !cutsheet.extras?.find(
              (cutsheetExtra) => cutsheetExtra?.name === activeExtra.name,
            ),
        ),
        ...cutsheet.extras,
      ] as ExtrasModel[];
    }
    if (cutsheet.trim && cutsheet.trim.length > 0) {
      duplicateTrim[cutsheet.animalSpecies] = [
        ...filter(
          [...extrasTrimOptions.trim[cutsheet.animalSpecies]],
          (activeTrim) =>
            !cutsheet.trim?.find(
              (cutsheetTrim) => cutsheetTrim?.name === activeTrim.name,
            ),
        ),
        ...cutsheet.trim,
      ] as TrimModel[];
    }

    return { extras: duplicateExtras, trim: duplicateTrim };
  }, [extrasTrimOptions, cutsheet]);

  // disable cuts blocked by other cuts, defined in the processor capabilities tab
  const blockedBy = useMemo(() => {
    let mappedBlockedBy: {
      name: string;
      primalCutId: string;
      toggledBy: string[];
    }[] = [];

    if (data?.primalCutBlockerMany && data.primalCutBlockerMany.length > 0) {
      // for each active cut add the blockedBy cuts to the mappedBlockedBy array, that will be used to disable the cuts blocked by the active cuts
      for (const activeCut of cutsheet.primalCuts) {
        const cutBlocker = data.primalCutBlockerMany.find(
          (cutBlocker) => cutBlocker.primalCutId === activeCut._id,
        );

        if (cutBlocker?.blockedBy && cutBlocker.blockedBy.length > 0) {
          const isCutAlreadyAdded = mappedBlockedBy.find(
            (item) => item.primalCutId === cutBlocker.primalCutId,
          );
          if (!isCutAlreadyAdded) {
            mappedBlockedBy = [
              ...mappedBlockedBy,
              ...(cutBlocker.blockedBy.map((blockedBy) => ({
                name: blockedBy?.name,
                primalCutId: blockedBy?.primalCutId,
                toggledBy: [cutBlocker.primalCutId],
              })) as {
                name: string;
                primalCutId: string;
                toggledBy: string[];
              }[]),
            ];
          }
        }
      }
    }

    return mappedBlockedBy;
  }, [cutsheet.primalCuts, data?.primalCutBlockerMany]);

  const cuts = useMemo(() => {
    let primalCuts = cutsheet.primalCuts;

    // filter out the cuts that are blocked by other cuts
    if (blockedBy.length > 0) {
      primalCuts = primalCuts.filter(
        (cut) =>
          !blockedBy.find(
            (blockedByItem) => blockedByItem.primalCutId === cut._id,
          ),
      );
    }

    return primalCuts.map((c) => ({
      label: c.name,
      value: c._id,
    }));
  }, [blockedBy, cutsheet.primalCuts]);

  if (cutsheet.isDeleted) {
    return null;
  }
  if (loading) {
    return <Loading />;
  }

  const duplicateCutSheet = async () => {
    return save<NewCutsheetForm>({
      type: 'save',
      title: 'Add a Cutsheet',
      icon: faPlus,
      size: 'xl',
      fullscreen: true,
      initialValues: {
        animalSpecies: cutsheet.animalSpecies,
        inspectionLevel: cutsheet.inspectionLevels?.[0] ?? undefined,
        name: `${cutsheet.name} - COPY`,
        pricePerPound: cutsheet.pricePerPound ?? 0,
        cuts,
        splitTypes: cutsheet.splitTypes!.map((st) => ({
          label: st as string,
          value: st as string,
        })),
        selectedSpecifications: cutsheet.selectedSpecifications ?? {},
        extras: duplicateExtrasTrimOptions.extras,
        trim: duplicateExtrasTrimOptions.trim,
        blockedBy,
      },
      body: (formik) => <CutsheetModal fP={formik} settings={settings?.data} />,

      validate: async (values) => {
        const errors: Record<string, string> = {};

        if (!values.name) {
          errors.name = 'Name is required';
        }
        if (values.splitTypes?.length === 0) {
          errors.splitTypes = 'At least one Split type is required';
        }

        return errors;
      },
      onSubmit: async ({
        animalSpecies,
        inspectionLevel,
        cuts,
        splitTypes,
        name,
        selectedSpecifications,
        pricePerPound = 0,
        extras,
        trim,
      }) => {
        const formattedExtras = inspectionLevel
          ? (map(
              filter(extras?.[animalSpecies], (extra) => extra.isActive) || [],
              partialRight(omit, ['__typename']),
            ) as ExtrasModel[])
          : [];

        const getFormattedTrim = () => {
          if (!inspectionLevel) {
            return [];
          }

          // if there is an isAllTrim option, only return that
          const isAllTrim = filter(trim?.[animalSpecies], (t) => t.isAllTrim);
          if (isAllTrim.length > 0) {
            return map(
              isAllTrim,
              partialRight(omit, ['__typename', 'disabled']),
            ) as TrimModel[];
          }

          return map(
            filter(trim?.[animalSpecies], (trim) => trim.isActive) || [],
            partialRight(omit, ['__typename', 'disabled']),
          ) as TrimModel[];
        };

        const variables: CutsheetUpsertMutationVariables = {
          primalCuts: map(cuts, (c) => c.value),
          splitTypes: map(splitTypes, (st) => st.value),
          type: EnumCutsheetType.Vendor,
          animalSpecies,
          inspectionLevels:
            inspectionLevel && !inspectionLevel.includes('Select one...')
              ? [inspectionLevel]
              : [],
          name,
          pricePerPound,
          selectedSpecifications,
          extras: formattedExtras,
          trim: getFormattedTrim(),
        };

        await upsertCutSheet({ variables });
        push({
          title: 'Save Successful',
          bg: 'primary',
          body: 'Cutsheet successfully saved.',
          delay: 4000,
        });
        refetch();
      },
    });
  };

  const handleCutSheetEdit = async () => {
    return save<EditCutsheetForm>({
      type: 'save',
      title: 'Edit Cutsheet',
      icon: faEdit,
      initialValues: {
        name: cutsheet.name,
        splitTypes: cutsheet.splitTypes!.map((st) => ({
          label: st as string,
          value: st as string,
        })),
      },
      body: (formik) => <EditCutsheetModal formik={formik} />,

      validate: async (values) => {
        const errors: Record<string, string> = {};

        if (!values.name) {
          errors.name = 'Name is required';
        }
        if (values.splitTypes?.length === 0) {
          errors.splitTypes = 'At least one Split type is required';
        }

        return errors;
      },
      onSubmit: async (values) => {
        await upsertCutSheet({
          variables: {
            cutsheetId: cutsheet._id,
            name: values.name,
            splitTypes: values.splitTypes?.map((st) => st.value),
          },
        });

        push({
          title: 'Success',
          body: `Cutsheet ${values.name} successfully updated.`,
          bg: 'primary',
          delay: 4000,
        });

        await refetch();
      },
    });
  };

  return (
    <Dropdown align="end" className="my-auto">
      <Dropdown.Toggle
        icon={faEllipsis}
        variant="primary"
        size="lg"
        as={FontAwesomeIcon}
        disabled={deleteCutsheetOp.loading || upsertCutSheetOp.loading}
        className="bg-primary text-white border border-primary rounded px-2"
        style={{ cursor: 'pointer', marginTop: '1px' }}
      >
        <span className="d-none d-md-inline">Actions</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={duplicateCutSheet}
          className="w-100 fw-bold me-2"
        >
          <FontAwesomeIcon icon={faClone} style={{ minWidth: '25px' }} />
          Duplicate
        </Dropdown.Item>
        <Dropdown.Item
          onClick={handleCutSheetEdit}
          className="w-100 fw-bold me-2"
        >
          <FontAwesomeIcon icon={faEdit} style={{ minWidth: '25px' }} />
          Edit
        </Dropdown.Item>
        <Dropdown.Item
          onClick={async () =>
            ask({
              type: 'ask',
              title: 'Confirm Deletion',
              body: `Are you sure you want to delete ${cutsheet.name}?`,
              onConfirm: async () => {
                await deleteCutsheet({
                  variables: { cutsheetId: cutsheet._id },
                });
                push({
                  title: 'Success',
                  body: `Cutsheet ${cutsheet.name} successfully deleted.`,
                  bg: 'primary',
                  delay: 5000,
                });

                await refetch();
              },
            })
          }
          className="w-100 fw-bold"
        >
          <FontAwesomeIcon icon={faTrash} style={{ minWidth: '25px' }} />
          Delete
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
