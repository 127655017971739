import { Button, useModal, useToastr } from '@farmshare/ui-components';
import { faGear } from '@fortawesome/free-solid-svg-icons';

import {
  useOfsSendgridInitMutation,
  useOfsStripeInitMutation,
  useOfsWooInitMutation,
  type Ofs,
} from 'lib/graphql';

import {
  OFSInitSendgridForm,
  type OFSInitSendgridFormForm,
} from './ofs-init-sendgrid-form';
import {
  OFSInitStripeForm,
  type OFSInitStripeFormForm,
} from './ofs-init-stripe-form';
import { OFSInitWooForm, type OFSInitWooFormForm } from './ofs-init-woo-form';

export interface OFSButtonsProps {
  ofs: Pick<
    Ofs,
    | '_id'
    | 'vendor'
    | 'url'
    | 'status'
    | 'sendgridComplete'
    | 'stripeComplete'
    | 'wooComplete'
    | 'ipV4Address'
  >;
  refetch: () => Promise<unknown>;
}

export function OFSButtons({ ofs, refetch }: OFSButtonsProps) {
  const { save } = useModal();
  const { push } = useToastr();

  const [initWoo, initWooOp] = useOfsWooInitMutation();
  const [initSendgrid, initSendgridOp] = useOfsSendgridInitMutation();
  const [initStripe, initStripeOp] = useOfsStripeInitMutation();

  let button: JSX.Element | null = null;

  switch (ofs.status) {
    case 'initialized':
      button = (
        <Button
          content="Setup Woo"
          icon={faGear}
          size="sm"
          isLoading={initWooOp.loading}
          onClick={() =>
            save<OFSInitWooFormForm>({
              type: 'save',
              title: 'Setup Woo',
              icon: faGear,
              initialValues: {},
              body: <OFSInitWooForm />,
              isLoading: initWooOp.loading,
              validate: ({ wooApiKey, wooApiSecret }) => {
                const errors: Partial<
                  Record<keyof OFSInitWooFormForm, string>
                > = {};
                if (wooApiKey) {
                  if (!/^ck_\w?/.test(wooApiKey)) {
                    errors.wooApiKey = 'Woo API Key should start with "ck_".';
                  } else if (!/^ck_\w{40}$/.test(wooApiKey)) {
                    errors.wooApiKey =
                      'Woo API Key should be 43 characters long.';
                  }
                } else {
                  errors.wooApiKey = 'Woo API Key is required';
                }
                if (wooApiSecret) {
                  if (!/^cs_\w?/.test(wooApiSecret)) {
                    errors.wooApiSecret =
                      'Woo API Secret should start with "cs_".';
                  } else if (!/^cs_\w{40}$/.test(wooApiSecret)) {
                    errors.wooApiSecret =
                      'Woo API Secret should be 43 characters long.';
                  }
                } else {
                  errors.wooApiSecret = 'Woo API Secret is required';
                }
                return errors;
              },
              onSubmit: async ({ wooApiKey, wooApiSecret }) => {
                if (wooApiKey && wooApiSecret) {
                  await initWoo({
                    variables: {
                      initOFSWoo: { ofsId: ofs._id, wooApiKey, wooApiSecret },
                    },
                  });
                  await refetch();
                  push({
                    title: 'Success',
                    body: 'Woo setup has been completed!',
                    bg: 'success',
                    delay: 5000,
                  });
                }
              },
            })
          }
        />
      );
      break;

    case 'woo_initialized':
      button = (
        <Button
          content="Setup Sendgrid"
          icon={faGear}
          size="sm"
          isLoading={initSendgridOp.loading}
          onClick={() =>
            save<OFSInitSendgridFormForm>({
              type: 'save',
              title: 'Setup Sendgrid',
              icon: faGear,
              initialValues: { email: ofs.vendor.address.email },
              body: <OFSInitSendgridForm />,
              isLoading: initSendgridOp.loading,
              validate: ({ type, email, domain }) => {
                const errors: Record<string, string> = {};
                if (!type) {
                  errors.type = 'Type is required';
                } else if (type === 'email' && !email) {
                  errors.email = 'Email is required';
                } else if (type === 'domain') {
                  if (!domain) {
                    errors.domain = 'Domain is required';
                  } else if (
                    !/^[a-z0-9]+\.\w{2,4}/.test(domain.toLowerCase())
                  ) {
                    errors.domain =
                      'Domain should start with "http://" or "https://"';
                  }
                }
                return errors;
              },
              onSubmit: async ({ type, email, domain }) => {
                if (type) {
                  await initSendgrid({
                    variables: {
                      initOFSSendgrid: { ofsId: ofs._id, type, email, domain },
                    },
                  });
                  await refetch();
                  push({
                    title: 'Success',
                    body: 'Sendgrid setup has been completed!',
                    bg: 'success',
                    delay: 5000,
                  });
                }
              },
            })
          }
        />
      );
      break;

    case 'sendgrid_initialized':
      button = (
        <Button
          content="Setup Stripe"
          icon={faGear}
          size="sm"
          isLoading={initStripeOp.loading}
          onClick={() =>
            save<OFSInitStripeFormForm>({
              type: 'save',
              title: 'Setup Stripe',
              icon: faGear,
              initialValues: {
                ofsUrl: ofs.url ?? '',
                testStripeApiKey: '',
                testStripeApiSecret: '',
                prodStripeApiKey: '',
                prodStripeApiSecret: '',
              },
              body: <OFSInitStripeForm />,
              isLoading: initStripeOp.loading,
              validate: ({
                testStripeApiKey,
                testStripeApiSecret,
                prodStripeApiKey,
                prodStripeApiSecret,
              }) => {
                const errors: Record<string, string> = {};
                if (!testStripeApiKey || !/^pk_test_/.test(testStripeApiKey)) {
                  errors.testStripeApiKey =
                    'Test Stripe API Key should start with "pk_test_"';
                }
                if (
                  !testStripeApiSecret ||
                  !/^[sr]k_test_/.test(testStripeApiSecret)
                ) {
                  errors.testStripeApiSecret =
                    'Test Stripe API Secret should start with "sk_test_" or "rk_test_"';
                }
                if (!prodStripeApiKey || !/^pk_live_/.test(prodStripeApiKey)) {
                  errors.prodStripeApiKey =
                    'Prod Stripe API Key should start with "pk_live_"';
                }
                if (
                  !prodStripeApiSecret ||
                  !/^[sr]k_live_/.test(prodStripeApiSecret)
                ) {
                  errors.prodStripeApiSecret =
                    'Prod Stripe API Secret should start with "sk_live_" or "rk_live_"';
                }
                return errors;
              },
              onSubmit: async (values) => {
                await initStripe({
                  variables: { initOFSStripe: { ofsId: ofs._id, ...values } },
                });
                await refetch();
                push({
                  title: 'Success',
                  body: 'Stripe setup has been completed!',
                  bg: 'success',
                  delay: 5000,
                });
              },
            })
          }
        />
      );
      break;
  }

  return <div className="d-flex justify-content-end">{button}</div>;
}
