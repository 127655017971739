import {
  Button,
  DataDetailList,
  Form,
  InputCheck,
  InputPhone,
  InputSelect,
  InputSelectOption,
  InputText,
  Loading,
  PageTitle,
  PhoneDisplay,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import {
  animalSpeciesHelper,
  AnimalSplitPart,
  animalSplitPartHelper,
  AnimalSplitType,
  animalSplitTypeHelper,
  formatFullName,
} from '@farmshare/utils';
import {
  faCheck,
  faFileEdit,
  faUser,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormikContext } from 'formik';
import { find, forEach, get, isNil, map, set, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import {
  Card,
  Col,
  Container,
  Row,
  type RowProps,
  Stack,
  Form as BSForm,
  Button as BSButton,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { User } from 'lib/graphql';

import { SchedulingCreateForm } from 'pages/scheduling/_views/create-scheduling-form';

import { AnimalHeadsCardHeader } from '../../../components/animal-heads/animal-heads-card-header';
import { useProcessingJobPage } from '../useProcessingJobPage';

function fillNewContacts(
  splitType: AnimalSplitType,
  currentContacts: ContactInfo[],
) {
  // This is the goofy logic to resolve the new contacts based upon new splitType and current contacts
  switch (splitType) {
    case 'whole': {
      const firstContact = currentContacts?.[0] ?? {};
      return [{ ...firstContact, splitPart: 'whole', label: 'Whole' }];
    }

    case 'half': {
      const firstContact = currentContacts?.[0] ?? {};
      const secondContact = currentContacts?.[1] ?? firstContact;
      return [
        { ...firstContact, splitPart: 'half', label: 'Half #1' },
        { ...secondContact, splitPart: 'half', label: 'Half #2' },
      ];
    }

    case 'quarters': {
      const firstContact = currentContacts?.[0] ?? {};
      const secondContact = currentContacts?.[1] ?? firstContact;
      const thirdContact = currentContacts?.[2] ?? firstContact;
      const fourthContact = currentContacts?.[3] ?? firstContact;
      return [
        { ...firstContact, splitPart: 'quarter' },
        { ...secondContact, splitPart: 'quarter' },
        { ...thirdContact, splitPart: 'quarter' },
        { ...fourthContact, splitPart: 'quarter' },
      ];
    }

    case 'half_and_two_quarters': {
      const firstContact = currentContacts?.[0] ?? {};
      const secondContact = currentContacts?.[1] ?? firstContact;
      const thirdContact = currentContacts?.[2] ?? firstContact;
      return [
        { ...firstContact, splitPart: 'half' },
        { ...secondContact, splitPart: 'quarter' },
        { ...thirdContact, splitPart: 'quarter' },
      ];
    }

    default:
      return [];
  }
}

interface EditAnimalHeadSplitTypeForm {
  splitType?: AnimalSplitType | string | 'Select one...' | undefined;
  contacts: ContactInfo[];
  requestedBy: User | undefined;
}

export interface ContactInfo {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  isRequestedByUser?: boolean;
  splitPart?: AnimalSplitPart;
}

type EditContactForm = Pick<
  ContactInfo,
  'firstName' | 'lastName' | 'phone' | 'email' | 'isRequestedByUser'
>;

const ContactInformationForm = ({
  currentUserInfo,
}: {
  currentUserInfo: Pick<
    EditContactForm,
    'firstName' | 'lastName' | 'phone' | 'email'
  >;
}) => {
  const { setValues, touched, values } = useFormikContext<ContactInfo>();

  useEffect(() => {
    if (values.isRequestedByUser) {
      setValues({
        ...values,
        firstName: currentUserInfo.firstName,
        lastName: currentUserInfo.lastName,
        phone: currentUserInfo.phone,
        email: currentUserInfo.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.isRequestedByUser, setValues, currentUserInfo]);

  return (
    <Stack gap={3}>
      <Stack direction="horizontal" gap={3}>
        <InputCheck
          type="switch"
          label="The scheduling customer is the contact for this animal split."
          nameOveride="isRequestedByUser"
          inline
        />
      </Stack>
      <Row className="mb-2">
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="First Name"
            floatingLabel
            required
            nameOveride="firstName"
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="Last Name"
            floatingLabel
            required
            nameOveride="lastName"
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputPhone
            label="Phone"
            floatingLabel
            nameOveride="phone"
            required={touched.phone && (!values.email || !!values.phone)}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="email"
            label="Email"
            floatingLabel
            nameOveride="email"
            required={touched.email && (!values.phone || !!values.email)}
          />
        </Col>
      </Row>
    </Stack>
  );
};

const ContactInformationCard = ({
  contactIdx,
  currentUserInfo,
  splitPart,
}: {
  contactIdx: number;
  splitPart: AnimalSplitPart;
  currentUserInfo: {
    firstName: string;
    lastName?: string;
    email?: string;
    phone?: string;
  };
}) => {
  const { save } = useModal();
  const { values, setFieldValue, errors, submitCount } =
    useFormikContext<SchedulingCreateForm>();

  const currentData: ContactInfo | undefined = useMemo(() => {
    const data = get(values, `contacts[${contactIdx}]`);
    if (data) {
      return data as ContactInfo;
    }
    return data;
  }, [values, contactIdx]);

  const contactErrors = useMemo(
    () => get(errors, `contacts.[${contactIdx}]`),
    [errors, contactIdx],
  );

  const header = useMemo(() => {
    return animalSplitPartHelper(splitPart).label;
  }, [splitPart]);

  const handleEditContact = () =>
    save<EditContactForm>({
      type: 'save',
      title: `Edit Contact ${header}`,
      icon: faFileEdit,
      initialValues: {
        firstName: currentData?.firstName ?? '',
        lastName: currentData?.lastName,
        phone: currentData?.phone,
        email: currentData?.email,
        isRequestedByUser: currentData?.isRequestedByUser ?? false,
      },
      body: <ContactInformationForm currentUserInfo={currentUserInfo} />,
      validate: (values) => {
        const errors: Partial<Record<keyof EditContactForm, string>> = {};

        if (!values.email && !values.phone) {
          set(errors, 'email', 'Please provide either a phone or email.');
          set(errors, 'phone', 'Please provide either a phone or email.');
        }

        return errors;
      },
      onSubmit: (contact) => {
        setFieldValue(`placeholderContacts[${contactIdx}]`, {
          firstName: contact.firstName,
          lastName: contact.lastName,
          phone: contact.phone,
          email: contact.email,
          isRequestedByUser: contact.isRequestedByUser,
          splitPart: splitPart,
        });

        setFieldValue(`contacts[${contactIdx}]`, {
          firstName: contact.firstName,
          lastName: contact.lastName,
          phone: contact.phone,
          email: contact.email,
          isRequestedByUser: contact.isRequestedByUser,
          splitPart: splitPart,
        });
      },
    });

  return (
    <Card className="h-100">
      <Card.Header className="d-flex align-items-center">
        {header}
        <BSButton variant="link" className="ms-auto">
          <FontAwesomeIcon icon={faFileEdit} onClick={handleEditContact} />
        </BSButton>
      </Card.Header>
      <Card.Header className="d-flex justify-content-center">
        <FontAwesomeIcon icon={faUser} size="4x" />
      </Card.Header>
      <Card.Body>
        <div className="d-block text-break">
          <DataDetailList
            rows={[
              {
                label: 'Name',
                value: currentData?.firstName
                  ? formatFullName(
                      currentData?.firstName,
                      currentData?.lastName,
                    )
                  : undefined,
              },
              {
                label: 'Phone',
                value: currentData?.phone ? (
                  <PhoneDisplay phone={currentData?.phone} />
                ) : undefined,
              },
              {
                label: 'Email',
                value: currentData?.email,
              },
            ]}
          />
        </div>
      </Card.Body>
      {submitCount > 0 && contactErrors && (
        <Card.Footer>
          <Stack gap={1} className="px-1 px-lg-2">
            <BSForm.Control.Feedback
              type="invalid"
              style={{ display: 'block' }}
            >
              {typeof contactErrors === 'string' ? contactErrors : null}
              {typeof contactErrors === 'object'
                ? map(contactErrors, (val: string) => <Row>{val}</Row>)
                : null}
            </BSForm.Control.Feedback>
          </Stack>
        </Card.Footer>
      )}
    </Card>
  );
};

export interface AnimalHead {
  splitType?: AnimalSplitType | 'Select one...' | undefined;
  contacts: ContactInfo[];
}

interface AnimalHeadFormProps {
  enabledAnimalSplitOptions: InputSelectOption[];
  animalSpecies?: string;
}

export const EditSplitTypeForm = ({
  enabledAnimalSplitOptions,
  animalSpecies,
}: AnimalHeadFormProps) => {
  const { values, setFieldTouched, submitCount, setFieldValue } =
    useFormikContext<EditAnimalHeadSplitTypeForm>();
  const currentUserInfo = useMemo(() => {
    if (values.requestedBy) {
      return {
        firstName: values.requestedBy.first_name ?? undefined,
        lastName: values.requestedBy.last_name ?? undefined,
        phone: values.requestedBy.phone ?? undefined,
        email: values.requestedBy.email ?? undefined,
      };
    }
  }, [values.requestedBy]);

  const animalSpeciesFormatted = useMemo(
    () => (animalSpecies ? animalSpeciesHelper(animalSpecies).label : ''),
    [animalSpecies],
  );

  const cardHeader = useMemo(() => {
    const header = animalSpeciesFormatted;

    return header;
  }, [animalSpeciesFormatted]);

  const splitValue: AnimalSplitType | string | 'Select one...' | undefined =
    useMemo(() => get(values, `splitType`), [values]);

  const showContacts = useMemo(
    () => !isNil(splitValue) && splitValue !== 'Select one...',
    [splitValue],
  );

  const colWidths: RowProps = useMemo(() => {
    if (splitValue) {
      switch (splitValue) {
        case 'whole':
          return { xs: 1 };
        case 'half':
        case 'quarters':
          return { lg: 2, xs: 1 };
        case 'half_and_two_quarters':
          return { lg: 3, xs: 1 };
      }
    }
    return { xs: 1 };
  }, [splitValue]);

  // We update the form data manually for contacts array when the splitValue changes
  useEffect(() => {
    if (!splitValue) {
      setFieldValue(`contacts`, [], false);
      return;
    }

    const newContacts = fillNewContacts(
      splitValue as AnimalSplitType,
      values.contacts,
    );

    setFieldValue('contacts', newContacts, false);
    // We only run this when the splitType on the form changes nothing else.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitValue, setFieldValue]);

  // Formik should do this automatically but it doesn't there isn't a good way to handle this globally in formik
  useEffect(() => {
    if (submitCount > 0) {
      setFieldTouched(`splitType`, true);
    }
  }, [submitCount, setFieldTouched]);

  return (
    <Card>
      <AnimalHeadsCardHeader isActive={true} cardHeader={cardHeader} />
      <Card.Body>
        <Stack gap={3}>
          <Row>
            <Col>
              <InputSelect
                label="Split"
                options={enabledAnimalSplitOptions}
                floatingLabel
                nameOveride={`splitType`}
                hint="Select the split you would like to use for this animal."
                required
              />
            </Col>
          </Row>

          <Row {...colWidths}>
            {showContacts &&
              map(values.contacts, (c, idx) => (
                <Col className="pb-4" key={idx}>
                  <ContactInformationCard
                    key={idx}
                    contactIdx={idx}
                    splitPart={c.splitPart as AnimalSplitPart}
                    currentUserInfo={currentUserInfo!}
                  />
                </Col>
              ))}
          </Row>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export function EditSplitType() {
  const navigate = useNavigate();
  const { animalHeadId } = useParams();
  const { push } = useToastr();
  const {
    updateSplitType,
    updateSplitTypeOp,
    processingJobDisplayName,
    processingJobQueryResult,
    jobId,
  } = useProcessingJobPage();

  const enabledAnimalSplitOptions = useMemo(() => {
    // find the animalOptions for the species
    const processorAnimalSettings =
      processingJobQueryResult.data?.findProcessorSchedulingById
        ?.processorSettings?.animalSettings ?? [];
    // lodash is returning the wrong type inference so I switched to find
    const animalSetting = processorAnimalSettings.find(
      (o) =>
        o?.species ===
        processingJobQueryResult?.data?.findProcessorSchedulingById
          ?.animalSpecies,
    );
    const options: InputSelectOption[] = [];
    forEach(animalSetting?.splitTypes ?? [], (split) => {
      if (split) {
        options.push(animalSplitTypeHelper(split));
      }
    });
    return sortBy(options, 'order');
  }, [
    processingJobQueryResult.data?.findProcessorSchedulingById
      ?.processorSettings?.animalSettings,
    processingJobQueryResult.data?.findProcessorSchedulingById?.animalSpecies,
  ]);

  const animalHead = useMemo(() => {
    return find(
      processingJobQueryResult?.data?.findProcessorSchedulingById?.animalHeads,
      (head) => head?._id === animalHeadId,
    );
  }, [
    animalHeadId,
    processingJobQueryResult?.data?.findProcessorSchedulingById?.animalHeads,
  ]);

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  if (processingJobQueryResult.loading) {
    return <Loading />;
  }

  return (
    <Container className="pt-3">
      <PageTitle
        title={'Edit Split Type'}
        showHr={false}
        innerBreadcrumbs={[
          { text: 'Bookings', to: '/processor/agenda' },
          { text: processingJobDisplayName, to: `/processing-job/${jobId}` },
        ]}
      />
      <Form<EditAnimalHeadSplitTypeForm>
        initialValues={{
          splitType: animalHead?.splitType ?? undefined,
          contacts: map(animalHead?.cutsheetInformation, (ci) => ({
            firstName: ci?.contactUser?.first_name,
            lastName: ci?.contactUser?.last_name ?? undefined,
            phone: ci?.contactUser?.phone ?? undefined,
            email: ci?.contactUser?.email ?? undefined,
            splitPart: ci?.splitPart
              ? (ci.splitPart as AnimalSplitPart)
              : undefined,
          })),
          requestedBy:
            (processingJobQueryResult.data?.findProcessorSchedulingById
              ?.requestedBy as User) ?? undefined,
        }}
        onSubmit={async (values) => {
          const { splitType, contacts } = values;

          const updatedAnimalHead = {
            splitType,
            animalHeadId,
            cutsheetInformation: map(contacts, (c) => ({
              // TODO properly handle this being undefined. It really shouldn't be but the graphql types are messing with this
              splitPart: c.splitPart as AnimalSplitPart,
              quantity: 1,
              contactInformation: {
                firstName: c.firstName,
                lastName: c.lastName,
                email: c.email,
                phone: c.phone,
              },
            })),
          };

          try {
            await updateSplitType({
              variables: {
                processorSchedulingId: jobId,
                record: updatedAnimalHead,
              },
            });
            push({
              title: 'Processing Job Updated',
              body: `Animal head updated successfully.`,
              bg: 'success',
              delay: 4000,
            });
            navigate(-1);
          } catch (error) {
            push({
              title: 'Error',
              body: (error as string).toString(),
              bg: 'danger',
              delay: 4000,
            });
          }
        }}
      >
        <Stack gap={3}>
          <EditSplitTypeForm
            enabledAnimalSplitOptions={enabledAnimalSplitOptions}
            animalSpecies={
              processingJobQueryResult.data?.findProcessorSchedulingById
                ?.animalSpecies
            }
          />

          <Stack
            direction="horizontal"
            gap={1}
            className="justify-content-end mb-3"
          >
            <Button
              disabled={updateSplitTypeOp.loading}
              variant="ghost"
              icon={faXmarkCircle}
              onClick={handleCancel}
              content="Cancel"
            />
            <Button
              isLoading={updateSplitTypeOp.loading}
              disabled={updateSplitTypeOp.loading}
              type="submit"
              content={'Update'}
              icon={faCheck}
              variant="primary"
            />
          </Stack>
        </Stack>
      </Form>
    </Container>
  );
}
