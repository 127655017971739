import moment from 'moment';
import { ReactNode } from 'react';
import { Offcanvas, Stack } from 'react-bootstrap';

import styles from './calendar-date-details.module.scss';
import {
  ProcessorSchedulingDetails,
  ProcessorSchedulingDetailsProps,
} from './processor-scheduling-details';

type CalendarDateDetailsProps = Omit<
  ProcessorSchedulingDetailsProps,
  'dateForStatusLookup'
> & {
  hideSideOver: () => void;
  isOpen: boolean;
  date?: Date;
  actions?: ReactNode;
};

export function CalendarDateDetails({
  hideSideOver,
  isOpen,
  date,
  actions,
  ...detailsProps
}: CalendarDateDetailsProps) {
  return (
    <Offcanvas
      show={isOpen}
      onHide={() => hideSideOver()}
      placement="end"
      scroll={false}
      className={styles.dateDetails}
      restoreFocus={false}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <Stack direction="horizontal" gap={3}>
            <div>{moment(date).format('LL')}</div>
          </Stack>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Stack className="h-100">
          <div style={{ flex: 1 }}>
            <ProcessorSchedulingDetails
              {...detailsProps}
              dateForStatusLookup={date}
            />
          </div>
          {actions && (
            <div className="text-center sticky-bottom">{actions}</div>
          )}
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
