import { Button, PaginationTable } from '@farmshare/ui-components';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce, keyBy, mapValues } from 'lodash';
import { useRef, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import {
  SortFindManyUserInput,
  Maybe,
  ViewProcessorAdminQueryResult,
  ProcessorCustomersPaginationQuery,
  useProcessorCustomersPaginationLazyQuery,
  ProcessorCustomersPaginationQueryVariables,
  type User,
  type UserPagination,
} from 'lib/graphql';

import styles from './customers-table.module.scss';

interface ProcessorCustomersProps {
  settings?: Maybe<ViewProcessorAdminQueryResult>;
}

const PAGE_SIZE = 10;

export default function ProcessorCustomers({
  settings,
}: ProcessorCustomersProps) {
  const vendor = useRecoilValue(vendorState);
  const [searchValue, setSearchValue] = useState<string>('');

  const processorCustomersPaginationQuery =
    useProcessorCustomersPaginationLazyQuery({
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        vendorId: vendor?._id,
        search: searchValue,
        page: 1,
        perPage: PAGE_SIZE,
        sort: SortFindManyUserInput.NameAsc,
      },
    });

  const search = (searchValue: string) => {
    processorCustomersPaginationQuery[1].refetch({
      vendorId: vendor?._id,
      search: searchValue,
      page: 1,
      perPage: PAGE_SIZE,
      sort: SortFindManyUserInput.NameAsc,
    });
  };
  const searchValueDebounced = useRef(debounce(search, 300));

  const onSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);

    searchValueDebounced.current(e.target.value);
  };
  return (
    <div className="pb-5 h-100">
      <Card className="bg-body-secondary rounded-4" body>
        <div className={styles.search}>
          <Form.Control
            className={`${styles.searchInput} search-input`}
            type="text"
            placeholder="Search customer by name, email, phone or farm"
            value={searchValue}
            onChange={onSearchValueChanged}
          />
          <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
        </div>
        <hr />
        <div className="pt-2 px-1 bg-body rounded-4">
          <PaginationTable<
            User,
            UserPagination,
            ProcessorCustomersPaginationQuery,
            ProcessorCustomersPaginationQueryVariables,
            SortFindManyUserInput
          >
            paginationQuery={processorCustomersPaginationQuery}
            defaultSort={SortFindManyUserInput.NameAsc}
            // filters={[{ label: 'Enabled', value: 'enabled' }]}
            // defaultFilters={['enabled']}
            columns={[
              {
                label: 'Name',
                field: 'first_name',
                formatter: (row) => (
                  <Link to={`/processor/customers/${row._id}`}>
                    {row.first_name} {row.last_name}
                  </Link>
                ),
              },
              {
                label: 'Producer',
                field: 'company',
                formatter: (row) => row?.company || '--',
              },
              {
                label: 'Email',
                field: 'email',
                minimumBreakpoint: 'md',
                formatter: (row) => row?.email || '--',
              },
              {
                label: 'Phone',
                field: 'phone',
                minimumBreakpoint: 'md',
                formatter: (row) => row?.phone || '--',
              },
              {
                label: '',
                formatter: (row) => (
                  <Button
                    className="ms-1 w-100"
                    content="Details"
                    icon={faInfoCircle}
                    href={`/processor/customers/${row._id}`}
                  />
                ),
              },
            ]}
            dataAccessor={(a) => a.getProcessorCustomers as UserPagination}
            buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
              return {
                filter: {
                  ...mapValues(
                    keyBy(allFilters, (k) => k.value),
                    (v) => v.isActive,
                  ),
                },
                vendorId: vendor?._id,
                search: searchValue,
                sort: defaultSort,
                page,
                perPage: PAGE_SIZE,
              };
            }}
          />
        </div>
      </Card>
    </div>
  );
}
