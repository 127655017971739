import {
  Loading,
  PaginationTable,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import {
  formatToCurrency,
  animalSplitTypeHelper,
  ALL_ANIMAL_SPLITS,
  animalSpeciesHelper,
  inspectionLevelHelper,
} from '@farmshare/utils';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  concat,
  filter,
  find,
  forEach,
  get,
  keyBy,
  map,
  mapValues,
  omit,
  partialRight,
  reduce,
  set,
} from 'lodash';
import { useMemo } from 'react';
import { Stack } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import { CutsheetTooltip } from 'components/cutsheet-tooltip/cutsheet-tooltip';

import {
  EnumCutsheetAnimalSpecies,
  EnumCutsheetType,
  ProcessorCapabilityExtras,
  SortFindManyCutsheetInput,
  useCutsheetPaginationLazyQuery,
  useCutsheetUpsertMutation,
  ViewProcessorAdminDocument,
  type Cutsheet,
  type CutsheetPagination,
  type CutsheetPaginationQuery,
  type CutsheetPaginationQueryVariables,
  type CutsheetUpsertMutationVariables,
  type Maybe,
  type ViewProcessorAdminQueryResult,
} from 'lib/graphql';

import { CutsheetButtons } from './_views/cutsheet-buttons';
import {
  CutsheetModal,
  ExtrasModel,
  ExtrasTrimOptionsType,
  TrimModel,
  type NewCutsheetForm,
} from './_views/cutsheet-modal';

interface ProcessorCutsheetsProps {
  settings?: Maybe<ViewProcessorAdminQueryResult>;
}

export default function ProcessorCutsheets({
  settings,
}: ProcessorCutsheetsProps) {
  const { save } = useModal();
  const { push } = useToastr();

  const vendor = useRecoilValue(vendorState)?._id;

  const cutsheetPaginationLazyQuery = useCutsheetPaginationLazyQuery({
    notifyOnNetworkStatusChange: true,
  });

  const [upsertCutSheet, upsertCutSheetOp] = useCutsheetUpsertMutation({
    refetchQueries: [{ query: ViewProcessorAdminDocument }],
  });

  const extrasTrimOptions = useMemo(() => {
    return reduce(
      settings?.data?.processorSettingsOne?.animalSettings,
      (acc, animalSetting) => {
        const species = animalSetting?.species;
        forEach(animalSetting?.inspectionLevels, (inspectionLevel) => {
          const capabilities = find(settings?.data?.processorCapabilityMany, {
            animalSpecies: animalSetting?.species,
            inspectionLevel,
          });
          const extras = map(
            filter(capabilities?.extras, (extra) => extra?.isActive),
            // Something with lodash lately has been causing it to incorrectly infer types it keeps thinkng things are <TheActualType> | number.
            // I can't figure out why it thinks things can be numbers.
            (extra: ProcessorCapabilityExtras) => ({
              name: extra?.name,
              pricePerPound: extra.pricePerPound,
              minLbs: extra.minLbs,
              isActive: false,
              quantity: 0,
            }),
          );
          const trim = map(
            filter(capabilities?.trim, (trim) => trim?.isActive),
            // Something with lodash lately has been causing it to incorrectly infer types it keeps thinkng things are <TheActualType> | number.
            // I can't figure out why it thinks things can be numbers.
            (trim: ProcessorCapabilityExtras) => ({
              name: trim?.name,
              pricePerPound: trim.pricePerPound,
              minLbs: trim.minLbs,
              isActive: false,
              quantity: 0,
              rank: 1,
              isAllTrim: false,
              disabled: false,
            }),
          );
          if (species) {
            const currentExtras = get(acc, `extras[${species}]`, []);
            set(acc, `extras[${species}]`, concat(currentExtras, extras));
            const currentTrim = get(acc, `trim[${species}]`, []);
            set(acc, `trim[${species}]`, concat(currentTrim, trim));
          }
        });

        return acc;
      },
      { extras: {}, trim: {} } as ExtrasTrimOptionsType,
    );
  }, [
    settings?.data?.processorCapabilityMany,
    settings?.data?.processorSettingsOne?.animalSettings,
  ]);

  if (settings?.loading || upsertCutSheetOp.loading) {
    return <Loading />;
  }

  return (
    <>
      <Stack direction="horizontal" className="justify-content-between">
        <div>
          <h2 className="fw-bold">Manage Cutsheets</h2>
          <p className="mb-0">
            Create and edit all your cutsheets here that will be available for
            your customers.
          </p>
        </div>
      </Stack>
      <hr />
      <PaginationTable<
        Cutsheet,
        CutsheetPagination,
        CutsheetPaginationQuery,
        CutsheetPaginationQueryVariables,
        SortFindManyCutsheetInput
      >
        enableSearchParams={false}
        paginationQuery={cutsheetPaginationLazyQuery}
        defaultSort={SortFindManyCutsheetInput.IdAsc}
        filters={[{ label: 'Archived', value: 'isDeleted' }]}
        defaultFilters={[]}
        columns={[
          {
            label: 'Name',
            field: 'name',
            formatter: (row) => (
              <div>
                {row.name}
                <CutsheetTooltip cutsheet={row} />
              </div>
            ),
          },
          {
            label: 'Type',
            field: 'animalType',
            formatter: (row) => animalSpeciesHelper(row.animalSpecies).label,
          },
          {
            label: 'Inspection',
            field: 'inspectionLevels',
            formatter: (row) => (
              <span>
                {map(
                  row.inspectionLevels,
                  (o) => inspectionLevelHelper(o ?? 'N/A').label,
                ).join(', ')}
              </span>
            ),
          },
          {
            label: 'Price/lb.',
            field: 'pricePerPound',
            formatter: (row) => formatToCurrency(row.pricePerPound || 0),
          },
          {
            label: 'Steaks/Pack',
            field: 'steaksPerPack',
            minimumBreakpoint: 'lg',
          },
          {
            label: 'Steak Thickness',
            field: 'steakThickness',
            formatter: (row) =>
              row.steakThickness ? (
                <span>
                  {row.steakThickness}&nbsp;
                  <span className="text-muted small">(in.)</span>
                </span>
              ) : null,
            minimumBreakpoint: 'lg',
          },
          {
            label: 'Ground Meat Size',
            field: 'groundMeatSize',
            formatter: (row) => (
              <span>
                {row.groundMeatSize}&nbsp;
                <span className="text-muted small">(lb.)</span>
              </span>
            ),
            minimumBreakpoint: 'md',
          },
          {
            label: 'Split Types',
            field: 'splitTypes',
            formatter: (row) => (
              <span>
                {row.splitTypes
                  ?.map((split) => animalSplitTypeHelper(split as string).label)
                  .join(', ') ?? 'None'}
              </span>
            ),
          },
          {
            formatter: (row) => (
              <CutsheetButtons
                cutsheet={row}
                extrasTrimOptions={extrasTrimOptions}
                refetch={cutsheetPaginationLazyQuery[1].refetch}
              />
            ),
          },
        ]}
        dataAccessor={(a) => a.cutsheetPagination as CutsheetPagination}
        buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
          return {
            filter: {
              ...mapValues(
                keyBy(allFilters, (k) => k.value),
                (v) => v.isActive,
              ),
              vendor,
            },
            sort: defaultSort,
            page,
            perPage,
          };
        }}
        actionButtons={(queryOp) => [
          {
            content: 'Add a Cutsheet',
            icon: faPlus,
            onClick: () =>
              save<NewCutsheetForm>({
                type: 'save',
                title: 'New Cutsheet',
                icon: faPlus,
                fullscreen: true,
                body: (fP) => (
                  <CutsheetModal fP={fP} settings={settings?.data} />
                ),
                initialValues: {
                  animalSpecies: EnumCutsheetAnimalSpecies.Beef,
                  inspectionLevel: undefined,
                  name: '',
                  pricePerPound: 0,
                  groundMeatSize: '1',
                  cuts: [],
                  splitTypes: map(ALL_ANIMAL_SPLITS, (split) => ({
                    label: split,
                    value: split,
                  })),
                  extras: extrasTrimOptions.extras,
                  trim: extrasTrimOptions.trim,
                },
                onSubmit: async ({
                  animalSpecies,
                  inspectionLevel,
                  cuts,
                  splitTypes,
                  name,
                  roastSize,
                  steaksPerPack,
                  groundMeatSize,
                  steakThickness,
                  pricePerPound = 0,
                  extras,
                  trim,
                }) => {
                  const formattedExtras = inspectionLevel
                    ? (map(
                        filter(
                          extras?.[animalSpecies],
                          (extra) => extra.isActive,
                        ) || [],
                        partialRight(omit, ['__typename']),
                      ) as ExtrasModel[])
                    : [];

                  const getFormattedTrim = () => {
                    if (!inspectionLevel) {
                      return [];
                    }

                    // if there is an isAllTrim option, only return that
                    const isAllTrim = filter(
                      trim?.[animalSpecies],
                      (t) => t.isAllTrim,
                    );
                    if (isAllTrim.length > 0) {
                      return map(
                        isAllTrim,
                        partialRight(omit, ['__typename', 'disabled']),
                      ) as TrimModel[];
                    }

                    return map(
                      filter(trim?.[animalSpecies], (trim) => trim.isActive) ||
                        [],
                      partialRight(omit, ['__typename', 'disabled']),
                    ) as TrimModel[];
                  };

                  const variables: CutsheetUpsertMutationVariables = {
                    primalCuts: map(cuts, (c) => c.value),
                    splitTypes: map(splitTypes, (st) => st.value),
                    type: EnumCutsheetType.Vendor,
                    groundMeatSize: parseFloat(groundMeatSize),
                    animalSpecies,
                    inspectionLevels:
                      inspectionLevel &&
                      !inspectionLevel.includes('Select one...')
                        ? [inspectionLevel]
                        : [],
                    name,
                    pricePerPound,
                    roastSize,
                    extras: formattedExtras,
                    trim: getFormattedTrim(),
                  };

                  if (steaksPerPack) {
                    variables.steaksPerPack = parseInt(steaksPerPack);
                  }
                  if (steakThickness) {
                    variables.steakThickness = parseFloat(steakThickness);
                  }
                  await upsertCutSheet({ variables });
                  push({
                    title: 'Save Successful',
                    bg: 'primary',
                    body: 'Cutsheet successfully saved.',
                    delay: 4000,
                  });
                  queryOp.refetch();
                },
                validate: async (values) => {
                  const errors: Record<string, any> = {};

                  if (values.splitTypes.length === 0) {
                    errors.splitTypes = 'At least one Split type is required';
                  }

                  if (!values.inspectionLevel) {
                    set(
                      errors,
                      'inspectionLevel',
                      'Please select at least one inspection level.',
                    );
                  }

                  // validate quantity for active extras
                  const extrasOptions =
                    values.extras?.[values.animalSpecies] ?? [];

                  if (extrasOptions.length > 0) {
                    const animalType = values.animalSpecies;

                    for (let i = 0; i < extrasOptions.length; i++) {
                      if (
                        extrasOptions[i].isActive &&
                        (extrasOptions[i].quantity ?? 0) <
                          (extrasOptions[i].minLbs ?? 0)
                      ) {
                        const extraErrors = errors.extras?.[animalType] || [];
                        extraErrors[i] = {
                          ...(extraErrors[i] ?? {}),
                          quantity: `Min quantity is ${extrasOptions[i].minLbs} lbs`,
                        };

                        errors.extras = {
                          ...errors?.extras,
                          [animalType]: extraErrors,
                        };
                      }
                    }
                  }

                  // check if rank is unique for active trims
                  const trimOptions = values.animalSpecies
                    ? values.trim?.[values.animalSpecies] ?? []
                    : [];
                  if (trimOptions.length > 0) {
                    const animalType = values.animalSpecies;

                    for (let i = 0; i < trimOptions.length; i++) {
                      // validate quantity for active trims
                      if (
                        trimOptions[i].isActive &&
                        !trimOptions[i].isAllTrim &&
                        (trimOptions[i].quantity ?? 0) <
                          (trimOptions[i].minLbs ?? 0)
                      ) {
                        const trimErrors = errors.trim?.[animalType] || [];
                        trimErrors[i] = {
                          ...(trimErrors[i] ?? {}),
                          quantity: `Min quantity is ${trimOptions[i].minLbs} lbs`,
                        };

                        errors.trim = {
                          ...errors?.trim,
                          [animalType]: trimErrors,
                        };
                      }

                      // check if rank is unique for active trims
                      const ranks = map(
                        filter(trimOptions, (t) => t.isActive),
                        (t) => t.rank,
                      );
                      if (ranks.length !== new Set(ranks).size) {
                        errors.trim = {
                          ...errors?.trim,
                          [animalType]: [
                            ...map(
                              filter(trimOptions, (t) => t.isActive),
                              (t) => ({
                                rank: 'Rank must be unique',
                              }),
                            ),
                          ],
                        };
                      }
                    }
                  }

                  // check if rank is greater than 0 for active trims
                  if (trimOptions.length > 0 && values.animalSpecies) {
                    const animalType = values.animalSpecies;

                    for (let i = 0; i < trimOptions.length; i++) {
                      if (trimOptions[i].isActive && trimOptions[i].rank < 1) {
                        const trimErrors = errors.trim?.[animalType] || [];

                        trimErrors[i] = {
                          ...(trimErrors[i] ?? {}),
                          rank: 'Rank must be greater than 0',
                        };
                        errors.trim = {
                          ...errors?.trim,
                          [animalType]: trimErrors,
                        };
                      }
                    }
                  }

                  return errors;
                },
              }),
          },
        ]}
      />
    </>
  );
}
