import { Loading } from '@farmshare/ui-components';
import { animalSpeciesHelper, inspectionLevelHelper } from '@farmshare/utils';
import { filter, find, first, flatMap, kebabCase, map, some } from 'lodash';
import { type ReactNode, useEffect, useMemo } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  type Maybe,
  type PrimalGroup,
  type ProcessorCapability,
  type ViewProcessorAdminQueryResult,
  usePrimalGroupManyQuery,
} from 'lib/graphql';

import { ProcessorCapabilitiesTabInner } from './_views/capability-tab-inner';

interface Tab {
  key: string;
  title: string;
  element: ReactNode;
}

interface ProcessorCapabilitiesTabProps {
  settings?: Maybe<ViewProcessorAdminQueryResult>;
}

export function ProcessorCapabilitiesTab({
  settings,
}: ProcessorCapabilitiesTabProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { subTab } = useParams();

  const { data, loading } = usePrimalGroupManyQuery({
    variables: {
      filter: {
        processorSettings: settings?.data?.processorSettingsOne?._id,
        isDefault: false,
      },
    },
  });

  const tabs: Tab[] = useMemo(() => {
    const _tabs: Pick<Tab, 'title' | 'element'>[] = flatMap(
      settings?.data?.processorSettingsOne?.animalSettings,
      (animalSetting) =>
        flatMap(animalSetting?.inspectionLevels, (inspectionLevel) => {
          const foundPrimalGroups = filter(
            data?.primalGroupMany,
            (pg) =>
              pg.animalSpecies === animalSetting?.species &&
              pg.inspectionLevel === inspectionLevel,
          );
          const capability = find(
            settings?.data?.processorCapabilityMany,
            (pc) =>
              pc.animalSpecies === animalSetting?.species &&
              pc.inspectionLevel === inspectionLevel,
          );

          return {
            title: `${
              animalSpeciesHelper(animalSetting?.species ?? '').label
            } ${inspectionLevelHelper(inspectionLevel ?? '').label}`,
            element: (
              <ProcessorCapabilitiesTabInner
                capability={
                  (capability ?? {
                    animalSpecies: animalSetting?.species,
                    inspectionLevel,
                  }) as ProcessorCapability
                }
                foundPrimalGroups={foundPrimalGroups as PrimalGroup[]}
              />
            ),
          };
        }),
    );
    return map(_tabs, (t): Tab => ({ ...t, key: kebabCase(t.title) }));
  }, [
    data?.primalGroupMany,
    settings?.data?.processorCapabilityMany,
    settings?.data?.processorSettingsOne?.animalSettings,
  ]);

  useEffect(() => {
    if (
      tabs.length &&
      /\/processor\/capabilities/gi.test(pathname) &&
      (!subTab || !some(tabs, (t) => t.key === subTab))
    ) {
      navigate(`/processor/capabilities/${first(tabs)?.key}`, {
        replace: true,
      });
    }
  }, [navigate, pathname, subTab, tabs]);

  if (settings?.loading || loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="mb-3">
        <h2 className="fw-bold">Manage Capabilities</h2>
        <div>
          Create and edit all your capabilities here that will be available for
          your cutsheets.
        </div>
      </div>
      <Tabs
        activeKey={subTab}
        onSelect={(tabName) => {
          if (tabName) {
            navigate(`/processor/capabilities/${tabName}`);
          }
        }}
      >
        {map(tabs, (tab, idx) => (
          <Tab
            key={idx}
            title={tab.title}
            eventKey={tab.key}
            mountOnEnter
            unmountOnExit
          >
            {tab.element}
          </Tab>
        ))}
      </Tabs>
    </>
  );
}
