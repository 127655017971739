import {
  DataDetailListMinimal,
  ExternalLink,
  Loading,
  MoneyDisplay,
  Table,
} from '@farmshare/ui-components';
import { animalSpeciesHelper, formatPhoneNumber } from '@farmshare/utils';
import moment from 'moment';
import { useMemo } from 'react';
import { Card, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import {
  EnumProcessorSchedulingStatus,
  ProcessorScheduling,
  ProcessorSchedulingByIdQuery,
  useProcessorSchedulingByIdQuery,
} from 'lib/graphql';

import renderJobHeadsCellHelper from 'pages/processor/lib/renderJobHeadsCellHelper';

import styles from './scheduling.module.scss';

const statusPriorities: Record<EnumProcessorSchedulingStatus, number> = {
  [EnumProcessorSchedulingStatus.Initial]: 0,
  [EnumProcessorSchedulingStatus.Requested]: 1,
  [EnumProcessorSchedulingStatus.Scheduled]: 2,
  [EnumProcessorSchedulingStatus.DroppedOff]: 3,
  [EnumProcessorSchedulingStatus.Killed]: 4,
  [EnumProcessorSchedulingStatus.Aging]: 5,
  [EnumProcessorSchedulingStatus.Curing]: 6,
  [EnumProcessorSchedulingStatus.ReadyPayAtPickup]: 7,
  [EnumProcessorSchedulingStatus.Invoicing]: 8,
  [EnumProcessorSchedulingStatus.InvoicePaid]: 9,
  [EnumProcessorSchedulingStatus.Completed]: 10,
  [EnumProcessorSchedulingStatus.CancelledByProcessor]: -1,
  [EnumProcessorSchedulingStatus.CancelledByProducer]: -1,
  [EnumProcessorSchedulingStatus.CheckoutExpired]: -1,
  [EnumProcessorSchedulingStatus.DeniedByProcessor]: -1,
  [EnumProcessorSchedulingStatus.DepositFailed]: -1,
};

const getChipStatus = (
  scheduledJob: ProcessorSchedulingByIdQuery['processorSchedulingById'],
) => {
  if (!scheduledJob) {
    return '-';
  }
  const formattedDate = scheduledJob.statusLastUpdated
    ? moment(scheduledJob.statusLastUpdated).format('LLL')
    : '-';

  switch (scheduledJob.status) {
    case EnumProcessorSchedulingStatus.CancelledByProcessor:
    case EnumProcessorSchedulingStatus.CancelledByProducer:
    case EnumProcessorSchedulingStatus.DeniedByProcessor:
      return `Cancelled on ${formattedDate}`;

    case EnumProcessorSchedulingStatus.CheckoutExpired:
    case EnumProcessorSchedulingStatus.DepositFailed:
      return `Error on ${formattedDate}`;

    case EnumProcessorSchedulingStatus.Scheduled:
    case EnumProcessorSchedulingStatus.Requested:
    case EnumProcessorSchedulingStatus.Initial:
      return `Scheduled at ${formattedDate}`;

    case EnumProcessorSchedulingStatus.Killed:
    case EnumProcessorSchedulingStatus.Invoicing:
      return `In Progress as of ${formattedDate}`;

    case EnumProcessorSchedulingStatus.Completed:
      return `Completed on ${formattedDate}`;

    default:
      return 'Unknown';
  }
};

export default function SchedulingDetailsHome() {
  const { schedulingId } = useParams();

  const { data, loading } = useProcessorSchedulingByIdQuery({
    variables: { id: schedulingId },
  });

  const formattedStatus = useMemo(() => {
    return getChipStatus(data?.processorSchedulingById);
  }, [data?.processorSchedulingById]);

  const animalSpecies = useMemo(
    () =>
      animalSpeciesHelper(data?.processorSchedulingById?.animalSpecies ?? '')
        ?.label,
    [data?.processorSchedulingById?.animalSpecies],
  );

  if (loading) {
    return <Loading />;
  }

  const vendorPhone =
    data?.processorSchedulingById?.vendor?.address.phone || '';
  const refundDate = moment
    .utc(data?.processorSchedulingById?.dropoffDate)
    .subtract(data?.processorSchedulingById?.refundDeadlineDays, 'days');

  return (
    <Container className={`${styles.schedulingDetailsContainer} py-4`}>
      <Row>
        <h3 className="fw-bold">
          {data?.processorSchedulingById?.requestedBy?.first_name}
          's Processing Details
        </h3>
      </Row>
      <hr />
      <div>
        {/* Status */}
        <div className="d-flex">
          <div className="me-auto">
            <p className="m-0 fw-bold">Status</p>
          </div>
          <div className="">
            <p className="m-0 ">{formattedStatus}</p>
          </div>
        </div>
        <hr />
        {/* Item details */}
        <div className="d-flex">
          <div className="me-auto">
            <p className="m-0 fw-bold">Item</p>
          </div>
          <div className="">
            <p className="m-0 ">
              {data?.processorSchedulingById &&
                renderJobHeadsCellHelper(
                  data.processorSchedulingById as ProcessorScheduling,
                )}
            </p>
          </div>
        </div>
        <hr />
        {/* Date row */}
        <div className="d-flex">
          <div className="me-auto">
            <p className="m-0 fw-bold">Drop Off Date</p>
          </div>
          <div className="">
            <p className="m-0 ">
              {moment
                .utc(data?.processorSchedulingById?.dropoffDate)
                .format('LL')}
            </p>
          </div>
        </div>
        {/* Contact Details */}
        <hr />
        <DataDetailListMinimal
          heading="Processor Contact Details"
          rows={[
            {
              label: 'Name',
              value: `${data?.processorSchedulingById?.vendor?.shop_name}`,
            },
            {
              label: 'Phone',
              value: formatPhoneNumber(vendorPhone),
            },
            {
              label: 'Email',
              value: data?.processorSchedulingById?.vendor?.address.email,
            },
            {
              // The farmshare FAQ for now. One day we might have it per processor`
              label: 'FAQ',
              value: <ExternalLink href={'/faq'} text="View FAQ" />,
            },
          ]}
        />
        <hr />
        <DataDetailListMinimal
          heading="Price Details"
          rows={[
            {
              label: 'Deposit',
              value: (
                <MoneyDisplay
                  value={data?.processorSchedulingById?.deposit?.amount}
                />
              ),
            },
          ]}
        />
        {data?.processorSchedulingById?.status &&
          statusPriorities[data?.processorSchedulingById?.status] >=
            statusPriorities[EnumProcessorSchedulingStatus.Killed] && (
            <>
              <hr />
              <Card className="animal-heads-list">
                <Card.Header className="d-flex align-items-center">
                  <span className="fw-bold fs-5">Yield Report</span>
                </Card.Header>
                <Card.Body>
                  <Table
                    rows={
                      data?.processorSchedulingById?.animalHeads?.map((ah) => ({
                        identifier: ah?.producerIdentifier ?? '-',
                        grade: ah?.grade ?? '-',
                        liveWeight: ah?.liveWeight,
                        hangingWeight: ah?.isHangingWeightPerSide
                          ? `${ah?.hangingWeightSideA} / ${ah?.hangingWeightSideB}`
                          : `${ah?.hangingWeight}`,
                        yield: ah?.yield,
                      })) ?? []
                    }
                    columns={[
                      {
                        label: 'Identifier',
                        field: 'identifier',
                      },
                      { label: 'Live Weight (lbs)', field: 'liveWeight' },
                      {
                        label: 'Hanging Weight (lbs.)',
                        field: 'hangingWeight',
                      },
                      {
                        label: 'Yield',
                        field: 'yield',
                      },
                      {
                        label: 'Grade',
                        field: 'grade',
                      },
                    ]}
                  />
                </Card.Body>
              </Card>
            </>
          )}
        <hr />
        <p className="">
          If you need to edit or cancel this processing job, please contact the
          processor using the information listed above.
        </p>
        <p className="m-0">
          You have until {refundDate.format('LL')} to cancel and receive a
          refund. If you cancel after this date, you will not be refunded your
          deposit.
        </p>
      </div>
    </Container>
  );
}
