import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import { isFunction, isNull, join, map } from 'lodash';
import { useCallback } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { IModal, IModalProperties } from './modal-renderer';
import { Button } from '../button/button';
import { FormValues } from '../form/form';

interface IModalInnerPropsBase<TForm extends FormValues<TForm>> {
  modal: IModal<TForm>;
  modalProps: IModalProperties;
  formikProps?: FormikProps<TForm>;
}

interface IModalInnerPropsSave<TForm extends FormValues<TForm>>
  extends IModalInnerPropsBase<TForm> {
  formikProps: FormikProps<TForm>;
}

export type IModalInnerProps<TForm extends FormValues<TForm>> =
  | IModalInnerPropsBase<TForm>
  | IModalInnerPropsSave<TForm>;

export default function ModalInner<TForm extends FormValues<TForm>>(
  props: IModalInnerProps<TForm>,
) {
  const renderModalBody = useCallback(() => {
    if (isFunction(props.modal.body)) {
      if (
        (props.formikProps && props.modal.type === 'save') ||
        (props.formikProps && props.modal.type === 'readOnly')
      ) {
        return props.modal.body(props.formikProps);
      }
      if (props.modal.type === 'info') {
        return props.modal.body(props.modalProps);
      }

      throw new Error(`Modal type doesn't support a function body.`);
    }
    return props.modal.body;
  }, [props.formikProps, props.modal, props.modalProps]);

  const renderModalFooter = useCallback(() => {
    if (isFunction(props.modal.footer)) {
      return props.modal.footer({
        formikProps: props.formikProps,
        modalProps: props.modalProps,
      });
    }
    return props.modal.footer;
  }, [props.formikProps, props.modal, props.modalProps]);

  return (
    <>
      <Modal.Header
        className={join(
          [...props.modalProps.headerClassNames, 'bg-body', 'border-bottom'],
          ' ',
        )}
        closeButton
      >
        <Modal.Title>
          {props.modal.icon && (
            <FontAwesomeIcon
              icon={props.modal.icon || props.modalProps.icon}
              className={join(['me-3', props.modal.iconClassName], ' ')}
            />
          )}
          {props.modal.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-body">
        {props.modal.error && (
          <Alert variant="danger">{props.modal.error}</Alert>
        )}
        {renderModalBody()}
      </Modal.Body>
      {props.modal.footer
        ? renderModalFooter()
        : !isNull(props.modal.footer) && (
            <Modal.Footer className="border-top">
              {map(props.modalProps.buttons, (button, idx) => (
                <Button key={idx} {...button} />
              ))}
            </Modal.Footer>
          )}
    </>
  );
}
