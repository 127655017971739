import {
  LazyQueryResultTuple,
  NetworkStatus,
  OperationVariables,
  QueryResult,
} from '@apollo/client';
import {
  faFilter,
  faRefresh,
  faColumns,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from '@tanstack/react-table';
import {
  bind,
  concat,
  filter,
  includes,
  join,
  map,
  reduce,
  split,
  omit,
  isNil,
} from 'lodash';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Alert, Col, Container, Row as BootstrapRow } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { NoResultsText, NoResultsOverrideType } from './no-results-text';
import { Pagination } from '../../components/pagination/pagination';
import { Button, type ButtonProps } from '../button/button';
import { DropdownMultiSelect } from '../dropdown-multi-select/dropdown-multi-select';
import { Loading } from '../loading/loading';
import { Table } from '../table/table';
import { ColumnProps } from '../table/table-types';

export interface PaginationFilter {
  label: string;
  value: string;
  isActive?: boolean;
}

export interface PaginationResponse<D> {
  count?: number | null;
  pageInfo: {
    currentPage: number;
    perPage: number;
    pageCount?: number | null;
    itemCount?: number | null;
    hasNextPage?: boolean | null;
    hasPreviousPage?: boolean | null;
  };
  items?: D[] | null;
}

interface Option {
  label: string;
  value: string;
  isActive?: boolean;
}

export interface PaginationTableProps<
  TData,
  TAccessor extends PaginationResponse<TData>,
  TQuery,
  TVars extends OperationVariables,
  TSort,
> {
  actionButtons?: (t: QueryResult<TQuery, TVars>) => ButtonProps[];
  dataAccessor: (r: TQuery) => TAccessor | undefined | null;
  columns: ColumnProps<TData, TQuery, TVars>[];
  filters?: PaginationFilter[];
  defaultFilters?: string[];
  defaultSort: TSort;
  buildFilterQuery: (
    allFilters: PaginationFilter[],
    sort: TSort,
    page: number,
    perPage: number,
  ) => TVars | undefined;
  paginationQuery: LazyQueryResultTuple<TQuery, TVars>;
  renderSubComponent?: (
    row: Row<TData>,
    operation?: QueryResult<TQuery, TVars>,
  ) => ReactNode;
  hideButtons?: boolean;
  isDashboard?: boolean;
  enableSearchParams?: boolean;
  noResultsOverride?: NoResultsOverrideType;
}

export function PaginationTable<
  TData,
  TAccessor extends PaginationResponse<TData>,
  TQuery,
  TVars extends OperationVariables,
  TSort,
>({
  actionButtons,
  dataAccessor,
  buildFilterQuery,
  columns,
  defaultFilters,
  defaultSort,
  filters,
  paginationQuery,
  renderSubComponent,
  hideButtons,
  isDashboard,
  enableSearchParams = true,
  noResultsOverride,
}: PaginationTableProps<TData, TAccessor, TQuery, TVars, TSort>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedColumns, setSelectedColumns] = useState(
    map(columns, (c) => c.label),
  );

  // Determine what the initial filters are based upon props and the url.
  const initialFilters = useMemo(() => {
    let _defaultFilters = defaultFilters;
    const filterParam = searchParams.get('filter');
    const deepLinkFilters = split(filterParam?.trim(), ',');

    if (enableSearchParams && filterParam && deepLinkFilters.length > 0) {
      _defaultFilters = deepLinkFilters;
    }

    return _defaultFilters;
    // I only want this to run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Determine what the initial page is based upon value in the url.
  const initialPage = useMemo(() => {
    const pageParam = parseFloat(searchParams.get('page') ?? '');
    if (enableSearchParams && !isNaN(pageParam)) {
      return pageParam;
    }
    return 1;
    // I only want this to run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState<number>(initialPage);
  const [perPage] = useState<number>(15);
  const [pageCount, setPageCount] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(10);

  const filteredColumns: ColumnProps<TData, TQuery, TVars>[] = filter(
    columns,
    (c) => {
      // Always show button columns
      const isButtonColumn: boolean = !c.label;
      return selectedColumns.includes(c.label) || isButtonColumn;
    },
  );

  const [loadData, operation] = paginationQuery;

  const accessor = useMemo(
    () => operation.data && dataAccessor(operation.data),
    [dataAccessor, operation.data],
  );

  const buildFilters = useCallback(
    (selectedFilters: string[]) => {
      return map(filters, (f) => ({
        ...f,
        isActive: includes(selectedFilters, f.value),
      }));
    },
    [filters],
  );

  const filterOptions = useMemo(() => {
    return map(filters, (f) => ({
      ...f,
      isActive: includes(initialFilters, f.value),
    }));
  }, [filters, initialFilters]);

  const [allFilters, setAllFilters] = useState<PaginationFilter[]>(
    buildFilters(initialFilters || []),
  );

  const handleSetPage = useCallback(
    (newPage: number) => {
      setPage(newPage);
      if (enableSearchParams) {
        searchParams.set('page', newPage.toString());

        setSearchParams(searchParams);
      }
      operation.refetch(
        buildFilterQuery(allFilters, defaultSort, newPage, perPage),
      );
    },
    [
      allFilters,
      buildFilterQuery,
      defaultSort,
      operation,
      perPage,
      searchParams,
      setSearchParams,
      enableSearchParams,
    ],
  );

  useEffect(() => {
    const pageParam = searchParams.get('page');
    const filterParam = searchParams.get('filter');

    if (pageParam) {
      setPage(parseInt(pageParam));
    } else {
      if (enableSearchParams) {
        searchParams.set('page', page.toString());
      }
    }

    if (filters) {
      if (filterParam) {
        setAllFilters(buildFilters(split(filterParam, ',')));
      } else {
        if (enableSearchParams) {
          searchParams.set(
            'filter',
            join(
              map(
                filter(allFilters, (f) => !!f.isActive),
                (m) => m.value,
              ),
              ',',
            ),
          );
        }
      }
    }

    if (enableSearchParams) {
      setSearchParams(searchParams, { replace: true });
    }

    if (!operation.loading && !operation.called && !operation.data) {
      loadData({
        variables: buildFilterQuery(allFilters, defaultSort, page, perPage),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    operation.loading,
    operation.called,
    operation.data,
    searchParams,
    setSearchParams,
  ]);

  useEffect(() => {
    if (operation.data) {
      if (!isNil(accessor?.pageInfo.itemCount)) {
        setItemCount(accessor.pageInfo.itemCount);
      }
      if (!isNil(accessor?.pageInfo.pageCount)) {
        setPageCount(accessor.pageInfo.pageCount);
      }

      // reset page to one if current page is no longer valid
      if (page > (accessor?.pageInfo?.pageCount ?? 0)) {
        setPage(1);
        if (enableSearchParams) {
          searchParams.set('page', '1');
        }
      }
    }
  }, [
    accessor,
    operation,
    operation.data,
    setPage,
    page,
    enableSearchParams,
    searchParams,
  ]);

  if (operation.networkStatus === NetworkStatus.loading) {
    return isDashboard ? (
      <Container className="py-4">
        <BootstrapRow className="justify-content-md-center">
          <Col md="auto">
            <FontAwesomeIcon
              icon={faSpinner}
              size="4x"
              className="text-primary"
              spin
            />
          </Col>
        </BootstrapRow>
      </Container>
    ) : (
      <Loading />
    );
  }

  const renderTable = () => {
    if (operation.loading) {
      return (
        <Container className="pt-4">
          <BootstrapRow className="justify-content-md-center">
            <Col md="auto">
              <FontAwesomeIcon
                icon={faSpinner}
                size="5x"
                className="text-primary"
                spin
              />
            </Col>
          </BootstrapRow>
        </Container>
      );
    }

    if (!accessor?.items || (accessor?.items && accessor?.items.length < 1)) {
      return <NoResultsText textOverride={noResultsOverride} />;
    }

    return (
      <Table<TData, TQuery, TVars>
        rows={accessor.items}
        rowAlignment="middle"
        renderSubComponent={renderSubComponent}
        columns={map(filteredColumns, (c) => {
          if (c.formatter) {
            c.formatter = bind(
              c.formatter,
              c,
              bind.placeholder,
              bind.placeholder,
              operation,
            );
          }
          return c;
        })}
      />
    );
  };

  return (
    <Container>
      <div className="d-flex justify-content-end gap-2 my-3">
        {!hideButtons &&
          map(
            concat(actionButtons ? actionButtons(operation) : [], {
              content: 'Refresh',
              icon: faRefresh,
              isLoading: operation.networkStatus === NetworkStatus.refetch,
              onClick: () => operation.refetch(),
            }),
            (b, idx) => (
              <Button
                key={idx}
                content={
                  <span className="d-none d-md-inline">{b.content}</span>
                }
                {...omit(b, 'content')}
              />
            ),
          )}
        {!hideButtons && (
          <>
            {/* Filters */}
            {filters && (
              <DropdownMultiSelect
                title="Filters"
                options={filterOptions}
                resetSelections
                content="Apply"
                icon={faFilter}
                onApply={(options) => {
                  const activeFilters = map(
                    filter(options, (f) => !!f.isActive),
                    (m) => m.value,
                  );

                  if (enableSearchParams) {
                    searchParams.set('filter', join(activeFilters, ','));
                    setSearchParams(searchParams, { replace: true });
                  }

                  return operation.refetch(
                    buildFilterQuery(
                      buildFilters(activeFilters),
                      defaultSort,
                      page,
                      perPage,
                    ),
                  );
                }}
              />
            )}
            {/* Column Filters */}
            <DropdownMultiSelect
              title="Columns"
              options={reduce(
                columns,
                (res: Option[], col, key) => {
                  if (col.label) {
                    res.push({
                      label: col.label || '',
                      value: col.label || '',
                      isActive: true,
                    });
                  }
                  return res;
                },
                [],
              )}
              content="Apply"
              resetSelections
              icon={faColumns}
              onApply={(options) => {
                const selectedOptions = map(
                  filter(options, (f) => !!f.isActive),
                  (m) => m.value,
                );
                setSelectedColumns(selectedOptions);
              }}
            />
          </>
        )}
      </div>
      {operation.error && (
        <Alert variant="danger">
          <pre>
            <code>{JSON.stringify(operation.error, null, 2)}</code>
          </pre>
        </Alert>
      )}
      {renderTable()}
      <div className="d-flex justify-content-center mt-2">
        <Pagination
          setPage={handleSetPage}
          currentPage={page}
          pageCount={pageCount}
          itemCount={itemCount}
        />
      </div>
    </Container>
  );
}
