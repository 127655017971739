import {
  Auth0Provider,
  Auth0ProviderOptions,
  WithAuthenticationRequiredOptions,
  withAuthenticationRequired,
} from '@auth0/auth0-react';
import { Error, ModalProvider, ToastrProvider } from '@farmshare/ui-components';
import { environment } from 'environments/environment';
import { includes } from 'lodash';
import { ComponentType, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { RecoilRoot, useRecoilState } from 'recoil';
import { userState } from 'state';

import { AuthApolloProvider } from 'components/auth-apollo-provider/auth-apollo-provider';

import { EnumUserRole } from 'lib/graphql';

import AdminPage from 'pages/admin';
import AdminAssetPage from 'pages/admin/assets';
import AdminJobExportPage from 'pages/admin/job-export';
import AdminLogisticsInvoicePage from 'pages/admin/logistics-invoices';
import AdminLogisticsInvoiceCreatePage from 'pages/admin/logistics-invoices/create';
import AdminPackagingPage from 'pages/admin/packaging';
import AdminProcessingInvoicesPage from 'pages/admin/processing-invoices';
import AdminShipmentPage from 'pages/admin/shipments';
import AdminTenantPage from 'pages/admin/tenants';
import AdminTenantDetailsPage from 'pages/admin/tenants/details';
import DashboardPage from 'pages/dashboard';
import FAQPage from 'pages/faq';
import HomePage from 'pages/home';
import ShopifyIntegrationPage from 'pages/integrations/shopify';
import LoginPage from 'pages/login';
import MarketingPage from 'pages/marketing';
import CustomerDetailsPage from 'pages/marketing/customers/details';
import OrdersPage from 'pages/orders';
import OrderDetailsPage from 'pages/orders/details';
import OrderPackListPage from 'pages/orders/pack-list';
import { EditSplitType } from 'pages/processing-job/_views/edit-split-type';
import { AnimalHead } from 'pages/processing-job/animal-head';
import { AddAnimalHead } from 'pages/processing-job/animal-head-add';
import { ProcessingJob } from 'pages/processing-job/processing-job';
import ProcessorAdminPage from 'pages/processor';
import { Waitlist } from 'pages/processor/scheduling-settings/_views/waitlist';
import ProductsPage from 'pages/products';
import ProductDetailsPage from 'pages/products/details';
import ProfilePage from 'pages/profile';
import PromosPage from 'pages/promos';
import SchedulingPage from 'pages/scheduling';
import SchedulingCreatePage from 'pages/scheduling/create';
import SchedulingDetailsHome from 'pages/scheduling/details';
import SchedulingEditCutsheets from 'pages/scheduling/edit';
import ProcessingPartnerDetail from 'pages/scheduling/processor';
import ShipmentsPage from 'pages/shipments';
import CreateShipmentPage from 'pages/shipments/create';
import ShipmentDetailsPage from 'pages/shipments/details';
import SignupPage from 'pages/signup';
import SuppliesPage from 'pages/supplies';
import WaitlistUnsubscribe from 'pages/waitlist-unsubscribe';

import { App } from './app';
import logo from './assets/images/partners.svg';

// create root
const root = createRoot(document.getElementById('root') as HTMLElement);

// Will only render the route in dev environments for feature flagging of new pages.
const DevOnlyRoute = (args: { component: ComponentType }) => {
  if (process.env.NODE_ENV === 'development') {
    const Component = args.component;
    return <Component />;
  } else {
    return (
      <Error
        error={{ name: '404', message: `Under Construction` }}
        logo={
          <img
            src={logo}
            height={100}
            alt="farmshare partners logo"
            className="mt-1 text-body p-3 p-md-0"
          />
        }
      />
    );
  }
};

// protected route component
const ProtectedRoute = (args: {
  component: ComponentType;
  options?: WithAuthenticationRequiredOptions;
  allowedUserRoles: EnumUserRole[];
}) => {
  const [user] = useRecoilState(userState);

  const Component = withAuthenticationRequired(args.component, args.options);
  // if you pass in a list of allowedUserRoles and the user is not of those allowed roles.
  // Admins can access all things.
  if (
    user &&
    args.allowedUserRoles &&
    !includes(args.allowedUserRoles, user?.role) &&
    user.role !== EnumUserRole.Admin
  ) {
    return (
      <Error
        error={{ name: '404', message: 'Page not found.' }}
        logo={
          <img
            src={logo}
            height={100}
            alt="farmshare partners logo"
            className="mt-1 text-body p-3 p-md-0"
          />
        }
      />
    );
  }
  return <Component />;
};

// auth provider
const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: Auth0ProviderOptions) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const connection = Boolean(searchParams.get('useEmail') === 'true')
    ? 'email'
    : Boolean(searchParams.get('usePhone') === 'true')
    ? 'sms'
    : undefined;

  return (
    <Auth0Provider
      onRedirectCallback={(appState) => {
        navigate(appState?.returnTo || window.location.pathname);
      }}
      cacheLocation="localstorage"
      useRefreshTokensFallback
      useRefreshTokens
      {...props}
      authorizationParams={{
        ...props.authorizationParams,
        scope: 'openid profile email phone offline_access',
        connection,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

// create routing patterns
const router = createBrowserRouter([
  { path: '/promos', element: <PromosPage /> },
  {
    element: (
      <Auth0ProviderWithRedirectCallback
        domain={environment.authDomain}
        clientId={environment.authClientId}
        authorizationParams={{
          redirect_uri: `${window.location.origin}/dashboard`,
          audience: environment.authAudience,
        }}
      >
        <AuthApolloProvider>
          <ModalProvider>
            <ToastrProvider>
              <App />
            </ToastrProvider>
          </ModalProvider>
        </AuthApolloProvider>
      </Auth0ProviderWithRedirectCallback>
    ),
    errorElement: (
      <Error
        error={{ name: '404', message: 'Page not found.' }}
        logo={
          <img
            src={logo}
            height={100}
            alt="farmshare partners logo"
            className="mt-1 text-body p-3 p-md-0"
          />
        }
      />
    ),
    children: [
      { index: true, element: <HomePage /> },
      { path: '/login', element: <LoginPage /> },
      { path: '/signup', element: <SignupPage /> },
      {
        path: '/scheduling',
        element: <SchedulingPage />,
      },
      {
        path: '/faq',
        element: <FAQPage />,
      },
      {
        path: '/scheduling/:slug',
        element: <ProcessingPartnerDetail />,
      },
      {
        path: '/scheduling/:slug/:animalType/:date',
        element: <SchedulingCreatePage />,
      },
      {
        path: '/scheduling/:schedulingId/details',
        element: <SchedulingDetailsHome />,
      },
      {
        path: '/scheduling/:schedulingId/cutsheets',
        element: (
          <ProtectedRoute
            component={SchedulingEditCutsheets}
            allowedUserRoles={[EnumUserRole.Basic, EnumUserRole.Vendor]}
          />
        ),
      },
      {
        path: '/admin',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Admin]}
            component={AdminPage}
          />
        ),
        children: [
          {
            path: '/admin/job-export',
            element: (
              <ProtectedRoute
                allowedUserRoles={[EnumUserRole.Admin]}
                component={AdminJobExportPage}
              />
            ),
          },
          {
            path: '/admin/assets',
            element: (
              <ProtectedRoute
                allowedUserRoles={[EnumUserRole.Admin]}
                component={AdminAssetPage}
              />
            ),
          },
          {
            path: '/admin/logistics-invoices',
            element: (
              <ProtectedRoute
                allowedUserRoles={[EnumUserRole.Admin]}
                component={AdminLogisticsInvoicePage}
              />
            ),
          },
          {
            path: '/admin/processing-invoices',
            element: (
              <ProtectedRoute
                allowedUserRoles={[EnumUserRole.Admin]}
                component={AdminProcessingInvoicesPage}
              />
            ),
          },
          {
            path: '/admin/logistics-invoices/create',
            element: (
              <ProtectedRoute
                allowedUserRoles={[EnumUserRole.Admin]}
                component={AdminLogisticsInvoiceCreatePage}
              />
            ),
          },
          {
            path: '/admin/packaging',
            element: (
              <ProtectedRoute
                allowedUserRoles={[EnumUserRole.Admin]}
                component={AdminPackagingPage}
              />
            ),
          },
          {
            path: '/admin/shipments',
            element: (
              <ProtectedRoute
                allowedUserRoles={[EnumUserRole.Admin]}
                component={AdminShipmentPage}
              />
            ),
          },
          {
            path: '/admin/tenants',
            element: (
              <ProtectedRoute
                allowedUserRoles={[EnumUserRole.Admin]}
                component={AdminTenantPage}
              />
            ),
          },
          {
            path: '/admin/tenants/:id',
            element: (
              <ProtectedRoute
                allowedUserRoles={[EnumUserRole.Admin]}
                component={AdminTenantDetailsPage}
              />
            ),
          },
        ],
      },
      {
        path: '/dashboard',
        element: (
          <ProtectedRoute
            component={DashboardPage}
            allowedUserRoles={[EnumUserRole.Basic, EnumUserRole.Vendor]}
          />
        ),
      },
      {
        path: '/integrations/shopify',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={ShopifyIntegrationPage}
          />
        ),
      },
      {
        path: '/marketing/:tab?',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={MarketingPage}
          />
        ),
      },
      {
        path: '/marketing/customers/:id',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={CustomerDetailsPage}
          />
        ),
      },
      {
        path: '/orders',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={OrdersPage}
          />
        ),
      },
      {
        path: '/orders/:id',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={OrderDetailsPage}
          />
        ),
      },
      {
        path: '/orders/:id/pack-list',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={OrderPackListPage}
          />
        ),
      },
      {
        path: '/products',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={ProductsPage}
          />
        ),
      },
      {
        path: '/products/:id',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={ProductDetailsPage}
          />
        ),
      },
      {
        path: '/shipments',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={ShipmentsPage}
          />
        ),
      },
      {
        path: '/shipments/create',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={CreateShipmentPage}
          />
        ),
      },
      {
        path: '/shipments/:id',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={ShipmentDetailsPage}
          />
        ),
      },
      {
        path: '/supplies',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={SuppliesPage}
          />
        ),
      },
      {
        path: '/profile',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Basic, EnumUserRole.Vendor]}
            component={ProfilePage}
          />
        ),
      },
      {
        path: 'processor/settings/waitlist',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={Waitlist}
          />
        ),
      },
      {
        path: '/waitlist/unsubscribe/:token',
        element: <WaitlistUnsubscribe />,
      },
      {
        path: '/processor/:tab?/:subTab?',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={ProcessorAdminPage}
          />
        ),
      },
      {
        path: 'processing-job/:jobId/:tab?',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={ProcessingJob}
          />
        ),
      },
      {
        path: 'processing-job/:jobId/head/:animalHeadId?',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={AnimalHead}
          />
        ),
      },
      {
        path: 'processing-job/:jobId/head/add',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={AddAnimalHead}
          />
        ),
      },
      {
        path: 'processing-job/:jobId/head/:animalHeadId/edit-split-type',
        element: (
          <ProtectedRoute
            allowedUserRoles={[EnumUserRole.Vendor]}
            component={EditSplitType}
          />
        ),
      },
    ],
  },
]);

// render to root
root.render(
  <StrictMode>
    <RecoilRoot>
      <IntercomProvider appId={environment.intercomKey}>
        <RouterProvider router={router} />
      </IntercomProvider>
    </RecoilRoot>
  </StrictMode>,
);
