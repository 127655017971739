import { SimplePaginationTable } from '@farmshare/ui-components';
import {
  NotificationHistoryItemType,
  Nullable,
  formatFullName,
} from '@farmshare/utils';
import { startCase } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { Stack } from 'react-bootstrap';

import {
  ProcessingJobByIdQueryResult,
  ProcessorSchedulingNotificationHistory,
} from 'lib/graphql';

interface Props {
  processingJob: ProcessingJobByIdQueryResult;
}

const formatNotificationType = (
  type?: Nullable<NotificationHistoryItemType | string> | undefined,
) => {
  if (!type) {
    return '-';
  }

  switch (type) {
    case 'missingCutsheet3Day':
      return '3 day cutsheet reminder';

    case 'missingCutsheet7Day':
      return '7 day cutsheet reminder';

    default:
      return type;
  }
};

export function JobNotificationsTab({ processingJob }: Props) {
  const data = useMemo(() => {
    const notificationHistory =
      processingJob.data?.findProcessorSchedulingById?.notificationHistory ??
      [];
    // This is super annoying because the typeing that is generated says there could be nulls in this array. Which is really stupid.
    return notificationHistory as ProcessorSchedulingNotificationHistory[];
  }, [processingJob.data?.findProcessorSchedulingById?.notificationHistory]);

  return (
    <>
      <Stack direction="horizontal" className="justify-content-between">
        <div>
          <h2 className="fw-bold">Notifications</h2>
        </div>
      </Stack>
      <Stack>
        <SimplePaginationTable<ProcessorSchedulingNotificationHistory>
          columns={[
            {
              label: 'Sent To',
              field: 'sentTo',
              formatter: (row) => {
                const fullName = formatFullName(
                  row.user?.first_name ?? '',
                  row.user?.last_name,
                );
                return `${fullName} - ${row.sentTo}`;
              },
            },
            {
              label: 'Type',
              field: 'type',
              formatter: (row) => formatNotificationType(row.type),
            },
            {
              label: 'Notification Type',
              field: 'notificationType',
              formatter: (row) =>
                row.notificationType ? startCase(row.notificationType) : '-',
            },
            {
              label: 'Sent On',
              field: 'sentOn',
              formatter: (row) =>
                row.sentOn ? moment(row.sentOn).format('LLL') : '-',
            },
            {
              label: 'Error',
              field: 'errorMessage',
              formatter: (row) =>
                row.errorMessage?.message ? row.errorMessage?.message : '-',
            },
          ]}
          data={data}
          isLoading={processingJob.loading}
          defaultPerPage={10}
          enableSearchParams={false}
        />
      </Stack>
    </>
  );
}
