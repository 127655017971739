import { Button, InputText, useModal } from '@farmshare/ui-components';
import { faDollar } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { useProcessorSettingsUpdateByIdMutation } from 'lib/graphql';

interface CommercializeProcessorButtonForm {
  commercializationDate: string;
}

interface CommercializeProcessorButtonProps {
  processorSettingsId: string;
  refetch: () => Promise<unknown>;
}

export function CommercializeProcessorButton({
  processorSettingsId,
  refetch,
}: CommercializeProcessorButtonProps) {
  const { save } = useModal();

  const [updateProcessorSettings, updateProcessorSettingsOp] =
    useProcessorSettingsUpdateByIdMutation();

  return (
    <div className="text-end">
      <Button
        content="Commercialize Processor"
        size="sm"
        icon={faDollar}
        isLoading={updateProcessorSettingsOp.loading}
        onClick={() =>
          save<CommercializeProcessorButtonForm>({
            type: 'save',
            title: 'Add Vendor',
            icon: faDollar,
            initialValues: {
              commercializationDate: moment().format('YYYY-MM-DD'),
            },
            body: (
              <div>
                <InputText
                  type="date"
                  label="Commercialization Date"
                  floatingLabel
                  required
                />
              </div>
            ),
            isLoading: updateProcessorSettingsOp.loading,
            onSubmit: async ({ commercializationDate }) => {
              await updateProcessorSettings({
                variables: {
                  id: processorSettingsId,
                  record: {
                    commercialStartDate: moment(commercializationDate).toDate(),
                  },
                },
              });
              await refetch();
            },
          })
        }
      />
    </div>
  );
}
