import {
  DataDetailList,
  ExternalLink,
  PhoneDisplay,
} from '@farmshare/ui-components';
import {
  formatToCurrency,
  type Nullable,
  animalSpeciesHelper,
} from '@farmshare/utils';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import { forEach, last, map, max, min, startCase } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { Card, Stack } from 'react-bootstrap';

import {
  EnumProcessorSettingsAnimalSettingsInspectionLevels,
  EnumProcessorSettingsCapacitySettingsDailyCapacitiesType,
} from 'lib/graphql';

import { SchedulingCreateForm } from './create-scheduling-form';

interface ProcessingDetailsProps {
  animalSpecies?: string;
  butcherSlotPricing: {
    price: number;
    inspectionLevel: string | null | undefined;
  }[];
  dropOffDate?: string;
  processor: {
    email?: Nullable<string>;
    name?: Nullable<string>;
    phone?: Nullable<string>;
  };
  refundDeadlineDays: number;
  spotsRemaining: {
    total: number;
    inspectionLevels?: Partial<
      Record<EnumProcessorSettingsAnimalSettingsInspectionLevels, number>
    >;
  };
  schedulingDepositPrice: number;
  isOnBehalfOf?: boolean;
  capacityConfigurationType:
    | EnumProcessorSettingsCapacitySettingsDailyCapacitiesType
    | null
    | undefined;
}

export function ProcessingDetails({
  animalSpecies,
  butcherSlotPricing,
  dropOffDate,
  processor,
  refundDeadlineDays,
  schedulingDepositPrice,
  spotsRemaining,
  isOnBehalfOf,
  capacityConfigurationType,
}: ProcessingDetailsProps) {
  const { values } = useFormikContext<SchedulingCreateForm>();
  const dropOffDateMoment = useMemo(() => {
    if (dropOffDate) {
      return moment.utc(dropOffDate);
    }
  }, [dropOffDate]);

  const lastDayToCancel = useMemo(() => {
    if (dropOffDateMoment) {
      return dropOffDateMoment
        .clone()
        .subtract(refundDeadlineDays + 1, 'days')
        .format('L');
    }
  }, [dropOffDateMoment, refundDeadlineDays]);

  const butcherSlotPriceDispay = useMemo(() => {
    const numbers = map(butcherSlotPricing, (o) => o.price);
    const minPrice = min(numbers);
    const maxPrice = max(numbers);
    if (minPrice !== maxPrice) {
      return `${formatToCurrency(minPrice)} - ${formatToCurrency(maxPrice)}`;
    }
    return minPrice ? formatToCurrency(minPrice) : undefined;
  }, [butcherSlotPricing]);

  const processingDetailsRows = useMemo(() => {
    const rows = [
      { label: 'Processor', value: processor.name },
      {
        label: 'Animal',
        value: animalSpeciesHelper(animalSpecies || '').label,
      },
      { label: 'Drop Off Date', value: dropOffDateMoment?.format('L') },
      { label: 'Last Day to Cancel', value: lastDayToCancel },
      {
        label: 'Total Spots Remaining',
        value: spotsRemaining.total,
      },
    ];

    if (
      capacityConfigurationType ===
      EnumProcessorSettingsCapacitySettingsDailyCapacitiesType.SpeciesInspection
    ) {
      forEach(spotsRemaining.inspectionLevels, (val, key) => {
        rows.push({ label: `${startCase(key)} Spots`, value: val ?? 0 });
      });
    }
    rows.push({
      label: 'Killslot Pricing',
      value: butcherSlotPriceDispay,
    });

    if (!isOnBehalfOf) {
      rows.push({
        label: 'Deposit',
        value: formatToCurrency(
          values.scheduledHeads?.length > 0
            ? values.scheduledHeads.length * schedulingDepositPrice
            : schedulingDepositPrice,
        ),
      });
    }

    return rows;
  }, [
    animalSpecies,
    butcherSlotPriceDispay,
    dropOffDateMoment,
    isOnBehalfOf,
    lastDayToCancel,
    processor.name,
    schedulingDepositPrice,
    spotsRemaining,
    capacityConfigurationType,
    values.scheduledHeads,
  ]);

  return (
    <Card className="h-100 bg-body-secondary" body>
      <Stack gap={3}>
        <DataDetailList
          heading={<b>Processing Details</b>}
          rows={processingDetailsRows}
        />
        {!isOnBehalfOf && (
          <>
            <div className="fst-italic text-muted">
              *Killslot pricing depends on the inspection level scheduled for
              each animal. The pricing is subject to change and will be
              determined at the harvest date.
            </div>
            <div className="fst-italic text-muted">
              *For cancellations, please contact the processor using the
              information listed below. This processor requires that you notify
              them {refundDeadlineDays} days in advance for a refund on your
              deposit.
            </div>
            <hr />
            <DataDetailList
              rows={[
                {
                  // The farmshare FAQ for now. One day we might have it per processor`
                  label: 'FAQ',
                  value: <ExternalLink href={'/faq'} text="View FAQ" />,
                },
                {
                  label: 'Email',
                  value: (
                    <ExternalLink
                      href={`mailto:${processor.email}`}
                      text={processor.email || ''}
                      icon={faEnvelope}
                    />
                  ),
                },
                {
                  label: 'Phone',
                  value: (
                    <ExternalLink
                      href={`tel:${processor.phone}`}
                      text={<PhoneDisplay phone={processor.phone || ''} />}
                      icon={faPhone}
                    />
                  ),
                },
              ]}
            />
            <div className="fst-italic text-muted">
              *If you have questions check out {processor.name}
              {last(processor.name) === 's' ? "' " : "'s "}
              FAQ or contact them using the information above.
            </div>
          </>
        )}
      </Stack>
    </Card>
  );
}
