import { InfoBox, InputText } from '@farmshare/ui-components';
import { Stack } from 'react-bootstrap';

export interface OFSInitWooFormForm {
  wooApiKey?: string;
  wooApiSecret?: string;
}

export function OFSInitWooForm() {
  return (
    <Stack gap={3}>
      <InfoBox
        content={
          <span>
            Enter the WooCommerce API key and secret. You can obtain this from
            the Woo Admin. The key should have <b>read/write</b> privileges.
          </span>
        }
      />
      <InputText type="text" label="Woo API Key" floatingLabel required />
      <InputText type="text" label="Woo API Secret" floatingLabel required />
    </Stack>
  );
}
