import { Loading } from '@farmshare/ui-components';
import { useMemo } from 'react';
import { Stack } from 'react-bootstrap';

import { ProcessingJobByIdQuery } from 'lib/graphql';

import { AnimalHeadsList } from './animal-heads-list';
import { type ProcessingJobPage } from '../useProcessingJobPage';

interface Props {
  processingJob: ProcessingJobPage['processingJobQueryResult'];
}

export interface ProcessingJobHeadsSectionProps {
  processingJob: ProcessingJobByIdQuery['findProcessorSchedulingById'];
}

export function JobHeadsTab({ processingJob }: Props) {
  const isLoading = useMemo(() => processingJob.loading, [processingJob]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Stack gap={3}>
      <AnimalHeadsList
        processingJob={processingJob.data?.findProcessorSchedulingById}
        isLoading={isLoading}
      />
    </Stack>
  );
}
