import { Button } from '@farmshare/ui-components';
import { faMoneyCheckDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from '@stripe/react-connect-js';
import { useCallback, useEffect, useState } from 'react';
import { Card, Offcanvas } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { vendorState } from 'state';

import {
  useStripeAccountCreateMutation,
  useStripeSessionCreateMutation,
} from 'lib/graphql';

const statuses: Record<string, any> = {
  pending: {
    title: 'Your Stripe account is in verification pending',
    description:
      'Stripe is reviewing your information. This usually takes 24-48 hours.',
    button: 'Check Status',
    bg: 'bg-info-subtle',
  },
  incomplete: {
    title: 'Setup your Stripe Account',
    description:
      'You have not setup your Stripe Account! Please do that as soon as possible!',
    button: 'Setup',
    bg: 'bg-danger-subtle',
  },
  actionRequired: {
    title: 'Action required for your Stripe account',
    description:
      'Stripe needs additional information to complete your account setup.',
    button: 'Provide Details',
    bg: 'bg-warning-subtle',
  },
};

export default function SetupStripe() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const vendor = useRecoilValue(vendorState);
  const [stripeAccount, setStripeAccount] = useState<any>();
  const [stripeConnectInstance, setStripeConnectInstance] =
    useState<StripeConnectInstance>();
  const [mounted, setMounted] = useState(false);

  const [createStripeAccount, createStripeAccountOp] =
    useStripeAccountCreateMutation({
      variables: {
        vendorId: vendor._id,
      },
    });

  const getOrCreateStripeAccount = () => {
    createStripeAccount().then((res) => {
      console.log(res.data?.stripeAccountCreate);
      setStripeAccount(res.data?.stripeAccountCreate?.stripe_config);
      setMounted(true);
    });
  };

  useEffect(() => {
    getOrCreateStripeAccount();
  }, []);

  const [createStripeSession, createStripeSessionOp] =
    useStripeSessionCreateMutation({
      variables: {
        vendorId: vendor._id,
      },
    });

  async function handleSetup() {
    setIsDialogOpen(true);
    let createdAccount;
    if (!vendor.stripe_config?.id) {
      createdAccount = await createStripeAccount();
    }

    const fetchClientSecret = async () => {
      const createdSession = await createStripeSession();
      return createdSession.data?.stripeSessionCreate?.stripe_config?.session
        .client_secret as string;
    };

    if (
      vendor.stripe_config?.id ||
      createdAccount?.data?.stripeAccountCreate?._id
    ) {
      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey:
            'pk_test_51Kb5omCZAQl1OOa7Opo0wjJT5fUIMzcWTJ5HkK7lRLAazD20rTLsnFaVU4Bq2cFYHIlVZTMuvy3mqGT63TqDgDmW00Yzji4MD0',
          fetchClientSecret,
          appearance: {
            overlays: 'drawer',
            variables: {
              colorPrimary: '#006f35',
            },
          },
        }),
      );
    }
  }

  if (
    (stripeAccount?.id && stripeAccount?.status === 'completed') ||
    !mounted
  ) {
    return;
  }

  return (
    <>
      <Card
        className={`rounded-4 mt-4 ${statuses[stripeAccount?.status].bg}`}
        body
      >
        <div className="d-flex gap-3 align-items-center">
          <FontAwesomeIcon
            className="text-primary"
            style={{ minWidth: '55px' }}
            icon={faMoneyCheckDollar}
            size="3x"
          />
          <div className="d-flex flex-column justify-content-between text-start">
            <div className="fw-bolder fs-2">
              {statuses[stripeAccount?.status].title}
            </div>
          </div>
        </div>
        <hr />
        <div className="px-4 py-3 gap-3 bg-body rounded-4 d-flex flex-wrap justify-content-between align-items-center">
          <div>{statuses[stripeAccount?.status].description}</div>
          <Button
            className="ms-auto"
            content={statuses[stripeAccount?.status].button}
            isLoading={
              createStripeAccountOp.loading || createStripeSessionOp.loading
            }
            onClick={() => {
              handleSetup();
            }}
          ></Button>
        </div>
      </Card>
      <Offcanvas
        show={isDialogOpen}
        onHide={() => {
          setIsDialogOpen(false);
        }}
        placement="end"
        scroll={false}
        style={
          {
            '--bs-offcanvas-width': '900px',
          } as React.CSSProperties
        }
        restoreFocus={false}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {statuses[stripeAccount?.status].title}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {stripeConnectInstance && (
            <div className="rounded-4 overflow-hidden bg-white px-4 pt-1 pb-4">
              <ConnectComponentsProvider
                connectInstance={stripeConnectInstance}
              >
                <ConnectAccountOnboarding
                  onExit={() => {
                    setTimeout(() => {
                      getOrCreateStripeAccount();
                    }, 1500);
                  }}
                />
              </ConnectComponentsProvider>
            </div>
          )}
          {createStripeAccountOp.error?.message ||
            (createStripeSessionOp.error?.message && (
              <p className="text-error">Something went wrong!</p>
            ))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
