import {
  Button,
  PageTitle,
  SimplePaginationTable,
} from '@farmshare/ui-components';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';

import { downloadFromBase64Encoded } from 'lib/downloadFromBase64Encoded';
import {
  Vendor,
  useProcessorSchedulingGenerateCsvByVendorIdMutation,
  useVendorListQuery,
} from 'lib/graphql';

export default function AdminJobExportPage() {
  const [startDate, setStartDate] = useState(moment().subtract(1, 'week'));
  const [endDate, setEndDate] = useState(moment());

  const vendorQuery = useVendorListQuery({
    variables: {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    },
  });
  const [generateCsv] = useProcessorSchedulingGenerateCsvByVendorIdMutation();

  const data = useMemo(() => {
    const notificationHistory = vendorQuery.data?.vendorList ?? [];
    return notificationHistory as Vendor[];
  }, [vendorQuery.data]);

  const handleExport = async (vendorId: string) => {
    const response = await generateCsv({
      variables: {
        vendorId,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      },
    });

    downloadFromBase64Encoded(
      response.data?.processorSchedulingGenerateCsvByVendorId?.file ?? '',
      response.data?.processorSchedulingGenerateCsvByVendorId?.filename ??
        'Job_Export.csv',
    );
  };

  return (
    <Container>
      <PageTitle title="Job Export" showBreadCrumbs={false} />
      <Row className="mb-3 px-3">
        <Col md="3">
          <Form.Label className="mb-0">Start Date</Form.Label>
          <Form.Control
            type="date"
            value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
            onChange={(e) => setStartDate(moment(e.target.value))}
          />
        </Col>
        <Col md="3">
          <Form.Label className="mb-0">End Date</Form.Label>
          <Form.Control
            type="date"
            value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
            onChange={(e) => setEndDate(moment(e.target.value))}
            min={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
          />
        </Col>
      </Row>
      <SimplePaginationTable<Vendor>
        data={data}
        isLoading={vendorQuery.loading}
        columns={[
          {
            label: 'Vendor',
            field: 'shop_name',
          },
          {
            label: 'Tenant',
            field: 'tenant.name',
          },
          {
            formatter: (row) => (
              <Button
                content="Export CSV"
                icon={faFileDownload}
                size="sm"
                onClick={() => handleExport(row._id)}
              />
            ),
          },
        ]}
        hideButtons
      />
    </Container>
  );
}
