import {
  ErrorMessage,
  Field,
  useFormikContext,
  type FieldAttributes,
} from 'formik';
import { camelCase, isObject, isString } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { FloatingLabel, Form, type FormControlProps } from 'react-bootstrap';

import { InputComponent } from '.';

export type InputSelectOption =
  | {
      value: string | number;
      label: string;
      disabled?: boolean;
      group?: string;
    }
  | string
  | number;

export interface InputSelectProps extends InputComponent {
  options?: InputSelectOption[];
}

export function InputSelect({
  label,
  options,
  disabled,
  hint,
  required,
  nameOveride,
  className,
  hidden,
  floatingLabel = false,
  readOnly = false,
}: InputSelectProps) {
  const { setFieldValue, errors } = useFormikContext<Record<string, string>>();

  const validate = useCallback(
    (value: string | number | undefined) => {
      if (required && (!value || value === undefined)) {
        return `${label} is required.`;
      }
    },
    [label, required],
  );

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  const fieldProps: FieldAttributes<FormControlProps> = useMemo(
    () => ({
      as: Form.Select,
      placeholder: label?.replace(/[?:]/g, ''),
      isInvalid: !!errors[name],
      // plaintext: !!readOnly,
      name,
      className,
      hidden,
      disabled,
      validate,
      required,
    }),
    [className, disabled, errors, hidden, label, name, required, validate],
  );

  const _options = useMemo(() => {
    // If there is only one option, set it as default
    if (options?.length === 1) {
      return renderOption(options[0], 0);
    } else {
      return (
        <>
          <option defaultValue={undefined}>Select one...</option>

          {options?.every((o) => typeof o === 'object') &&
          options?.some((o) => !!o.group)
            ? Object.entries(
                options?.reduce(
                  (acc, option) => {
                    if (typeof option === 'object' && option.group) {
                      // If the option has a group, place it in that group
                      acc[option.group] = acc[option.group] || [];
                      acc[option.group].push(option);
                    } else {
                      // Otherwise, it's a single option without a group
                      acc['ungrouped'] = acc['ungrouped'] || [];
                      acc['ungrouped'].push(option);
                    }
                    return acc;
                  },
                  {} as Record<string, InputSelectOption[]>,
                ),
              ).map(([group, groupOptions]) =>
                group === 'ungrouped' ? (
                  groupOptions.map((option, idx) => renderOption(option, idx))
                ) : (
                  <optgroup key={group} label={group}>
                    {groupOptions.map((option, idx) =>
                      renderOption(option, idx),
                    )}
                  </optgroup>
                ),
              )
            : options?.map((option, idx) => renderOption(option, idx))}
        </>
      );
    }
  }, [options]);

  function renderOption(option: InputSelectOption, idx: number): JSX.Element {
    return isObject(option) ? (
      <option key={idx} value={option.value} disabled={option.disabled}>
        {option.label}
      </option>
    ) : (
      <option key={idx} value={isString(option) ? camelCase(option) : option}>
        {option}
      </option>
    );
  }

  useEffect(() => {
    if (options?.length === 1) {
      const opt = options[0];
      setFieldValue(name, typeof opt === 'object' ? opt.value : opt);
    }
  }, [options, name, setFieldValue]);

  return readOnly ? (
    <>
      <Form.Label className="fw-light">
        {label}:
        {fieldProps.required && (
          <sup className="text-danger fw-bold">&nbsp;*</sup>
        )}
      </Form.Label>
      <Field {...fieldProps} as={Form.Control} />
    </>
  ) : (
    <Form.Group controlId={`form.${name}`} className={className}>
      {floatingLabel ? (
        <FloatingLabel
          controlId={`floatingInput.${name}`}
          label={
            <>
              {label}
              {fieldProps.required && (
                <sup className="text-danger fw-bold">&nbsp;*</sup>
              )}
            </>
          }
        >
          <Field {...fieldProps}>{_options}</Field>
        </FloatingLabel>
      ) : (
        <div>
          {label && (
            <Form.Label>
              {label}:
              {required && <sup className="text-danger fw-bold">&nbsp;*</sup>}
            </Form.Label>
          )}
          <Field {...fieldProps}>{_options}</Field>
        </div>
      )}
      {hint && <Form.Text>{hint}</Form.Text>}
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <Form.Control.Feedback
            type="invalid"
            // Since we are not using bootstrap Form elements but formik we should force this to display the error message
            style={{ display: 'block' }}
          >
            {msg}
          </Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
}
