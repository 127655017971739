import { useAuth0 } from '@auth0/auth0-react';
import {
  InputSelect,
  Loading,
  SiteFooter,
  SiteHeader,
  useModal,
} from '@farmshare/ui-components';
import { faRepeat, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { environment } from 'environments/environment';
import { find, first, map, some, sortBy } from 'lodash';
import {
  ReactElement,
  Suspense,
  cloneElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import {
  EnumUserRole,
  type User,
  type Vendor,
  useUserTenantVendorLazyQuery,
  useUserUpdateActiveVendorMutation,
} from 'lib/graphql';

import logo from './assets/images/logo-roca-green.svg';
import whiteLogo from './assets/images/logo-roca-white.svg';
import { themeState, userState, vendorState } from './state';

export function App() {
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();

  const { save } = useModal();

  const [theme, setTheme] = useRecoilState(themeState);
  const [user, setUserState] = useRecoilState(userState);
  const [vendor, setVendor] = useRecoilState(vendorState);
  const [allVendors, setAllVendors] = useState<Vendor[]>([]);

  const [search] = useSearchParams();

  const [getUser, getUserOperation] = useUserTenantVendorLazyQuery();
  const [updateUserActiveVendor] = useUserUpdateActiveVendorMutation();

  useEffect(() => {
    if (isAuthenticated) {
      getUser();
    }
  }, [getUser, isAuthenticated]);

  useEffect(() => {
    if (getUserOperation.data?.userTenantVendor) {
      let localVendor: Vendor;
      const localUser = getUserOperation.data.userTenantVendor as User;

      if (getUserOperation.data.userTenantVendor.active_vendor) {
        localVendor = getUserOperation.data.userTenantVendor
          .active_vendor as Vendor;
      } else {
        localVendor = first(
          getUserOperation.data.userTenantVendor.tenant?.vendors,
        ) as Vendor;
      }

      if (localUser && localUser._id !== user?._id) {
        setUserState(localUser);
      }

      if (localVendor && localVendor._id !== vendor?._id) {
        setVendor(localVendor as Vendor);
      }
    }
  }, [
    getUserOperation.data?.userTenantVendor,
    setUserState,
    setVendor,
    vendor,
    user,
  ]);

  useEffect(() => {
    if (search.has('error')) {
      console.error('[Auth Error]', search.get('error_description'));
      logout();
    }
  }, [logout, search]);

  const dashboardNavLinks = useMemo(() => {
    const _links: {
      label: string;
      href?: string;
      dropDownItems?: {
        label: string;
        href: string;
      }[];
    }[] = [];

    // Only non-processor users will see the scheduling link
    if (
      !some(
        getUserOperation.data?.userTenantVendor?.tenant?.modules,
        (m) => m.name === 'processor',
      )
    ) {
      _links.push({ label: 'Scheduling', href: '/scheduling' });
      if (user?.role === EnumUserRole.Basic) {
        _links.push({ label: 'Chat', href: '/chat' });
      }
    }

    if (getUserOperation.data?.userTenantVendor?.tenant?.modules) {
      _links.push(
        { label: 'Agenda', href: '/processor/agenda' },
        {
          label: 'Calendar',
          href: '/processor/calendar',
        },
        {
          label: 'Customers',
          dropDownItems: [
            { label: 'All Customers', href: '/processor/customers' },
            { label: 'Chat', href: '/processor/chat' },
            { label: 'Waitlist', href: '/processor/settings/waitlist' },
          ],
        },
        {
          label: 'Jobs',
          dropDownItems: [
            { label: 'All Jobs', href: '/processor/jobs' },
            { label: 'Add Job', href: '/processor/add-job' },
          ],
        },
        {
          label: 'Settings',
          dropDownItems: [
            { label: 'Processor Settings', href: '/processor/settings' },
            { label: 'Capabilities', href: '/processor/capabilities' },
            { label: 'Cutsheets', href: '/processor/cutsheets' },
          ],
        },
      );
      const adminModule = find(
        getUserOperation.data.userTenantVendor.tenant.modules,
        (m) => m.label === 'Admin',
      );
      if (adminModule) {
        _links.push({ label: adminModule.label, href: adminModule.href });
      }
    }

    return _links;
  }, [getUserOperation.data?.userTenantVendor, user]);

  if (
    allVendors.length === 0 &&
    (getUserOperation.data?.userTenantVendor?.tenant?.vendors?.length || 0) > 1
  ) {
    setAllVendors(
      getUserOperation.data?.userTenantVendor?.tenant?.vendors as Vendor[],
    );
  }

  const dropdownItems = useMemo(() => {
    const items: ReactElement[] = [
      <Dropdown.Header>Settings</Dropdown.Header>,
      <Dropdown.Item as={Link} to="/profile">
        <FontAwesomeIcon icon={faUser} className="me-2" />
        Profile
      </Dropdown.Item>,
    ];

    if ((allVendors.length || 0) > 1) {
      items.push(
        <Dropdown.Item
          className="switch-vendor"
          onClick={() => {
            save<{ vendor: string }>({
              type: 'save',
              title: 'Switch Vendor',
              icon: faRepeat,
              initialValues: { vendor: vendor?._id },
              saveIcon: faRepeat,
              saveText: 'Switch',
              body: (
                <div>
                  <InputSelect
                    label="Vendor"
                    options={map(
                      sortBy(allVendors, (v) => v.shop_name),
                      (v) => ({ label: v.shop_name, value: v._id }),
                    )}
                    floatingLabel
                    required
                  />
                </div>
              ),
              onSubmit: (values) => {
                const vendor = find(allVendors, (v) => v._id === values.vendor);

                if (vendor) {
                  updateUserActiveVendor({
                    variables: {
                      userId: user._id,
                      active_vendor: values.vendor,
                    },
                  });
                  setVendor(vendor as Vendor);
                  window.location.reload();
                }
              },
            });
          }}
        >
          <FontAwesomeIcon icon={faRepeat} className="me-2" />
          Switch Vendor
        </Dropdown.Item>,
      );
    }

    items.push(<Dropdown.Divider />);

    // add on "key" prop for array rendering
    return map(items, (item, key) => cloneElement(item, { key }));
  }, [
    allVendors,
    save,
    setVendor,
    updateUserActiveVendor,
    user?._id,
    vendor?._id,
  ]);

  if (isLoading || getUserOperation.loading) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <SiteHeader<User>
        user={user}
        isProduction={environment.production}
        title="Partners"
        navLinks={dashboardNavLinks}
        loginFn={loginWithRedirect}
        logoutFn={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
        // TODO PPPP type of user will matter here so we can display correct logo for processor scheduling only user
        logo={
          <img
            src={!user ? whiteLogo : logo}
            height={39}
            alt={`farmshare ${!user && 'partners'} logo`}
            className="mt-1 text-body"
          />
        }
        dropdownItems={dropdownItems}
        vendorName={vendor?.shop_name}
      />
      <Outlet />
      <div style={{ height: 50 }} /> {/** adding bottom "padding" to site */}
      <SiteFooter theme={theme} setTheme={setTheme} />
    </Suspense>
  );
}
