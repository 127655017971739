import { Button, Loading } from '@farmshare/ui-components';
import { map } from 'lodash';
import { useMemo } from 'react';
import { Col, Row, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {
  AnimalHeadFragment,
  ProcessingJobByIdDocument,
  ProcessingJobByIdQuery,
  useProcessorSchedulingAnimalHeadPartialUpdateByIdMutation,
} from 'lib/graphql';

import { AnimalHeadCutsheetInformation } from './animal-head-cutsheet-information';
import { type ProcessingJobPage } from '../useProcessingJobPage';

interface Props {
  processingJob: ProcessingJobPage['processingJobQueryResult'];
  updateProcessingJob: ProcessingJobPage['updateProcessingJob'];
  updateProcessingJobOp: ProcessingJobPage['updateProcessingJobOp'];
}

export interface ProcessingJobDetailsSectionProps {
  processingJob: ProcessingJobByIdQuery['findProcessorSchedulingById'];
  updateProcessingJob: ProcessingJobPage['updateProcessingJob'];
  updateProcessingJobOp: ProcessingJobPage['updateProcessingJobOp'];
}

export function CutsheetsTab({ processingJob }: Props) {
  const navigate = useNavigate();
  const isLoading = useMemo(() => processingJob.loading, [processingJob]);

  const [updateAnimalHead, updateAnimalHeadOp] =
    useProcessorSchedulingAnimalHeadPartialUpdateByIdMutation({
      refetchQueries: [
        {
          query: ProcessingJobByIdDocument,
          variables: {
            jobId: processingJob.data?.findProcessorSchedulingById?._id,
            includeNotificationHistory: true,
            includeProcessorSettings: true,
          },
        },
      ],
    });

  if (isLoading) {
    return <Loading />;
  }

  const atLeastOneHeadHasASplit =
    processingJob.data?.findProcessorSchedulingById?.animalHeads?.some(
      (animalHead: AnimalHeadFragment | null) => {
        return !!animalHead?.splitType;
      },
    );

  if (!atLeastOneHeadHasASplit) {
    return (
      <Row>
        <Col className="alert alert-danger">
          None of the heads on this job have a split selected. Jump over to
          heads and do that now!
        </Col>
      </Row>
    );
  }

  return (
    <>
      <Button
        href={`/scheduling/${processingJob.data?.findProcessorSchedulingById?._id}/cutsheets`}
        content="Edit All Cutsheets"
        className="edit-all-cutsheets"
        style={{
          height: 32,
          position: 'absolute',
          top: 0,
          right: 'calc(var(--bs-gutter-x)* 0.5)',
        }}
      />
      <Stack gap={3}>
        <Row className="g-4">
          {map(
            processingJob.data?.findProcessorSchedulingById?.animalHeads,
            (animalHead, idx) => {
              return map(animalHead?.cutsheetInformation, (ci, ind) => {
                return (
                  <Col lg={6} key={ci?._id}>
                    <AnimalHeadCutsheetInformation
                      processingJob={
                        processingJob.data?.findProcessorSchedulingById
                      }
                      pageType="cutsheets-tab"
                      cutsheetInformationIdx={ind}
                      animalHeadIdx={idx}
                      animalHead={animalHead}
                      cutsheetInformation={ci}
                      processingJobId={
                        processingJob.data?.findProcessorSchedulingById?._id
                      }
                      updateAnimalHead={updateAnimalHead}
                      updateAnimalHeadOp={updateAnimalHeadOp}
                      onEditCutsheetClicked={() => {
                        navigate(
                          `/scheduling/${processingJob.data?.findProcessorSchedulingById?._id}/cutsheets?cutsheetInformationId=${ci?._id}&animalHeadId=${animalHead?._id}`,
                        );
                      }}
                    />
                  </Col>
                );
              });
            },
          )}
        </Row>
      </Stack>
    </>
  );
}
