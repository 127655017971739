import { InputSelect, InputText } from '@farmshare/ui-components';
import { useFormikContext } from 'formik';
import { find, map } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Stack } from 'react-bootstrap';

import type { Vendor } from 'lib/graphql';

export interface OFSModalForm {
  vendor: string;
  ofsName: string;
}

interface OFSModalProps {
  vendors?: Pick<Vendor, 'shop_name' | 'address' | '_id'>[];
}

export function OFSModal(props: OFSModalProps) {
  const { setFieldValue, values } = useFormikContext<OFSModalForm>();

  const vendor = useMemo(() => {
    if (values.vendor) {
      return find(props.vendors, { _id: values.vendor });
    }
    return undefined;
  }, [props.vendors, values.vendor]);

  useEffect(() => {
    if (vendor) {
      setFieldValue('ofsName', vendor.shop_name);
    }
  }, [setFieldValue, values.ofsName, vendor]);

  return (
    <Stack gap={3} className="mb-md-2">
      <InputSelect
        label="Vendor"
        options={map(props.vendors, (v) => ({
          label: v.shop_name,
          value: v._id,
        }))}
        hint="Select the vendor that will be associated with this OFS."
        floatingLabel
        required
      />
      <InputText
        label="OFS Name"
        type="text"
        hint="The name of the OFS."
        floatingLabel
        required
      />
    </Stack>
  );
}
