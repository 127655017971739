import { InputText, InputTextArea, InputCheck } from '@farmshare/ui-components';
import { useFormikContext } from 'formik';
import { map } from 'lodash';
import { Col, Row, Stack } from 'react-bootstrap';

import { ProcessorScheduling } from 'lib/graphql';
import { processingJobAnimalHeadLabel } from 'lib/processingJobUtils';

export interface AnimalHeadGenerateInvoiceForm {
  _id?: string;
  additionalCost?: number;
  additionalCostReason?: string;
}
export interface GenerateInvoiceModalFormProps {
  animalHeads: AnimalHeadGenerateInvoiceForm[];
  isPayAtPickup: boolean;
  additionalPickupDetails?: string;
}

const HeadTracking = ({
  ahidx,
  heading,
}: {
  ahidx: number;
  heading: string;
}) => {
  return (
    <Stack gap={2}>
      <h5 className="fw-bold">{heading}</h5>
      <Row>
        <Col>
          <InputText
            type="number"
            min={0}
            nameOveride={`animalHeads.${ahidx}.additionalCost`}
            label="Additional Cost"
            step={0.01}
            placeholder="$0.00"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InputTextArea
            label="Reason"
            nameOveride={`animalHeads.${ahidx}.additionalCostReason`}
            showLabel
            className="mt-3"
            rows={3}
          />
        </Col>
      </Row>
      <hr />
    </Stack>
  );
};

export const GenerateInvoiceModal = ({
  scheduledJob,
}: {
  scheduledJob: ProcessorScheduling;
}) => {
  const { values } = useFormikContext<GenerateInvoiceModalFormProps>();

  return (
    <Stack gap={2}>
      <InputCheck
        label="Will the user pay in cash at pickup?"
        nameOveride="isPayAtPickup"
        type="checkbox"
      />
      <hr />
      {map(values.animalHeads, (animalHead, idx) => {
        return (
          <HeadTracking
            key={idx}
            ahidx={idx}
            heading={processingJobAnimalHeadLabel({
              producerIdentifier:
                scheduledJob?.animalHeads?.[idx]?.producerIdentifier,
              animalSpecies: scheduledJob.animalSpecies,
              inspectionLevel:
                scheduledJob?.animalHeads?.[idx]?.inspectionLevel ?? undefined,
              requestedBy: {
                first_name: scheduledJob.requestedBy?.first_name,
                last_name: scheduledJob.requestedBy?.last_name,
              },
              animalNumber: idx + 1,
            })}
          />
        );
      })}

      <InputTextArea
        label="Add any additional details you would like the customer to know"
        nameOveride="additionalPickupDetails"
        showLabel
        rows={3}
      />
    </Stack>
  );
};
