import {
  Button,
  Form,
  InputSelectOption,
  Loading,
  PageTitle,
  useToastr,
} from '@farmshare/ui-components';
import { animalSplitTypeHelper, inspectionLevelHelper } from '@farmshare/utils';
import { faCheck, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { forEach, map, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Container, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import {
  AnimalHeadInput,
  CutsheetInformationInput,
  EnumProcessorSchedulingAnimalSpecies,
} from 'lib/graphql';
import { isAnimalBeef } from 'lib/processingJobUtils';

import { useProcessingJobPage } from './useProcessingJobPage';
import {
  AddAnimalHeadForm,
  AnimalHead,
} from '../scheduling/_views/add-animal-head-form';

interface AddAnimalHeadFormData {
  animalHeads: AnimalHead[];
}

export function AddAnimalHead() {
  const navigate = useNavigate();
  const { push } = useToastr();
  const {
    updateProcessingJob,
    updateProcessingJobOp,
    processingJobDisplayName,
    processingJobQueryResult,
    jobId,
  } = useProcessingJobPage();

  const enabledAnimalSplitOptions = useMemo(() => {
    // find the animalOptions for the species
    const processorAnimalSettings =
      processingJobQueryResult.data?.findProcessorSchedulingById
        ?.processorSettings?.animalSettings ?? [];
    // lodash is returning the wrong type inference so I switched to find
    const animalSetting = processorAnimalSettings.find(
      (o) =>
        o?.species ===
        processingJobQueryResult?.data?.findProcessorSchedulingById
          ?.animalSpecies,
    );
    const options: InputSelectOption[] = [];
    forEach(animalSetting?.splitTypes ?? [], (split) => {
      if (split) {
        options.push(animalSplitTypeHelper(split));
      }
    });
    return sortBy(options, 'order');
  }, [
    processingJobQueryResult.data?.findProcessorSchedulingById
      ?.processorSettings?.animalSettings,
    processingJobQueryResult.data?.findProcessorSchedulingById?.animalSpecies,
  ]);

  const enabledAnimalInspectionLevelOptions = useMemo(() => {
    // find the animalOptions for the species
    const processorAnimalSettings =
      processingJobQueryResult.data?.findProcessorSchedulingById
        ?.processorSettings?.animalSettings ?? [];
    // lodash is returning the wrong type inference so I switched to find
    const animalSetting = processorAnimalSettings.find(
      (o) =>
        o?.species ===
        processingJobQueryResult?.data?.findProcessorSchedulingById
          ?.animalSpecies,
    );
    // turn these into options
    const options: InputSelectOption[] = [];
    if (
      processingJobQueryResult?.data?.findProcessorSchedulingById
        ?.processorSettings?.animalSettings?.[0]?.inspectionLevels
    )
      forEach(animalSetting?.inspectionLevels ?? [], (inspectionLevel) => {
        if (inspectionLevel) {
          options.push(inspectionLevelHelper(inspectionLevel));
        }
      });

    return sortBy(options, 'order');
  }, [
    processingJobQueryResult.data?.findProcessorSchedulingById
      ?.processorSettings?.animalSettings,
    processingJobQueryResult.data?.findProcessorSchedulingById?.animalSpecies,
  ]);

  const handleCancel = useCallback(() => navigate(-1), [navigate]);

  if (processingJobQueryResult.loading) {
    return <Loading />;
  }

  return (
    <Container className="pt-3">
      <PageTitle
        title={'Add Animal Heads'}
        showHr={false}
        // TODO only show this if they are the processor.
        // Other users will need to go elsewhere.
        innerBreadcrumbs={[
          { text: 'Bookings', to: '/processor/agenda' },
          { text: processingJobDisplayName, to: `/processing-job/${jobId}` },
        ]}
      />
      <Form<
        AddAnimalHeadFormData & {
          requesterContactInformation: {
            firstName?: string;
            lastName?: string;
            phone?: string;
            email?: string;
          };
        }
      >
        initialValues={{
          animalHeads: [],
          requesterContactInformation: {
            firstName:
              processingJobQueryResult.data?.findProcessorSchedulingById
                ?.requestedBy?.first_name,
            lastName:
              processingJobQueryResult.data?.findProcessorSchedulingById
                ?.requestedBy?.last_name ?? undefined,
            phone:
              processingJobQueryResult.data?.findProcessorSchedulingById
                ?.requestedBy?.phone ?? undefined,
            email:
              processingJobQueryResult.data?.findProcessorSchedulingById
                ?.requestedBy?.email ?? undefined,
          },
        }}
        onSubmit={async (values) => {
          const animalIsTypeBeef = isAnimalBeef(
            processingJobQueryResult.data?.findProcessorSchedulingById
              ?.animalSpecies as EnumProcessorSchedulingAnimalSpecies,
          );
          const newAnimalHeads: AnimalHeadInput[] = map(
            values.animalHeads,
            (scheduledHead) => {
              const cutsheetInformation: CutsheetInformationInput[] = map(
                scheduledHead.contacts,
                (contact) => {
                  return {
                    splitPart: contact.splitPart,
                    quantity: 1,
                    cutsheet: contact.cutsheet?.id,
                    contactInformation: {
                      firstName: contact.firstName,
                      lastName: contact.lastName,
                      email: contact.email,
                      phone: contact.phone,
                    },
                  };
                },
              );
              return {
                splitType: scheduledHead.splitType,
                producerIdentifier: scheduledHead.producerIdentifier,
                inspectionLevel: scheduledHead.inspectionLevel,
                sex: scheduledHead.sex,
                isOverThirtyMonths: animalIsTypeBeef
                  ? scheduledHead.isOverThirtyMonths ?? false
                  : undefined,
                cutsheetInformation,
              };
            },
          );
          try {
            await updateProcessingJob({
              variables: {
                processorSchedulingId: jobId,
                record: {
                  addAnimalHeads: newAnimalHeads,
                },
              },
            });
            push({
              title: 'Processing Job Updated',
              body: `A new animal head has been added to the processing job.`,
              bg: 'success',
              delay: 4000,
            });

            navigate(-1);
          } catch (error) {
            push({
              title: 'Error',
              body: (error as string).toString(),
              bg: 'danger',
              delay: 4000,
            });
          }
        }}
      >
        <Stack gap={3}>
          <AddAnimalHeadForm
            enabledAnimalSplitOptions={enabledAnimalSplitOptions}
            enabledAnimalInspectionLevelOptions={
              enabledAnimalInspectionLevelOptions
            }
            isActive={true}
            isOnBehalfOf={true}
            name="animalHeads"
            scheduledHeadsIdx={0}
            isSingle
            animalSpecies={
              processingJobQueryResult.data?.findProcessorSchedulingById
                ?.animalSpecies
            }
          />

          <Stack
            direction="horizontal"
            gap={1}
            className="justify-content-end mb-3"
          >
            <Button
              disabled={updateProcessingJobOp.loading}
              variant="ghost"
              icon={faXmarkCircle}
              onClick={handleCancel}
              content="Cancel"
            />
            <Button
              isLoading={updateProcessingJobOp.loading}
              disabled={updateProcessingJobOp.loading}
              type="submit"
              content={'Add'}
              icon={faCheck}
              variant="primary"
            />
          </Stack>
        </Stack>
      </Form>
    </Container>
  );
}
