import {
  PageTitle,
  Button,
  useModal,
  Loading,
  useToastr,
} from '@farmshare/ui-components';
import { formatPhoneNumber, capitalize } from '@farmshare/utils';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { map, set } from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { Container, Row, Table } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { vendorState } from 'state';

import {
  useWaitlistByVendorIdQuery,
  useWaitlistAddMutation,
  useWaitlistDeleteMutation,
  WaitlistByVendorIdDocument,
  useViewProcessingPartnerQuery,
  EnumProcessorSchedulingAnimalSpecies,
} from 'lib/graphql';

import {
  WaitlistFormData,
  WaitListModal,
} from 'pages/scheduling/_views/waitlist-modal';

export function Waitlist() {
  const { save, ask } = useModal();
  const { push } = useToastr();
  const [vendor] = useRecoilState(vendorState);

  const { data: processorData, loading: processorDataLoading } =
    useViewProcessingPartnerQuery({
      variables: {
        vendorId: vendor?._id,
      },
    });

  const { data: waitListData, loading: waitlistDataLoading } =
    useWaitlistByVendorIdQuery({
      variables: {
        vendorId: vendor?._id,
      },
    });
  const [waitListAdd, waitListAddOp] = useWaitlistAddMutation({
    refetchQueries: [
      {
        query: WaitlistByVendorIdDocument,
        variables: {
          vendorId: vendor?._id,
        },
      },
    ],
  });
  const [waitListDelete, waitListDeleteOp] = useWaitlistDeleteMutation({
    refetchQueries: [
      {
        query: WaitlistByVendorIdDocument,
        variables: {
          vendorId: vendor?._id,
        },
      },
    ],
  });

  const handleDeleteCustomer = useCallback(
    (customerId: string) => {
      return ask({
        type: 'ask',
        title: 'Confirm Deletion',
        body: 'Are you sure you want to delete this customer from the waitlist? Once you delete it you will not be able to restore it.',
        yesText: 'Delete',
        yesIcon: faTrash,
        onConfirm: async () => {
          await waitListDelete({
            variables: {
              customerId,
            },
          });

          push({
            title: 'Waitlist Updated',
            body: `Customer deleted from waitlist`,
            bg: 'success',
            delay: 4000,
          });
        },
      });
    },
    [ask, push, waitListDelete],
  );

  if (!vendor || processorDataLoading || waitlistDataLoading) {
    return <Loading />;
  }

  if (processorData?.processingPartnersOne?.isWaitlistFunctionalityDisabled) {
    return (
      <Container className="pt-3">
        <PageTitle
          title={'View Waitlist'}
          showHr={false}
          innerBreadcrumbs={[{ text: 'Settings', to: '/processor/settings' }]}
        />
        <p className="ps-2 mb-0 fs-5 ">
          Waitlist functionality is disabled for this vendor
        </p>
      </Container>
    );
  }

  return (
    <Container className="pt-3">
      <PageTitle
        title={'View Waitlist'}
        showHr={false}
        innerBreadcrumbs={[{ text: 'Settings', to: '/processor/settings' }]}
      />
      <p className="ps-2 mb-0 fs-5 ">
        These contacts will be notified as appointment become available
      </p>
      <hr />
      <div className="d-flex justify-content-end">
        <Button
          content="Add to Waitlist"
          variant="primary"
          icon={faPlus}
          disabled={waitListAddOp.loading || waitListDeleteOp.loading}
          onClick={() => {
            save<WaitlistFormData>({
              backdrop: 'static',
              type: 'save',
              size: 'lg',
              title: (
                <Row>
                  <div>
                    <h4 className="fw-bold mb-0">Join Waitlist</h4>
                    <p className="text-secondary mb-0 fs-5">
                      The customer will be notified as appointment become
                      available
                    </p>
                  </div>
                </Row>
              ),
              cancelText: 'Close',
              body: <WaitListModal />,
              initialValues: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                company: '',
                animal: '',
                dates: {
                  start: moment().format('YYYY-MM-DD'),
                  end: moment().add(6, 'days').format('YYYY-MM-DD'),
                },
              },
              validate: (values) => {
                const errors: Partial<WaitlistFormData> = {};

                if (!values.email && !values.phone) {
                  set(
                    errors,
                    'email',
                    'Please provide either a phone or email.',
                  );
                  set(
                    errors,
                    'phone',
                    'Please provide either a phone or email.',
                  );
                }

                return errors;
              },
              async onSubmit(values) {
                try {
                  await waitListAdd({
                    variables: {
                      vendorId: vendor?._id,
                      ...values,
                      sendWaitlistEmail: !!(
                        values.email && values.email.trim().length > 0
                      ),
                      sendWaitlistSMS: !!(
                        values.phone && values.phone.trim().length > 0
                      ),
                      animal:
                        values.animal as EnumProcessorSchedulingAnimalSpecies,
                      startDate: moment(values.dates.start).format('M/DD/YYYY'),
                      endDate: moment(values.dates.end).format('M/DD/YYYY'),
                    },
                  });
                  push({
                    title: 'Success',
                    body: 'Customer added to waitlist',
                    bg: 'success',
                    delay: 4000,
                  });
                } catch (error) {
                  push({
                    title: 'Error',
                    body: (error as string).toString(),
                    bg: 'danger',
                    delay: 4000,
                  });
                }
              },
            });
          }}
        />
      </div>
      <Table className="mb-0" borderless responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Company/Farm</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Animal</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {waitListData?.waitlistByVendorId?.length === 0 ? (
            <tr>
              <td colSpan={7} className="text-center">
                No contacts on waitlist
              </td>
            </tr>
          ) : (
            map(waitListData?.waitlistByVendorId, (customer) => (
              <tr key={customer?._id}>
                <td>{`${customer?.firstName} ${customer?.lastName}`}</td>
                <td>{customer?.company || '--'}</td>
                <td>{formatPhoneNumber(customer?.phone || '--')}</td>
                <td>{customer?.email || '--'}</td>
                <td>{capitalize(customer?.animal as string)}</td>
                <td>{moment.utc(customer?.startDate).format('MM/DD/YYYY')}</td>
                <td>{moment.utc(customer?.endDate).format('MM/DD/YYYY')}</td>
                <td style={{ width: '1%' }}>
                  <Button
                    content="Delete"
                    variant="danger"
                    size="sm"
                    icon={faTrash}
                    disabled={waitListAddOp.loading || waitListDeleteOp.loading}
                    onClick={() => {
                      handleDeleteCustomer(customer?._id);
                    }}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Container>
  );
}
