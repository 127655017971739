import { filter, map } from 'lodash';
import moment from 'moment';

import {
  EnumProcessorSchedulingStatus,
  FilterFindManyProcessorSchedulingInput,
  User,
  Vendor,
} from 'lib/graphql';

export const shownStatuses = [
  EnumProcessorSchedulingStatus.Scheduled,
  EnumProcessorSchedulingStatus.DroppedOff,
  EnumProcessorSchedulingStatus.Killed,
  EnumProcessorSchedulingStatus.Aging,
  EnumProcessorSchedulingStatus.Curing,
  EnumProcessorSchedulingStatus.Invoicing,
  EnumProcessorSchedulingStatus.ReadyPayAtPickup,
  EnumProcessorSchedulingStatus.InvoicePaid,
];

export const completedStatuses = [
  EnumProcessorSchedulingStatus.CancelledByProcessor,
  EnumProcessorSchedulingStatus.CancelledByProducer,
  EnumProcessorSchedulingStatus.Completed,
  EnumProcessorSchedulingStatus.DeniedByProcessor,
];

type FilterArgs = {
  vendorId: Vendor['_id'];
  customerId?: User['_id'];
};

export function getProcessorSchedulingQueryFilters(
  filters: FilterArgs,
): Record<string, FilterFindManyProcessorSchedulingInput> {
  const f = {
    vendor: filters.vendorId,
    ...(filters.customerId ? { requestedBy: filters.customerId } : {}),
  };
  return {
    pendingApprovals: {
      ...f,
      isDeleted: false,
      status: EnumProcessorSchedulingStatus.Requested,
    },
    dropoffAlerts: {
      ...f,
      isDeleted: false,
      status: EnumProcessorSchedulingStatus.Scheduled,
      _operators: {
        dropoffDate: {
          lte: moment.utc().startOf('day').toISOString(),
        },
      },
    },
    missingCutsheets: {
      ...f,
      isDeleted: false,
      AND: [
        {
          animalHeads: [
            {
              cutsheetInformation: [],
            },
          ],
        },
        {
          animalHeads: [
            {
              cutsheetInformation: [{ cutsheet: null }],
            },
          ],
        },
      ],
    },
    notifications: {
      ...f,
    },
    processorScheduling: {
      ...f,
      isDeleted: false,
    },
    bookingsInProgress: {
      ...f,
      isDeleted: false,
      OR: map(
        filter(
          shownStatuses,
          (s) =>
            (s as EnumProcessorSchedulingStatus) !==
            EnumProcessorSchedulingStatus.Completed,
        ),
        (s: string) => ({
          status: s as EnumProcessorSchedulingStatus,
        }),
      ),
    },
    bookingsCompleted: {
      ...f,
      isDeleted: false,
      OR: map(completedStatuses, (s) => ({
        status: s as EnumProcessorSchedulingStatus,
      })),
    },
  };
}
