import { InputChipOption, InputQuantity } from '@farmshare/ui-components';
import { useFormikContext } from 'formik';
import { findIndex, flatMap, get, map, sortBy } from 'lodash';
import { Card, Col, Stack } from 'react-bootstrap';

import {
  EnumProcessorSettingsAnimalSettingsSpecies,
  EnumProcessorSettingsCapacityConfigurationType,
} from 'lib/graphql';

import { type SettingsFormData } from './settings-form';

interface CapacityBlockProps {
  title: string;
  currentCapacityConfigurationType: string;
  dailyCapacitySetting?: {
    dayOfWeek?: string;
    isEnabled?: boolean;
    dailyCapacities?: any[];
  };
  parentBaseName: string;
  // Used for sorting the custom capacity days
  day?: string;
}

interface RowItemProps {
  label: string;
  name: string;
  className?: string;
}

const RowItem = ({ label, name, className }: RowItemProps) => {
  return (
    <Stack
      direction="horizontal"
      className={`justify-content-between py-1 px-3`}
    >
      <b>{label}</b>
      <InputQuantity
        nameOveride={name}
        min={0}
        max={10000}
        step={1}
        showEdit={true}
        className={className}
      />
    </Stack>
  );
};

const SpeciesConfigurationBlock = ({
  animalSpecies,
  baseName,
  values,
}: {
  animalSpecies: InputChipOption[];
  baseName: string;
  capacities: any;
  values: any;
}) => {
  return map(sortBy(animalSpecies, 'sortValue'), (species, idx) => {
    const label = species.label;
    const capacityIdx = findIndex(
      get(values, `${baseName}.capacities`),
      (o: any) => o.species === species.value,
    );
    return (
      <RowItem
        key={idx}
        label={label}
        name={`${baseName}.capacities.[${capacityIdx}].value`}
      />
    );
  });
};

const SpeciesInspectionConfigurationBlock = ({
  animalSpecies,
  inspectionLevels,
  baseName,
  values,
}: {
  animalSpecies: InputChipOption[];
  inspectionLevels: Partial<
    Record<EnumProcessorSettingsAnimalSettingsSpecies, Array<InputChipOption>>
  >;
  baseName: string;
  values: any;
}) => {
  const baseNameForClass = baseName
    .replace(/[.\[\]]/g, '-')
    .replace(/--+/g, '-');
  return flatMap(sortBy(animalSpecies, 'sortValue'), (species, idx) => {
    const inspections =
      inspectionLevels[
        species.value as EnumProcessorSettingsAnimalSettingsSpecies
      ] ?? [];
    return map(inspections, (inspectionLevel, jdx) => {
      const label = `${inspectionLevel.label} ${species.label}`;
      const capacityIdx = findIndex(
        get(values, `${baseName}.capacities`),
        (o: any) => {
          return (
            o.species === species.value &&
            o.inspectionLevel === inspectionLevel.value
          );
        },
      );

      return (
        <RowItem
          key={`${baseName}.${idx}.${jdx}`}
          className={`${baseNameForClass}capacities-${capacityIdx}-value`}
          label={label}
          name={`${baseName}.capacities.[${capacityIdx}].value`}
        />
      );
    });
  });
};

const WeightedAnimalUnitConfigurationBlock = ({
  baseName,
  values,
}: {
  baseName: string;
  values: any;
}) => {
  // Unlike the other capacity configurations there is only one item in the array ever.
  // All the animal species are configured to take a portion of these units
  const capacityIdx = 0;
  return (
    <RowItem
      label="Animal units"
      name={`${baseName}.capacities.[${capacityIdx}].value`}
    />
  );
};

export function CapacityBlock({
  parentBaseName,
  title,
  currentCapacityConfigurationType,
}: CapacityBlockProps) {
  // This will get annoying once we move custom day capacity to its own form
  const { values } = useFormikContext<SettingsFormData>();
  const dailyCapacitiesPath = parentBaseName
    ? `${parentBaseName}.dailyCapacities`
    : 'dailyCapacities';
  const currentCapacitySettingsIdx = findIndex(
    get(values, `${dailyCapacitiesPath}`),
    (o: any) => o.type === currentCapacityConfigurationType,
  );

  return (
    <Col xs={12} sm={6} lg={4} xl={3}>
      <Card className="bg-body-tertiary text-center">
        <Card.Body className="px-0">
          <p className="mt-1 mb-3">{title}</p>
          <hr className="mb-0" />
          <div className="py-3">
            {currentCapacityConfigurationType ===
              EnumProcessorSettingsCapacityConfigurationType.Species && (
              <SpeciesConfigurationBlock
                animalSpecies={values.animalSpecies}
                baseName={`${dailyCapacitiesPath}.[${currentCapacitySettingsIdx}]`}
                capacities={[]}
                values={values}
              />
            )}

            {currentCapacityConfigurationType ===
              EnumProcessorSettingsCapacityConfigurationType.SpeciesInspection && (
              <SpeciesInspectionConfigurationBlock
                animalSpecies={values.animalSpecies}
                inspectionLevels={values.inspectionLevels}
                baseName={`${dailyCapacitiesPath}.[${currentCapacitySettingsIdx}]`}
                values={values}
              />
            )}
            {currentCapacityConfigurationType ===
              EnumProcessorSettingsCapacityConfigurationType.WeightedAnimalUnit && (
              <WeightedAnimalUnitConfigurationBlock
                baseName={`${dailyCapacitiesPath}.[${currentCapacitySettingsIdx}]`}
                values={values}
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
}
