import { AnimalSplitPart } from '@farmshare/utils';
import { faFileEdit, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Card, Stack, Button, CloseButton } from 'react-bootstrap';

export interface AnimalHeadsCardHeaderProps {
  isActive?: boolean;
  cardHeader: string;
  producerIdentifier?: string;
  splitValue?: string;
  inspectionLevel?: string;
  contactsFill?: {
    label: string;
    splitPart: AnimalSplitPart;
  }[];
  onEdit?: () => void;
  handleEdit?: () => void;
  onRemove?: (idx: number) => void;
  handleRemove?: () => void;
}

export function AnimalHeadsCardHeader({
  isActive,
  cardHeader,
  producerIdentifier,
  splitValue,
  inspectionLevel,
  contactsFill,
  onEdit,
  handleEdit,
  onRemove,
  handleRemove,
}: AnimalHeadsCardHeaderProps) {
  return (
    <Card.Header className="d-flex">
      {isActive ? (
        <>
          {cardHeader}
          {onRemove && (
            <CloseButton className="ms-auto" onClick={handleRemove} />
          )}
        </>
      ) : (
        <>
          <Stack direction="horizontal" gap={3}>
            <div>{cardHeader}</div>
            {producerIdentifier && (
              <Badge bg="secondary">{producerIdentifier}</Badge>
            )}
            <Badge bg="secondary">{splitValue}</Badge>
            <Badge bg="secondary">{inspectionLevel}</Badge>
            {contactsFill && (
              <Badge bg="secondary">
                <FontAwesomeIcon icon={faUser} /> {contactsFill.length}
              </Badge>
            )}
            {handleEdit && (
              <Button variant="link" className="ms-auto">
                <FontAwesomeIcon icon={faFileEdit} onClick={handleEdit} />
              </Button>
            )}
          </Stack>
          {onEdit && (
            <FontAwesomeIcon
              className="ms-auto"
              icon={faFileEdit}
              onClick={handleEdit}
            />
          )}
        </>
      )}
    </Card.Header>
  );
}
