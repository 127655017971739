import {
  DataDetailList,
  ExternalLink,
  Loading,
  PageTitle,
  PhoneDisplay,
  Form,
  Button,
  useToastr,
  Error,
  useModal,
} from '@farmshare/ui-components';
import {
  Nullable,
  animalSpeciesHelper,
  animalSplitTypeHelper,
  formatFullName,
  formatToCurrency,
  inspectionLevelHelper,
} from '@farmshare/utils';
import {
  faEnvelope,
  faPenToSquare,
  faPhone,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
  concat,
  filter,
  find,
  findIndex,
  forEach,
  get,
  last,
  map,
  max,
  min,
  omit,
  partialRight,
  reduce,
  set,
} from 'lodash';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Row, Stack } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import { CutsheetTooltip } from 'components/cutsheet-tooltip/cutsheet-tooltip';

import {
  EnumCutsheetAnimalSpecies,
  EnumCutsheetType,
  EnumProcessorSchedulingAnimalSpecies,
  EnumProcessorSchedulingStatus,
  ProcessorCapabilityExtras,
  useCutsheetCreateOneMutation,
  useProcessorSchedulingCutsheetInformationPartialUpdateMutation,
  useViewSchedulingEditQuery,
  type Cutsheet,
  type CutsheetCreateOneMutationVariables,
  type CutsheetPartialUpdateInput,
  type ViewSchedulingCreateQuery,
} from 'lib/graphql';
import { processingJobAnimalHeadLabel } from 'lib/processingJobUtils';

import {
  ExtrasModel,
  ExtrasTrimOptionsType,
  TrimModel,
} from 'pages/processor/cutsheets/_views/cutsheet-modal';

import {
  SchedulingCustomCutsheetModal,
  SchedulingCustomCutsheetModalForm,
} from './_views/custom-cutsheet-modal';
import {
  CutsheetRequest,
  EditCutsheets,
  EditCutsheetsForm,
} from './_views/edit-cutsheets';
import logo from '../../assets/images/partners.svg';

interface JobDetailsProps {
  animalSpecies?: string;
  butcherSlotPricing: {
    price: number;
    inspectionLevel: string | null | undefined;
  }[];
  dropOffDate?: Nullable<string>;
  headCount?: Nullable<number>;
  processor: {
    email?: Nullable<string>;
    name?: Nullable<string>;
    phone?: Nullable<string>;
  };
  refundDeadlineDays: number;
  schedulingDepositPrice?: Nullable<number>;
  status?: EnumProcessorSchedulingStatus;
  requestedBy: string;
}

const JobDetails = ({
  animalSpecies,
  butcherSlotPricing,
  dropOffDate,
  headCount,
  processor,
  refundDeadlineDays,
  schedulingDepositPrice,
  status,
  requestedBy,
}: JobDetailsProps) => {
  const lastDayToCancel = useMemo(() => {
    if (dropOffDate) {
      const momentDate = moment.utc(dropOffDate);
      return momentDate
        ?.clone()
        .subtract(refundDeadlineDays + 1, 'days')
        .format('L');
    }
  }, [dropOffDate, refundDeadlineDays]);

  const butcherSlotPriceDispay = useMemo(() => {
    const numbers = map(butcherSlotPricing, (o) => o.price);
    const minPrice = min(numbers);
    const maxPrice = max(numbers);
    if (minPrice !== maxPrice) {
      return `${formatToCurrency(minPrice)} - ${formatToCurrency(maxPrice)}`;
    }
    return minPrice ? formatToCurrency(minPrice) : undefined;
  }, [butcherSlotPricing]);

  return (
    <Card className="h-100" body>
      <Stack gap={3}>
        <DataDetailList
          heading="Processing Details"
          rows={[
            {
              label: 'Processor',
              value: processor.name,
            },
            {
              label: 'Requested by',
              value: requestedBy,
            },
            {
              label: 'Animal',
              value: animalSpeciesHelper(animalSpecies || '').label,
            },
            {
              label: 'Drop Off Date',
              value: dropOffDate ?? '-',
            },
            {
              label: 'Last Day to Cancel',
              value: lastDayToCancel,
            },
            { label: 'Total Head Count', value: headCount },
            { label: 'Status', value: status },
            {
              label: 'Deposit',
              value: formatToCurrency(schedulingDepositPrice),
            },
            {
              label: 'Killslot Price',
              value: butcherSlotPriceDispay,
            },
          ]}
        />
        <div className="fst-italic text-muted">
          *For cancellations, please contact the processor using the information
          listed below. This processor requires that you notify them{' '}
          {refundDeadlineDays} days in advance for a refund on your deposit.
        </div>
        <hr />
        <DataDetailList
          rows={[
            {
              // The farmshare FAQ for now. One day we might have it per processor`
              label: 'FAQ',
              value: <ExternalLink href={'/faq'} text="View FAQ" />,
            },
            {
              label: 'Email',
              value: (
                <ExternalLink
                  href={`mailto:${processor.email}`}
                  text={processor.email || ''}
                  icon={faEnvelope}
                />
              ),
            },
            {
              label: 'Phone',
              value: (
                <ExternalLink
                  href={`tel:${processor.phone}`}
                  text={<PhoneDisplay phone={processor.phone || ''} />}
                  icon={faPhone}
                />
              ),
            },
          ]}
        />
        <div className="fst-italic text-muted">
          *If you have questions check out {processor.name}
          {last(processor.name) === 's' ? "' " : "'s "}
          FAQ or contact them using the information above.
        </div>
      </Stack>
    </Card>
  );
};

export default function SchedulingEditCutsheets() {
  const { schedulingId } = useParams();
  const user = useRecoilValue(userState);
  const { push } = useToastr();
  const { save } = useModal();
  const { data, loading } = useViewSchedulingEditQuery({
    variables: {
      jobId: schedulingId,
      userId: user?._id,
    },
  });

  const butcherSlotPricing = useMemo(() => {
    const animalSetting = find(
      data?.findProcessorSchedulingById?.processorSettings?.animalSettings,
      (o) => o?.species === data?.findProcessorSchedulingById?.animalSpecies,
    );

    return reduce(
      animalSetting?.butcherSlotPricing,
      (
        prices: {
          price: number;
          inspectionLevel: string | null | undefined;
        }[],
        butcherSlotPrice,
      ) => {
        if (butcherSlotPrice) {
          const { price, inspectionLevel } = butcherSlotPrice;
          prices.push({ price, inspectionLevel });
        }
        return prices;
      },
      [],
    );
  }, [
    data?.findProcessorSchedulingById?.processorSettings?.animalSettings,
    data?.findProcessorSchedulingById?.animalSpecies,
  ]);

  const extrasTrimOptions = useMemo(() => {
    return reduce(
      data?.findProcessorSchedulingById?.processorSettings?.animalSettings,
      (acc, animalSetting) => {
        const species = animalSetting?.species;
        forEach(animalSetting?.inspectionLevels, (inspectionLevel) => {
          const capabilities = find(
            data?.findProcessorSchedulingById?.processorCapabilities,
            {
              animalSpecies: animalSetting?.species,
              inspectionLevel,
            },
          );
          const extras = map(
            filter(capabilities?.extras, (extra) => extra?.isActive),
            // Something with lodash lately has been causing it to incorrectly infer types it keeps thinkng things are <TheActualType> | number.
            // I can't figure out why it thinks things can be numbers.
            (extra: ProcessorCapabilityExtras) => ({
              name: extra?.name,
              pricePerPound: extra.pricePerPound,
              minLbs: extra.minLbs,
              isActive: false,
              quantity: 0,
            }),
          );
          const trim = map(
            filter(capabilities?.trim, (trim) => trim?.isActive),
            // Something with lodash lately has been causing it to incorrectly infer types it keeps thinkng things are <TheActualType> | number.
            // I can't figure out why it thinks things can be numbers.
            (trim: ProcessorCapabilityExtras) => ({
              name: trim?.name,
              pricePerPound: trim.pricePerPound,
              minLbs: trim.minLbs,
              isActive: false,
              quantity: 0,
              rank: 1,
              isAllTrim: false,
              disabled: false,
            }),
          );
          if (species) {
            const currentExtras = get(acc, `extras[${species}]`, []);
            set(acc, `extras[${species}]`, concat(currentExtras, extras));
            const currentTrim = get(acc, `trim[${species}]`, []);
            set(acc, `trim[${species}]`, concat(currentTrim, trim));
          }
        });

        return acc;
      },
      { extras: {}, trim: {} } as ExtrasTrimOptionsType,
    );
  }, [
    data?.findProcessorSchedulingById?.processorCapabilities,
    data?.findProcessorSchedulingById?.processorSettings?.animalSettings,
  ]);

  const [userCutsheets, setUserCutsheets] = useState<
    ViewSchedulingCreateQuery['cutsheetMany']
  >(data?.cutsheetMany ?? []);

  useEffect(() => {
    if (data?.cutsheetMany) {
      setUserCutsheets(data.cutsheetMany);
    }
  }, [data?.cutsheetMany]);

  const userCutsheetRequests = useMemo(() => {
    const isRequestingUser =
      data?.findProcessorSchedulingById?.requestedBy?._id === user?._id;

    const isProcessor =
      data?.findProcessorSchedulingById?.vendor?._id ===
      user?.active_vendor?._id;

    const animalSpecies = data?.findProcessorSchedulingById?.animalSpecies;

    const cutsheetRequests = reduce(
      data?.findProcessorSchedulingById?.animalHeads,
      (acc: CutsheetRequest[], animalHead, idx) => {
        const filtered =
          filter(animalHead?.cutsheetInformation, (ci) => {
            const isContactUser = ci?.contactUser?._id === user?._id;
            return isRequestingUser || isProcessor || isContactUser;
          }) ?? [];

        if (filtered.length > 0) {
          acc.push({
            animalHeadId: animalHead?._id,
            cutsheets: filtered,
            heading: processingJobAnimalHeadLabel({
              producerIdentifier: animalHead?.producerIdentifier,
              animalSpecies,
              inspectionLevel: animalHead?.inspectionLevel ?? undefined,
              animalNumber: idx + 1,
              requestedBy: {
                first_name:
                  data?.findProcessorSchedulingById?.requestedBy?.first_name,
                last_name:
                  data?.findProcessorSchedulingById?.requestedBy?.last_name,
              },
            }),
            splitType: animalHead?.splitType ?? '',
            inspectionLevel: animalHead?.inspectionLevel ?? undefined,
          });
        }

        return acc;
      },
      [],
    );
    return cutsheetRequests;
  }, [
    data?.findProcessorSchedulingById?.animalHeads,
    user,
    data?.findProcessorSchedulingById?.vendor,
    data?.findProcessorSchedulingById?.requestedBy,
    data?.findProcessorSchedulingById?.animalSpecies,
  ]);

  const [updateScheduling, updateSchedulingOp] =
    useProcessorSchedulingCutsheetInformationPartialUpdateMutation();

  const [createCutsheet, createCutsheetOp] = useCutsheetCreateOneMutation();

  const validCutsheets = useMemo(() => {
    // These are already filtered by animal type
    // Only display available cutsheets that match the cutsheet request
    const userRequestSplitTypes = map(
      userCutsheetRequests,
      (cutsheetRequest) => cutsheetRequest.splitType,
    );
    const vendorCutsheets = filter(
      data?.findProcessorSchedulingById?.vendorCutsheets ?? [],
      (cutsheet) =>
        cutsheet.splitTypes?.some((splitType) =>
          userRequestSplitTypes.includes(splitType as string),
        ),
    );

    // Filter to the correct animal type
    const filteredCutsheets = filter(
      userCutsheets,
      (c) =>
        (c.animalSpecies as unknown as EnumProcessorSchedulingAnimalSpecies) ===
        data?.findProcessorSchedulingById?.animalSpecies,
    );

    // Add any cutsheets that are not included but attached to the job. I.e user cutsheet but you are a power user so you don't see someone elses custom cutsheet.
    forEach(userCutsheetRequests, (cutsheetRequest) => {
      forEach(cutsheetRequest.cutsheets, (ci) => {
        if (
          ci?.cutsheet?._id &&
          ci?.cutsheet?.type === EnumCutsheetType.User &&
          findIndex(filteredCutsheets, (fc) => fc._id === ci.cutsheet?._id) < 0
        ) {
          filteredCutsheets.push({ ...(ci.cutsheet as Cutsheet) });
        }
      });
    });

    return concat([], vendorCutsheets, filteredCutsheets) as Cutsheet[];
  }, [userCutsheets, data?.findProcessorSchedulingById, userCutsheetRequests]);

  const cutsheetOptions = useMemo(() => {
    return map(validCutsheets, (c) => ({
      label: c.name,
      value: c._id,
      splitTypes: c.splitTypes ?? [],
      inspectionLevels: c.inspectionLevels ?? [],
      cutsheetType: c.type,
    }));
  }, [validCutsheets]);

  if (loading) {
    return <Loading />;
  }

  if (!data?.findProcessorSchedulingById) {
    return (
      <Error
        error={{ name: '404', message: 'Scheduled processing job not found.' }}
        logo={
          <img
            src={logo}
            height={100}
            alt="farmshare partners logo"
            className="mt-1 text-body p-3 p-md-0"
          />
        }
      />
    );
  }

  return (
    <div className="pt-3 pt-lg-4">
      <PageTitle
        title="Manage Processing Job Cutsheets"
        showBreadCrumbs={false}
      />

      <Container>
        <Row className="g-3 g-lg-4">
          <Col lg={3} className="pb-3">
            <JobDetails
              animalSpecies={data?.findProcessorSchedulingById?.animalSpecies}
              butcherSlotPricing={butcherSlotPricing}
              dropOffDate={data?.findProcessorSchedulingById?.dropoffDateStr}
              processor={{
                email:
                  data?.findProcessorSchedulingById?.vendor?.address?.email,
                name: data?.findProcessorSchedulingById?.vendor?.shop_name,
                phone: data?.findProcessorSchedulingById?.vendor?.address.phone,
              }}
              refundDeadlineDays={
                data?.findProcessorSchedulingById?.refundDeadlineDays || 0
              }
              schedulingDepositPrice={
                data?.findProcessorSchedulingById?.deposit?.amount
              }
              status={data?.findProcessorSchedulingById?.status}
              headCount={data?.findProcessorSchedulingById?.headCount}
              requestedBy={formatFullName(
                data?.findProcessorSchedulingById?.requestedBy?.first_name ??
                  '',
                data?.findProcessorSchedulingById?.requestedBy?.last_name ??
                  undefined,
              )}
            />
          </Col>
          <Col lg={9}>
            <Stack gap={3}>
              <Card>
                <Card.Header className="d-flex">
                  Available Cutsheets
                  <Button
                    className="ms-auto"
                    content="Add Custom Cutsheet"
                    icon={faPlus}
                    size="sm"
                    isLoading={createCutsheetOp.loading}
                    onClick={() =>
                      save<SchedulingCustomCutsheetModalForm>({
                        type: 'save',
                        title: 'Custom Cutsheet',
                        initialValues: {
                          name: '',
                          cuts: [],
                          extras: extrasTrimOptions.extras,
                          trim: extrasTrimOptions.trim,
                          blockedBy: [],
                        },
                        isLoading: createCutsheetOp.loading,
                        icon: faPlus,
                        fullscreen: true,
                        body: (fP) => (
                          <SchedulingCustomCutsheetModal
                            fP={fP}
                            capabilities={
                              data.findProcessorSchedulingById
                                ?.processorCapabilities
                            }
                            animalSpecies={
                              data.findProcessorSchedulingById?.animalSpecies
                            }
                            settings={
                              data.findProcessorSchedulingById
                                ?.processorSettings ?? undefined
                            }
                          />
                        ),
                        onSubmit: async ({
                          name,
                          cuts,
                          groundMeatSize,
                          roastSize,
                          steakThickness,
                          steaksPerPack,
                          extras,
                          trim,
                          notes,
                        }) => {
                          const animalSpecies =
                            data.findProcessorSchedulingById?.animalSpecies;

                          const formattedExtras = animalSpecies
                            ? (map(
                                filter(
                                  extras?.[animalSpecies],
                                  (extra) => extra.isActive,
                                ) || [],
                                partialRight(omit, ['__typename']),
                              ) as ExtrasModel[])
                            : [];

                          const getFormattedTrim = () => {
                            if (!animalSpecies) {
                              return [];
                            }

                            // if there is an isAllTrim option, only return that
                            const isAllTrim = filter(
                              trim?.[animalSpecies],
                              (t) => t.isAllTrim,
                            );
                            if (isAllTrim.length > 0) {
                              return map(
                                isAllTrim,
                                partialRight(omit, ['__typename', 'disabled']),
                              ) as TrimModel[];
                            }

                            return map(
                              filter(
                                trim?.[animalSpecies],
                                (trim) => trim.isActive,
                              ) || [],
                              partialRight(omit, ['__typename', 'disabled']),
                            ) as TrimModel[];
                          };

                          const variables: CutsheetCreateOneMutationVariables =
                            {
                              record: {
                                type: user
                                  ? EnumCutsheetType.User
                                  : EnumCutsheetType.Orphan,
                                animalSpecies: data.findProcessorSchedulingById
                                  ?.animalSpecies as unknown as EnumCutsheetAnimalSpecies,
                                inspectionLevels: [],
                                primalCuts: map(cuts, (c) => c.value),
                                user: user?._id,
                                name,
                                extras: formattedExtras,
                                trim: getFormattedTrim(),
                                notes,
                              },
                            };
                          if (steaksPerPack) {
                            variables.record.steaksPerPack =
                              parseInt(steaksPerPack);
                          }
                          if (steakThickness) {
                            variables.record.steakThickness =
                              parseFloat(steakThickness);
                          }
                          if (groundMeatSize) {
                            variables.record.groundMeatSize =
                              parseFloat(groundMeatSize);
                          }
                          if (roastSize) {
                            variables.record.roastSize = roastSize;
                          }
                          const response = await createCutsheet({ variables });
                          if (response.data?.cutsheetCreateOne?.record) {
                            setUserCutsheets(
                              concat(userCutsheets, [
                                response.data.cutsheetCreateOne
                                  .record as Cutsheet,
                              ]),
                            );
                          }
                        },
                        validate: (values) => {
                          const errors: Record<string, any> = {};
                          const animalSpecies =
                            data.findProcessorSchedulingById?.animalSpecies;
                          if (!animalSpecies) {
                            return errors;
                          }

                          // validate quantity for active extras
                          const extrasOptions =
                            values.extras?.[animalSpecies] ?? [];

                          if (extrasOptions.length > 0) {
                            const animalType = animalSpecies;

                            for (let i = 0; i < extrasOptions.length; i++) {
                              if (
                                extrasOptions[i].isActive &&
                                (extrasOptions[i].quantity ?? 0) <
                                  (extrasOptions[i].minLbs ?? 0)
                              ) {
                                const extraErrors =
                                  errors.extras?.[animalType] || [];
                                extraErrors[i] = {
                                  ...(extraErrors[i] ?? {}),
                                  quantity: `Min quantity is ${extrasOptions[i].minLbs} lbs`,
                                };

                                errors.extras = {
                                  ...errors?.extras,
                                  [animalType]: extraErrors,
                                };
                              }
                            }
                          }

                          // check if rank is unique for active trims
                          const trimOptions = animalSpecies
                            ? values.trim?.[animalSpecies] ?? []
                            : [];
                          if (trimOptions.length > 0) {
                            const animalType = animalSpecies;

                            for (let i = 0; i < trimOptions.length; i++) {
                              // validate quantity for active trims
                              if (
                                trimOptions[i].isActive &&
                                !trimOptions[i].isAllTrim &&
                                (trimOptions[i].quantity ?? 0) <
                                  (trimOptions[i].minLbs ?? 0)
                              ) {
                                const trimErrors =
                                  errors.trim?.[animalType] || [];
                                trimErrors[i] = {
                                  ...(trimErrors[i] ?? {}),
                                  quantity: `Min quantity is ${trimOptions[i].minLbs} lbs`,
                                };

                                errors.trim = {
                                  ...errors?.trim,
                                  [animalType]: trimErrors,
                                };
                              }

                              // check if rank is unique for active trims
                              const ranks = map(
                                filter(trimOptions, (t) => t.isActive),
                                (t) => t.rank,
                              );
                              if (ranks.length !== new Set(ranks).size) {
                                errors.trim = {
                                  ...errors?.trim,
                                  [animalType]: [
                                    ...map(
                                      filter(trimOptions, (t) => t.isActive),
                                      (t) => ({
                                        rank: 'Rank must be unique',
                                      }),
                                    ),
                                  ],
                                };
                              }
                            }
                          }

                          // check if rank is greater than 0 for active trims
                          if (trimOptions.length > 0 && animalSpecies) {
                            const animalType = animalSpecies;

                            for (let i = 0; i < trimOptions.length; i++) {
                              if (
                                trimOptions[i].isActive &&
                                trimOptions[i].rank < 1
                              ) {
                                const trimErrors =
                                  errors.trim?.[animalType] || [];

                                trimErrors[i] = {
                                  ...(trimErrors[i] ?? {}),
                                  rank: 'Rank must be greater than 0',
                                };
                                errors.trim = {
                                  ...errors?.trim,
                                  [animalType]: trimErrors,
                                };
                              }
                            }
                          }

                          return errors;
                        },
                      })
                    }
                  />
                </Card.Header>
                <Card.Body>
                  <Row xs={1} sm={2} lg={3} xl={3} className="w-100 g-2">
                    {map(validCutsheets, (cutsheet, idx) => {
                      return (
                        <Col key={idx}>
                          <Card className="h-100">
                            <Card.Body>
                              <Stack>
                                <Row>
                                  <Col xs={9} className="text-truncate fw-bold">
                                    {cutsheet.name}
                                  </Col>
                                  <Col>
                                    <CutsheetTooltip cutsheet={cutsheet} />
                                  </Col>
                                </Row>
                                <span>
                                  {cutsheet.type === EnumCutsheetType.Vendor
                                    ? `${formatToCurrency(
                                        cutsheet.pricePerPound || 0,
                                      )}/lb`
                                    : 'Custom'}
                                </span>
                                <span>
                                  {map(
                                    cutsheet.inspectionLevels,
                                    (o) =>
                                      inspectionLevelHelper(o ?? 'N/A').label,
                                  ).join(', ')}
                                </span>
                                <span>
                                  {map(
                                    cutsheet.splitTypes,
                                    (o) =>
                                      animalSplitTypeHelper(o ?? 'N/A').label,
                                  ).join(', ')}
                                </span>
                              </Stack>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Card.Body>
              </Card>

              <Form<EditCutsheetsForm>
                initialValues={{
                  cutsheetRequests: userCutsheetRequests,
                }}
                validate={(values) => {
                  const errors: Partial<
                    Record<keyof EditCutsheetsForm, string>
                  > = {};

                  return errors;
                }}
                onSubmit={async (form) => {
                  let toast = {
                    title: 'Cutsheets Updated!',
                    body: 'Your cutsheets have been successfully updated!',
                    bg: 'primary',
                    delay: 4000,
                  };
                  try {
                    const animalSetting = find(
                      data?.findProcessorSchedulingById?.processorSettings
                        ?.animalSettings,
                      (o) =>
                        o?.species ===
                        data?.findProcessorSchedulingById?.animalSpecies,
                    );

                    const updateRecords = reduce(
                      form.cutsheetRequests,
                      (acc: CutsheetPartialUpdateInput[], cutsheetRequest) => {
                        const records = map(
                          cutsheetRequest?.cutsheets,
                          (ci) => {
                            return {
                              _id: ci?._id,
                              notes: ci?.notes,
                              cutsheet:
                                (ci?.cutsheet?._id as unknown as string) ===
                                'Select one...'
                                  ? null
                                  : ci?.cutsheet?._id,
                            };
                          },
                        );

                        acc = concat(acc, records);
                        return acc;
                      },
                      [],
                    );
                    await updateScheduling({
                      variables: {
                        processorSchedulingId:
                          data?.findProcessorSchedulingById?._id,
                        record: updateRecords,
                      },
                    });
                  } catch (err) {
                    toast = {
                      ...toast,
                      title: 'Error',
                      body: (err as string).toString(),
                      bg: 'danger',
                    };
                  }
                  push(toast);
                }}
              >
                <EditCutsheets cutsheetOptions={cutsheetOptions} />

                <Stack
                  direction="horizontal"
                  gap={1}
                  className="justify-content-end mt-3 mb-5"
                >
                  <Button
                    isLoading={updateSchedulingOp.loading}
                    disabled={updateSchedulingOp.loading}
                    type="submit"
                    content="Save Cutsheets"
                    icon={faPenToSquare}
                    variant="primary"
                  />
                </Stack>
              </Form>
            </Stack>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
