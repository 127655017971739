import { PageTitle } from '@farmshare/ui-components';
import { includes, map, startCase } from 'lodash';
import { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

const links = ['logistics-invoices', 'processing-invoices', 'tenants'];

export default function AdminPage() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (includes(['/admin', '/admin/'], location.pathname)) {
      navigate('/admin/tenants');
    }
  }, [location.pathname, navigate]);

  return (
    <Container className="pt-4">
      <PageTitle title="Admin" show={false} />
      <Row>
        <Col md={2} className="mb-3 mb-md-0" style={{ minHeight: '50vh' }}>
          <Card className="h-100" body>
            <h4>Admin Panel</h4>
            <hr />
            <ul className="list-unstyled">
              {map(links, (link) => (
                <li
                  key={link}
                  className={
                    location.pathname.replace(/\/admin\/?/gi, '') === link
                      ? 'fw-bold mb-2'
                      : 'mb-2'
                  }
                >
                  <Link to={`/admin/${link}`}>{startCase(link)}</Link>
                </li>
              ))}
            </ul>
          </Card>
        </Col>
        <Col md={10}>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
}
