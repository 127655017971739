import { Loading } from '@farmshare/ui-components';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { Stack } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { userState, vendorState } from 'state';

import {
  SortFindManyProcessorSchedulingInput,
  useProcessorSchedulingManyQuery,
  useProcessorSchedulingPaginationLazyQuery,
} from 'lib/graphql';

import NeedAttentionContainer from './_views/need-attention-container';
import NotificationsContainer from './_views/notifications-container';
import PendingApprovalsContainer from './_views/pending-approvals-container';
import SetupStripe from './_views/setup-stripe';
import TableContainer, { ExtraColumnsEnum } from './_views/table-container';
import { getProcessorSchedulingQueryFilters } from './filters';
import { GenerateKillsheetButton } from '../lib/_views/generate-killsheet-button';

export default function ProcessorDashboard() {
  const user = useRecoilValue(userState);
  const vendor = useRecoilValue(vendorState);

  const {
    pendingApprovals: pendingApprovalsQueryFilter,
    missingCutsheets: missingCutsheetsQueryFilter,
    notifications: notificationsQueryFilter,
    processorScheduling: processorSchedulingQueryFilter,
    bookingsInProgress: bookingsInProgressQueryFilter,
    bookingsCompleted: completedBookingsQueryFilter,
    dropoffAlerts: dropoffAlertsQueryFilter,
  } = getProcessorSchedulingQueryFilters({ vendorId: vendor?._id });

  // Pagination Queries
  const bookingsInProgressPaginationQuery =
    useProcessorSchedulingPaginationLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        filter: bookingsInProgressQueryFilter,
        page: 1,
        perPage: 5,
      },
    });
  const completedBookingsPaginationQuery =
    useProcessorSchedulingPaginationLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        filter: completedBookingsQueryFilter,
        page: 1,
        perPage: 10,
      },
    });
  const dropoffAlertsPaginationQuery =
    useProcessorSchedulingPaginationLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorSchedulingInput.DropoffdateAsc,
        filter: dropoffAlertsQueryFilter,
        page: 1,
        perPage: 10,
      },
    });

  const {
    data: processorSchedulingsData,
    loading: processorSchedulingsLoading,
  } = useProcessorSchedulingManyQuery({
    fetchPolicy: 'network-only',
    variables: { filter: processorSchedulingQueryFilter },
  });

  return processorSchedulingsLoading || !vendor || !user ? (
    <Loading />
  ) : (
    <div className="pb-5">
      <Stack direction="horizontal" className="justify-content-between pb-2">
        <div>
          <h2 className="fw-bold">
            {user?.first_name ? `Welcome, ${user.first_name}!` : 'Welcome!'}
          </h2>
          <p className="m-0">
            Check out your current bookings and update the status of ongoing
            processes here.
          </p>
        </div>
        <GenerateKillsheetButton />
      </Stack>
      <SetupStripe />
      <PendingApprovalsContainer
        processorSchedulingsData={processorSchedulingsData}
        titleText="Pending Approvals"
        subText=""
        noResultsText="No Pending Approvals"
        icon={faCalendarCheck}
        buildFilter={pendingApprovalsQueryFilter}
        hideButtons
        onChangeCallback={() => {
          bookingsInProgressPaginationQuery[1].refetch();
          completedBookingsPaginationQuery[1].refetch();
          dropoffAlertsPaginationQuery[1].refetch();
        }}
      />
      <NeedAttentionContainer
        processorSchedulingsData={processorSchedulingsData}
        titleText="Jobs Need Attention"
        subText=""
        noResultsText="No Pending Jobs"
        icon={faHourglassHalf}
        buildFilter={missingCutsheetsQueryFilter}
        hideButtons
        onChangeCallback={() => {
          bookingsInProgressPaginationQuery[1].refetch();
          completedBookingsPaginationQuery[1].refetch();
          dropoffAlertsPaginationQuery[1].refetch();
        }}
      />
      <TableContainer
        className="in-progress"
        processorSchedulingsData={processorSchedulingsData}
        titleText="Bookings In Progress"
        subText="Update Statuses to notify your customers!"
        noResultsText="No Bookings in Progress"
        icon={faCalendarCheck}
        buildFilter={bookingsInProgressQueryFilter}
        query={bookingsInProgressPaginationQuery}
        hideButtons
        rowActionButtons={['Details', 'Cancel']}
        onChangeCallback={() => {
          bookingsInProgressPaginationQuery[1].refetch();
          completedBookingsPaginationQuery[1].refetch();
          dropoffAlertsPaginationQuery[1].refetch();
        }}
        extraColumns={[ExtraColumnsEnum.StatusSelect]}
      />
      <TableContainer
        className="dropoff-alerts"
        processorSchedulingsData={processorSchedulingsData}
        titleText="Drop Off Alerts"
        subText=""
        noResultsText="No Drop Off Alerts"
        icon={faCalendarCheck}
        buildFilter={dropoffAlertsQueryFilter}
        query={dropoffAlertsPaginationQuery}
        hideButtons
        rowActionButtons={['Details', 'Cancel']}
        onChangeCallback={() => {
          bookingsInProgressPaginationQuery[1].refetch();
          completedBookingsPaginationQuery[1].refetch();
          dropoffAlertsPaginationQuery[1].refetch();
        }}
        extraColumns={[ExtraColumnsEnum.StatusSelect]}
      />
      <TableContainer
        processorSchedulingsData={processorSchedulingsData}
        className="completed"
        titleText="Completed Bookings"
        subText=""
        noResultsText="No Completed Bookings To Show"
        icon={faCalendarCheck}
        buildFilter={completedBookingsQueryFilter}
        query={completedBookingsPaginationQuery}
        rowActionButtons={['Details']}
        hideButtons
        onChangeCallback={() => {
          completedBookingsPaginationQuery[1].refetch();
        }}
        extraColumns={[
          ExtraColumnsEnum.StatusText,
          ExtraColumnsEnum.StatusLastUpdated,
        ]}
        perPageOverride={10}
      />
      <NotificationsContainer
        titleText="Notifications"
        subText=""
        noResultsText="No notifications"
        icon={faHourglassHalf}
        buildFilter={notificationsQueryFilter}
        hideButtons
      />
    </div>
  );
}
