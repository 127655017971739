import { Field, ErrorMessage, useFormikContext, FieldProps } from 'formik';
import { camelCase } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Form } from 'react-bootstrap';

import { InputComponent } from '.';

export interface InputCheckProps extends InputComponent {
  type?: 'checkbox' | 'switch';
  inline?: boolean;
}

export function InputCheck({
  label,
  disabled,
  hint,
  required,
  nameOveride,
  className,
  type = 'checkbox',
  inline = false,
}: InputCheckProps) {
  const { errors } = useFormikContext<Record<string, boolean>>();

  const validate = useCallback(
    (value: string | number | boolean | undefined) => {
      if (required && (!value || value === undefined)) {
        return `${label} is required.`;
      }
    },
    [label, required],
  );

  const camelLabel = useMemo(() => camelCase(label), [label]);

  const name = useMemo(
    () => nameOveride ?? camelLabel,
    [camelLabel, nameOveride],
  );

  return (
    <Form.Group className={className}>
      {label !== '' && !inline && (
        <Form.Label htmlFor={`form.${name}.${camelLabel}`} title={camelLabel}>
          {label}
          {required && <sup className="text-danger fw-bold">&nbsp;*</sup>}
        </Form.Label>
      )}
      <Field name={name} validate={validate} isInvalid={errors[name]}>
        {({ field }: FieldProps) => (
          <Form.Check
            id={`form.${name}.${camelLabel}`}
            type={type}
            placeholder={label}
            disabled={disabled}
            required={required}
            label={inline ? label : null}
            checked={field.value}
            {...field}
          />
        )}
      </Field>
      {hint && <Form.Text>{hint}</Form.Text>}
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <Form.Control.Feedback
            type="invalid"
            // Since we are not using bootstrap Form elements but formik we should force this to display the error message
            style={{ display: 'block' }}
          >
            {msg}
          </Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
}
