import { InputCheckGroup } from '@farmshare/ui-components';
import {
  animalSpeciesHelper,
  animalSplitPartHelper,
  formatFullName,
  inspectionLevelHelper,
} from '@farmshare/utils';
import { Stack } from 'react-bootstrap';

import { EnumCutsheetInspectionLevels } from 'lib/graphql';

import { CutsheetRequest } from './edit-cutsheets';

interface AddCutsheetToCartProps {
  cutsheetRequests: CutsheetRequest[];
  inspectionLevels?: EnumCutsheetInspectionLevels[] | null;
}

export interface AddCutsheetToCartModalForm {
  cutsheets: string[];
}

function getLabel(
  request: CutsheetRequest,
  cutsheet: CutsheetRequest['cutsheets'][0],
  index: number,
) {
  const inspectionLevelLabel = request.inspectionLevel
    ? inspectionLevelHelper(request.inspectionLevel).label
    : '';
  const animalSpeciesLabel = request?.animalSpecies
    ? `${animalSpeciesHelper(request?.animalSpecies).label} ${
        typeof request.animalIndex !== 'undefined'
          ? `#${request.animalIndex + 1}`
          : ''
      }`
    : '';
  const splitPart = animalSplitPartHelper(cutsheet?.splitPart ?? 'N/A');
  const splitTypeValue =
    request.animalHead?.splitType !== 'whole'
      ? `#${
          index +
          (request.animalHead?.splitType === 'half_and_two_quarters' &&
          splitPart.value === 'quarter'
            ? 0
            : 1)
        }`
      : '';

  return [
    ...[inspectionLevelLabel, animalSpeciesLabel].filter(Boolean),
    '-',
    cutsheet?.contactUser
      ? formatFullName(
          cutsheet?.contactUser.first_name ?? '',
          cutsheet.contactUser.last_name,
        )
      : formatFullName(
          request.requestedBy?.first_name ?? '',
          request.requestedBy?.last_name,
        ),
    request.animalHead?.producerIdentifier ?? '',
    `(${splitPart.label}${
      splitTypeValue.length > 0 ? ` ${splitTypeValue}` : ''
    })`,
  ].join(' ');
}

export function AddCutsheetToCart({
  cutsheetRequests,
  inspectionLevels,
}: AddCutsheetToCartProps) {
  return (
    <Stack gap={3} className="mb-md-2 px-2 px-md-3">
      {cutsheetRequests.map((request, reqI) => {
        if (
          inspectionLevels?.length === 0 ||
          inspectionLevels?.includes(
            request.inspectionLevel as unknown as EnumCutsheetInspectionLevels,
          )
        ) {
          return request.cutsheets.map((k, i) => (
            <InputCheckGroup
              key={`${reqI}-${i}`}
              type="checkbox"
              nameOveride="cutsheets"
              value={`[${reqI}].cutsheets.[${i}]`}
              label={getLabel(request, k, i)}
              inline
            />
          ));
        }

        return null;
      })}
    </Stack>
  );
}
