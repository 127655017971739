import { PageTitle } from '@farmshare/ui-components';
import { kebabCase, map, some } from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CutsheetsTab } from './_views/cutsheets-tab';
import { JobDetailsTab } from './_views/job-details-tab';
import { JobHeadsTab } from './_views/job-heads-tab';
import { JobNotificationsTab } from './_views/job-notifications-tab';
import { NotesTab } from './_views/notes-tab';
import { useProcessingJobPage } from './useProcessingJobPage';

interface Tab {
  key: string;
  title: string;
  element: ReactNode;
}

export function useDebouncedRefresh(
  refetch: () => void,
  delay = 5000, // default 5 seconds
) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const debouncedRefresh = useCallback(() => {
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    // Set a new one
    timeoutRef.current = setTimeout(() => {
      refetch();
    }, delay);
  }, [refetch, delay]);

  // Clear on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return debouncedRefresh;
}

export function ProcessingJob() {
  const navigate = useNavigate();
  const { tab } = useParams();
  const [searchParams] = useSearchParams();

  const parentPage = useMemo(() => {
    return searchParams.get('parent') || 'bookings';
  }, [searchParams]);

  const {
    processingJobQueryResult,
    processingJobDisplayName,
    updateProcessingJob,
    updateProcessingJobOp,
    jobId,
  } = useProcessingJobPage();

  const delayedRefresh = useDebouncedRefresh(processingJobQueryResult.refetch);

  const tabs: Tab[] = useMemo(() => {
    const _tabs = [
      {
        title: 'Details',
        element: (
          <JobDetailsTab
            processingJob={processingJobQueryResult}
            updateProcessingJob={updateProcessingJob}
            updateProcessingJobOp={updateProcessingJobOp}
            delayedRefresh={delayedRefresh}
          />
        ),
      },
      {
        title: 'Heads',
        element: <JobHeadsTab processingJob={processingJobQueryResult} />,
      },
      {
        title: 'Cutsheets',
        element: (
          <CutsheetsTab
            processingJob={processingJobQueryResult}
            updateProcessingJob={updateProcessingJob}
            updateProcessingJobOp={updateProcessingJobOp}
          />
        ),
      },
      {
        title: 'Notifications',
        element: (
          <JobNotificationsTab processingJob={processingJobQueryResult} />
        ),
      },
      {
        title: 'Notes',
        element: <NotesTab processingJob={processingJobQueryResult} />,
      },
    ];

    return map(_tabs, (t) => ({ ...t, key: kebabCase(t.title) }));
  }, [
    delayedRefresh,
    processingJobQueryResult,
    updateProcessingJob,
    updateProcessingJobOp,
  ]);

  useEffect(() => {
    if (!tab || !some(tabs, (t) => t.key === tab)) {
      navigate(`/processing-job/${jobId}/details?parent=${parentPage}`, {
        replace: true,
      });
    }
  }, [navigate, tab, tabs, jobId, parentPage]);

  return (
    <Container className="pt-3">
      <PageTitle
        title={processingJobDisplayName}
        showHr={false}
        innerBreadcrumbs={[
          { text: 'Bookings', to: `/processor/${parentPage}` },
        ]}
      />
      <Container className="position-relative">
        <Tabs
          activeKey={tab}
          onSelect={(tabName) => {
            if (tabName) {
              navigate(
                `/processing-job/${jobId}/${tabName}?parent=${parentPage}`,
              );
            }
          }}
        >
          {map(tabs, (tab, idx) => (
            <Tab
              key={tab.key}
              title={tab.title}
              eventKey={tab.key}
              className={`tab-container-${tab.key}`}
            >
              <Container className="mt-4 px-0">{tab.element}</Container>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </Container>
  );
}
