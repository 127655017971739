import { type ColumnProps, PaginationTable } from '@farmshare/ui-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Card, Col } from 'react-bootstrap';

import {
  FilterFindManyProcessorSchedulingInput,
  SortFindManyProcessorNotificationInput,
  useProcessorNotificationPaginationLazyQuery,
  ProcessorNotificationPagination,
  ProcessorNotificationPaginationQuery,
  ProcessorNotificationPaginationQueryVariables,
  ProcessorNotification,
} from 'lib/graphql';

export default function NotificationsContainer({
  titleText,
  subText,
  noResultsText,
  icon,
  buildFilter,
  hideButtons,
  perPageOverride,
}: {
  titleText: string;
  subText: string;
  noResultsText?: string;
  icon: any;
  buildFilter: FilterFindManyProcessorSchedulingInput;
  hideButtons?: boolean;
  perPageOverride?: number;
}) {
  const processorNotificationPaginationQuery =
    useProcessorNotificationPaginationLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        sort: SortFindManyProcessorNotificationInput.IdDesc,
        filter: buildFilter,
        page: 1,
        perPage: perPageOverride ?? 5,
      },
    });

  const columns: ColumnProps<
    ProcessorNotification,
    ProcessorNotificationPaginationQuery,
    ProcessorNotificationPaginationQueryVariables
  >[] = [
    {
      label: 'Sent To',
      field: 'sentTo',
    },
    {
      label: 'Type',
      field: 'type',
    },
    {
      label: 'Notification Type',
      field: 'notificationType',
    },
    {
      label: 'Sent On',
      field: 'sentOn',
      formatter: (row) => moment(row.sentOn).utc().format('LL'),
    },
    {
      label: 'Error',
      field: 'errorMessage',
      formatter: (row) => {
        return row.errorMessage ? row.errorMessage.message : '-';
      },
    },
  ];

  return (
    <Col className="pt-4 notifications">
      <Card className="bg-body-secondary rounded-4" body>
        <div className="d-flex gap-3 align-items-center">
          <FontAwesomeIcon
            className="text-primary"
            style={{ minWidth: '55px' }}
            icon={icon}
            size="3x"
          />
          <div className="d-flex flex-column justify-content-between text-start">
            <div className="fw-bolder fs-2">
              {processorNotificationPaginationQuery[1].data
                ?.processorNotificationPagination?.count || 0}{' '}
              {titleText}
            </div>
            {subText && <div className="text-secondary">{subText}</div>}
          </div>
        </div>
        <hr />
        <div className="px-1 bg-body rounded-4">
          <PaginationTable<
            ProcessorNotification,
            ProcessorNotificationPagination,
            ProcessorNotificationPaginationQuery,
            ProcessorNotificationPaginationQueryVariables,
            SortFindManyProcessorNotificationInput
          >
            paginationQuery={processorNotificationPaginationQuery}
            columns={[...columns]}
            dataAccessor={(a) =>
              a.processorNotificationPagination as ProcessorNotificationPagination
            }
            defaultSort={SortFindManyProcessorNotificationInput.IdDesc}
            buildFilterQuery={(_, defaultSort, page, __) => {
              return {
                filter: buildFilter,
                sort: defaultSort,
                page,
                perPage: perPageOverride ?? 5,
              };
            }}
            hideButtons={hideButtons}
            isDashboard
            enableSearchParams={false}
            noResultsOverride={{
              mainText: noResultsText ?? 'No Notifications',
              subText: "Try refreshing your page if this doesn't seem right.",
            }}
          />
        </div>
      </Card>
    </Col>
  );
}
