import {
  Button,
  ColumnProps,
  Table,
  useToastr,
} from '@farmshare/ui-components';
import {
  faCheck,
  faInfoCircle,
  faRemove,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import {
  EnumProcessorSchedulingStatus,
  ProcessorScheduling,
  ProcessorSchedulingCalendarQuery,
  ProcessorSchedulingCalendarQueryVariables,
} from 'lib/graphql';

import renderJobHeadsCellHelper from 'pages/processor/lib/renderJobHeadsCellHelper';

import styles from './calendar-date-details.module.scss';
import StatusSelector from '../../lib/_views/status-selector';
import { handleAcceptBooking } from '../../lib/helpers/processorStatusHandlers';
import {
  COMPLETED_STATUSES,
  IN_PROGRESS_STATUSES,
  renderStatusText,
} from '../../lib/helpers/processorStatusPresentation';
import { StatusChanges } from '../../lib/hooks/useStatusChanges';

export interface ProcessorSchedulingDetailsProps {
  events?: ProcessorScheduling[];
  openCancelModal: (processorScheduling: ProcessorScheduling) => void;
  processorChangeCallback: (
    processorId: string,
    newStatus: EnumProcessorSchedulingStatus,
  ) => void;
  statusChanges: StatusChanges;
  dateForStatusLookup?: Date;
}

export function ProcessorSchedulingDetails({
  events,
  openCancelModal,
  processorChangeCallback,
  statusChanges,
  dateForStatusLookup,
}: ProcessorSchedulingDetailsProps) {
  const navigate = useNavigate();
  const { push } = useToastr();

  const baseColumns: ColumnProps<
    ProcessorScheduling,
    ProcessorSchedulingCalendarQuery,
    ProcessorSchedulingCalendarQueryVariables
  >[] = [
    {
      label: 'Order',
      formatter: (row) => [
        <Link to={`/processing-job/${row._id}/?parent=bookings-calendar`}>
          {renderJobHeadsCellHelper(row)}
        </Link>,
        dateForStatusLookup &&
          ` (${renderStatusForDate(row, dateForStatusLookup)})`,
      ],
    },
    {
      label: 'Producer',
      field: 'requestedBy',
      formatter: (row) =>
        row.requestedBy?.company
          ? row.requestedBy?.company
          : `${row.requestedBy?.first_name} ${row.requestedBy?.last_name}`,
    },
  ];

  const { pending, completed, inProgress } = useMemo(() => {
    const pending = events?.filter(
      (event) => event.status === EnumProcessorSchedulingStatus.Requested,
    );

    const completed = events?.filter((event) =>
      COMPLETED_STATUSES.includes(event.status),
    );

    const inProgress = events?.filter((event) =>
      IN_PROGRESS_STATUSES.includes(event.status),
    );

    return { pending, completed, inProgress };
  }, [events]);

  return (
    <>
      {pending && pending?.length > 0 && (
        <div>
          <h5>Pending bookings:</h5>
          <Table
            rows={pending}
            columns={[
              ...baseColumns,
              {
                label: 'Status',
                field: 'status',
                formatter: (row) => renderStatusText(row.status),
              },
              {
                formatter: (row) => (
                  <div className={styles.rowButtons}>
                    <Button
                      className={styles.rowButton}
                      icon={faInfoCircle}
                      onClick={() => navigate(`/processing-job/${row._id}`)}
                    />
                    <Button
                      variant="success"
                      className={styles.rowButton}
                      icon={faCheck}
                      onClick={() =>
                        handleAcceptBooking(
                          row,
                          statusChanges.updateScheduling,
                          push,
                          processorChangeCallback,
                        )
                      }
                    />
                  </div>
                ),
              },
            ]}
          />
          <hr />
        </div>
      )}
      {inProgress && inProgress?.length > 0 && (
        <div>
          <h5>Bookings in progress:</h5>
          <Table
            rows={inProgress}
            columns={[
              ...baseColumns,
              {
                label: 'Status',
                field: 'status',
                formatter: (row) => {
                  return (
                    <StatusSelector
                      key={`status-${row._id}`}
                      row={row}
                      updateScheduling={statusChanges.updateScheduling}
                      updateSchedulingOp={statusChanges.updateSchedulingOp}
                      handleInvoicing={statusChanges.handleInvoicing}
                      handleKilled={statusChanges.handleKilled}
                      handleDroppedOff={statusChanges.handleDroppedOff}
                      onChange={(newStatus) =>
                        processorChangeCallback(row._id, newStatus)
                      }
                    />
                  );
                },
              },
              {
                formatter: (row) => (
                  <div className={styles.rowButtons}>
                    <Button
                      className={styles.rowButton}
                      icon={faInfoCircle}
                      onClick={() => navigate(`/processing-job/${row._id}`)}
                    />
                    <Button
                      className={styles.rowButton}
                      icon={faRemove}
                      variant="danger"
                      onClick={() => openCancelModal(row)}
                    />
                  </div>
                ),
              },
            ]}
          />
          <hr />
        </div>
      )}
      {completed && completed?.length > 0 && (
        <div>
          <h5>Completed bookings:</h5>
          <Table
            rows={completed}
            columns={[
              ...baseColumns,
              {
                label: 'Drop Off',
                field: 'dropoffDate',
                formatter: (row) => moment(row.dropoffDate).utc().format('LL'),
              },
              {
                label: 'Status',
                field: 'status',
                formatter: (row) => renderStatusText(row.status),
              },
              {
                formatter: (row) => (
                  <div className={styles.rowButtons}>
                    <Button
                      className={styles.rowButton}
                      icon={faInfoCircle}
                      onClick={() => navigate(`/processing-job/${row._id}`)}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      )}
      {events && events?.length === 0 && <div>No bookings</div>}
    </>
  );
}

function renderStatusForDate(
  row: ProcessorScheduling,
  dateForStatusLookup: Date,
) {
  const lookupDate = stripTimeFromDate(dateForStatusLookup.toISOString());

  if (stripTimeFromDate(row.dropoffDate) === lookupDate) {
    return 'Drop Off';
  }

  for (const head of row.animalHeads ?? []) {
    if (stripTimeFromDate(head?.killDate) === lookupDate) {
      return 'Harvest';
    }

    if (stripTimeFromDate(head?.cutDate) === lookupDate) {
      return 'Cut';
    }
  }

  return '';
}
function stripTimeFromDate(dateForStatusLookup?: string) {
  return dateForStatusLookup?.split('T')[0];
}
