export const enum ESpecificationNames {
  groundMeatSize = 'groundMeatSize',
  roastSize = 'roastSize',
  steakThickness = 'steakThickness',
  steaksPerPack = 'steaksPerPack',
}

export const specificationsNames = {
  [ESpecificationNames.groundMeatSize]: 'Ground Meat Size',
  [ESpecificationNames.roastSize]: 'Roast Size',
  [ESpecificationNames.steakThickness]: 'Steak Thickness',
  [ESpecificationNames.steaksPerPack]: 'Steaks/Pack',
};

export const defaultSpecifications = [
  {
    name: specificationsNames.groundMeatSize,
    fid: ESpecificationNames.groundMeatSize,
    isRequired: true,
    isVisible: true,
    measurementUnit: 'pounds',
    options: [
      {
        name: '1',
        isDefault: true,
        isVisible: true,
      },
      {
        name: '1.5',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '2',
        isDefault: false,
        isVisible: true,
      },
    ],
  },
  {
    name: specificationsNames.roastSize,
    fid: ESpecificationNames.roastSize,
    isRequired: false,
    isVisible: true,
    measurementUnit: 'none',
    options: [
      {
        name: '2-3',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '3-4',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '4-5',
        isDefault: false,
        isVisible: true,
      },
    ],
  },
  {
    name: specificationsNames.steakThickness,
    fid: ESpecificationNames.steakThickness,
    isRequired: false,
    isVisible: true,
    measurementUnit: 'inches',
    options: [
      {
        name: '0.7',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '1',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '1.25',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '1.5',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '1.75',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '2',
        isDefault: false,
        isVisible: true,
      },
    ],
  },
  {
    name: specificationsNames.steaksPerPack,
    fid: ESpecificationNames.steaksPerPack,
    isRequired: false,
    isVisible: true,
    measurementUnit: 'pounds',
    options: [
      {
        name: '1',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '2',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '3',
        isDefault: false,
        isVisible: true,
      },
      {
        name: '4',
        isDefault: false,
        isVisible: true,
      },
    ],
  },
];

export const enum MeasurementUnit {
  pounds = 'pounds',
  inches = 'inches',
  none = 'none',
}

export const measurementUnitLabels = {
  [MeasurementUnit.pounds]: 'lb.',
  [MeasurementUnit.inches]: 'in.',
  [MeasurementUnit.none]: '',
};

export function getMeasurementLabel(unit: any) {
  return unit in measurementUnitLabels
    ? measurementUnitLabels[unit as MeasurementUnit]
    : '';
}
