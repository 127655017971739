import {
  Button,
  DataDetailListMinimal,
  InputCheck,
  InputPhone,
  InputText,
  MoneyDisplay,
  PhoneDisplay,
  useModal,
  useToastr,
} from '@farmshare/ui-components';
import {
  animalSpeciesHelper,
  animalSplitPartHelper,
  formatFullName,
  inspectionLevelHelper,
} from '@farmshare/utils';
import {
  faEdit,
  faEnvelope,
  faFileDownload,
  faFileEdit,
} from '@fortawesome/free-solid-svg-icons';
import { useFormikContext } from 'formik';
import { CSSProperties, useCallback, useMemo } from 'react';
import { Alert, AlertHeading, Card, Col, Row, Stack } from 'react-bootstrap';

import { downloadFromBase64Encoded } from 'lib/downloadFromBase64Encoded';
import { downloadFromPresignedUrl } from 'lib/downloadFromPresignedUrl';
import {
  CutsheetInformationFragment,
  EnumUserRole,
  ProcessingJobByIdDocument,
  ProcessingJobByIdQuery,
  useProcessorSchedulingGenerateCutsheetRequestMutation,
  useProcessorSchedulingNotificationSendMutation,
} from 'lib/graphql';

import { AnimalHeadDetailsSectionProps } from '../animal-head';

interface EditContactDetailsFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  changeUser?: boolean;
  shouldUpdateExistingUser: boolean;
}

const EditContactDetailsForm = ({ canEdit }: { canEdit: boolean }) => {
  const { values } = useFormikContext<EditContactDetailsFormData>();

  return (
    <Stack gap={3}>
      {canEdit && (
        <>
          <Row>
            <Col>
              <InputCheck
                type="switch"
                inline
                label="Would you like to update the existing user?"
                nameOveride="shouldUpdateExistingUser"
              />
            </Col>
          </Row>
          {values.shouldUpdateExistingUser && (
            <Row>
              <Col>
                <Alert variant="warning">
                  <AlertHeading className="h6">
                    <b>You are about to update this contact across all jobs</b>
                  </AlertHeading>
                  <p className="mb-0">
                    This change will apply to every job where this contact is
                    listed. The previous information will be replaced, and the
                    update cannot be undone.
                  </p>
                </Alert>
              </Col>
            </Row>
          )}
        </>
      )}
      {!canEdit && (
        <>
          <Row>
            <Col>
              <Alert variant="warning">
                <AlertHeading>
                  <div>Unable to edit user information</div>
                </AlertHeading>
                <p>
                  The contact user doesn't allow updating their information. You
                  may only change the user to a new one.
                </p>
              </Alert>
            </Col>
          </Row>

          <Row>
            <Col>
              <InputCheck
                type="switch"
                inline
                label="Would you like to change this user?"
                nameOveride="changeUser"
              />
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="First Name"
            floatingLabel
            nameOveride="firstName"
            disabled={!canEdit && !values.changeUser}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="text"
            label="Last Name"
            floatingLabel
            nameOveride="lastName"
            disabled={!canEdit && !values.changeUser}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputPhone
            label="Phone"
            floatingLabel
            nameOveride="phone"
            disabled={!canEdit && !values.changeUser}
            required={!values.email}
          />
        </Col>
      </Row>
      <Row>
        <Col className="px-2 pb-3">
          <InputText
            type="email"
            label="Email"
            floatingLabel
            nameOveride="email"
            disabled={!canEdit && !values.changeUser}
            required={!values.phone}
          />
        </Col>
      </Row>
    </Stack>
  );
};

interface Props extends AnimalHeadDetailsSectionProps {
  cutsheetInformation?: CutsheetInformationFragment | null;
  cutsheetInformationIdx: number;
  animalHeadIdx?: number;
  processingJob?: ProcessingJobByIdQuery['findProcessorSchedulingById'];
  pageType?: 'cutsheets-tab' | 'head-detail';
  onEditCutsheetClicked?: () => void;
}

export function AnimalHeadCutsheetInformation({
  processingJobId,
  processingJob,
  animalHead,
  animalHeadIdx,
  cutsheetInformation,
  cutsheetInformationIdx,
  pageType = 'head-detail',
  updateAnimalHead,
  onEditCutsheetClicked,
}: Props) {
  const { save, ask } = useModal();
  const { push } = useToastr();

  const [sendNotification, sendNotificationOp] =
    useProcessorSchedulingNotificationSendMutation({
      refetchQueries: [
        {
          query: ProcessingJobByIdDocument,
          variables: {
            jobId: processingJobId,
            includeNotificationHistory: true,
            includeProcessorSettings: true,
          },
        },
      ],
    });

  const [generateCutsheetPdf, generateCutsheetPdfOp] =
    useProcessorSchedulingGenerateCutsheetRequestMutation();

  const heading = useMemo(() => {
    const isCutsheetsTab = pageType === 'cutsheets-tab';
    const isHeadDetail = pageType === 'head-detail';

    const splitPart = animalSplitPartHelper(
      cutsheetInformation?.splitPart ?? 'N/A',
    );

    const inspectionLevelLabel = animalHead?.inspectionLevel
      ? inspectionLevelHelper(animalHead.inspectionLevel).label
      : '';

    const animalSpeciesLabel = processingJob?.animalSpecies
      ? `${animalSpeciesHelper(processingJob.animalSpecies).label} ${
          typeof animalHeadIdx !== 'undefined' ? `#${animalHeadIdx + 1}` : ''
        }`
      : '';

    const splitTypeValue =
      animalHead?.splitType !== 'whole'
        ? `${
            cutsheetInformationIdx +
            (animalHead?.splitType === 'half_and_two_quarters' &&
            splitPart.value === 'quarter'
              ? 0
              : 1)
          }`
        : '';

    const pageTitle = `${splitPart.label} ${
      isHeadDetail ? '#' : ''
    }${splitTypeValue}`;

    return [
      ...(isCutsheetsTab
        ? [inspectionLevelLabel, animalSpeciesLabel].filter(Boolean)
        : []),
      pageTitle,
    ].join(' ');
  }, [
    cutsheetInformation?.splitPart,
    cutsheetInformationIdx,
    animalHead?.splitType,
    animalHead?.inspectionLevel,
    animalHeadIdx,
    pageType,
    processingJob?.animalSpecies,
  ]);

  const handleViewCutsheet = useCallback(async () => {
    debugger;

    if (processingJobId && cutsheetInformation?._id) {
      try {
        const response = await generateCutsheetPdf({
          variables: {
            processorSchedulingId: processingJobId,
            cutsheetRequestId: cutsheetInformation._id,
          },
        });

        if (
          response.data?.processorSchedulingGenerateCutsheetRequest?.fileUrl
        ) {
          downloadFromPresignedUrl(
            response.data?.processorSchedulingGenerateCutsheetRequest?.fileUrl,
          );
        } else if (
          response.data?.processorSchedulingGenerateCutsheetRequest?.file
        ) {
          downloadFromBase64Encoded(
            response.data?.processorSchedulingGenerateCutsheetRequest.file,
            response.data?.processorSchedulingGenerateCutsheetRequest
              ?.filename ?? 'Cutsheet.pdf',
          );
        }
      } catch (error) {
        push({
          title: 'Error',
          body: (error as string).toString(),
          bg: 'danger',
          delay: 4000,
        });
      }
    }
  }, [processingJobId, cutsheetInformation?._id, generateCutsheetPdf, push]);

  const handleEditCutsheet = useCallback(() => {
    if (processingJobId) {
      if (pageType === 'cutsheets-tab') {
        onEditCutsheetClicked?.();
      } else {
        const url = `/scheduling/${processingJobId}/cutsheets`;
        window.location.href = url;
      }
    }
  }, [onEditCutsheetClicked, pageType, processingJobId]);

  const handleEditContact = useCallback(() => {
    const initialValues = {
      firstName: cutsheetInformation?.contactUser?.first_name,
      lastName: cutsheetInformation?.contactUser?.last_name ?? undefined,
      phone: cutsheetInformation?.contactUser?.phone ?? undefined,
      email: cutsheetInformation?.contactUser?.email ?? undefined,
      shouldUpdateExistingUser: false,
    };

    return save<EditContactDetailsFormData>({
      type: 'save',
      title: 'Edit Contact Details',
      icon: faFileEdit,
      initialValues: { ...initialValues },
      body: (
        <EditContactDetailsForm
          canEdit={
            cutsheetInformation?.contactUser?.role === EnumUserRole.Basic
          }
        />
      ),
      onSubmit: async (values) => {
        await updateAnimalHead({
          variables: {
            processorSchedulingId: processingJobId,
            animalHeadId: animalHead?._id,
            record: {
              cutsheetInformation: {
                _id: cutsheetInformation?._id,
                shouldUpdateExistingUser: values.shouldUpdateExistingUser,
                contactUser: {
                  _id: cutsheetInformation?.contactUser?._id,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  phone: values.phone ?? undefined,
                  email: values.email ?? undefined,
                },
              },
            },
          },
        });

        push({
          title: 'Processing Job Updated',
          body: `The contact user has been updated on the processing job.`,
          bg: 'success',
          delay: 4000,
        });
      },
    });
  }, [
    save,
    cutsheetInformation?.contactUser,
    cutsheetInformation?._id,
    processingJobId,
    animalHead?._id,
    updateAnimalHead,
    push,
  ]);

  const handleSendCutsheetEmail = useCallback(() => {
    const fullName = formatFullName(
      cutsheetInformation?.contactUser?.first_name ?? '',
      cutsheetInformation?.contactUser?.last_name,
    );

    return ask({
      type: 'ask',
      title: cutsheetInformation?.contactUser?.email
        ? 'Confirm Email Send'
        : 'Confirm SMS Send',
      body: `Are you sure you want to send the add cutsheet notification to ${fullName} at ${
        cutsheetInformation?.contactUser?.email
          ? cutsheetInformation.contactUser.email
          : cutsheetInformation?.contactUser?.phone
      }`,
      onConfirm: async () => {
        await sendNotification({
          variables: {
            type: 'addCutsheets',
            notificationRequest: {
              scheduledJobId: processingJobId,
              contactUserId: cutsheetInformation?.contactUser?._id,
            },
          },
        });
        push({
          title: 'Notification Sent',
          body: `The add cutsheet notification has been sent.`,
          bg: 'success',
          delay: 4000,
        });
      },
    });
  }, [
    ask,
    cutsheetInformation?.contactUser,
    sendNotification,
    processingJobId,
    push,
  ]);

  return (
    <Card
      body
      className="h-100 animal-head-cutsheet-information bg-body-secondary rounded-4"
    >
      <span className="fw-bold fs-5">{heading}</span>
      <Card.Body className="p-0 pt-2">
        <div className="d-block text-break mb-3 bg-body p-3 rounded-4">
          <DataDetailListMinimal
            tableProps={{
              className: 'm-0',
              style: {
                '--bs-table-bg': 'transparent',
              } as CSSProperties,
            }}
            heading={
              <Stack
                direction="horizontal"
                className="d-flex flex-wrap justify-content-between gap-2"
              >
                <div className="fw-bolder fs-6">Contact Information</div>
                <Stack gap={2} direction="horizontal">
                  {(cutsheetInformation?.contactUser?.email ||
                    cutsheetInformation?.contactUser?.phone) && (
                    <Button
                      size="sm"
                      content="Send Cutsheet Reminder"
                      onClick={handleSendCutsheetEmail}
                      icon={faEnvelope}
                    />
                  )}

                  <Button
                    size="sm"
                    content="Edit Contact"
                    className="ms-auto"
                    onClick={handleEditContact}
                    icon={faEdit}
                  />
                </Stack>
              </Stack>
            }
            rows={[
              {
                label: 'Name',
                value: formatFullName(
                  cutsheetInformation?.contactUser?.first_name ?? '',
                  cutsheetInformation?.contactUser?.last_name,
                ),
              },
              {
                label: 'Phone',
                value: cutsheetInformation?.contactUser?.phone ? (
                  <PhoneDisplay phone={cutsheetInformation.contactUser.phone} />
                ) : undefined,
              },
              {
                label: 'Email',
                value: cutsheetInformation?.contactUser?.email,
              },
            ]}
          />
        </div>
        <div className="d-block text-break bg-body p-3 rounded-4">
          <DataDetailListMinimal
            tableProps={{
              className: 'm-0',
              style: {
                '--bs-table-bg': 'transparent',
              } as CSSProperties,
            }}
            heading={
              <Stack
                direction="horizontal"
                className="d-flex flex-wrap justify-content-between gap-2"
              >
                <div className="fw-bolder fs-6">Cutsheet Information</div>
                <Stack gap={2} direction="horizontal">
                  <Button
                    size="sm"
                    content="Download Cutsheet"
                    className="btn-download-cutsheet"
                    disabled={
                      !cutsheetInformation?.cutsheet?._id ||
                      generateCutsheetPdfOp.loading
                    }
                    isLoading={generateCutsheetPdfOp.loading}
                    onClick={handleViewCutsheet}
                    icon={faFileDownload}
                  />
                  <Button
                    size="sm"
                    content={`${
                      cutsheetInformation?.cutsheet?._id ? 'Edit' : 'Add'
                    } Cutsheet`}
                    onClick={handleEditCutsheet}
                    icon={faEdit}
                    className="btn-edit-cutsheet"
                  />
                </Stack>
              </Stack>
            }
            rows={[
              {
                label: 'Cutsheet',
                className: 'cutsheet-name',
                value: cutsheetInformation?.cutsheet?.name,
              },
              {
                label: 'Is Custom',
                value:
                  cutsheetInformation?.cutsheet?.type === 'user' ? 'Yes' : 'No',
              },
              {
                label: 'Price/lb',
                value: cutsheetInformation?.cutsheet?.pricePerPound ? (
                  <MoneyDisplay
                    value={cutsheetInformation?.cutsheet?.pricePerPound}
                  />
                ) : undefined,
              },
              {
                label: 'Notes',
                value: cutsheetInformation?.notes,
                className: 'cutsheet-notes',
              },
            ]}
          />
        </div>
      </Card.Body>
    </Card>
  );
}
