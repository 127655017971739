import { Button, useModal, useToastr } from '@farmshare/ui-components';
import { RecursivePartial } from '@farmshare/utils';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FormikProps } from 'formik';
import { concat, filter, map, omit, set } from 'lodash';
import { useRecoilValue } from 'recoil';
import { userState } from 'state';

import {
  Cutsheet,
  CutsheetCreateOneMutationVariables,
  EnumCutsheetAnimalSpecies,
  EnumCutsheetType,
  EnumProcessorSchedulingAnimalSpecies,
  ProcessorCapability,
  ProcessorSettings,
  useCutsheetCreateOneMutation,
  ViewSchedulingCreateQuery,
  useCutsheetSoftDeleteMutation,
} from 'lib/graphql';

import {
  ExtrasModel,
  TrimModel,
} from 'pages/processor/cutsheets/_views/cutsheet-modal';

import {
  SchedulingCustomCutsheetModal,
  SchedulingCustomCutsheetModalForm,
} from './custom-cutsheet-modal';

interface CustomizeCutsheetButtonProps {
  fP: FormikProps<SchedulingCustomCutsheetModalForm>;
  cutsheet?: Cutsheet;
  cutsheetId: string | undefined;
  userCutsheets: ViewSchedulingCreateQuery['cutsheetMany'];
  setUserCutsheets: (
    newUserCutsheets: ViewSchedulingCreateQuery['cutsheetMany'],
  ) => void;
  capabilities?: RecursivePartial<ProcessorCapability>[];
  animalSpecies?: EnumProcessorSchedulingAnimalSpecies;
  settings?: RecursivePartial<ProcessorSettings>;
}

export function CustomizeCutsheetButton({
  fP,
  capabilities,
  animalSpecies,
  settings,
  cutsheet,
  cutsheetId,
  setUserCutsheets,
  userCutsheets,
}: CustomizeCutsheetButtonProps) {
  const user = useRecoilValue(userState);
  const { push } = useToastr();
  const { save } = useModal();

  const [createCutsheet, createCutsheetOp] = useCutsheetCreateOneMutation();
  const [deleteCutsheet, deleteCutsheetOp] = useCutsheetSoftDeleteMutation();

  return (
    <Button
      content="Customize Cutsheet"
      icon={faEdit}
      onClick={() => {
        fP.setFieldValue('name', `${fP.values.name} - Copy`);
        save<SchedulingCustomCutsheetModalForm>({
          type: 'save',
          title: 'Custom Cutsheet',
          // the initial values don't need to be passed in here, they are already set in the formik form but are required for the save modal
          initialValues: {
            name: `${fP.values.name} - Copy`,
            cuts: fP.values.cuts,
            extras: fP.values.extras,
            trim: fP.values.trim,
            blockedBy: fP.values.blockedBy,
            selectedSpecifications: {},
          },
          isLoading: createCutsheetOp.loading || deleteCutsheetOp.loading,
          icon: faPlus,
          fullscreen: true,
          body: (fP) => (
            <SchedulingCustomCutsheetModal
              fP={fP}
              capabilities={capabilities}
              animalSpecies={animalSpecies}
              settings={settings}
              cutsheet={cutsheet}
            />
          ),
          onSubmit: async ({
            name,
            cuts,
            selectedSpecifications,
            activeSpecification,
            extras,
            trim,
            notes,
            inspectionLevel,
          }) => {
            const formattedExtras = animalSpecies
              ? (map(
                  filter(extras?.[animalSpecies], (extra) => extra.isActive) ||
                    [],
                  (extra) => ({
                    ...omit(extra, ['__typename']),
                    quantity: Number(extra.quantity),
                  }),
                ) as ExtrasModel[])
              : [];
            const getFormattedTrim = () => {
              if (!animalSpecies) {
                return [];
              }
              // if there is an isAllTrim option, only return that
              const isAllTrim = filter(
                trim?.[animalSpecies],
                (t) => t.isAllTrim,
              );
              if (isAllTrim.length > 0) {
                return map(isAllTrim, (trim) => ({
                  ...omit(trim, ['__typename', 'disabled']),
                  quantity: Number(trim.quantity),
                })) as TrimModel[];
              }
              return map(
                filter(trim?.[animalSpecies], (trim) => trim.isActive) || [],
                (trim) => ({
                  ...omit(trim, ['__typename', 'disabled']),
                  quantity: Number(trim.quantity),
                }),
              ) as TrimModel[];
            };
            const variables: CutsheetCreateOneMutationVariables = {
              record: {
                type: user ? EnumCutsheetType.User : EnumCutsheetType.Orphan,
                animalSpecies:
                  animalSpecies as unknown as EnumCutsheetAnimalSpecies,
                inspectionLevels: [inspectionLevel!],
                primalCuts: map(cuts, (c) => c.value),
                user: user?._id,
                name,
                selectedSpecifications,
                activeSpecification,
                extras: formattedExtras,
                trim: getFormattedTrim(),
                notes,
              },
            };
            const response = await createCutsheet({
              variables,
            });
            if (response.data?.cutsheetCreateOne?.record) {
              // soft delete the original cutsheet
              if (cutsheetId) {
                await deleteCutsheet({
                  variables: { cutsheetId },
                });
              }
              // remove the original cutsheet from the userCutsheets array and add the new cutsheet
              setUserCutsheets(
                concat(
                  filter(userCutsheets, (c) => c._id !== cutsheetId),
                  [response.data.cutsheetCreateOne.record as Cutsheet],
                ),
              );
            }
          },
          validate: (values) => {
            const errors: Record<string, any> = {};
            if (!animalSpecies) {
              return errors;
            }
            if (
              !values.inspectionLevel ||
              (values.inspectionLevel as string) === 'Select one...'
            ) {
              set(
                errors,
                'inspectionLevel',
                'Please select one inspection level.',
              );
            }
            // validate quantity for active extras
            const extrasOptions = values.extras?.[animalSpecies] ?? [];
            if (extrasOptions.length > 0) {
              const animalType = animalSpecies;
              for (let i = 0; i < extrasOptions.length; i++) {
                if (
                  extrasOptions[i].isActive &&
                  (extrasOptions[i].quantity ?? 0) <
                    (extrasOptions[i].minLbs ?? 0)
                ) {
                  const extraErrors = errors.extras?.[animalType] || [];
                  extraErrors[i] = {
                    ...(extraErrors[i] ?? {}),
                    quantity: `Min quantity is ${extrasOptions[i].minLbs} lbs`,
                  };
                  errors.extras = {
                    ...errors?.extras,
                    [animalType]: extraErrors,
                  };
                }
              }
            }
            // check if rank is unique for active trims
            const trimOptions = animalSpecies
              ? values.trim?.[animalSpecies] ?? []
              : [];
            if (trimOptions.length > 0) {
              const animalType = animalSpecies;
              for (let i = 0; i < trimOptions.length; i++) {
                // validate quantity for active trims
                if (
                  trimOptions[i].isActive &&
                  !trimOptions[i].isAllTrim &&
                  (trimOptions[i].quantity ?? 0) < (trimOptions[i].minLbs ?? 0)
                ) {
                  const trimErrors = errors.trim?.[animalType] || [];
                  trimErrors[i] = {
                    ...(trimErrors[i] ?? {}),
                    quantity: `Min quantity is ${trimOptions[i].minLbs} lbs`,
                  };
                  errors.trim = {
                    ...errors?.trim,
                    [animalType]: trimErrors,
                  };
                }
                // check if rank is unique for active trims
                const ranks = map(
                  filter(trimOptions, (t) => t.isActive),
                  (t) => t.rank,
                );
                if (ranks.length !== new Set(ranks).size) {
                  errors.trim = {
                    ...errors?.trim,
                    [animalType]: [
                      ...map(
                        filter(trimOptions, (t) => t.isActive),
                        (t) => ({
                          rank: 'Rank must be unique',
                        }),
                      ),
                    ],
                  };
                }
              }
            }
            // check if rank is greater than 0 for active trims
            if (trimOptions.length > 0 && animalSpecies) {
              const animalType = animalSpecies;
              for (let i = 0; i < trimOptions.length; i++) {
                if (trimOptions[i].isActive && trimOptions[i].rank < 1) {
                  const trimErrors = errors.trim?.[animalType] || [];
                  trimErrors[i] = {
                    ...(trimErrors[i] ?? {}),
                    rank: 'Rank must be greater than 0',
                  };
                  errors.trim = {
                    ...errors?.trim,
                    [animalType]: trimErrors,
                  };
                }
              }
            }
            return errors;
          },
        });
      }}
    />
  );
}
