import { EnumProcessorSchedulingStatus } from 'lib/graphql';

export const renderStatusText = (statusValue: string) => {
  switch (statusValue) {
    case EnumProcessorSchedulingStatus.Requested:
    case EnumProcessorSchedulingStatus.Initial:
      return 'Requested';
    case EnumProcessorSchedulingStatus.Scheduled:
      return 'Scheduled';
    case EnumProcessorSchedulingStatus.DroppedOff:
      return 'Dropped Off';
    case EnumProcessorSchedulingStatus.Killed:
      return 'Killed';
    case EnumProcessorSchedulingStatus.Aging:
      return 'Aging';
    case EnumProcessorSchedulingStatus.Curing:
      return 'Curing';
    case EnumProcessorSchedulingStatus.Invoicing:
      return 'Invoicing';
    case EnumProcessorSchedulingStatus.ReadyPayAtPickup:
      return 'Ready, Pay At Pickup';
    case EnumProcessorSchedulingStatus.InvoicePaid:
      return 'Invoice Paid';
    case EnumProcessorSchedulingStatus.Completed:
      return 'Completed';
    case EnumProcessorSchedulingStatus.CancelledByProcessor:
      return 'Cancelled by Processor';
    case EnumProcessorSchedulingStatus.CancelledByProducer:
      return 'Cancelled by Producer';
    case EnumProcessorSchedulingStatus.DepositFailed:
      return 'Deposit Failed';
    case EnumProcessorSchedulingStatus.CheckoutExpired:
      return 'Checkout Expired';
    case EnumProcessorSchedulingStatus.DeniedByProcessor:
      return 'Denied';
    default:
      return 'Unknown';
  }
};

export enum EnumProcessorStatusGroups {
  Requested = 'Requested',
  Scheduled = 'Scheduled',
  In_Progress = 'In Progress',
  Completed = 'Completed',
}

export enum EnumProcessorStatusGroupColor {
  Requested = '#CC7351',
  Scheduled = '#EE4266',
  In_Progress = '#f28800',
  Completed = '#006F35',
}

export const ALL_SHOWN_STATUSES = [
  EnumProcessorSchedulingStatus.Requested,
  EnumProcessorSchedulingStatus.Scheduled,
  EnumProcessorSchedulingStatus.DroppedOff,
  EnumProcessorSchedulingStatus.Killed,
  EnumProcessorSchedulingStatus.Aging,
  EnumProcessorSchedulingStatus.Curing,
  EnumProcessorSchedulingStatus.Invoicing,
  EnumProcessorSchedulingStatus.ReadyPayAtPickup,
  EnumProcessorSchedulingStatus.InvoicePaid,
  EnumProcessorSchedulingStatus.Completed,
];

export const IN_PROGRESS_STATUSES = [
  EnumProcessorSchedulingStatus.DroppedOff,
  EnumProcessorSchedulingStatus.Killed,
  EnumProcessorSchedulingStatus.Aging,
  EnumProcessorSchedulingStatus.Curing,
  EnumProcessorSchedulingStatus.Invoicing,
  EnumProcessorSchedulingStatus.InvoicePaid,
  EnumProcessorSchedulingStatus.ReadyPayAtPickup,
  EnumProcessorSchedulingStatus.Scheduled,
];

export const COMPLETED_STATUSES = [
  EnumProcessorSchedulingStatus.Completed,
  EnumProcessorSchedulingStatus.CancelledByProcessor,
  EnumProcessorSchedulingStatus.CancelledByProducer,
  EnumProcessorSchedulingStatus.DepositFailed,
  EnumProcessorSchedulingStatus.CheckoutExpired,
  EnumProcessorSchedulingStatus.DeniedByProcessor,
];

export const getStatusColor = (statusValue: string) => {
  switch (statusValue) {
    case EnumProcessorSchedulingStatus.Requested:
    case EnumProcessorSchedulingStatus.Initial:
      return EnumProcessorStatusGroupColor.Requested;

    case EnumProcessorSchedulingStatus.Scheduled:
      return EnumProcessorStatusGroupColor.Scheduled;

    case EnumProcessorSchedulingStatus.DroppedOff:
    case EnumProcessorSchedulingStatus.Killed:
    case EnumProcessorSchedulingStatus.Aging:
    case EnumProcessorSchedulingStatus.Curing:
    case EnumProcessorSchedulingStatus.Invoicing:
    case EnumProcessorSchedulingStatus.ReadyPayAtPickup:
    case EnumProcessorSchedulingStatus.InvoicePaid:
      return EnumProcessorStatusGroupColor.In_Progress;

    case EnumProcessorSchedulingStatus.Completed:
    case EnumProcessorSchedulingStatus.CancelledByProcessor:
    case EnumProcessorSchedulingStatus.CancelledByProducer:
    case EnumProcessorSchedulingStatus.DepositFailed:
    case EnumProcessorSchedulingStatus.CheckoutExpired:
    case EnumProcessorSchedulingStatus.DeniedByProcessor:
      return EnumProcessorStatusGroupColor.Completed;

    default:
      return EnumProcessorStatusGroupColor.Completed;
  }
};

export const getGroupColor = (statusValue: string) => {
  switch (statusValue) {
    case EnumProcessorStatusGroups.Requested:
      return EnumProcessorStatusGroupColor.Requested;

    case EnumProcessorStatusGroups.Scheduled:
      return EnumProcessorStatusGroupColor.Scheduled;

    case EnumProcessorStatusGroups.In_Progress:
      return EnumProcessorStatusGroupColor.In_Progress;

    case EnumProcessorStatusGroups.Completed:
      return EnumProcessorStatusGroupColor.Completed;

    default:
      return EnumProcessorStatusGroupColor.Completed;
  }
};
